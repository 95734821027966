import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreationAccountMeasure, PostMeasureInput } from '@/api/creation';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { Pagenation, PageSize } from '@/components/Elements/Pagenation/Pagenation';
import { getImageUrl } from '@/utils/image';

import { useAccount } from './AccountContext';
import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';

export const ProjectList = () => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<PageSize>(10);
  const [projectName, setProjectName] = useState('');

  const {
    accounts,
    selectedAccount,
    selectedProject,
    projects,
    updateProjects,
    projectPageData,
    addProject,
    selectProject: addSelectProject,
    changeSelectedProject,
    selectedPost,
  } = useAccount();

  const handleClick = () => {
    setOpen(true);
  };

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handleSubmit = () => {
    const newProject: PostMeasureInput = {
      name: projectName,
    };

    addProject('12345678901234567890ABCDEF', newProject);
    addSelectProject(projectName);
    console.log('---ProjectList.tsx---');
    console.log('新しく作成されたproject:（newProject）:', newProject);
    // setProject(projectName);
    setProjectName(projectName);
    setOpen(false);

    // navigate(`/PRST_production_management/${measureId}`); // 遷移時にmeasureIdを渡す
    navigate(`/PRST_production_management/ABCDEFGH1234567890ABCDEFGH?accountId=${selectedAccount?.id}`); // 遷移時にmeasureIdを渡す
  };

  useEffect(() => {
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('作成されたアカウント全て（accounts）:', accounts);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
  }, [selectedAccount, accounts, selectedProject, selectedPost]);

  useEffect(() => {
    if (selectedAccount) {
      updateProjects(selectedAccount.id, perPage, currentPage, 'desc', 'createdAt');
    }
  }, [currentPage, perPage]);

  const selectProject = (project: CreationAccountMeasure) => () => {
    changeSelectedProject(selectedAccount?.id || '', project.id);

    // navigate(`/PRST_production_management/${measureId}`); // 遷移時にmeasureIdを渡す
    navigate(`/PRST_production_management/ABCDEFGH1234567890ABCDEFGH?accountId=${selectedAccount?.id}`); // 遷移時にmeasureIdを渡す
  };

  const replaceDate = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  const ProjectAddModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[228px] w-[512px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[180px] w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[110px] w-[464px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">施策を追加</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-[62px] w-[440px]">
                        <div>
                          <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                            施策名
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              value={projectName}
                              onChange={handleProjectNameChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleSubmit}
                      >
                        登録する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  return (
    <>
      <div className="w-[940px] bg-gray-50 px-[24px] py-[16px]">
        <div className="w-[840px]">
          <div className="mb-[16px] flex h-[38px] w-[840px] justify-between">
            <p className="text-base font-semibold leading-7 text-gray-700">施策一覧</p>
            <Button
              startIcon={<PlusImage className="h-[20px] w-[20px]" />}
              size="md"
              className="whitespace-nowrap text-sm font-medium leading-5"
              style={{
                paddingTop: '9px',
                paddingBottom: '9px',
                paddingLeft: '10px',
                paddingRight: '12px',
              }}
              onClick={handleClick}
            >
              施策を追加
            </Button>
          </div>
          <div className="h-[525px] rounded bg-[#FFFFFF] px-[19px] pt-[24px]">
            <table className="w-[840px]">
              {/* 1列目 */}
              <thead className="flex">
                <tr>
                  <th scope="col" className="sticky w-[191px] py-[10px] pl-[16px] text-left">
                    <span className="text-xs font-normal leading-4 tracking-wider text-gray-700">施策名</span>
                  </th>
                  <th scope="col" className="sticky w-[165px] py-[10px] pl-[16px] text-left">
                    <span className="text-xs font-normal leading-4 tracking-wider text-gray-700">作成日</span>
                  </th>
                  <th scope="col" className="sticky w-[92px] py-[10px] pl-[16px] text-left">
                    <span className="text-xs font-normal leading-4 tracking-wider text-gray-700">投稿予定数</span>
                  </th>
                  <th scope="col" className="sticky w-[92px] py-[10px] pl-[16px] text-left">
                    <span className="text-xs font-normal leading-4 tracking-wider text-gray-700">投稿完了数</span>
                  </th>
                  <th scope="col" className="sticky w-[145px] py-[10px] pl-[16px] text-left">
                    <span className="text-xs font-normal leading-4 tracking-wider text-gray-700">クリエイター</span>
                  </th>
                  <th scope="col" className="sticky w-[118px] py-[10px] pl-[16px] text-left">
                    <span className="text-xs font-normal leading-4 tracking-wider text-gray-700"></span>
                  </th>
                </tr>
              </thead>
              {/* 2列目 */}
              <tbody className="flex h-[356px] w-[803px] flex-col overflow-x-hidden  overflow-y-scroll bg-white">
                {projects.map((project, index) => (
                  <tr key={project.name} className={`cursor-pointer ${index % 2 == 0 ? 'bg-gray-50' : 'bg-white'}`}>
                    <td>
                      <button onClick={selectProject(project)} className="h-[64px] w-[191px]">
                        <div className="flex items-center">
                          <div className="py-6 pl-4">
                            <div className="text-sm font-normal leading-5 text-gray-700">{project.name}</div>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button onClick={selectProject(project)} className="h-[64px] w-[165px]">
                        <div className="flex items-center">
                          <div className="py-6 pl-4">
                            <div className="text-sm font-normal leading-5 text-gray-700">
                              {replaceDate(project.createdAt)}
                            </div>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button onClick={selectProject(project)} className="h-[64px] w-[92px]">
                        <div className="flex items-center">
                          <div className="py-6 pl-4">
                            <div className="text-sm font-normal leading-5 text-gray-700">{project.postsPlan}</div>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button onClick={selectProject(project)} className="h-[64px] w-[92px]">
                        <div className="flex items-center">
                          <div className="py-6 pl-4">
                            <div className="text-sm font-normal leading-5 text-gray-700">{project.postsResult}</div>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button onClick={selectProject(project)} className="h-[64px] w-[145px]">
                        <div className="flex items-center">
                          <div className="py-6 pl-4">
                            <div className="text-sm font-normal leading-5 text-gray-700">
                              <img
                                src={getImageUrl(project.influencerSnsAccounts.rows[0].thumbnail || '')}
                                alt="thumbnail"
                                className="h-[24px] w-[24px] rounded-full"
                              />
                              {/* <p className="h-[20px] w-[56px] rounded bg-[#F3F4F6] align-middle text-xs font-medium text-[#1F2937]">
                              未招待
                            </p> */}
                            </div>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button onClick={selectProject(project)} className="h-[64px] w-[118px]">
                        <div className="flex items-center">
                          <div className="py-6 pl-4">
                            <div className="text-sm font-normal leading-5 text-gray-700">
                              <DotsHorizontalIcon width={24} height={24} />
                            </div>
                          </div>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {projectPageData && projects.length && (
              <Pagenation
                currentPageNumber={currentPage}
                currentPageSize={perPage}
                togalDataCount={projectPageData.totalRows}
                totalPageNumber={projectPageData.totalPages}
                setPageNumber={setCurrentPage}
                setPageSize={setPerPage}
              />
            )}
          </div>
        </div>
      </div>
      <div className="relative">{ProjectAddModal()}</div>
    </>
  );
};
