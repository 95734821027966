import { MainLayout } from '@/components/Layout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PostDetailView } from '../components/PostDetailView';

export const ManagementProject = () => {
  return (
    <>
      <div className="flex">
        <MainLayout
          sidebarProps={{
            firstSideBarselected: firstSidebarSelect.calendar,
            secondSideBarselected: secondSidebarSelect.prstPost,
          }}
        >
          <PostDetailView />
        </MainLayout>
      </div>
    </>
  );
};
