import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

import { ProjectType } from '@/api/project';
import Photo from '@/assets/images/Photo.png';
import { FileUploadedTile } from '@/components/Elements/FileUpload/FileUpload';
import { UPLOAD_FILE_TYPES } from '@/config/index';
import { getImageUrlIfRelative } from '@/utils/image';

export interface DetailImagesProps {
  open: boolean;
  images: {
    id: string; // image id
    objectKey: string; // image url or video url
  }[];
}

interface DisplayImage {
  isSaved: boolean;
  url: string;
}

interface Props {
  projectType: ProjectType;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailImages: {
    id: string;
    objectKey: string;
  }[];
  PutPostMedia: (uploads?: File[], deletes?: string[]) => Promise<void>;
}

export const DetailImages = (props: Props) => {
  const [displayImages, setDisplayImages] = useState<DisplayImage[]>(
    props.detailImages.map((image) => {
      return {
        isSaved: true,
        url: image.objectKey,
      };
    })
  );
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [uploadFileError, setUploadFileError] = useState<string | null>(null);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);

  useEffect(() => {
    setDisplayImages(
      props.detailImages.map((image) => {
        return {
          isSaved: true,
          url: image.objectKey,
        };
      })
    );
  }, [props.detailImages]);

  const handleChange = (files: File[]) => {
    // ファイルは３つまで設定可能なので、設定可能ファイル数を制限する
    if (displayImages.length > 3) return;
    files.forEach((file: File) => {
      if (file.size > 30000000) return; //30 MB 以上のファイルは設定できない
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const data = reader.result?.toString();
        if (data) {
          setDisplayImages((prev) => [
            ...prev,
            {
              isSaved: false,
              url: data,
            },
          ]);
        }
        setUploadFiles((prev) => [...prev, file]);
      });
      if (file) {
        reader.readAsDataURL(file);
      }
    });
    setUploadFileError(null);
  };

  const onCloseDetailImageModal = async (isSave: boolean) => {
    if (isSave && (uploadFiles.length === 0 || displayImages.length === 0)) {
      setUploadFileError('詳細画像は必須項目です。');
      return;
    }
    if (deleteIds.length === 0) {
      await props.PutPostMedia(uploadFiles, undefined);
    } else {
      await props.PutPostMedia(uploadFiles, deleteIds);
    }

    props.setIsOpen(false);
    setDisplayImages(
      props.detailImages.map((image) => {
        return {
          isSaved: true,
          url: image.objectKey,
        };
      })
    );
    setUploadFileError(null);
    setUploadFiles([]);
    setDeleteIds([]);
  };

  const handleDeleteImage = (index: number) => {
    const deleteId = displayImages[index].isSaved ? props.detailImages[index].id : '';
    if (deleteId !== '' && !deleteIds.includes(deleteId)) {
      setDeleteIds((prev) => [...prev, deleteId]);
    }
    if (!displayImages[index].isSaved) {
      setUploadFiles((prev) => prev.filter((_, i) => i !== index - props.detailImages.length));
    }
    setDisplayImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onCloseDetailImageModal(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[600px] w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      onCloseDetailImageModal(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="px-5 text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[656px] text-lg font-medium leading-6 text-gray-900">
                      詳細画像*
                    </Dialog.Title>
                    <div className="text-left">
                      <p className="text-sm text-gray-500">「※」が付いている項目は必須項目です</p>
                    </div>
                    <div className="mb-[50px]">
                      <p className="pt-[8px] text-sm font-normal leading-5 text-gray-500">
                        案件の詳細を伝える画像になります。最大3枚まで掲載できます。
                      </p>
                    </div>

                    <div className="col-span-full">
                      <Dropzone onDrop={(acceptedFiles) => handleChange(acceptedFiles)} accept={UPLOAD_FILE_TYPES}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="flex w-full justify-center rounded-lg border-2 border-dashed border-gray-900/25 py-[48px]"
                            {...getRootProps()}
                          >
                            <div className="text-center">
                              <img className="mx-auto h-[36px] w-[36px] text-gray-400" src={Photo} alt="" />
                              <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                <p className="pl-1 text-sm font-medium">ドラッグ&ドロップもしくは</p>
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer rounded-md bg-white text-sm font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                  style={{ color: '#007CC2' }}
                                >
                                  <span>ファイルをアップロード</span>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                    {...getInputProps()}
                                  />
                                </label>
                              </div>

                              {props.projectType === ProjectType.EmeraldPost ? (
                                <p className="text-xs leading-5 text-gray-600">
                                  対応ファイルは30MB以内のPNG, JPG, GIF, または200MB以内のmp4
                                </p>
                              ) : (
                                <p className="text-xs leading-5 text-gray-600">対応ファイルは30MB以内のPNG, JPG, GIF</p>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div className="flex flex-row justify-between pt-8">
                      {displayImages.length > 0 ? (
                        <div className="relative">
                          <img
                            src={
                              displayImages[0].isSaved
                                ? getImageUrlIfRelative(displayImages[0].url)
                                : displayImages[0].url
                            }
                            alt="preview"
                            className="h-[112px] w-[192px]"
                          />
                          <IconButton
                            onClick={() => handleDeleteImage(0)}
                            sx={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              right: '4px',
                              top: '4px',
                              opacity: '0.8',
                              background: '#F3F4F6',
                              borderRadius: '4px',
                            }}
                          >
                            <CloseIcon sx={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </div>
                      ) : (
                        <FileUploadedTile className="h-[112px] w-[192px]" height={36} width={36}></FileUploadedTile>
                      )}
                      {displayImages.length > 1 ? (
                        <div className="relative">
                          <img
                            src={
                              displayImages[1].isSaved
                                ? getImageUrlIfRelative(displayImages[1].url)
                                : displayImages[1].url
                            }
                            alt="preview"
                            className="h-[112px] w-[192px]"
                          />
                          <IconButton
                            onClick={() => handleDeleteImage(1)}
                            sx={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              right: '4px',
                              top: '4px',
                              opacity: '0.8',
                              background: '#F3F4F6',
                              borderRadius: '4px',
                            }}
                          >
                            <CloseIcon sx={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </div>
                      ) : (
                        <FileUploadedTile className="h-[112px] w-[192px]" height={36} width={36}></FileUploadedTile>
                      )}
                      {displayImages.length > 2 ? (
                        <div className="relative">
                          <img
                            src={
                              displayImages[2].isSaved
                                ? getImageUrlIfRelative(displayImages[2].url)
                                : displayImages[2].url
                            }
                            alt="preview"
                            className="h-[112px] w-[192px]"
                          />
                          <IconButton
                            onClick={() => handleDeleteImage(2)}
                            sx={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              right: '4px',
                              top: '4px',
                              opacity: '0.8',
                              background: '#F3F4F6',
                              borderRadius: '4px',
                            }}
                          >
                            <CloseIcon sx={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </div>
                      ) : (
                        <FileUploadedTile className="h-[112px] w-[192px]" height={36} width={36}></FileUploadedTile>
                      )}
                    </div>
                    {uploadFileError && (
                      <div className="pt-2 text-base font-semibold text-red-400">{uploadFileError}</div>
                    )}
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#007CC2' }}
                    onClick={() => {
                      onCloseDetailImageModal(true);
                    }}
                  >
                    保存する
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      onCloseDetailImageModal(false);
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
