import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SnsType } from '@/api/influencer';
import { ProjectAdoption, ProjectAdoptionAdoptionEnum } from '@/api/project';
import {
  GetSpiritApplicantsOrderEnum,
  GetSpiritApplicantsRowsEnum,
  GetSpiritApplicantsStatusEnum,
  SpiritHearingOption,
} from '@/api/spirit';
import { PROJECT_STATUS } from '@/config';
import { patchInfluencerFollow } from '@/hooks/Influencer';
import { patchProjectAdoption, patchProjectAdoptionDecision } from '@/hooks/project';
import { getSpiritApplicantHearing } from '@/hooks/SPIRIT';

import CompleteModal from '../../Modals/CompleteMadal';
import ConfirmModal from '../../Modals/ConfirmModal';
import HearingModal, { HearingContent, isValidKey, TaskKeyType, taskType } from '../../Modals/ModalHearing';

import people from './common/Pic/people.png';
import { ApplicantSchedule } from './common/Types/common_types';
import Instagram_Content from './Instagram/Instagram_Content';
import Other_Content from './other/Other_Content';
import Tiktok_Content from './Tiktok/Tiktok_Content';
import Twitter_Content from './Twitter/Twitter_Content';
import Youtube_Content from './Youtube/Youtube_Content';

/**
 *
 * @export
 * @interface GetProjectApplicantsOutput
 */
export interface GetProjectApplicantsOutput {
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  from: number;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  to: number;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  perPage: number;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  totalRows: number;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  totalPages: number;
  /**
   *
   * @type {Array<ProjectApplicantItem>}
   * @memberof GetProjectApplicantsOutput
   */
  rows: Array<ProjectApplicantItem>;
  /**
   *
   * @type {ProjectApplicantCount}
   * @memberof GetProjectApplicantsOutput
   */
  count: ProjectApplicantCount;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  rewards: number;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  adoptionPlan: number;
  /**
   *
   * @type {number}
   * @memberof GetProjectApplicantsOutput
   */
  taxRate: number;
  /**
   *
   * @type {string}
   * @memberof GetProjectApplicantsOutput
   */
  fractionType: string;
}

/**
 *
 * @export
 * @interface ProjectApplicantCount
 */
export interface ProjectApplicantCount {
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantCount
   */
  applicant: number;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantCount
   */
  approved: number;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantCount
   */
  reserved: number;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantCount
   */
  canBeReserver: number;
}

/**
 *
 * @export
 * @interface ProjectApplicantItem
 */
export interface ProjectApplicantItem {
  /**
   *
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  applicantIndex: number;
  /**
   *
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  influencerId: string;
  /**
   *
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  snsAccountNo: string;
  /**
   *
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  snsAccountId: string;
  /**
   *
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  thumbnail: string | null;
  /**
   * 採用されてない場合はnull
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  adoptedInfluencerId?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  evaluation: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  evaluationCounts: number;
  /**
   *
   * @type {boolean}
   * @memberof ProjectApplicantItem
   */
  follow: boolean;
  /**
   *
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  enthusiasm: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProjectApplicantItem
   */
  isAlternate: boolean;
  /**
   *
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  operatorComment: string | null;
  /**
   * 採用:1、補欠:2、不採用:3
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  selectionStatus: number;
  /**
   * 最終確認 unconfirmed: 未確認 confirming: 確認中 confirmed_ok: 確認OK confirmed_ng: 確認NG
   * @type {string}
   * @memberof ProjectApplicantItem
   */
  isFinalConfirmation?: ProjectApplicantItemIsFinalConfirmationEnum;
  /**
   *
   * @type {boolean}
   * @memberof ProjectApplicantItem
   */
  isGoodQuality?: boolean | null;
  /**
   * フォロワー数/チャンネル登録者数
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  followers?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  following?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  impressions?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  reach?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  reachRate?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  preservations?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  engagements?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  engagementRate?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  frequency?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  reply?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  repost?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  goodAvg?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  shareAvg?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  views?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  viewRate?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  commentsAvg?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  highEvaluationAvg?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProjectApplicantItem
   */
  mediaCount?: number | null;
  /**
   *
   * @type {SnsType}
   * @memberof ProjectApplicantItem
   */
  snsType: SnsType;
}

export const ProjectApplicantItemIsFinalConfirmationEnum = {
  Unconfirmed: 'unconfirmed',
  Confirming: 'confirming',
  ConfirmedOk: 'confirmed_ok',
  ConfirmedNg: 'confirmed_ng',
} as const;

export type ProjectApplicantItemIsFinalConfirmationEnum =
  (typeof ProjectApplicantItemIsFinalConfirmationEnum)[keyof typeof ProjectApplicantItemIsFinalConfirmationEnum];

export type AdoptionValue = (typeof ProjectAdoptionAdoptionEnum)[keyof typeof ProjectAdoptionAdoptionEnum];

export interface ApplicantProps {
  applicant: GetProjectApplicantsOutput;
  setApplicant: React.Dispatch<React.SetStateAction<GetProjectApplicantsOutput>>;
  projectId: string;
  getApplicantData: (rows?: number, page?: number) => void;
  schedule: ApplicantSchedule;
  sortData: GetSpiritApplicantsOrderEnum;
  setSortData: React.Dispatch<React.SetStateAction<GetSpiritApplicantsOrderEnum>>;
  applicantListCurrentPage: number;
  setApplicantListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  setOrderWord: React.Dispatch<React.SetStateAction<string>>;
  setSelectionStatus: React.Dispatch<React.SetStateAction<GetSpiritApplicantsStatusEnum | undefined>>;
  //SNSType追加
  snsType: string | undefined;
  status: string;
}

export default function ApplicantContent(props: ApplicantProps) {
  const searchParams = new URLSearchParams(window.location.search);
  const sns = searchParams.get('sns');

  const { count, rows, rewards, total, adoptionPlan, taxRate, fractionType } = props.applicant;

  const [isHearingModal, setIsHearingModal] = useState<boolean>(false);
  const [hearingContents, setHearingContents] = useState<HearingContent[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const getRoundedValue = (value: number, fractionType: string) => {
    switch (fractionType) {
      case 'floor':
        return Math.floor(value);
      case 'ceiling':
        return Math.ceil(value);
      case 'round':
        return Math.round(value);
      default:
        return value;
    }
  };
  const baseAmount = useMemo(
    () => rewards * Math.max(0, count.approved - adoptionPlan),
    [rewards, count.approved, adoptionPlan]
  );
  const totalAmount = useMemo(() => baseAmount + baseAmount * (taxRate / 100), [baseAmount, taxRate]);
  const roundedTotalAmount = useMemo(() => getRoundedValue(totalAmount, fractionType), [totalAmount, fractionType]);

  const [isComfirmModal, setIsConfirmModal] = useState(false);
  const [isCompleteModal, setIsCompleteModal] = useState(false);

  const queryStringRows: number = +(searchParams.get('rows') || 10);
  const queryCurrentPage: number = +(searchParams.get('page') || 1);

  const [sortStateArray, setSortStateArray] = useState<string[]>(new Array(20).fill('ascending'));

  // 案件採用者確定処理
  const decitionAdoption = async () => {
    try {
      await patchProjectAdoptionDecision(props.projectId);
      props.getApplicantData(queryStringRows as GetSpiritApplicantsRowsEnum, queryCurrentPage);
      setIsConfirmModal(false);
      setIsCompleteModal(true);
    } catch (e: any) {
      Sentry.captureException(e);
      return;
    }
  };

  // 案件採用者設定処理
  const changeAdoption = async (influencerId: string, status: AdoptionValue) => {
    try {
      const adoption: ProjectAdoption = {
        applyingInfluencerId: influencerId,
        adoption: status,
      };
      await patchProjectAdoption(props.projectId, [adoption]);
      props.getApplicantData(queryStringRows as GetSpiritApplicantsRowsEnum, queryCurrentPage);
    } catch (e: any) {
      Sentry.captureException(e);
      return;
    }
  };

  // 気になる処理
  const changeFollowStatus = async (influencerId: string) => {
    try {
      await patchInfluencerFollow(props.projectId, [influencerId]);
      props.getApplicantData(queryStringRows as GetSpiritApplicantsRowsEnum, queryCurrentPage);
    } catch (e: any) {
      Sentry.captureException(e);
      return;
    }
  };

  // 採用者確定後案件詳細画面表示処理
  const afterFunc = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('status', PROJECT_STATUS.FINAL_CONFIRMATION);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  // ヒアリングモーダル表示処理
  const showHearingModal = async (snsAccountId: string) => {
    try {
      const contents: SpiritHearingOption[] = await getSpiritApplicantHearing(props.projectId, snsAccountId);
      const validContents = contents.filter((content) => isValidKey(content.taskType));
      const contentsInfo: HearingContent[] = validContents.map((content: SpiritHearingOption) => {
        const items = content.selectedOptionItems.map((item) => {
          return {
            description: item.description,
            optionItems: item.optionItems,
          };
        });
        return {
          task: taskType[content.taskType as TaskKeyType] as string,
          contentItems: items,
        };
      });
      setHearingContents(contentsInfo);
      setIsHearingModal(true);
    } catch (error: any) {
      // エラーが起きた場合は何もしない
      Sentry.captureException(error);
      return;
    }
  };

  const changeSortStatus = async (status: string) => {
    // フィルター時はソート、表示ページが初期化するための処理
    props.setApplicantListCurrentPage(1);
    props.setSortData('asc');
    props.setOrderWord('idx');

    const newSortStateArray = new Array(20).fill('ascending');
    setSortStateArray(newSortStateArray);

    if (status === 'すべて') props.setSelectionStatus(undefined);
    switch (status) {
      case '採用':
        props.setSelectionStatus('approved');
        break;
      case '補欠':
        props.setSelectionStatus('reserved');
        break;
      case '不採用':
        props.setSelectionStatus('rejected');
        break;
      default: {
        props.setSelectionStatus(undefined);
      }
    }
  };

  const renderContent = () => {
    switch (sns) {
      case 'instagram':
        return (
          <Instagram_Content
            rows={rows}
            status={props.status}
            changeFollowStatus={changeFollowStatus}
            changeAdoption={changeAdoption}
            showHearingModal={showHearingModal}
            totalPageCount={props.applicant.totalPages}
            getApplicantData={props.getApplicantData}
            totalRows={props.applicant.totalRows}
            sortData={props.sortData}
            setSortData={props.setSortData}
            setOrderWord={props.setOrderWord}
            applicantListCurrentPage={props.applicantListCurrentPage}
            setApplicantListCurrentPage={props.setApplicantListCurrentPage}
            setPerPage={props.setPerPage}
            sortStateArray={sortStateArray}
            setSortStateArray={setSortStateArray}
          />
        );
      case 'x':
        return (
          <Twitter_Content
            rows={rows}
            setTwitterRows={props.setApplicant}
            status={props.status}
            changeFollowStatus={changeFollowStatus}
            changeAdoption={changeAdoption}
            showHearingModal={showHearingModal}
            totalPageCount={props.applicant.totalPages}
            getApplicantData={props.getApplicantData}
            totalRows={props.applicant.totalRows}
            sortData={props.sortData}
            setSortData={props.setSortData}
            setOrderWord={props.setOrderWord}
            applicantListCurrentPage={props.applicantListCurrentPage}
            setApplicantListCurrentPage={props.setApplicantListCurrentPage}
            setPerPage={props.setPerPage}
            sortStateArray={sortStateArray}
            setSortStateArray={setSortStateArray}
          />
        );
      case 'tik_tok':
        return (
          <Tiktok_Content
            rows={rows}
            setTiktokRows={props.setApplicant}
            status={props.status}
            changeFollowStatus={changeFollowStatus}
            changeAdoption={changeAdoption}
            showHearingModal={showHearingModal}
            totalPageCount={props.applicant.totalPages}
            getApplicantData={props.getApplicantData}
            totalRows={props.applicant.totalRows}
            sortData={props.sortData}
            setSortData={props.setSortData}
            setOrderWord={props.setOrderWord}
            applicantListCurrentPage={props.applicantListCurrentPage}
            setApplicantListCurrentPage={props.setApplicantListCurrentPage}
            setPerPage={props.setPerPage}
            sortStateArray={sortStateArray}
            setSortStateArray={setSortStateArray}
          />
        );
      case 'youtube':
        return (
          <Youtube_Content
            rows={rows}
            setYoutubeRows={props.setApplicant}
            status={props.status}
            changeFollowStatus={changeFollowStatus}
            changeAdoption={changeAdoption}
            showHearingModal={showHearingModal}
            totalPageCount={props.applicant.totalPages}
            getApplicantData={props.getApplicantData}
            totalRows={props.applicant.totalRows}
            sortData={props.sortData}
            setSortData={props.setSortData}
            setOrderWord={props.setOrderWord}
            applicantListCurrentPage={props.applicantListCurrentPage}
            setApplicantListCurrentPage={props.setApplicantListCurrentPage}
            setPerPage={props.setPerPage}
            sortStateArray={sortStateArray}
            setSortStateArray={setSortStateArray}
          />
        );
      default:
        return (
          <Other_Content
            rows={rows}
            status={props.status}
            changeFollowStatus={changeFollowStatus}
            changeAdoption={changeAdoption}
            showHearingModal={showHearingModal}
            totalPageCount={props.applicant.totalPages}
            getApplicantData={props.getApplicantData}
            totalRows={props.applicant.totalRows}
            sortData={props.sortData}
            setSortData={props.setSortData}
            setOrderWord={props.setOrderWord}
            applicantListCurrentPage={props.applicantListCurrentPage}
            setApplicantListCurrentPage={props.setApplicantListCurrentPage}
            setPerPage={props.setPerPage}
            sortStateArray={sortStateArray}
            setSortStateArray={setSortStateArray}
          />
        );
    }
  };

  return (
    <>
      {props.applicant.totalRows == 0 ? (
        <>
          <div className="flex items-center justify-center">
            <img src={people} alt="company" />
          </div>
          <div className="flex items-center justify-center pt-[5px]">
            <div>応募者はいません</div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between px-[40px]">
            <div>
              <div className="flex h-[44px]">
                <div className="pl-[10px] pt-[12px] text-sm font-normal leading-5 text-gray-500">採用</div>
                <div className="pl-[8px] pt-[10px] text-base font-bold leading-6 text-gray-500">
                  {count.approved}/{adoptionPlan}
                </div>
                <div className="pl-[4px] pt-[17px] text-xs font-semibold uppercase leading-4 tracking-wider text-gray-500">
                  人
                </div>
                <div className="pl-[16px] pt-[12px] text-sm font-normal leading-5 text-gray-500">補欠</div>
                <div className="pl-[8px] pt-[10px] text-base font-bold leading-6 text-gray-500">
                  {count.reserved}/{count.canBeReserver}
                </div>
                <div className="pl-[4px] pt-[17px] text-xs font-semibold uppercase leading-4 tracking-wider">人</div>
                <div className="pl-[18px] pt-[14px] text-xs font-normal leading-5 text-gray-500">1人あたり</div>
                <div className="pl-[4px] pt-[10px] text-base font-bold leading-6 text-gray-500">
                  {rewards.toLocaleString()}円
                </div>
                <div className="pl-[8px] pt-[14px] text-xs font-medium leading-5 text-gray-500">
                  で募集人数以上の採用も可能です。
                </div>
              </div>
              <div className="flex pt-[39px]">
                <div>
                  <select
                    id="location"
                    name="location"
                    className="mt-2 block h-[34px] w-[143px] rounded-md border-0 py-1.5 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      changeSortStatus(e.target.value);
                    }}
                  >
                    <option value={'すべて'}>すべて</option>
                    <option value={'採用'}>採用</option>
                    <option value={'不採用'}>不採用</option>
                    <option value={'補欠'}>補欠</option>
                  </select>
                </div>
                <div className="pl-[16px] pt-[8px]">
                  <div>
                    <button
                      type="button"
                      className={clsx(
                        rows.length > 0 &&
                          props.status == PROJECT_STATUS.APPROVED &&
                          rows.every((row) => row.selectionStatus !== 3)
                          ? 'bg-blue-500 hover:bg-blue-500 focus-visible:outline-blue-500'
                          : 'pointer-events-none bg-gray-300 hover:bg-gray-300 focus-visible:outline-gray-300',
                        'h-[32px] w-[143px] rounded  px-2 text-sm font-medium text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 '
                      )}
                      disabled={
                        rows.length === 0 &&
                        props.status != PROJECT_STATUS.APPROVED &&
                        rows.every((row) => row.selectionStatus === 3)
                      }
                      onClick={() => {
                        setIsConfirmModal(true);
                      }}
                    >
                      採用者を確定する
                    </button>
                  </div>
                </div>
                <div className="pl-[16px] pt-[20px]">
                  <div className="text-xs font-medium leading-5 text-gray-500">
                    選考期間：
                    {props.schedule ? `${props.schedule.from} - ${props.schedule.to}` : ''}
                  </div>
                </div>
              </div>
            </div>
            {/*案件作成時の見積り額*/}
            <div className="h-[117px] w-[344px] bg-white px-[16px] pt-[16px]">
              <div className="flex justify-between">
                <div className="text-xs font-medium leading-4">案件作成時の見積り額</div>
                <div className="text-base font-bold leading-6">{total.toLocaleString()}円</div>
              </div>
              <div className="flex justify-between">
                <div className="flex text-xs font-medium leading-4">
                  <div className="text-xs font-medium leading-4">{Math.max(0, count.approved - adoptionPlan)}人</div>
                  <div className="">追加採用費用</div>
                </div>
                <div className="text-base font-bold leading-6">{`+${roundedTotalAmount.toLocaleString()}円`}</div>
              </div>
              <div className="h-[1px] w-[312px] bg-gray-200"></div>
              <div className="flex h-[24px] w-[312px] justify-between">
                <div className="pt-[12px] text-xs font-semibold leading-4 text-gray-500">合計</div>
                <div className="flex">
                  <div className="pr-[8px] pt-[12px] text-xs font-normal leading-5 text-gray-500">{`(税込)`}</div>
                  <div className="pt-[8px] text-base font-bold leading-6">
                    {(total + roundedTotalAmount).toLocaleString()}円
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 応募者一覧 */}
          <div className="p-[30px] pt-[24px]">{renderContent()}</div>
        </>
      )}
      <HearingModal contents={hearingContents} isOpen={isHearingModal} setModal={setIsHearingModal} />
      <ConfirmModal
        isOpen={isComfirmModal}
        titleText={'この内容で採用者を確定しますか？'}
        contentText={'採用予定のインフルエンサーの確認はお済みですか？採用者確定後は変更できません。'}
        completeButtonText={'採用者を確定'}
        cancelButtonText={'キャンセル'}
        setIsOpen={setIsConfirmModal}
        completeFunc={decitionAdoption}
      />
      <CompleteModal
        open={isCompleteModal}
        modalText="インフルエンサーの採用者が確定しました"
        afterFunc={afterFunc}
        setOpen={setIsCompleteModal}
        buttonText="案件詳細へ"
        contentText="弊社担当が、選んだインフルエンサーに対して、改めてスケジュールの確認を行います。案件に参加するインフルエンサーが正式に決定するまで、しばらくお待ちください。"
      />
    </>
  );
}
