import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState, useEffect } from 'react';

import { ProjectStatus, ProjectType } from '@/api/project';
import Help from '@/assets/icons/bx-help-circle.png';
import { RadioBox } from '@/components/Elements/RadioBox';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import {
  OptionAllType,
  OptionMapType,
  SortByOption,
} from '@/components/ProjectManagements/Applicant/Contents/common/Types/ArgsType';
//import Dropdown from '@/components/ProjectManagements/Modals/Simple_Dropdown';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectType: ProjectType;
  projectStatus: ProjectStatus;
  option: OptionAllType;
  setOption: React.Dispatch<React.SetStateAction<OptionAllType>>;
  optionMap: OptionMapType;
  PutPostOption: (option: OptionAllType) => Promise<void>;
}

export default function Popup(props: Props) {
  const [displayOption, setDisplayOption] = useState<OptionAllType>(props.option);
  const isOptionKey = (key: string): key is Exclude<keyof OptionAllType, 'defaultOption'> => {
    return Object.keys(props.option)
      .filter((val) => val !== 'defaultOption')
      .includes(key);
  };
  const optionKeys: Map<number, keyof OptionAllType> = new Map(
    Object.keys(props.option).map((key, index) => {
      const setKey = isOptionKey(key) ? key : 'defaultOption';
      return [index, setKey];
    })
  );

  const changeState = async (isSave: boolean) => {
    if (isSave) {
      await props.PutPostOption(displayOption);
    } else {
      setDisplayOption(props.option);
    }
    props.setOpen(false);
  };

  const onChange = (keyName: keyof OptionAllType, selectValue: keyof OptionAllType) => {
    if (keyName === 'defaultOption') return;
    const newOption = { ...displayOption };
    newOption[keyName] = parseInt(selectValue);
    setDisplayOption(newOption);
  };

  useEffect(() => {
    if (props.open) {
      setDisplayOption(props.option);
    }
  }, [props.open, props.option]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => changeState(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[382px] w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-semibold leading-6 text-gray-900">
                  オプション設定
                </Dialog.Title>
                <div className="mb-5 text-left">
                  <p className="text-sm text-gray-500">「※」が付いている項目は必須項目です</p>
                </div>
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      changeState(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                {SortByOption(Array.from(optionKeys.entries())).map(([index, key], count) => {
                  const arg = props.optionMap.get(key);
                  if (arg === undefined) return null;
                  return (
                    <>
                      <div className={count === 0 ? 'flex items-center' : 'flex items-center pt-[32px]'}>
                        <div>
                          <div className="text-sm font-semibold leading-5 text-gray-700">
                            {arg.title}
                            {arg.tipContent ? '*' : ''}
                          </div>
                        </div>
                        <div className="group relative pl-[7.33px]">
                          <img className="h-[16px] w-[16px]" src={Help} alt="" />
                          {arg.tipContent && (
                            <Tooltip
                              isSpeechBubble={true}
                              variant="gray"
                              toolTipClassName="px-3 py-2"
                              tooltipText={arg.tipContent}
                              className="absolute left-[-36px] top-[-72px] hidden w-[500px] text-xs group-hover:inline-block"
                            ></Tooltip>
                          )}
                        </div>
                      </div>
                      <div className="pt-[16px]">
                        <RadioBox
                          title=""
                          values={arg.radioValues}
                          name={'reward' + index.toString()}
                          value={displayOption[key]?.toString() || '0'}
                          onClick={(e: any) => onChange(key, e.target.value)}
                        ></RadioBox>
                      </div>
                    </>
                  );
                })}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#007CC2' }}
                    onClick={() => {
                      // 特別 Alert.tsx を表示させる
                      changeState(true);
                    }}
                  >
                    保存する
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      changeState(false);
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
