import { CalendarIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GetProjectOverviewOutput, SnsType, ProjectStatus } from '@/api/project';
import {
  GetSpiritApplicantsOrderByEnum,
  GetSpiritApplicantsOrderEnum,
  GetSpiritApplicantsRowsEnum,
  GetSpiritApplicantsStatusEnum,
  GetSpiritDetailOutput,
  ProjectImageRow,
  SPIRITApiFactory,
  SpiritTaskItem,
} from '@/api/spirit';
import { ReactComponent as ApplicantIcon } from '@/assets/icons/bx-group.svg';
import NoImage from '@/assets/images/NoImage.png';
import { MainLayout } from '@/components/Layout/MainLayout';
import ApplicantContent, {
  ApplicantProps,
  GetProjectApplicantsOutput,
} from '@/components/ProjectManagements/Applicant/Contents/ApplicantContent';
import { ApplicantSchedule } from '@/components/ProjectManagements/Applicant/Contents/common/Types/common_types';
import { PhaseNavigation } from '@/components/ProjectManagements/PhaseNavigation/Components/PhaseNavigation';
import { TabView } from '@/components/ProjectManagements/PhaseNavigation/Components/TabView';
import { phaseInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/PhaseNavigationInterfaces';
import { TabInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/TabViewInterfaces';
import ProjectOverview from '@/components/ProjectManagements/ProjectOverview/Components/ProjectOverview';
import { projectOverview } from '@/components/ProjectManagements/ProjectOverview/Interfaces/ProjectOverViewInterfaces';
import ScheduleContent, { ScheduleProps } from '@/components/ProjectManagements/ProjectSchedule/Components/Schedule';
import {
  projectSchedule,
  ScheduleList,
} from '@/components/ProjectManagements/ProjectSchedule/Interface/ProjectScheduleInterface';
import { PROJECT_TYPES } from '@/config';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
import { getProjectOverviews } from '@/hooks/ProjectInfo';
import { getSpiritDetail, getSpiritSchedule } from '@/hooks/SPIRIT';
import { axiosInstance } from '@/utils/axiosInstance';
import { formatYYYYMMDD } from '@/utils/format';
import { getImageUrl } from '@/utils/image';
import { convertStatusToStep, convertStatusToDisplayLabelForSpirit } from '@/utils/projectUtils';

import Recruitment from '../components/details/Recruitment';
import { PageHeader } from '../components/layouts/headers';
import Image from '../components/png/samples/Image.png';
import { projectDetail } from '../interfaces/otherProjectInterface';
import { projectInterface, recruitmentTitleInterface, advancedSettings } from '../interfaces/PopupInterfaces';

export type projectDetailSpirit = projectDetail & {
  tasks: SpiritTaskItem[];
  snsType: SnsType;
  featuredImage: string | null;
  projectDetailSpirit: GetSpiritDetailOutput;
  setProjectDetailSpirit: React.Dispatch<React.SetStateAction<GetSpiritDetailOutput>>;
  fetchSpiritProjectDetail: () => Promise<AxiosResponse<GetSpiritDetailOutput, any> | undefined>;
  minimumReward: number | undefined;
  setMinimumReward: React.Dispatch<React.SetStateAction<number | undefined>>;
  standardReward: number | undefined;
  setStandardReward: React.Dispatch<React.SetStateAction<number | undefined>>;
  displayRewards?: number | undefined;
  setDisplayRewards?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

// 応募者選定期間抽出用
// from: インフルエンサーの募集期間の開始
// to: インフルエンサーの選定期間の終了
function filterBySpiritSchedule(items: ScheduleList): ApplicantSchedule {
  const recruitmentPeriod = items.find((item) => item.label.trim() == 'インフルエンサー募集期間');
  const selectionPeriod = items.find((item) => item.label.trim() == 'インフルエンサー選定期間');
  const schedule: ApplicantSchedule = {
    from: formatYYYYMMDD(recruitmentPeriod?.from || ''),
    to: formatYYYYMMDD(selectionPeriod?.to || ''),
  };
  return schedule;
}

export function Spirit(): React.ReactElement {
  const [outlineInfo, setOutlineInfo] = useState<projectOverview>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    scheduleStartedAt: null,
    scheduleEndedAt: null,
    budget: 0,
    kpi: [],
    orderInfo: {
      companyName: '',
      address: '',
      capital: '',
      represent: '',
      established: '',
      business: '',
    },
  });
  const [recruitmentTitle, setRecruitmentTitle] = useState<recruitmentTitleInterface>({
    title: '',
    image: NoImage,
    selectSNS: 0,
  });
  const [projectDetail, setProjectDetail] = useState<projectInterface>({
    headcount: -1,
    compensationAmount: -1,
    applicationCondition: '',
    preferredQualifications: [],
    description: '',
  });
  const [projectSchedule, setProjectSchedule] = useState<projectSchedule>();
  const [advancedSettings, setAdvancedSettings] = useState<advancedSettings>();
  const [tabInfo, setTabInfo] = useState<TabInfo[]>([]);
  const [modalImage, setModalImage] = useState<string[]>([]);
  const [interviewDate, setInterviewDate] = useState<string>('');
  const [operaitonType, setOperaitonType] = useState<string>('');
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [spotConsultationDateStart, setSpotConsultationDateStart] = useState<string>('');
  const [spotConsultationDateEnd, setSpotConsultationDateEnd] = useState<string>('');
  const [questionCounts, setQuestionCounts] = useState<string>('');
  const [questionCreate, setQuestionCreate] = useState<string>('');
  const [questionnaireDate, setQuestionnaireDate] = useState<string>('');
  const [considerations, setConsiderations] = useState<string[]>([]);
  const [openDialogPublicAttention, setOpenDialogPublicAttention] = useState<boolean>(false);
  const [applicant, setApplicant] = useState<GetProjectApplicantsOutput>({
    from: 0,
    to: 0,
    perPage: 0,
    totalRows: 0,
    totalPages: 0,
    rows: [],
    count: {
      applicant: 0,
      approved: 0,
      reserved: 0,
      canBeReserver: 0,
    },
    rewards: 0,
    total: 0,
    adoptionPlan: 0,
    taxRate: 0,
    fractionType: '',
  });
  const [sortData, setSortData] = useState<GetSpiritApplicantsOrderEnum>('asc');
  const [applicantListCurrentPage, setApplicantListCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [oderWord, setOrderWord] = useState<string>('idx');
  const [selectionStatus, setSelectionStatus] = useState<GetSpiritApplicantsStatusEnum>();

  const [minimumReward, setMinimumReward] = useState<number | undefined>();
  const [standardReward, setStandardReward] = useState<number | undefined>();
  const [displayRewards, setDisplayRewards] = useState<number | undefined>();

  //projectDetailOutput
  const [tasks, setTasks] = useState<SpiritTaskItem[]>([]);
  const [snsType, setSnsType] = useState<SnsType>();
  const [detailImages, setDetailImages] = useState<ProjectImageRow[]>([]);
  const [companyName, setCompanyName] = useState<string>('');
  const [projectDetailSpirit, setProjectDetailSpirit] = useState<GetSpiritDetailOutput>({} as GetSpiritDetailOutput);
  const [projectName, setProjectName] = useState<string>('');
  const [projectGroupName, setProjectGroupName] = useState<string>('');
  const [projectScheduleDate, setProjectScheduleDate] = useState<{
    projectScheduleStartedAt: string;
    projectScheduleEndedAt: string;
  }>({ projectScheduleStartedAt: '', projectScheduleEndedAt: '' });
  const [projectStatus, setProjectStatus] = useState<string>(ProjectStatus.Draft);

  const search = useLocation().search;

  const queryString = new URLSearchParams(search);
  const queryTab: string = queryString.get('tab') || 'overview';
  const projectId: string = queryString.get('projectId') || '';

  const pahse: phaseInfo[] = [
    {
      name: convertStatusToDisplayLabelForSpirit(ProjectStatus.Draft),
      isCurrent: ProjectStatus.Draft == projectStatus || ProjectStatus.Review == projectStatus,
      isDone: convertStatusToStep(projectStatus) > convertStatusToStep(ProjectStatus.Draft),
    },
    {
      name: convertStatusToDisplayLabelForSpirit(ProjectStatus.Approved),
      isCurrent: ProjectStatus.Approved == projectStatus,
      isDone: convertStatusToStep(projectStatus) > convertStatusToStep(ProjectStatus.Approved),
    },
    {
      name: convertStatusToDisplayLabelForSpirit(ProjectStatus.FinalConfirmation),
      isCurrent: ProjectStatus.FinalConfirmation == projectStatus,
      isDone: convertStatusToStep(projectStatus) > convertStatusToStep(ProjectStatus.FinalConfirmation),
    },
    {
      name: convertStatusToDisplayLabelForSpirit(ProjectStatus.Implementation),
      isCurrent: ProjectStatus.Implementation == projectStatus,
      isDone: convertStatusToStep(projectStatus) > convertStatusToStep(ProjectStatus.Implementation),
    },
    {
      name: convertStatusToDisplayLabelForSpirit(ProjectStatus.Completion),
      isCurrent: ProjectStatus.Completion == projectStatus,
      isDone: convertStatusToStep(projectStatus) > convertStatusToStep(ProjectStatus.Completion),
    },
  ];

  const getApplicantData = async (rows: GetSpiritApplicantsRowsEnum = 10, page: number) => {
    try {
      const defaultApi = SPIRITApiFactory(undefined, undefined, axiosInstance);
      const response = await defaultApi.getSpiritApplicants(
        projectId,
        rows,
        page,
        sortData,
        oderWord as GetSpiritApplicantsOrderByEnum,
        selectionStatus as GetSpiritApplicantsStatusEnum
      );
      console.log('応募者データの取得に成功しました。', response);
      return response;
    } catch (error) {
      Sentry.captureException(error);
      console.error('応募者データの取得に失敗しました。', error);
    }
  };

  const getApplicantProp = async (rows: GetSpiritApplicantsRowsEnum = 10, page: number) => {
    const applicantData = await getApplicantData(rows, page);
    if (applicantData && applicantData.data) {
      const applicantDataWithRewards = {
        from: applicantData.data.from,
        to: applicantData.data.to,
        perPage: applicantData.data.perPage,
        totalRows: applicantData.data.totalRows,
        totalPages: applicantData.data.totalPages,
        rows: applicantData.data.rows,
        count: applicantData.data.count,
      };
      setApplicant((prev) => {
        return {
          ...prev,
          ...applicantDataWithRewards,
        };
      });
    }
  };

  useEffect(() => {
    (async () => {
      const projectOverview: GetProjectOverviewOutput = await getProjectOverviews(projectId);
      const projectDetailOutput: GetSpiritDetailOutput = await getSpiritDetail(projectId);
      setProjectStatus(projectDetailOutput.header.projectStatus);
      const projectSchedule = await getSpiritSchedule(projectId);
      const convertedKpiArray: string[] = projectOverview.kpi.map((element) => `・${element}\n`);
      if (projectOverview) {
        setOutlineInfo({
          marketBackground: projectOverview.marketBackground,
          problem: projectOverview.problem,
          purpose: projectOverview.purpose,
          measure: projectOverview.measure,
          kpi: convertedKpiArray,
          budget: projectOverview.budget,
          scheduleStartedAt: projectOverview.scheduleStartedAt,
          scheduleEndedAt: projectOverview.scheduleEndedAt,
          orderInfo: projectOverview.orderInfo,
        });
      }

      await getProjectDetailProps();
      getApplicantProp(10, applicantListCurrentPage);
      setTasks(projectDetailOutput.tasks);
      setSnsType(projectDetailOutput.snsType);
      setProjectDetailSpirit(projectDetailOutput);
      setDetailImages(
        projectDetailOutput.detailImages.map((image) => ({
          id: image.id,
          objectKey: getImageUrl(image.objectKey),
        }))
      );
      setCompanyName(projectDetailOutput.details.companyName);
      if (projectSchedule) {
        setProjectSchedule({
          projectStatus: convertStatusToStep(projectStatus),
          scheduleData: projectSchedule,
          projectType: PROJECT_TYPES.SPIRIT,
        });
      }
      setProjectName(projectDetailOutput.header.projectName);
      if (projectDetailOutput.header.projectGroupName) {
        setProjectGroupName(projectDetailOutput.header.projectGroupName ?? '');
      }
      setProjectScheduleDate((prevState) => ({
        ...prevState,
        projectScheduleStartedAt: projectOverview.scheduleStartedAt ?? '',
        projectScheduleEndedAt: projectOverview.scheduleEndedAt ?? '',
      }));
    })();
    setRecruitmentTitle({
      title:
        convertStatusToStep(projectStatus) !== 1
          ? 'さっと塗るだけ!お手軽エイジングケア! 究極のオールインワン「Alice プレミア ゼロ」をInstagramでPRしてください♡'
          : undefined,
      image: convertStatusToStep(projectStatus) !== 1 ? Image : undefined,
      selectSNS: convertStatusToStep(projectStatus) !== 1 ? 1 : undefined,
    });
    // setDetailImages(convertStatusToStep(projectStatus) !== 1 ? [Image, Image, Image] : detailImages);
    setProjectDetail({
      headcount: convertStatusToStep(projectStatus) !== 1 ? 3 : -1,
      compensationAmount: convertStatusToStep(projectStatus) !== 1 ? 10000 : -1,
      applicationCondition: convertStatusToStep(projectStatus) !== 1 ? '20代〜30代の女性' : '-',
      preferredQualifications: convertStatusToStep(projectStatus) !== 1 ? ['コスメ1', 'コスメ２', 'コスメ３'] : [],
      description:
        convertStatusToStep(projectStatus) !== 1
          ? '5年後、10年後もずっと若々しく麗しい肌印象でいるために。\n美肌の起源に着目し全方位からエイジングケアを網羅する、カナデル史上最高峰オールインワン「カナデルプレミア ゼロ」を是非お試しください。'
          : '',
    });
    setAdvancedSettings({
      visibilitySettings: convertStatusToStep(projectStatus) !== 1 ? 1 : undefined,
      companyName: convertStatusToStep(projectStatus) !== 1 ? 'ABC株式会社' : undefined,
      cautionsList:
        convertStatusToStep(projectStatus) !== 1
          ? [
              '採用後、必ず期間内に連絡が取れる方のみご応募ください。',
              '応募期間終了後のキャンセルはできかねます。',
              '本案件は同伴不可となっておりますので予めご了承ください。',
            ]
          : undefined,
    });
  }, [projectStatus]);

  useEffect(() => {
    getApplicantProp(perPage as GetSpiritApplicantsRowsEnum, applicantListCurrentPage);
  }, [sortData, applicantListCurrentPage, oderWord, selectionStatus]);

  const fetchSpiritProjectDetail = async () => {
    try {
      const defaultApi = SPIRITApiFactory(undefined, undefined, axiosInstance);
      const response = await defaultApi.getSpiritDetail(projectId);
      console.log('案件詳細データの取得に成功しました。', response);
      setMinimumReward(response.data.estimate.calculateReward.minimum);
      setStandardReward(response.data.estimate.calculateReward.standard);
      return response;
    } catch (error) {
      Sentry.captureException(error);
      console.error('案件詳細データの取得に失敗しました。', error);
    }
  };

  const getProjectDetailProps = async () => {
    const projectDetailData = await fetchSpiritProjectDetail();
    console.log(projectDetailData);
    if (projectDetailData && projectDetailData.data) {
      setApplicant((prev) => {
        return {
          ...prev,
          adoptionPlan: projectDetailData.data.summary.adoptionPlan,
          taxRate: projectDetailData.data.estimate.taxRate,
          fractionType: projectDetailData.data.estimate.fractionType,
          rewards: projectDetailData.data.summary.rewards,
          total: projectDetailData.data.estimate.total,
        };
      });
    }
  };

  useEffect(() => {
    setSortData('asc');
    setApplicantListCurrentPage(1);
  }, []);

  useEffect(() => {
    setTabInfo([
      {
        title: '案件概要',
        icon: DocumentTextIcon,
        component: (props: projectOverview) => <ProjectOverview {...props} />,
        componentProps: outlineInfo,
        query: 'overview',
        isActive: queryTab === 'overview',
      },
      {
        title: '募集内容詳細',
        icon: PencilIcon,
        component: (props: projectDetailSpirit) => <Recruitment {...props} />,
        componentProps: {
          status: projectStatus,
          recruitmentTitle: recruitmentTitle,
          setRecruitmentTitle: setRecruitmentTitle,
          projectDetail: projectDetail,
          setProjectDetail: setProjectDetail,
          advancedSettings: advancedSettings,
          setAdvancedSettings: setAdvancedSettings,
          modalImage: modalImage,
          setModalImage: setModalImage,
          interviewDate: convertStatusToStep(projectStatus) == 1 ? interviewDate : '2023年8月31日',
          setInterviewDate: setInterviewDate,
          operationType: operaitonType,
          setOperationType: setOperaitonType,
          isRequesting: isRequesting,
          setIsRequesting: setIsRequesting,
          spotConsultationDateStart: spotConsultationDateStart,
          setSpotConsultationDateStart: setSpotConsultationDateStart,
          spotConsultationDateEnd: spotConsultationDateEnd,
          setSpotConsultationDateEnd: setSpotConsultationDateEnd,
          questionCounts: questionCounts,
          setQuestionCounts: setQuestionCounts,
          questionnaireDate: questionnaireDate,
          setQuestionnaireDate: setQuestionnaireDate,
          questionCreate: questionCreate,
          setQuestionCreate: setQuestionCreate,
          considerations: considerations,
          setConsiderations: setConsiderations,
          openDialogPublicAttention: openDialogPublicAttention,
          setOpenDialogPublicAttention: setOpenDialogPublicAttention,
          conpanyName: companyName,
          tasks,
          snsType,
          detailImages: detailImages,
          setDetailImages: setDetailImages,
          projectDetailSpirit,
          setProjectDetailSpirit,
          fetchSpiritProjectDetail,
          minimumReward,
          setMinimumReward,
          standardReward,
          setStandardReward,
          displayRewards,
          setDisplayRewards,
        } as unknown as projectDetailSpirit,
        query: 'detail',
        isActive: queryTab === 'detail',
      },
      {
        title: 'スケジュール',
        icon: CalendarIcon,
        component: (props: ScheduleProps) => <ScheduleContent {...props} />,
        componentProps: {
          projectStatus: convertStatusToStep(projectStatus),
          scheduleData: projectSchedule === undefined ? [] : projectSchedule.scheduleData,
        },
        query: 'schedule',
        isActive: queryTab === 'schedule',
      },
      {
        title: '応募者',
        icon: ApplicantIcon,
        component: (props: ApplicantProps) => <ApplicantContent {...props} />,
        componentProps: {
          applicant: applicant,
          setApplicant: setApplicant,
          projectId: projectId,
          getApplicantData: getApplicantProp,
          schedule: projectSchedule?.scheduleData ? filterBySpiritSchedule(projectSchedule.scheduleData) : undefined,
          sortData: sortData,
          setSortData: setSortData,
          applicantListCurrentPage: applicantListCurrentPage,
          setApplicantListCurrentPage: setApplicantListCurrentPage,
          perPage: perPage,
          setPerPage: setPerPage,
          setOrderWord: setOrderWord,
          setSelectionStatus: setSelectionStatus,
          status: projectStatus,
        },
        query: 'applicant',
        isActive: queryTab === 'applicant',
        className: 'fill-current',
      },
    ]);
  }, [
    outlineInfo,
    queryTab,
    projectStatus,
    projectSchedule?.projectType,
    projectSchedule?.scheduleData,
    recruitmentTitle,
    detailImages,
    projectDetail,
    advancedSettings,
    modalImage,
    interviewDate,
    operaitonType,
    applicant?.rows,
    projectDetailSpirit,
  ]);

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.spirit,
      }}
    >
      <div className="flex h-[auto] w-[1440px] bg-gray-50">
        <div className="h-full w-[1181px] bg-gray-50">
          <div>{PageHeader(projectGroupName, projectName, projectScheduleDate)}</div>
          <div>{PhaseNavigation(pahse)}</div>
          <div className="pt-[10px] ">
            <div className="h-full w-[1181px] border-[1px] border-gray-200">
              {tabInfo.length > 0 && <TabView tabInfo={tabInfo} />}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
