import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { Fragment, useEffect, useState } from 'react';

import { AnalysisSpiritFeedbackAdopterRow, AnalysisSpiritFeedbackGoodQualityCount } from '@/api/analysisSpirit';
import {
  GetInfluencerSnsAccountOutput,
  ProjectType,
  PutInfluencerEvaluationInputEvaluationEnum,
} from '@/api/influencer';
import Arrow_Right from '@/assets/icons/Arrow_Right.png';
import { ReactComponent as QuestionIcon } from '@/assets/icons/question-mark-circle.svg';
import { SelectMenus } from '@/components/Elements/SelectMenus';
import { TextArea } from '@/components/Elements/TextArea';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import { patchInfluencerBonus, putInfluencerEvaluation } from '@/hooks/Influencer';
import { ValidationMessages } from '@/utils/message';

import { AccountInfo, AccountInfoProps } from './AccountInfo';
import { AdopterSlider, SelectFeedback } from './feedbackCommon';
import FeedbackStars from './feesbackStar';

export interface PreFeedbackModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectType: string;
  goodQualityCount: AnalysisSpiritFeedbackGoodQualityCount;
  snsAccount: GetInfluencerSnsAccountOutput | undefined;
  adopter: AnalysisSpiritFeedbackAdopterRow;
  setAdopter: React.Dispatch<React.SetStateAction<AnalysisSpiritFeedbackAdopterRow>>;
  selectFeedback: (feedback: SelectFeedback) => void;
  adopterSlider: AdopterSlider;
  accountInfo: AccountInfoProps;
}

export const PreFeedbackModal = (props: PreFeedbackModalProps) => {
  const [evaluation, setEvaluation] = useState<number>(4);
  props.snsAccount?.evaluation.evaluation;
  const [isGoodQualityCandidate, setIsGoodQualityCandidate] = useState<boolean>(
    props.adopter.isGoodQualityCandidate ?? false
  );
  const [GoodQualityCandidateCount, setGoodQualityCandidateCount] = useState<number>(
    props.goodQualityCount.candidateCount ?? 0
  );
  const [feedbackComment, setFeedbackComment] = useState<string>(props.adopter.GoodQualityReason ?? '');
  const [feedbackCommentValidator, setFeedbackCommentValidator] = useState<string>('');

  useEffect(() => {
    // インフルエンサーへの評価をセット
    setEvaluation(props.adopter.evaluation);
  }, [props.adopter.evaluation]);

  useEffect(() => {
    setGoodQualityCandidateCount(props.goodQualityCount.candidateCount);
  }, [props.goodQualityCount.candidateCount]);

  useEffect(() => {
    setIsGoodQualityCandidate(props.adopter.isGoodQualityCandidate ?? false);
  }, [props.adopter.isGoodQualityCandidate]);

  const checkGoodQuality = (targetValue?: string) => {
    const validateComment = (comment: string) => {
      if (comment.length > 200) {
        setFeedbackCommentValidator(ValidationMessages.strMaxLenMessage('ボーナス候補理由', 200));
        return false;
      }
      setFeedbackCommentValidator('');
      return true;
    };

    const feedbackCommentValid = validateComment(feedbackComment);
    const isTargetValueValid = targetValue ? validateComment(targetValue) : false;
    if (isTargetValueValid || feedbackCommentValid) return true;
    return false;
  };

  const saveInfuencerInfo = async () => {
    // Good Quality ボーナス付与
    if (props.projectType == ProjectType.Spirit || props.projectType == ProjectType.EmeraldPost) {
      await patchInfluencerBonus(props.adopter.snsAccountId, {
        adoptedInfluencerId: props.adopter.adoptedInfluencerId,
        isGoodQuality: isGoodQualityCandidate,
        reason: feedbackComment,
      });
    }

    // インフルエンサーへの評価を保存
    await putInfluencerEvaluation(props.adopter.adoptedInfluencerId, {
      evaluation: evaluation as PutInfluencerEvaluationInputEvaluationEnum,
    });
  };

  const onSave = async (isGoodQuality: boolean) => {
    try {
      let closeFlag = true;
      closeFlag = checkGoodQuality();
      if (!closeFlag) return;

      if (isGoodQuality) {
        props.selectFeedback({
          adoptedInfluencerId: props.adopter.adoptedInfluencerId,
          snsAccountId: props.adopter.snsAccountId,
          evaluation: evaluation,
          isGoodQualityCandidate: isGoodQualityCandidate,
          feedbackComment: feedbackComment,
        });
      } else {
        props.selectFeedback({
          adoptedInfluencerId: props.adopter.adoptedInfluencerId,
          evaluation: evaluation,
        });
      }
      if (props.projectType == ProjectType.Spirit) {
        await saveInfuencerInfo();
      }
      props.setAdopter((prev) => ({
        ...prev,
        GoodQualityReason: feedbackComment,
      }));
      props.setOpen(false);
    } catch (error: any) {
      // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
      Sentry.captureException(error);
      throw new Error(
        `HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`
      );
    }
  };

  const onClose = () => {
    props.setOpen(false);
  };

  const tooltipTextEvaluation =
    'インフルエンサーの仕事を5段階の星評価でお願いします。\n評価する際の基準は以下の通りです。\n<評価基準例>\n・投稿が目的に合致していて効果的だったか。\n・クリエイティブが、期待を超えるオリジナリティある見せ方をしていたか。\n・秀逸なハッシュタグが使用されており、表現方法が参考になったか。\n・テキストに商材への熱意が伝わり、ユーザーの共感を呼ぶ内容だったか。\nなど。';

  const tooltipTextBonus =
    '企業やブランドがインフルエンサーに対して商品やサービスをPRしてもらう場合に、企業やブランドとの関係性を明らかにするために必要です。また、そのまま広告として配信できるので、実際の使用感などを合わせた効果的な広告配信が行えます。';

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[1096px] rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="mb-[16px] flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">フィードバック</span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start gap-2 p-px">
                  <div className="flex w-[1084px] flex-col gap-6 self-stretch">
                    {/* account information */}
                    <AccountInfo
                      projectType={props.accountInfo.projectType as ProjectType}
                      thumbnail={props.accountInfo.thumbnail}
                      evaluation={props.accountInfo.evaluation}
                      evaluationCount={props.accountInfo.evaluationCount}
                      snsType={props.accountInfo.snsType}
                      followers={props.accountInfo.followers}
                      isReserved={props.accountInfo.isReserved}
                      snsAccountNumber={props.accountInfo.snsAccountNumber}
                    />
                    <div className="flex flex-col items-start gap-4">
                      <div className="flex flex-col items-start gap-2 self-stretch">
                        <div className="flex items-start gap-2">
                          <span className="text-sm font-semibold leading-5">インフルエンサーへの評価</span>
                          <div className="group relative pl-[7.33px]">
                            <QuestionIcon />
                            <Tooltip
                              isSpeechBubble={true}
                              variant="gray"
                              toolTipClassName="px-3 py-2"
                              tooltipText={tooltipTextEvaluation}
                              className="absolute left-[-34px] top-[-200px] hidden w-[520px] whitespace-pre-wrap text-xs group-hover:inline-block"
                            ></Tooltip>
                          </div>
                        </div>
                        <div className="flex items-start gap-1">
                          <span className="text-sm font-medium leading-5 text-gray-500">
                            インフルエンサーへの評価はデフォルトで5段階評価中4で設定されています。
                          </span>
                        </div>
                      </div>
                      <div className="flex w-[138px] items-center">
                        <SelectMenus
                          title=""
                          options={[
                            { value: '1', label: '', children: FeedbackStars(1) },
                            { value: '2', label: '', children: FeedbackStars(2) },
                            { value: '3', label: '', children: FeedbackStars(3) },
                            { value: '4', label: '', children: FeedbackStars(4) },
                            { value: '5', label: '', children: FeedbackStars(5) },
                          ]}
                          widthSize="w-[138px]"
                          className="absolute z-50 text-sm font-medium leading-5 text-gray-700"
                          value={evaluation.toString()}
                          buttonClass="pl-[17px]"
                          onChange={(e: any) => {
                            setEvaluation(Number(e.value));
                          }}
                        ></SelectMenus>
                      </div>
                    </div>

                    {!!props.goodQualityCount?.possibleCount && props.projectType == ProjectType.Spirit && (
                      <>
                        <div className="flex flex-col items-start gap-4">
                          <div className="flex flex-col items-start gap-2 self-stretch">
                            <div className="flex items-start gap-2">
                              <span className="text-sm font-semibold leading-5">Good Quality ボーナス付与</span>
                              <div className="group relative pl-[7.33px]">
                                <QuestionIcon />
                                <Tooltip
                                  isSpeechBubble={true}
                                  variant="gray"
                                  toolTipClassName="px-3 py-2"
                                  tooltipText={tooltipTextBonus}
                                  className="absolute left-[-34px] top-[-120px] hidden w-[520px] whitespace-pre-wrap text-xs group-hover:inline-block"
                                ></Tooltip>
                              </div>
                            </div>
                            <div className="flex items-start gap-1">
                              <span className="text-sm font-medium leading-5 text-gray-500">
                                Good Quality ボーナス付与候補を{props.goodQualityCount.possibleCount}
                                人まで選択することができます。付与されたインフルエンサーはプラスで報酬を得られれます。
                              </span>
                            </div>
                          </div>
                          <div className="flex w-[291px] items-end justify-between">
                            <SelectMenus
                              title=""
                              options={[
                                { value: '1', label: '候補にする' },
                                { value: '2', label: '候補にしない' },
                              ]}
                              widthSize="w-[142px]"
                              className="absolute text-sm font-medium leading-5 text-gray-700"
                              value={isGoodQualityCandidate ? '1' : '2'}
                              onChange={(e: any) => {
                                if (e.value === '1' && !isGoodQualityCandidate) {
                                  setGoodQualityCandidateCount(GoodQualityCandidateCount + 1);
                                  setIsGoodQualityCandidate(true);
                                }
                                if (e.value === '2' && isGoodQualityCandidate) {
                                  setGoodQualityCandidateCount(GoodQualityCandidateCount - 1);
                                  setIsGoodQualityCandidate(false);
                                }
                              }}
                            ></SelectMenus>
                            <div className="flex w-[133px] justify-between">
                              <div>
                                <span className="text-sm font-normal leading-5 text-gray-500">付与候補者数</span>
                              </div>
                              <div className="flex w-[41px] items-end justify-between">
                                <div>
                                  <span className="text-base font-bold leading-6 text-gray-500">
                                    {GoodQualityCandidateCount}/{props.goodQualityCount.possibleCount}
                                  </span>
                                </div>
                                <div>
                                  <span className="text-xs font-semibold leading-4">人</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-4">
                          <div className="flex flex-col items-start gap-2 self-stretch">
                            <div className="flex items-start gap-2">
                              <span className="text-sm font-semibold leading-5">
                                Good Quality ボーナス候補理由（任 意）
                              </span>
                            </div>
                          </div>
                          <div className="flex w-[1049px] flex-col items-start">
                            <TextArea
                              placeholder="候補にする理由をご記入ください。"
                              className="h-[120px]"
                              width="w-[1049px]"
                              value={feedbackComment}
                              onChange={(e: any) => {
                                setFeedbackComment(e.target.value);
                              }}
                              isError={feedbackCommentValidator !== ''}
                            />
                            {feedbackCommentValidator !== '' && (
                              <p className="mt-[10px] text-sm font-normal leading-5 text-red-500">
                                {feedbackCommentValidator}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                        style={{ background: '#007CC2' }}
                        onClick={() => onSave(GoodQualityCandidateCount !== 0)}
                      >
                        保存する
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={onClose}
                      >
                        キャンセル
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            {props.adopterSlider.canNext && (
              <div>
                <button
                  className="pl-[35.8px]"
                  onClick={() => props.adopterSlider.nextAdopter(props.adopter.adoptedInfluencerId)}
                >
                  <img src={Arrow_Right} alt="" className="size-[40px]" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
