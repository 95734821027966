import { ReactComponent as QuestionIcon } from '@/assets/icons/question-mark-circle.svg';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';

export type DetailPopver = {
  title: string;
  description: string;
  tooltipContent: string;
  optionalElement?: JSX.Element;
};

export const DetailPopver = (props: DetailPopver) => {
  return (
    <div className="flex flex-col items-start gap-4">
      <div className="flex flex-col items-start gap-2 self-stretch">
        <div className="flex items-start gap-2">
          <span className="text-sm font-semibold leading-5">{props.title}</span>
          <div className="group relative pl-[7.33px]">
            <QuestionIcon />
            <Tooltip
              isSpeechBubble={true}
              variant="gray"
              toolTipClassName="px-3 py-2"
              tooltipText={props.tooltipContent}
              className="absolute left-[-34px] top-[-200px] hidden w-[520px] whitespace-pre-wrap text-xs group-hover:inline-block"
            ></Tooltip>
          </div>
        </div>
        <div className="flex items-start gap-1">
          <span className="text-sm font-medium leading-5 text-gray-500">{props.description}</span>
        </div>
      </div>
      <div className="flex items-center">{props.optionalElement}</div>
    </div>
  );
};
