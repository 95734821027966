import React, { Fragment, useState } from 'react';

import { GetCreationOutput } from '@/api/creation';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';

import { Comment, Reply } from '../interfaces/PRSTProductionManagementInterface';

import { ReactComponent as BoldIcon } from './assets/bx-bold.svg';
import { ReactComponent as FontcolorIcon } from './assets/bx-font-color.svg';
import { ReactComponent as ImageAddIcon } from './assets/bx-image-add.svg';
import { ReactComponent as ItalicIcon } from './assets/bx-italic.svg';
import { ReactComponent as UnderlineIcon } from './assets/bx-underline.svg';
import { ReactComponent as CommentAvatarIcon } from './assets/Comment_Avatar.svg';
import { ImageCreateView } from './ImageCreateView';

interface ImageViewProps {
  postDetail: GetCreationOutput;
  setPostDetail: React.Dispatch<React.SetStateAction<GetCreationOutput>>;
  updatePost: () => void;
}

export const ImageView = (props: ImageViewProps) => {
  const [imageComments, setImageComments] = useState<Comment[]>([]);
  const [imageComment, setImageComment] = useState<boolean>(false);
  const [comment, setComment] = useState('');
  const [reply, setReply] = useState('');
  const [replyWindow, setReplyWindow] = useState<number | null>(null);
  const [replyWindow2, setReplyWindow2] = useState<number | null>(null);

  const handleImageCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleImageCommentReplysChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(e.target.value);
  };

  const handleAddImageComment = () => {
    if (comment !== '') {
      console.log('imageComments', imageComments);
      console.log('送信ボタンが押されました。');
      const newImageComments: Comment = {
        accountName: '',
        comment: comment,
        replys: [],
      };
      setImageComments((prev) => [...prev, newImageComments]);
      setComment('');
    } else {
      return;
    }
  };

  const handleReplyImageComment = (index: number) => {
    if (reply !== '') {
      const newReply: Reply = {
        accountName: '',
        reply: reply,
      };
      imageComments[index].replys.push(newReply);
      setReplyWindow(null);
      setReply('');
    } else {
      setReplyWindow(null);
      setReply('');
    }
  };

  const handleCloseReplys = (index: number) => {
    setReplyWindow2(index);
    setReplyWindow2(null);
  };

  const handleReplyInput = (index: number) => {
    setReplyWindow(index);
  };

  const handleOpenReply = (index: number) => {
    setReplyWindow2(index);
  };

  const CommentWindow = () => {
    return (
      <>
        <div className="absolute right-0 top-0 z-20 w-[406px] bg-white px-[24px] pt-[40px] shadow-[0_10px_10px_rgba(0,0,0,0.04),0_20px_25px_rgba(0,0,0,0.1)]">
          <div className="mb-[22px] flex w-full justify-end">
            <button className="mr-0">
              <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setImageComment(false)} />
            </button>
          </div>
          <form className="mb-[24px] h-[184px] w-[358px]">
            <label htmlFor="comment" className="h-[28px] w-[220px] text-xl font-bold leading-7">
              イメージ案へのコメント
            </label>
            <div className="my-[24px] h-[108px] w-[358px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
              <div className="h-[92px] w-[326px]">
                <textarea
                  className="block h-[58px] w-full resize-none border-0 bg-transparent px-0 pb-0 pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                  placeholder="新規コメント"
                  value={comment}
                  onChange={handleImageCommentsChange}
                  defaultValue={''}
                />
                <div className="flex h-[34px] w-[326px] items-end justify-between">
                  <div className="flex h-[24px] w-[164px] items-center justify-between">
                    <button className="size-[16px]">
                      <ImageAddIcon width={16} height={16} fill="#000000" />
                    </button>
                    <div className="h-[24px] w-px border-l border-[#0000001A] "></div>
                    <div className="flex h-[17px] w-[117px] items-center justify-between">
                      <button className="size-[16px]">
                        <BoldIcon width={16} height={16} fill="#000000" />
                      </button>
                      <button className="size-[16px]">
                        <ItalicIcon width={16} height={16} fill="#000000" />
                      </button>
                      <button className="size-[16px]">
                        <UnderlineIcon width={17} height={17} fill="#000000" />
                      </button>
                      <button className="size-[16px]">
                        <FontcolorIcon width={17} height={17} fill="#000000" />
                      </button>
                    </div>
                  </div>
                  <Button
                    onClick={handleAddImageComment}
                    className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
                  >
                    送信
                  </Button>
                </div>
              </div>
            </div>
            <div className="h-px w-[358px] border-t border-[#DDDDDD]"></div>
          </form>
          <div className="w-[358px]">
            {imageComments.map((imageComment, index) => (
              <div key={index} className="mb-[24px] py-[8px]">
                <div className="mb-[8px] flex h-[60px] w-[358px] justify-between py-[8px]">
                  <div>
                    <CommentAvatarIcon width={24} height={24} />
                  </div>
                  <div className="h-[44px] w-[322px]">
                    <div className="flex justify-between">
                      <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">Whitney Francis</div>
                      <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                    </div>
                    <p className="text-sm font-normal leading-5 text-gray-700">{imageComment.comment}</p>
                  </div>
                </div>
                {imageComment.replys.length > 0 ? (
                  replyWindow2 === index ? (
                    <div>
                      <button className="text-sm font-medium leading-5" onClick={() => handleCloseReplys(index)}>
                        <div className="flex h-[24px] w-[116px] justify-between">
                          <ChevronDownIcon width={24} height={24} fill="#6B7280" />
                          <div className="h-[20px] w-[84px] items-center text-sm font-medium leading-5 text-gray-500">
                            返信を閉じる
                          </div>
                        </div>
                      </button>
                      <div>
                        {imageComments[index].replys.map((reply, i) => (
                          <div key={i} className="mb-[8px] w-[358px] py-[8px] pl-[24px]">
                            <div className="mb-[8px] flex justify-between py-[8px]">
                              <div>
                                <CommentAvatarIcon width={24} height={24} />
                              </div>
                              <div className="w-[298px]">
                                <div className="flex justify-between">
                                  <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">
                                    Whitney Francis
                                  </div>
                                  <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                                </div>
                                <p className="text-sm font-normal leading-5 text-gray-700">{reply.reply}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="text-sm font-medium leading-5 text-[#007CC2]"
                        onClick={() => handleOpenReply(index)}
                      >
                        <div className="flex h-[24px] w-[109px]">
                          <ChevronDownIcon width={24} height={24} fill="#007CC2" />
                          <div className="h-[20px] w-[77px] items-center text-sm font-medium leading-5">
                            返信（{imageComment.replys.length}）
                          </div>
                        </div>
                      </button>
                    </div>
                  )
                ) : null}
                {replyWindow === index ? (
                  <div className="h-[124px] w-[358px] py-[8px] pl-[24px]">
                    <div className="h-[108px] w-[334px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
                      <div className="h-[92px] w-[302px]">
                        <textarea
                          className="block h-[58px] w-full resize-none border-0 bg-transparent px-0 pb-0 pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                          placeholder="返信"
                          value={reply}
                          onChange={handleImageCommentReplysChange}
                          defaultValue={''}
                        />
                        <div className="flex h-[34px] w-[302px] items-end justify-between">
                          <div className="flex h-[24px] w-[164px] items-center justify-between">
                            <button className="size-[16px]">
                              <ImageAddIcon width={16} height={16} fill="#000000" />
                            </button>
                            <div className="h-[24px] w-px border-l border-[#0000001A] "></div>
                            <div className="flex h-[17px] w-[117px] items-center justify-between">
                              <button className="size-[16px]">
                                <BoldIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="size-[16px]">
                                <ItalicIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="size-[16px]">
                                <UnderlineIcon width={17} height={17} fill="#000000" />
                              </button>
                              <button className="size-[16px]">
                                <FontcolorIcon width={17} height={17} fill="#000000" />
                              </button>
                            </div>
                          </div>
                          <Button
                            onClick={() => handleReplyImageComment(index)}
                            className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
                          >
                            送信
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {(imageComments[index].replys.length === 0 || replyWindow2 === index) && replyWindow !== index ? (
                  <div className="flex h-[24px] w-[60px] justify-between">
                    <div className="size-[24px]"></div>
                    <button
                      className="w-[28px] text-sm font-semibold leading-5 text-gray-500"
                      onClick={() => handleReplyInput(index)}
                    >
                      返信
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <ImageCreateView
        postDetail={props.postDetail}
        setPostDetail={props.setPostDetail}
        updatePost={props.updatePost}
      />
      {imageComment && <CommentWindow />}
    </>
  );
};
