import { SnsType } from '@/api/analysisSpirit/api';
import { InstagramPostType } from '@/types';

import { MediaData } from '../../features/project_analysis/components/posts/modals/modalCommonParts';
import { modalArgsEnum, stringToEnum } from '../../features/project_analysis/interfaces/modalInterface';

import { Slider } from './Slider';

export interface PreviewCommonPostProps {
  snsType: string;
  posted_method: modalArgsEnum;
  mediaData: MediaData;
  postType?: InstagramPostType;
  body?: string;
  url?: string;
}

// SNSアカウント詳細_投稿インサイト_プレビュー部分表示部品（画像、動画、文字等）
export const PreviewCommonPost = ({
  snsType,
  mediaData,
  postType = 'Feed',
  body = '',
  url = '',
}: PreviewCommonPostProps) => {
  const postTypeUse = postType?.toString() || '';
  const mediaType = mediaData.mediaType ?? '';
  const mediaTypeEnum = stringToEnum(mediaType) ?? '';
  console.log('mediaTypeEnum:', mediaTypeEnum);

  const renderMediaContent = () => {
    switch (mediaTypeEnum) {
      case modalArgsEnum.VIDEO:
        return mediaData.sources[0] ? (
          <div className="flex flex-col items-center gap-4">
            <div className="flex h-[372px] w-[400px] items-center justify-center rounded-lg border border-[#D3DADF] bg-[#D3DADF]">
              <video controls src={mediaData.sources[0]} className="h-[372px] w-[400px] rounded-lg">
                <track kind="captions" />
              </video>
            </div>
          </div>
        ) : (
          <></>
        );
      case modalArgsEnum.IMAGE:
        return (
          <div>
            <img src={mediaData.sources[0]} alt="sampleX" className="h-[372px] w-[400px] rounded-lg object-cover" />
          </div>
        );
      case modalArgsEnum.MULTIPLEMEDIA:
        return (
          <div className="flex flex-col gap-4">
            <Slider mediaDatas={mediaData.sources} width={100} height={400} />
          </div>
        );
      case modalArgsEnum.CAROUSEL:
        return (
          <div className="mb-5 flex w-full flex-col items-center gap-4">
            <Slider mediaDatas={mediaData.sources} width={100} height={400} />
          </div>
        );
      default:
        return null;
    }
  };

  if (snsType === SnsType.X) {
    return (
      <div className="flex w-[432px] flex-col items-start gap-[15px]">
        <div
          className="flex flex-col items-start gap-[10px] self-stretch bg-white px-4 pb-4 pt-3"
          id="twitter-post-preview"
        >
          <div className="flex flex-col items-start gap-3 self-stretch" id="content">
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div id="main-content" className="flex flex-col items-start gap-2 self-stretch">
                <div className="flex flex-col items-start gap-4 self-stretch">
                  <div className="mb-8 flex flex-1 flex-col items-start gap-[10px] self-stretch">
                    {renderMediaContent()}
                  </div>
                  <span className="whitespace-pre-wrap break-all text-xl font-medium leading-7">{body}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (snsType === SnsType.Youtube) {
    return (
      <div className="flex flex-col items-start gap-[15px]">
        <div className="w-[360px] rounded-lg">
          <div className="flex flex-col items-start gap-4 self-stretch">
            <div className="flex flex-1 flex-col items-start gap-[10px] self-stretch">{renderMediaContent()}</div>
            <span className="whitespace-pre-wrap break-all text-xl font-medium leading-7">{body}</span>
            <a className="mt-4 whitespace-pre-wrap text-xl font-medium leading-7 text-[#007CC2]" href={url}>
              {url}
            </a>
          </div>
        </div>
      </div>
    );
  } else if (snsType === SnsType.Instagram) {
    return <PreviewPost postType={postTypeUse} body={body ?? ''} renderMediaContent={renderMediaContent} />;
  } else if (snsType === SnsType.TikTok) {
    return (
      <div className="flex h-[1064px] w-[1049px] items-start gap-10">
        <div className="flex w-[414px] flex-col items-start">
          <div className="flex flex-col items-start gap-4 self-stretch">
            <div className="flex flex-1 flex-col items-start gap-[10px] self-stretch">{renderMediaContent()}</div>
            <span className="whitespace-pre-wrap break-all text-xl font-medium leading-7">{body}</span>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export const ImageDisplay = (sources: string[]) => (
  <div>
    {sources.map((src, index) => (
      <img key={index} src={src} alt={`Post ${index + 1}`} />
    ))}
  </div>
);
export const VideoDisplay = (sources: string[]) => (
  <div>
    {sources.map((src, index) => (
      <video key={index} controls>
        <source src={src} type="video/mp4" />
        <track kind="captions" />
      </video>
    ))}
  </div>
);

export const CarouselDisplay = (sources: string[]) => (
  <div className="flex space-x-4 overflow-x-scroll">
    {sources.map((src, index) => (
      <img key={index} src={src} alt={`Post ${index + 1}`} />
    ))}
  </div>
);

interface PreviewPostProps {
  postType: string;
  body: string;
  renderMediaContent: () => JSX.Element | null;
}

export const PreviewPost = ({ postType, body, renderMediaContent }: PreviewPostProps) => {
  return (
    <div className={`flex  w-[360px] flex-col items-start gap-[15px] rounded-lg`}>
      {postType === 'Story' || postType === 'Real' ? (
        <div className="flex gap-[10px] rounded bg-white pb-3">
          <div className="flex w-[360px] flex-col items-start gap-[5px]">
            <div className="flex flex-col items-start gap-[10px]">
              <div id="main-content" className="flex flex-col items-start gap-2 self-stretch">
                <div className="flex flex-col items-start gap-4 self-stretch">
                  <div className="flex flex-1 flex-col items-start gap-[10px] self-stretch">{renderMediaContent()}</div>
                  <span className="mt-4 whitespace-pre-wrap break-all text-lg font-medium leading-7">{body}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-[360px] items-center justify-between"></div>
          <div id="main-content" className="flex flex-col items-start gap-2 self-stretch">
            <div className="flex flex-col items-start gap-4 self-stretch">
              <div className="flex flex-1 flex-col items-start gap-[10px] self-stretch">{renderMediaContent()}</div>
              <span className="mt-4 whitespace-pre-wrap break-all text-lg font-medium leading-7">{body}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
