import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment, useState } from 'react';

// import { PreviewPost } from '@/components/Preview/twitter';

import Arrow_Right from '@/assets/icons/Arrow_Right.png';
import { ReactComponent as BookmarkIcon } from '@/assets/icons/bx-bookmark.svg';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PostIcon } from '@/assets/icons/bx-notepad.svg';
import { ReactComponent as RepostIcon } from '@/assets/icons/bx-repost.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import { ReactComponent as ShowIcon } from '@/assets/icons/bx-show.svg';
import { ReactComponent as UserPlusIcon } from '@/assets/icons/bx-user-plus.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/bx-user.svg';
import { ReactComponent as StarStrokeIcon } from '@/assets/icons/star-stroke.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
import youtube from '@/assets/youtube.png';
import { InstagramPostType } from '@/types/index';
import { convertInstgrampostType } from '@/utils/sns';

import { modalArgsEnum } from '../../../interfaces/modalInterface';
import { tableBodyProps } from '../emeraldPost';
import { SummaryFeed, SummaryStories, SummaryReal } from '../instgram/summary';
import { InsightPost } from '../twitter/summary';

import AccountInsight from './tabs/AccountInsight';
import FollowerAttributeInstagram from './tabs/FollwerAttributes';
import AccountInsightInstagram from './tabs/PostInsightInstgram';
import AccountInsightTwitter from './tabs/PostInsightTwitter';
import RecruitmentInformation from './tabs/RecruitmentInformation';
import FollowerAttributeTwitter from './tabs/twiiter/FollwerAttributes';

export interface modalEmeraldProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accountInformation: tableBodyProps;
  // postType: InstagramPostType;
  posted_method: modalArgsEnum;
  postType: InstagramPostType;
}
interface TabProps {
  name: string;
  key: string;
}

// Dupricated:共通版の src/features/project_analysis/components/posts/modals/modalCommon.tsx を使用してください
export default function ModalEmerald(props: modalEmeraldProps) {
  const [tabKey, setTabKey] = useState<string>('accoutPost');
  const selectTab = (): TabProps[] => {
    if (props.accountInformation.sns === youtube) {
      return [
        {
          name: '投稿情報',
          key: 'accoutPost',
        },
        {
          name: 'アカウントインサイト',
          key: 'accountInsight',
        },
        {
          name: 'チャンネル登録者属性',
          key: 'subscriber',
        },
        // {
        //   name: '採用前情報',
        //   key: 'information',
        // },
      ];
    } else {
      return [
        {
          name: '投稿情報',
          key: 'accoutPost',
        },
        {
          name: 'アカウントインサイト',
          key: 'accountInsight',
        },
        {
          name: 'フォロワー属性',
          key: 'follower',
        },
        // {
        //   name: '採用前情報',
        //   key: 'information',
        // },
      ];
    }
  };
  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };

  const handeRightTab = () => {
    const index = selectTab().findIndex((tab: TabProps) => tab.key == tabKey);
    if (index == selectTab().length - 1) {
      setTabKey(selectTab()[0].key);
    } else {
      setTabKey(selectTab()[index + 1].key);
    }
  };

  const selectContent = () => {
    if (tabKey == 'accoutPost') {
      const selected = `1回目（${convertInstgrampostType(props.postType)}）`;
      return props.accountInformation.snsWord === 'twitter' ? (
        <AccountInsightTwitter
          id={props.accountInformation.id}
          avator={props.accountInformation.icon}
          insight={InsightPost('w-[285px]', '0.25', '51,897', '71,897', '51,897', '71,897', '51,897')}
          selected="1回目ポスト"
          body={`イーロン・マスクによるTwitter買収の投資家ピッチの内容🔥\n- 売上$5bn→$28bn\n- 広告売上比率50%以下\n- ユーザー数を2.1億人→ 9.3億人\n- サブスク型新プロダクトを1億人\n- ペイメント売上を1.3bn\n\na16z、Binance、Fidelityがこれに乗った🤯`}
          postTime="2021/02/21 19:10"
          posted_method={props.posted_method}
        />
      ) : props.accountInformation.snsWord === 'instagram' ? (
        <AccountInsightInstagram
          id={props.accountInformation.id}
          avator={props.accountInformation.icon}
          postType={props.postType}
          insight={
            props.postType == 'Feed'
              ? SummaryFeed(
                  'w-[321px]',
                  '71,897',
                  '51,897',
                  '0.25',
                  '51,897',
                  '71,897',
                  '51,897',
                  '71,897',
                  '71,897',
                  '2,257'
                )
              : props.postType == 'Story'
              ? SummaryStories('w-[321px]', '71,897', '51,897', '2,257', '2,257', '51,897')
              : SummaryReal('w-[321px]', '51,897', '0.25', '51,897', '51,897', '51,897', '51,897', '51,897', '51,897')
          }
          selected={selected}
        />
      ) : (
        <></>
      );
    } else if (tabKey == 'accountInsight') {
      console.log(props.accountInformation.snsWord);
      if (props.accountInformation.snsWord === 'instagram') {
        return (
          <AccountInsight
            insights={[
              {
                left: {
                  icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'フォロワー数',
                  value: '51,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <UserPlusIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'フォロー数',
                  value: '2,257',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <ShowIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'インプレッション数',
                  value: '51,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'エンゲージメント率',
                  value: '0.25%',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'エンゲージメント数',
                  value: '51,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'いいね数（平均）',
                  value: '71,897',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'コメント数(平均)',
                  value: '51,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <ShareIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'シェア数（平均）',
                  value: '71,897',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <BookmarkIcon width={24} height={24} fill="#FFFFFF" />,
                  label: '保存数（平均）',
                  value: '71,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <UserIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'ユニークアカウント数',
                  value: '2,257',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <UserIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'ユニークアカウント率',
                  value: '30.00%',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
                  label: '投稿数',
                  value: '2,257',
                  className: 'w-[396px]',
                },
              },
            ]}
          />
        );
      } else {
        return (
          <AccountInsight
            insights={[
              {
                left: {
                  icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'フォロワー数',
                  value: '51,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <UserPlusIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'フォロー数',
                  value: '2,257',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'エンゲージメント率',
                  value: '0.25%',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'エンゲージメント数',
                  value: '51,897',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'いいね数（平均）',
                  value: '71,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
                  label: '返信数(平均)',
                  value: '51,897',
                  className: 'w-[396px]',
                },
              },
              {
                left: {
                  icon: <RepostIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'リポスト数(平均)',
                  value: '71,897',
                  className: 'w-[396px]',
                },
                right: {
                  icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
                  label: 'ポスト数',
                  value: '51,897',
                  className: 'w-[396px]',
                },
              },
            ]}
          />
        );
      }
    } else if (tabKey == 'follower' || tabKey == 'subscriber') {
      if (props.accountInformation.snsWord === 'instagram') {
        return <FollowerAttributeInstagram />;
      } else {
        return <FollowerAttributeTwitter />;
      }
    } else {
      return (
        <RecruitmentInformation
          enthusiasm={`普段から、ギフトチケットを利用しているので具体的かつ比較しながら、貴社のサービスを紹介できます！`}
          comments={`フォロワー数が多く、リーチ数が見込めます。`}
          introduction={`元美容部員の美容系動画クリエイター、美容ライターのアカウント。\nコスメ紹介、メイク動画をアップしています。`}
          strong={`プチプラからハイブランドまで幅広くコスメを紹介しています。\n元美容部員の経験を活かし、真似しやすいメイク方法をアップしています。`}
          achievements={`・アカウント運用から1年でフォロワー数1万人突破\n・2023年1月 株式会社○○のPR案件を担当\n・コスメブランド○○をプロデュース"`}
        />
      );
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[1096px] overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">SNSアカウントの詳細</span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start gap-2 p-[1px]">
                  <div className="flex w-[1084px] flex-col gap-6 self-stretch">
                    {/* account information */}
                    <div className="flex h-[71px] items-start gap-[15px] self-stretch">
                      <img src={props.accountInformation.icon} alt="" />
                      <div className="flex flex-col items-start gap-[10px] self-stretch">
                        <div className="flex items-center gap-1">
                          <span className="text-sm font-medium text-gray-500">ID {props.accountInformation.id}</span>
                        </div>
                        <div className="flex items-center gap-[19px]">
                          <div className="flex items-center">
                            <div className="flex items-center gap-[3px]">
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarStrokeIcon height={16} width={16} fill="#ECC94B" />
                            </div>
                            <div>
                              <span className="text-sm font-medium text-gray-500">（13）</span>
                            </div>
                          </div>
                          <div className="flex items-center gap-[2px]">
                            <span className="text-sm font-medium text-gray-500">SNS</span>
                            <img src={props.accountInformation.sns} alt="" className="h-[17.9px] w-[17.9px]" />
                          </div>
                          <div className="flex items-center gap-[6px]">
                            <div className="flex items-start gap-1">
                              <span className="text-sm font-medium text-gray-500">フォロワー</span>
                              <span className="text-sm font-medium text-gray-500">10,000</span>
                            </div>
                          </div>
                          {/* <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-500">補欠採用</span>
                            <Badge color="green" round="xl" className="px-[10px]">
                              あり
                            </Badge>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* tabs */}
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <div className="inline-flex w-[1280px] items-start gap-8  border-b-[1px] border-gray-200">
                        {selectTab().map((tab: TabProps) => {
                          return (
                            <button
                              key={tab.key}
                              className={clsx('flex flex-col items-center justify-center')}
                              onClick={() => {
                                handleTabSelct(tab.key);
                              }}
                            >
                              <div
                                className={clsx(
                                  'flex items-center justify-center px-1 pb-4 pt-0',
                                  tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                )}
                              >
                                <span className="text-sm font-medium leading-5">{tab.name}</span>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {/* contents */}
                    {selectContent()}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div>
              <button className="pl-[35.8px]" onClick={() => handeRightTab()}>
                <img src={Arrow_Right} alt="" className="h-[40px] w-[40px]" />
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
