import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';

import {
  EmeraldPostInformation,
  EmeraldPostPlan,
  EmeraldPostSnsDetail,
  ProjectStatus,
  PutEmeraldPostPlanInput,
} from '@/api/emeraldPost';
import { ProjectType } from '@/api/project';
import Help from '@/assets/icons/bx-help-circle.png';
import NoImage from '@/assets/images/NoImage.png';
import SmartPhone from '@/assets/images/SmartPhone.png';
import instagram from '@/assets/instagram.png';
import Xtwitter from '@/assets/TwitterLogo.png';
import { Button } from '@/components/Elements';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import { OptionAllType, OptionMapType } from '@/components/ProjectManagements/Applicant/Contents/common/Types/ArgsType';
import Estimate from '@/components/ProjectManagements/Components/Estimate';
import KpiComponent from '@/components/ProjectManagements/Components/KPI';
import { DetailImages } from '@/components/ProjectManagements/Modals/DetailImages';
import Option from '@/components/ProjectManagements/Modals/Option';
import {
  PutEmeraldPostInformation,
  PutEmeraldPostMedia,
  PutEmeraldPostOption,
  putEmeraldPostPlan,
  PutEmeraldPostSnsDetail,
} from '@/hooks/EmeraldPost';
import { insertPostApplicationInfo } from '@/hooks/project';
import { getImageUrl } from '@/utils/image';

import ErrorImage from '../../assets/samples/bx-error-circle.svg';
import { projectDetail } from '../../interfaces/emeraldProjectInterface';
import ExpectedProjectDurationDisplay from '../../uilts/ExpectedProjectDurationDisplay';
import numberFormatComma from '../../uilts/NumberFormatComma';
import { AnnouncementDetails } from '../Modals/AnnouncementDetails';
import { DeliveryAgencyService } from '../Modals/DeliveryAgencyService';
import { DeliveryPeriod } from '../Modals/DeliveryPeriod';
import { PricingSettings } from '../Modals/PricingSettings';
import { SnsDeliveryDetails } from '../Modals/SnsDeliveryDetails';

export default function Recruitment(props: projectDetail) {
  const navigate = useNavigate();
  const [deliveryAgencyServiceModalIsOpen, setDeliveryAgencyServiceModalIsOpen] = useState(false);
  const [pricingSettingsModalIsOpen, setPricingSettingsModalIsOpen] = useState(false);
  const [announcementDetailsModalIsOpen, setAnnouncementDetailsModalIsOpen] = useState(false);
  //const [imageModalIsOpen, setImageModalIsOpen] = useState(false);

  const isDraft = (projectStatus: ProjectStatus): boolean => projectStatus === ProjectStatus.PostDraft;
  const isReview = (projectStatus: ProjectStatus): boolean => {
    return projectStatus === ProjectStatus.PostReview || projectStatus === ProjectStatus.Review;
  };
  const isWait = (projectStatus: ProjectStatus): boolean => projectStatus === ProjectStatus.WaitPost;
  const isPosting = (projectStatus: ProjectStatus): boolean => projectStatus === ProjectStatus.Posting;

  const [deliveryPeriodModalIsOpen, setDeliveryPeriodModalIsOpen] = useState(false);
  const [dialogIsApplyed, setDialogIsApplyed] = useState<boolean>(props.isApplyed ? props.isApplyed : false);
  const [dialogPlan, setDialogPlan] = useState<EmeraldPostPlan>(
    props.selectPlanId
      ? props.plans.filter((plan) => plan.id == props.selectPlanId)[0]
      : { id: '', name: '', price: 0, heartCounts: 0, isPredictReach: false, minPredictReach: 0 }
  );

  const searchParams = new URLSearchParams(window.location.search);
  const projectId = searchParams.get('projectId') || '';

  //  plan引数
  const PutEmeraldPostPlan = (projectId: string) => async (request: PutEmeraldPostPlanInput) => {
    const response = await putEmeraldPostPlan(projectId)(request);
    props.setSelectPlanId(response.plan.selectedPlanId);
    setDialogPlan(props.plans.filter((plan) => plan.id === response.plan.selectedPlanId)[0]);
  };

  // notice引数
  const title = props.announcementDetails.title ? props.announcementDetails.title : null;
  const html = props.announcementDetails.notice ? ReactHtmlParser(props.announcementDetails.notice) : null;
  const PutEmeraldPostNotice = (projectId: string) => async (annouce: EmeraldPostInformation) => {
    const response = await PutEmeraldPostInformation(projectId, annouce);
    props.setAnnouncementDetails(response.information);
  };

  // Option引数
  const optionModalMap: OptionMapType = new Map([
    [
      'goodQuality',
      {
        title: 'Good Qualityボーナス',
        radioValues: [
          { value: '0', label: 'なし' },
          { value: '1', label: 'あり' },
        ],
        tipContent:
          'Good Quality ボーナスは、インフルエンサーが投稿したコンテンツのクオリティが高い場合に支払われるボーナスです。',
      },
    ],
    [
      'rewrite',
      {
        title: 'リライト',
        radioValues: [
          { value: '0', label: 'なし' },
          { value: '1', label: 'あり' },
        ],
        tipContent: `インフルエンサーの投稿に関して、著作権等は投稿したインフルエンサーに帰属します。
    そのため、インフルエンサーの投稿を貴社にて二次利用をご希望される場合には、インフルエンサーに許諾をとった上で二次利用費が発生いたします。
    具体的な金額は広告のクリエイティブとして利用するのか、会社HPやECサイトで利用するのか、顔出しされているクリエイティブかどうかなどで変わってきますので、営業担当にお問い合わせください。`,
      },
    ],
    [
      'deliverImage',
      {
        title: '画像納品',
        radioValues: [
          { value: '0', label: 'なし' },
          { value: '1', label: 'あり' },
        ],
        tipContent: '別途費用がかかりますが、インフルエンサーの選定をLIDDELL側で代行することが可能です。',
      },
    ],
  ]);
  const PutEmeraldPostOptionUpdate = (projectId: string) => async (option: OptionAllType) => {
    await PutEmeraldPostOption(projectId, option);
    props.setSummaryOption(option);
  };

  // react-draft-wysiwyg以外を使う場合は、ContentStateを使う必要がある(JSONになる可能性あり)
  //const [dialogDetailImages, setDialogDetailImages] = useState<string[]>(props.images || []);
  const setSnsDeliveryDetailsModalIsChange = (isOpen: boolean) => {
    props.setDeliveryDetails({
      snsDeliveryDetails: props.deliveryDetails.snsDeliveryDetails,
      snsDeliveryDetailsModalIsOpen: isOpen,
    });
    console.log('setSnsDeliveryDetailsModalIsChange', props.deliveryDetails.snsDeliveryDetailsModalIsOpen);
  };

  const modalSetters = [
    setDeliveryAgencyServiceModalIsOpen,
    setPricingSettingsModalIsOpen,
    setAnnouncementDetailsModalIsOpen,
    setSnsDeliveryDetailsModalIsChange,
    setDeliveryPeriodModalIsOpen,
  ];

  const handleEditClick = (index: number) => {
    if (index == 5) {
      setImageModalIsOpen(true);
    }
    modalSetters.forEach((setModalOpen, idx) => {
      console.log(setModalOpen.name, idx === index);
      setModalOpen(idx === index);
    });
  };

  const onCloseDeliveryAgencyService = (isSave: boolean) => {
    setDeliveryAgencyServiceModalIsOpen(false);
    if (isSave) {
      props.setIsApplyed(dialogIsApplyed);
    } else {
      setDialogIsApplyed(props.isApplyed ?? props.isApplyed);
    }
  };

  const onClosePricingSettingsModalIsOpen = () => {
    setPricingSettingsModalIsOpen(false);
  };

  const onCloseDeliveryPeriodModalIsOpen = (isSave: boolean) => {
    setDeliveryPeriodModalIsOpen(false);
    if (isSave) {
      console.log('saved: DeliveryPeriod');
    }
  };

  const onCloseAnnouncementDetailsModalIsOpen = (isSave: boolean) => {
    setAnnouncementDetailsModalIsOpen(false);
    if (isSave) {
      console.log('saved: NoticeDetails');
    }
  };

  const displayText = (text: string | Array<string>) => {
    return text ? `${text}` : '-';
  };

  // 申請ボタンを押せる条件の関数
  const isApplicationButtonEnable = () => {
    return props.selectPlanId &&
      (props.announcementDetails.title || props.announcementDetails.title !== '') &&
      props.images.length > 0 &&
      props.deliveryDetails.snsDeliveryDetails.instagram.text &&
      props.deliveryDetails.snsDeliveryDetails.x.text &&
      props.postTerm.startedAt &&
      props.postTerm.endedAt &&
      typeof props.summaryOption.goodQuality === 'number' &&
      typeof props.summaryOption.rewrite === 'number' &&
      typeof props.summaryOption.deliverImage === 'number'
      ? true
      : false;
    // props.announcementDetails.notice &&
  };

  const hensyuuClassName = 'h-[20px] w-[28px] whitespace-nowrap text-sm font-medium leading-5 text-[#007CC2]';

  // const selectedEstimate = (): EstimateProps[] => {
  //   const fixedItem = props.estimate.items.fixedItems.map((item) => {
  //     return {
  //       title: item.name,
  //       quantity: item.counts,
  //       unitPrice: item.price,
  //     };
  //   });
  //   const optionFixedItem = props.estimate.items.optionFixedItems.map((item) => {
  //     return {
  //       title: item.name,
  //       quantity: item.counts,
  //       unitPrice: item.price,
  //     };
  //   });
  //   const optionFreeItem = props.estimate.items.optionFreeItems.map((item) => {
  //     return {
  //       title: item.name,
  //       quantity: item.counts,
  //       unitPrice: item.price,
  //     };
  //   });
  //   return fixedItem.concat(optionFixedItem).concat(optionFreeItem);
  // };

  const handleButton = () => {
    (async () => {
      const res = await insertPostApplicationInfo(projectId);
      console.log(res);
      props.setProjectFlow(res.status);
      searchParams.set('status', res.status);
      console.log(searchParams.toString());
      navigate('?' + searchParams.toString());
    })();
  };

  const displayAmount = (price: number): string => `¥${displayText(numberFormatComma(price))}`;

  const displayTags = (dbGetValue: string[]) => {
    const values = dbGetValue;
    return (
      <div className="flex flex-row gap-2">
        {(() => {
          if (values) {
            return values.map((tag: string, index: number) => {
              return (
                <div key={index} className="">
                  <span className="rounded-full  bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                    {tag}
                  </span>
                </div>
              );
            });
          } else {
            return (
              <div className="">
                <span className="rounded-full  bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">-</span>
              </div>
            );
          }
        })()}
      </div>
    );
  };
  const PutEmeraldPostSnsUpdate = (projectId: string) => async (snsDeliveryDetails: EmeraldPostSnsDetail) => {
    const response = await PutEmeraldPostSnsDetail(projectId, snsDeliveryDetails);
    props.setDeliveryDetails({ snsDeliveryDetails: response.snsDetail, snsDeliveryDetailsModalIsOpen: false });
  };

  const displayImage = (images: string[]) => {
    {
      return (
        <div className="flex">
          <img src={images.length > 0 ? images[0] : NoImage} alt="" className="mr-[16px] h-[140px] w-[219px]" />
          <img src={images.length > 1 ? images[1] : NoImage} alt="" className="mr-[16px] h-[140px] w-[219px]" />
          <img src={images.length > 2 ? images[2] : NoImage} alt="" className="mr-[16px] h-[140px] w-[219px]" />
        </div>
      );
    }
  };
  const PutEmeraldPostMediaUpdate = (projectId: string) => async (uploads?: File[], deleteIds?: string[]) => {
    const response = await PutEmeraldPostMedia(projectId, uploads, deleteIds);
    props.setImages(
      response.postImages.map((image) => {
        return {
          objectKey: getImageUrl(image.objectKey),
          id: image.id,
        };
      })
    );
  };

  const displayDeliveryPeriod = (dbGetDeliveryPeriodFrom: string | null, dbGetDeliveryPeriodTo: string | null) => {
    const from = dbGetDeliveryPeriodFrom || '';
    const to = dbGetDeliveryPeriodTo || '';
    return from || to ? `${ExpectedProjectDurationDisplay(from, to)}` : '-';
  };

  const [imageModalIsOpen, setImageModalIsOpen] = useState<boolean>(false);

  return (
    <div>
      <div className="ml-[40px]">
        <div className="pr-16">
          {/* TODO バックエンド作成時はすべて、コンストに移行 */}
          {isDraft(props.status) ? (
            <div className="flex w-[1053px] items-center border-b-[1px] border-gray-200">
              <p className="mb-[20px] h-[24px] w-[1027px] text-lg font-medium leading-6 text-gray-900">配信詳細</p>
              <Button
                className={
                  isApplicationButtonEnable()
                    ? 'mb-[20px] h-[42px] w-[210px] px-[17px] py-[9px]'
                    : 'mb-[20px] h-[42px] w-[210px] whitespace-nowrap rounded-md bg-gray-300 px-[17px] py-[9px] text-[#FFFFFF]'
                }
                variant="primary"
                size="sm"
                disabled={isApplicationButtonEnable() ? false : true}
                // disabled={false}
                onClick={() => handleButton()}
              >
                この内容で申請する
              </Button>
            </div>
          ) : isReview(props.status) || isWait(props.status) ? (
            <div className="flex w-[1053px] items-center border-b-[1px] border-gray-200">
              <p className="mb-[20px] h-[24px] w-[1027px] text-lg font-medium leading-6 text-gray-900">配信詳細</p>
              <Button
                className="mb-[20px] h-[42px] w-[210px] px-[17px] py-[9px]"
                variant="primary"
                size="sm"
                disabled={true}
              >
                申請中
              </Button>
            </div>
          ) : isPosting(props.status) ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="secoundary" size="sm" style={{ margin: '10px' }} disabled>
                編集する
              </Button>
              <Button variant="primary" size="sm" style={{ margin: '10px' }} disabled>
                この内容で申請する
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {isDraft(props.status) && isApplicationButtonEnable() ? (
        <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
          <div className="mr-[12px] h-[20px] w-[20px]">
            <img src={ErrorImage} alt="" />
          </div>
          <div className="h-[48px] w-[989px] text-blue-800">
            <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">
              募集内容を確認し、申請をしてください。
            </p>
            <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
              申請されましたら、LIDDELL担当者が再確認の上、募集に入ります。
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
      {isReview(props.status) ? (
        <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
          <div className="mr-[12px] h-[20px] w-[20px]">
            <img src={ErrorImage} alt="" />
          </div>
          <div className="h-[48px] w-[989px] text-blue-800">
            <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">申請を受け付けました。</p>
            <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
              LIDDELL担当者が確認しておりますので、お待ちください。
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="ml-[40px] flex flex-row">
        <div>
          <div>
            {/* メインコンテンツ */}
            <div className="mt-[24px] h-auto w-[726.75px] rounded-lg border-[1px] border-[#E5E7EB] bg-[#FFFFFF] px-[24px] pb-[16px] pt-[24px]">
              {/* 配信プラン設定タイトル */}
              <div className="flex items-center">
                <p className="mr-[306.75px] h-[28px] w-[344px] text-base font-semibold leading-7 text-gray-500">
                  配信プラン設定
                </p>
                {isDraft(props.status) ? (
                  <button className={hensyuuClassName} onClick={() => handleEditClick(1)}>
                    編集
                  </button>
                ) : (
                  ''
                )}
              </div>
              {/* プラン名 */}
              <div className="mt-[24px] flex w-[679px] border-y-[1px] border-gray-200 py-[16px]">
                <p className="h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">プラン名</p>
                <p className="h-[20px] w-[446px] text-sm font-normal leading-5 text-gray-900">
                  {dialogPlan.name ? dialogPlan.name : '-'}
                </p>
              </div>
              {/* 料金 */}
              <div className="flex w-[679px] border-b-[1px] border-gray-200 py-[16px]">
                <p className="h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">料金</p>
                <p className="h-[20px] w-[446px] text-sm font-normal leading-5 text-gray-900">
                  {dialogPlan.price ? displayAmount(dialogPlan.price) : '-'}
                </p>
              </div>
              {/* ハート数 */}
              <div className="flex w-[679px] border-b-[1px] border-gray-200 py-[16px]">
                <div className="flex min-h-[20px] w-[233px] items-center gap-[5.33px]">
                  <div className="text-sm font-semibold leading-5">ハート数</div>
                  <div className="group relative pl-[7.33px]">
                    <img className="h-[16px] w-[16px]" src={Help} alt="" />
                    <Tooltip
                      isSpeechBubble={true}
                      variant="gray"
                      toolTipClassName="px-3 py-2"
                      tooltipText={'ハート数'}
                      className="absolute bottom-[18px] left-[-36px] hidden w-[100px] whitespace-normal text-xs group-hover:inline-block"
                    ></Tooltip>
                  </div>
                </div>
                <p className="h-[20px] w-[446px] text-sm font-normal leading-5 text-gray-900">
                  {dialogPlan.heartCounts ? dialogPlan.heartCounts : '-'}
                </p>
              </div>
              {/* 予測リーチ */}
              <div className="flex w-[679px] border-b-[1px] border-gray-200 py-[16px]">
                <div className="flex min-h-[20px] w-[233px] items-center gap-[5.33px]">
                  <div className="text-sm font-semibold leading-5">予測リーチ</div>
                  <div className="group relative pl-[7.33px]">
                    <img className="h-[16px] w-[16px]" src={Help} alt="" />
                    <Tooltip
                      isSpeechBubble={true}
                      variant="gray"
                      toolTipClassName="px-3 py-2"
                      tooltipText={'予測リーチ'}
                      className="absolute bottom-[18px] left-[-36px] hidden w-[100px] whitespace-normal text-xs group-hover:inline-block"
                    ></Tooltip>
                  </div>
                </div>
                <p className="h-[20px] w-[446px] text-sm font-normal leading-5 text-gray-900">
                  {dialogPlan.isPredictReach ? 'あり' : '-'}
                </p>
              </div>
              {/* お知らせ内容詳細タイトル */}
              <div className="mb-[16px] mt-[48px] flex items-center border-b-[1px]">
                <p className="mr-[306.75px] h-auto w-[344px] py-[16px] text-base font-semibold leading-7 text-gray-500">
                  お知らせ内容詳細
                </p>
                {isDraft(props.status) ? (
                  <button className={hensyuuClassName} onClick={() => handleEditClick(2)}>
                    編集
                  </button>
                ) : (
                  ''
                )}
              </div>
              <div className="mt-2 max-h-[496px] overflow-y-auto">
                {title ? <p className="mb-[24px] text-2xl font-bold leading-7 text-gray-900">{title}</p> : ''}
                {html ? html : ''}
                {title || html ? '' : '-'}
                {/* {announcementDetailsDisplay()} */}
              </div>

              {/* SNS配信詳細タイトル */}
              <div className="mt-[48px] flex items-center">
                <p className="mr-[306.75px] h-[28px] w-[344px] text-base font-semibold leading-7 text-gray-500">
                  SNS配信詳細
                </p>
                {isDraft(props.status) ? (
                  <button className={hensyuuClassName} onClick={() => handleEditClick(3)}>
                    編集
                  </button>
                ) : (
                  ''
                )}
              </div>
              {/* インスタ */}
              <div className="mt-[24px] flex w-[679px] items-center border-gray-200 pb-[16px]">
                <div className="mr-[8px] flex h-[32px] w-[32px] items-center rounded-2xl bg-gray-100">
                  <img src={instagram} alt="" className="m-auto" />
                </div>
                <p className="h-[20px] w-[446px] text-sm font-semibold leading-5 text-gray-400">Instagram用</p>
              </div>
              {/* テキスト */}
              <div className="flex w-[679px] border-b-[1px] border-gray-200 py-[16px]">
                <p className="mr-[8px] h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">テキスト</p>
                <p className="h-[20px] w-[438px] text-sm font-normal leading-5 text-gray-900">
                  {props.deliveryDetails.snsDeliveryDetails.instagram.text
                    ? props.deliveryDetails.snsDeliveryDetails.instagram.text
                    : '-'}
                </p>
              </div>
              {/* 投稿に含めるハッシュタグ */}
              <div className="flex w-auto items-center border-b-[1px] border-gray-200 py-[16px]">
                <p className="mr-[8px] h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">
                  投稿に含めるハッシュタグ(#)
                </p>
                <div className="flex flex-row gap-2">
                  {displayTags(
                    props.deliveryDetails.snsDeliveryDetails.instagram.hashTags
                      ? props.deliveryDetails.snsDeliveryDetails.instagram.hashTags.length
                        ? props.deliveryDetails.snsDeliveryDetails.instagram.hashTags
                        : []
                      : []
                  )}
                </div>
              </div>
              {/* 投稿に含めるアカウントタグ */}
              <div className="flex w-auto items-center border-b-[1px] border-gray-200 py-[16px]">
                <p className="mr-[8px] h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">
                  投稿に含めるアカウントタグ(@)
                </p>
                <p className="rounded-full  bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                  {props.deliveryDetails.snsDeliveryDetails.instagram.accountTag
                    ? props.deliveryDetails.snsDeliveryDetails.instagram.accountTag
                    : '-'}
                </p>
              </div>
              {/* ストーリーズ投稿 リンクスタンプ */}
              <div className="flex w-[679px] border-b-[1px] border-gray-200 py-[16px]">
                <p className="mr-[8px] h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">
                  ストーリーズ投稿 リンクスタンプ
                </p>
                <p className="h-[20px] w-[438px] text-sm font-normal leading-5 text-gray-900">
                  {props.deliveryDetails.snsDeliveryDetails.instagram.storiesUrl
                    ? props.deliveryDetails.snsDeliveryDetails.instagram.storiesUrl
                    : '-'}
                </p>
              </div>
              {/* X */}
              <div className="mt-[30px] flex w-[679px] items-center border-b-[1px] border-gray-200 pb-[16px]">
                <div className="mr-[8px] flex h-[32px] w-[32px] items-center rounded-2xl bg-gray-100">
                  <img src={Xtwitter} alt="" className="m-auto" />
                </div>
                <p className="h-[20px] w-[446px] text-sm font-semibold leading-5 text-gray-400">X用</p>
              </div>
              {/* テキスト */}
              <div className="flex w-[679px] border-b-[1px] border-gray-200 py-[16px]">
                <p className="mr-[8px] h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">テキスト</p>
                <p className="h-[20px] w-[438px] text-sm font-normal leading-5 text-gray-900">
                  {props.deliveryDetails.snsDeliveryDetails.x.text
                    ? props.deliveryDetails.snsDeliveryDetails.x.text
                    : '-'}
                </p>
              </div>
              {/* 投稿に含めるハッシュタグ */}
              <div className="flex w-auto items-center border-b-[1px] border-gray-200 py-[16px]">
                <p className="mr-[8px] h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">
                  投稿に含めるハッシュタグ
                </p>
                <div className="flex flex-row gap-2">
                  {displayTags(
                    props.deliveryDetails.snsDeliveryDetails.x.hashTags
                      ? props.deliveryDetails.snsDeliveryDetails.x.hashTags.length
                        ? props.deliveryDetails.snsDeliveryDetails.x.hashTags
                        : []
                      : []
                  )}
                </div>
              </div>
              {/* 投稿に含めるアカウントタグ */}
              <div className="flex w-auto items-center border-b-[1px] border-gray-200 py-[16px]">
                <p className="mr-[8px] h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">
                  投稿に含めるアカウントタグ(@)
                </p>
                <p className="rounded-full  bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                  {props.deliveryDetails.snsDeliveryDetails.x.accountTag
                    ? props.deliveryDetails.snsDeliveryDetails.x.accountTag
                    : '-'}
                </p>
              </div>
              {/* SNS配信用画像 */}
              <div className="mb-[24px] mt-[48px] flex items-center">
                <p className="mr-[306.75px] h-[28px] w-[344px] text-base font-semibold leading-7 text-gray-500">
                  SNS配信用画像
                </p>
                {isDraft(props.status) ? (
                  <button className={hensyuuClassName} onClick={() => handleEditClick(5)}>
                    編集
                  </button>
                ) : (
                  ''
                )}
              </div>
              {displayImage(props.images.map((image) => image.objectKey))}
              {/* 配信期間 */}
              <div className="mt-[48px] flex items-center">
                <p className="mr-[306.75px] h-[28px] w-[344px] text-base font-semibold leading-7 text-gray-500">
                  配信期間
                </p>
                {isDraft(props.status) ? (
                  <button className={hensyuuClassName} onClick={() => handleEditClick(4)}>
                    編集
                  </button>
                ) : (
                  ''
                )}
              </div>
              {/* 期間 */}
              <div className="mr-[8px] mt-[24px] flex w-[679px] border-y-[1px] border-gray-200 py-[16px]">
                <p className="h-[20px] w-[233px] text-sm font-semibold leading-5 text-gray-900">期間</p>
                <p className="h-[20px] w-[438px] text-sm font-normal leading-5 text-gray-900">
                  {displayDeliveryPeriod(props.postTerm.startedAt, props.postTerm.endedAt)}
                </p>
              </div>

              <div className="-translate-x-6">
                {/*オプション設定*/}
                <Option
                  projectType={ProjectType.EmeraldPost}
                  projectStatus={props.status}
                  optionMap={optionModalMap}
                  option={props.summaryOption}
                  setOption={props.setSummaryOption}
                  PutPostOption={PutEmeraldPostOptionUpdate(projectId)}
                />

                {/*KPI*/}
                {KpiComponent(
                  props.kpi.items.length === 0
                    ? [
                        {
                          name: 'リーチ数',
                          comment: '-',
                        },
                        {
                          name: '投稿数',
                          comment: '-',
                        },
                      ]
                    : props.kpi.items.map((item) => {
                        return {
                          name: item.name,
                          comment: item.comment,
                        };
                      })
                )}
              </div>
            </div>
          </div>

          {/*お見積*/}
          <div className="mt-[24px] w-[728px] ">
            <div className="h-auto w-[728px] border-[1px] border-[#E5E7EB] bg-[#FFFFFF]">{Estimate(undefined)}</div>
          </div>
        </div>
        <div className="h-[689.76px] w-[286.25px]">
          <div className="h-[28+24px] w-[240+40px] pl-[40px] pt-[24px] text-base font-semibold leading-7 text-gray-500">
            インフルエンサー画面プレビュー
          </div>
          <div>
            <img className="h-[597.76px] w-[326.25px] pl-[40px] pt-[16px]" src={SmartPhone} alt="" />
          </div>
        </div>
      </div>
      <DeliveryAgencyService
        open={deliveryAgencyServiceModalIsOpen}
        onClose={onCloseDeliveryAgencyService}
        isApplyed={dialogIsApplyed}
        setIsApplyed={setDialogIsApplyed}
      ></DeliveryAgencyService>
      <PricingSettings
        open={pricingSettingsModalIsOpen}
        onClose={onClosePricingSettingsModalIsOpen}
        plans={props.plans}
        selectPlanId={props.selectPlanId}
        PutPostPlanUpdate={PutEmeraldPostPlan(projectId)}
      ></PricingSettings>
      <AnnouncementDetails
        open={announcementDetailsModalIsOpen}
        onClose={onCloseAnnouncementDetailsModalIsOpen}
        text={props.announcementDetails}
        PutPostNoticeUpdate={PutEmeraldPostNotice(projectId)}
      ></AnnouncementDetails>
      <SnsDeliveryDetails
        snsDeliveryDetailsModalIStates={props.deliveryDetails}
        setSnsDeliveryDetailsModalIStates={props.setDeliveryDetails}
        PutPostSnsUpdate={PutEmeraldPostSnsUpdate(projectId)}
      ></SnsDeliveryDetails>
      <DetailImages
        projectType={ProjectType.EmeraldPost}
        isOpen={imageModalIsOpen}
        setIsOpen={setImageModalIsOpen}
        detailImages={props.images}
        PutPostMedia={PutEmeraldPostMediaUpdate(projectId)}
      ></DetailImages>
      <DeliveryPeriod
        open={deliveryPeriodModalIsOpen}
        onClose={onCloseDeliveryPeriodModalIsOpen}
        postTerm={props.postTerm}
        setPostTerm={props.setPostTerm}
      ></DeliveryPeriod>
    </div>
  );
}
