import InstaStoryPlayIcon from './assets/InstaStoryPlayIcon.png';

export const PRSTProductionManagementContentsApprovingTikTokView = ({ imagePath }: { imagePath: string }) => {
  return (
    <div className="w-[245px]">
      <img src={imagePath} alt="" className="mb-[20px]" />
      <img src={InstaStoryPlayIcon} alt="" />
    </div>
  );
};
