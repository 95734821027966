import React, { useState, KeyboardEvent, Fragment, useEffect } from 'react';

import { CreationStatus, GetCreationOutput, PostingType } from '@/api/creation';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as PencilImage } from '@/assets/icons/bx-pencil.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { getImageUrlIfRelative } from '@/utils/image';

import { Comment, Reply } from '../interfaces/PRSTProductionManagementInterface';

import { ReactComponent as BoldIcon } from './assets/bx-bold.svg';
import { ReactComponent as EditIcon } from './assets/bx-edit-alt.svg';
import { ReactComponent as FontcolorIcon } from './assets/bx-font-color.svg';
import { ReactComponent as ImageAddIcon } from './assets/bx-image-add.svg';
import { ReactComponent as ItalicIcon } from './assets/bx-italic.svg';
import { ReactComponent as MenuLeftIcon } from './assets/bx-menu-alt-left.svg';
import { ReactComponent as UnderlineIcon } from './assets/bx-underline.svg';
import { ReactComponent as CommentAvatarIcon } from './assets/Comment_Avatar.svg';
import CommentIcon from './assets/CommentIcon.png';
import { ReactComponent as InformationImage } from './assets/Information carousel_Two Color 1.svg';
import InstaReelImage from './assets/InstaReelImage.png';
import InstaStoryImage from './assets/InstaStoryImage.png';
import SampleMediaImage2 from './assets/SampleMediaImage2.png';
import SampleMediaImage2Other from './assets/SampleMediaImage2Other.png';
import SampleMediaImage2TikTok from './assets/SampleMediaImage2TicTok.png';
import SampleMediaImage2Twitter from './assets/SampleMediaImage2Twitter.png';
import SampleMediaImage2YouTube from './assets/SampleMediaImage2YouTube.png';
import SampleMediaImage2YouTubeShort from './assets/SampleMediaImage2YouTubeShort.png';
import { ContentModal } from './ContentModal';
import { PRSTProductionManagementContentsApprovedView } from './PRSTProductionManagementContentsApprovedView';
import { PRSTProductionManagementContentsApprovingInstagramReelView } from './PRSTProductionManagementContentsApprovingInstagramReelView';
import { PRSTProductionManagementContentsApprovingInstagramStoryView } from './PRSTProductionManagementContentsApprovingInstagramStoryView';
import { PRSTProductionManagementContentsApprovingOtherView } from './PRSTProductionManagementContentsApprovingOtherView';
import { PRSTProductionManagementContentsApprovingTikTokView } from './PRSTProductionManagementContentsApprovingTikTokView';
import { PRSTProductionManagementContentsApprovingTwitterImageView } from './PRSTProductionManagementContentsApprovingTwitterImageView';
import { PRSTProductionManagementContentsApprovingTwitterNoImageView } from './PRSTProductionManagementContentsApprovingTwitterNoImageView';
import { PRSTProductionManagementContentsApprovingYouTubeShortView } from './PRSTProductionManagementContentsApprovingYouTubeShortView';
import { PRSTProductionManagementContentsApprovingYouTubeView } from './PRSTProductionManagementContentsApprovingYouTubeView';

interface ContentViewProps {
  postDetail: GetCreationOutput;
  setPostDetail: React.Dispatch<React.SetStateAction<GetCreationOutput>>;
  updatePost: () => void;
}

export const ContentView = ({ postDetail, setPostDetail }: ContentViewProps) => {
  const [isComposing, setIsComposing] = useState(false);
  const [contentComments, setContentComments] = useState<Comment[]>([]);
  const [contentComment, setContentComment] = useState<boolean>(false);
  const [comment, setComment] = useState('');
  const [reply, setReply] = useState('');
  const [replyWindow, setReplyWindow] = useState<number | null>(null);
  const [replyWindow2, setReplyWindow2] = useState<number | null>(null);
  const [contentApproved, setContentApproved] = useState<boolean>(false);
  const [isContentOpen, setIsContentOpen] = useState<boolean>(false);
  const handleContentModalClose = () => {
    setIsContentOpen(false);
  };
  const [contentsMedia, setContentsMedia] = useState<Array<string>>([]);
  const [contentsMediaIndex, setContentsMediaIndex] = useState<number>(0);
  const changeContentsMediaIndex = (index: number, length: number) => {
    if (length > index + 1) {
      const newIndex = index + 1;
      setContentsMediaIndex(newIndex);
    }
    if (length === index + 1) {
      setContentsMediaIndex(0);
    }
  };
  const imageUrlRelative = (path: string) => {
    const fullUrl = getImageUrlIfRelative(path);
    return fullUrl;
  };

  const statusToStepNum = (status: CreationStatus): number => {
    switch (status) {
      case CreationStatus.Draft:
        return 1;
      case CreationStatus.DraftApproved:
        return 2;
      case CreationStatus.Contents:
        return 3;
      case CreationStatus.ContentsApproved:
        return 4;
      case CreationStatus.Completed:
        return 5;
      default:
        return 0;
    }
  };
  const handleContentApproved = () => {
    if (postDetail) {
      // updatePost();
      setPostDetail({
        ...postDetail,
        status: CreationStatus.ContentsApproved,
      });
      setContentApproved(true);
    }
  };

  const handleContentCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleContentCommentReplysChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(e.target.value);
  };
  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  const handleCloseReplys = (index: number) => {
    setReplyWindow2(index);
    setReplyWindow2(null);
  };

  const handleReplyInput = (index: number) => {
    setReplyWindow(index);
  };

  const handleOpenReply = (index: number) => {
    setReplyWindow2(index);
  };

  const handleAddContentComment = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !isComposing && comment !== '') {
      event.preventDefault();
      console.log('contentComments', contentComments);
      console.log('送信ボタンが押されました。');
      const newContentComments: Comment = {
        accountName: '',
        comment: comment,
        replys: [],
      };
      setContentComments((prev) => [...prev, newContentComments]);
      setComment('');
    } else {
      return;
    }
  };

  const handleReplyContentComment = (index: number) => {
    if (reply !== '') {
      const newReply: Reply = {
        accountName: '',
        reply: reply,
      };
      contentComments[index].replys.push(newReply);
      setReplyWindow(null);
      setReply('');
    } else {
      setReplyWindow(null);
      setReply('');
    }
  };

  const CommentWindow1 = () => {
    return (
      <>
        <div className="absolute right-0 top-0 z-20 w-[406px] bg-white px-[24px] pt-[40px] shadow-[0_10px_10px_rgba(0,0,0,0.04),0_20px_25px_rgba(0,0,0,0.1)]">
          <div className="mb-[22px] flex w-full justify-end">
            <button className="mr-[0px]">
              <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setContentComment(false)} />
            </button>
          </div>
          <form className="mb-[24px] h-[121px] w-[358px]">
            <label htmlFor="comment" className="h-[28px] w-[220px] text-xl font-bold leading-7">
              コンテンツへのコメント
            </label>
            <div className="my-[24px] h-[45px] w-[358px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
              <div className="h-[92px] w-[326px]">
                <textarea
                  className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                  placeholder="新規コメント"
                  value={comment}
                  onChange={handleContentCommentsChange}
                  onKeyDown={handleAddContentComment}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  defaultValue={''}
                />
              </div>
            </div>
            <div className="h-[1px] w-[358px] border-t-[1px] border-[#DDDDDD]"></div>
          </form>
          <div className="w-[358px]">
            {contentComments.map((contentComment, index) => (
              <div key={index} className="mb-[24px] py-[8px]">
                <div className="mb-[8px] flex h-[60px] w-[358px] justify-between py-[8px]">
                  <div>
                    <CommentAvatarIcon width={24} height={24} />
                  </div>
                  <div className="h-[44px] w-[322px]">
                    <div className="flex justify-between">
                      <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">Whitney Francis</div>
                      <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                    </div>
                    <p className="text-sm font-normal leading-5 text-gray-700">{contentComment.comment}</p>
                  </div>
                </div>
                {contentComment.replys.length > 0 ? (
                  replyWindow2 === index ? (
                    <div>
                      <button className="text-sm font-medium leading-5" onClick={() => handleCloseReplys(index)}>
                        <div className="flex h-[24px] w-[116px] justify-between">
                          <ChevronDownIcon width={24} height={24} fill="#6B7280" />
                          <div className="h-[20px] w-[84px] items-center text-sm font-medium leading-5 text-gray-500">
                            返信を閉じる
                          </div>
                        </div>
                      </button>
                      <div>
                        {contentComments[index].replys.map((reply, i) => (
                          <div key={i} className="mb-[8px] w-[358px] py-[8px] pl-[24px]">
                            <div className="mb-[8px] flex justify-between py-[8px]">
                              <div>
                                <CommentAvatarIcon width={24} height={24} />
                              </div>
                              <div className="w-[298px]">
                                <div className="flex justify-between">
                                  <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">
                                    Whitney Francis
                                  </div>
                                  <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                                </div>
                                <p className="text-sm font-normal leading-5 text-gray-700">{reply.reply}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="text-sm font-medium leading-5 text-[#007CC2]"
                        onClick={() => handleOpenReply(index)}
                      >
                        <div className="flex h-[24px] w-[109px]">
                          <ChevronDownIcon width={24} height={24} fill="#007CC2" />
                          <div className="h-[20px] w-[77px] items-center text-sm font-medium leading-5">
                            返信（{contentComment.replys.length}）
                          </div>
                        </div>
                      </button>
                    </div>
                  )
                ) : null}
                {replyWindow === index ? (
                  <div className="h-[124px] w-[358px] py-[8px] pl-[24px]">
                    <div className="h-[108px] w-[334px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
                      <div className="h-[92px] w-[302px]">
                        <textarea
                          className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                          placeholder="返信"
                          value={reply}
                          onChange={handleContentCommentReplysChange}
                          defaultValue={''}
                        />
                        <div className="flex h-[34px] w-[302px] items-end justify-between">
                          <div className="flex h-[24px] w-[164px] items-center justify-between">
                            <button className="h-[16px] w-[16px]">
                              <ImageAddIcon width={16} height={16} fill="#000000" />
                            </button>
                            <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
                            <div className="flex h-[17px] w-[117px] items-center justify-between">
                              <button className="h-[16px] w-[16px]">
                                <BoldIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <ItalicIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <UnderlineIcon width={17} height={17} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <FontcolorIcon width={17} height={17} fill="#000000" />
                              </button>
                            </div>
                          </div>
                          <Button
                            onClick={() => handleReplyContentComment(index)}
                            className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
                          >
                            送信
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {(contentComments[index].replys.length === 0 || replyWindow2 === index) && replyWindow !== index ? (
                  <div className="flex h-[24px] w-[60px] justify-between">
                    <div className="h-[24px] w-[24px]"></div>
                    <button
                      className="w-[28px] text-sm font-semibold leading-5 text-gray-500"
                      onClick={() => handleReplyInput(index)}
                    >
                      返信
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    setContentsMedia((postDetail?.contents?.media || []).map(imageUrlRelative));
  }, [postDetail]);

  const handleClickCreateContents = async () => {
    try {
      setIsContentOpen(true);
    } catch (error) {
      console.error('コンテンツ情報の取得に失敗しました:', error);
    }
  };

  const SelectPostingType = (type: PostingType) => {
    switch (type) {
      case PostingType.Feed:
        return (
          <PRSTProductionManagementContentsApprovedView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : SampleMediaImage2}
          />
        );
      case PostingType.Reel:
        return (
          <PRSTProductionManagementContentsApprovingInstagramReelView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : InstaReelImage}
          />
        );
      case PostingType.Stories:
        return (
          <PRSTProductionManagementContentsApprovingInstagramStoryView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : InstaStoryImage}
          />
        );
      case PostingType.TikTok:
        return (
          <PRSTProductionManagementContentsApprovingTikTokView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : SampleMediaImage2TikTok}
          />
        );
      case PostingType.XImagePost:
        return (
          <PRSTProductionManagementContentsApprovingTwitterImageView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : SampleMediaImage2Twitter}
          />
        );
      case PostingType.XPost:
        return <PRSTProductionManagementContentsApprovingTwitterNoImageView />;
      case PostingType.Youtube:
        return (
          <PRSTProductionManagementContentsApprovingYouTubeView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : SampleMediaImage2YouTube}
          />
        );
      case PostingType.YoutubeShort:
        return (
          <PRSTProductionManagementContentsApprovingYouTubeShortView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : SampleMediaImage2YouTubeShort}
          />
        );
      default:
        return (
          <PRSTProductionManagementContentsApprovingOtherView
            imagePath={contentsMedia ? contentsMedia[contentsMediaIndex] : SampleMediaImage2Other}
          />
        );
    }
  };

  return (
    <>
      {/* ToDo 下記のpostStatusをpostDetail?.status && postDetail?.statusに戻す */}
      {postDetail.status === CreationStatus.Contents ? (
        <div className="w-[1133px] p-[25px]">
          <div className="flex h-[525px] w-[1133px] items-center justify-center rounded bg-[#FFFFFF] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[478px] w-[1085px]">
              <div className="mb-[24px] h-[28px] w-[92px]">
                <p className="text-lg font-semibold leading-7 text-gray-500">コンテンツ</p>
              </div>
              <div className="flex h-[326px] w-[1085px] items-center justify-center py-[48px]">
                <div className="h-[230px] w-[252px] pb-[4px]">
                  <div className="mb-[24px] h-[164px] w-[252px]">
                    <InformationImage width={252} height={136} />
                    <div className="mt-[8px] flex h-[20px] w-[252px] justify-center">
                      <p className="w-[252px] text-center text-[14px] font-normal leading-5 text-[#6B7280]">
                        コンテンツがまだ制作されていません。
                      </p>
                    </div>
                  </div>
                  <Button
                    startIcon={<PencilImage fill="#FFFFFF" className="h-[20px] w-[20px]" />}
                    onClick={handleClickCreateContents}
                  >
                    コンテンツを制作する
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* ToDo 下記のpostStatusになっている部分を全てpostDetail?.statusに戻す */}
      {postDetail && statusToStepNum(postDetail.status) > 3 ? (
        <div className="px-[24px]">
          <div className="mt-[25px] w-[1131px] px-[40px] py-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="mb-[24px] flex h-[31px] w-[1051px] items-center justify-between">
              <div className="flex h-[28px] w-[92px] items-center">
                <span className="text-lg font-semibold leading-7 text-gray-500">コンテンツ</span>
              </div>
              <div className="flex h-[38px] w-[206px] items-center justify-between">
                {/* ToDo 下記のpostDetail.statusになっている部分を全てpostDetail.statusに戻す */}
                {statusToStepNum(postDetail.status) > 4 || contentApproved ? (
                  <div className="flex h-[24px] w-[76px] items-center justify-between bg-green-100 px-[8px] py-[2px]">
                    <span className="text-sm font-medium leading-5 text-green-800">承認済</span>
                    <button>
                      <ChevronDownIcon fill="#10B981" width={16} height={16} />
                    </button>
                  </div>
                ) : (
                  <Button className="text-sm font-medium leading-5" onClick={handleContentApproved}>
                    承認する
                  </Button>
                )}
                <button>
                  <EditIcon width={24} height={24} fill="#6B7280" />
                </button>
                <button onClick={() => setContentComment(true)}>
                  <img src={CommentIcon} alt="" />
                </button>
              </div>
            </div>
            <div className="w-[1051px]">
              <span className="mb-[4px] h-[20px] w-[70px] text-sm font-semibold leading-5 text-gray-700">
                プレビュー
              </span>
              <div className="mb-[4px] flex h-[28px] items-center">
                <span className="mr-[5px] text-sm font-semibold leading-5 text-gray-700">
                  {contentsMediaIndex + 1} / {contentsMedia.length}枚目
                </span>
                <div className="h-[28px]">
                  <button onClick={() => changeContentsMediaIndex(contentsMediaIndex, contentsMedia.length)}>
                    <ChevronRightIcon width={28} height={28} fill="#374151" />
                  </button>
                </div>
              </div>
              <div className="flex h-[453px] w-[1051px] justify-between py-[10px]">
                {SelectPostingType(postDetail?.type)}
                <div className="h-[433px] border-l border-gray-200"></div>
                <div className="w-[681px]">
                  <div className="w-[681px]">
                    <div className="mb-[15px] flex h-[20px] w-[76px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <span className="text-xs font-normal leading-4 text-gray-800">テキスト</span>
                    </div>
                    <div className="mb-[40px] h-[256px] w-[681px] text-xs font-normal leading-4 text-gray-800">
                      {postDetail && postDetail.contents?.body}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="relative">
        <ContentModal
          isOpen={isContentOpen}
          creationId={postDetail.id}
          postingType={postDetail.type}
          closeModal={handleContentModalClose}
          setPostDetail={setPostDetail}
        />
      </div>
      {contentComment && <CommentWindow1 />}
    </>
  );
};
