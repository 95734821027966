import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

// APIやコンポーネントのインポート
import { GetAnalysisJJFeedbackAdoptersRowsEnum } from '@/api/analysisJaneJohn';
import { GetAnalysisSpiritSummaryOutput } from '@/api/analysisSpirit';
import { GetJaneJohnListOutput } from '@/api/janeJohn';
import { GetProjectOverviewOutput, SnsType } from '@/api/project';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/bx-file.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as ImagesIcon } from '@/assets/icons/bx-images.svg';
import { ReactComponent as NotepadIcon } from '@/assets/icons/bx-notepad.svg';
import { Badge } from '@/components/Elements/Badge';
import { PROJECT_TYPES } from '@/config/index';
import { getSummary } from '@/hooks/Analysis';
import { getJaneJohnList } from '@/hooks/JaneJohn';
//import { getProjectOverviews } from '@/hooks/ProjectInfo';
import { getAnalysisJaneJohnFeedbackAdopters } from '@/hooks/JaneJohnAnalysis';
import { isInstagram, isTikTok, isTwitter } from '@/utils/sns';

// 各種コンポーネントのインポート
import AnalysisResult from '../Analysis/result';
import Feedback from '../feedback/feedback';
import FollowerAttributeInstagram from '../followerAttribute/instagram';
import FollowerAttributeTiktok from '../followerAttribute/tiktok';
import FollowerAttributeTwitter from '../followerAttribute/twiiter';
import FollowerAttributeYoutube from '../followerAttribute/youtube';
import JanejoneInfluencerList from '../posts/Janejohn_Other_SNS/janejoneInfluencerList';
import ProjectDetails from '../projectDetails/projectDetails';

export interface SummaryProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sns: string;
  projectType: string;
  id: string;
  project: string;
  projectName: string;
  span: string;
}

interface TabProps {
  name: string;
  key: string;
  icon: React.ReactElement<any, any>;
  activeIcon: React.ReactElement<any, any>;
}

export default function Summary(props: SummaryProps) {
  const { id, open, setOpen, sns, projectType, project, projectName, span } = props;

  const [summary, setSummary] = useState<GetAnalysisSpiritSummaryOutput | GetJaneJohnListOutput>();
  console.log(summary);
  const [isCompleteFeedback, setIsCompleteFeedback] = useState(false); //Feedback完了フラグ APIから取得 一旦ハードコーディング
  // 選択されたタブのキーを管理するステート
  const [tabKey, setTabKey] = useState<string>('result');

  // 案件概要を管理するステート
  const [projectOverview, setProjectOverview] = useState<GetProjectOverviewOutput>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    kpi: [],
    budget: 0,
    scheduleStartedAt: '',
    scheduleEndedAt: '',
    orderInfo: {
      companyName: '',
      address: '',
      represent: '',
      established: '',
      capital: '',
      business: '',
    },
  });
  console.log(setProjectOverview);

  // フィードバックを更新するための関数を返す
  const updateAdopters = (projectId: string) => async (rows?: GetAnalysisJJFeedbackAdoptersRowsEnum, page?: number) => {
    console.log('JJの検索処理が走りました');
    return getAnalysisJaneJohnFeedbackAdopters(projectId, rows, page);
  };

  // プロジェクトIDが変更された場合に案件概要を取得する
  useEffect(() => {
    if (props.id.length > 0) {
      (async () => {
        let result;
        if (props.projectType === PROJECT_TYPES.SPIRIT || props.projectType === PROJECT_TYPES.OTHER) {
          result = await getSummary(props.id);
        } else if (props.projectType === PROJECT_TYPES.JANE_JOHN) {
          result = await getJaneJohnList(undefined, 'analysis', 30, 1);
        }
        setSummary(result);
      })();
    }
  }, [id]);

  // 現在選択されているタブのコンテンツを表示する
  const displayContent = () => {
    if (tabKey == 'result') {
      // 結果タブ
      return resultContent();
    } else if (tabKey == 'follower') {
      // フォロワー属性タブ
      return followerAttribute();
    } else if (tabKey == 'post') {
      // 投稿タブ
      return postContent();
    } else if (tabKey == 'feedback') {
      // フィードバックタブ
      return influencer_feedback();
    } else {
      // 案件概要タブ
      return projectDetailContent();
    }
  };

  const resultContent = () => {
    return <AnalysisResult projectId={props.id} />;
  };

  // フォロワー属性コンテンツを表示
  const followerAttribute = () => {
    if (isInstagram(sns)) {
      return <FollowerAttributeInstagram project_title={PROJECT_TYPES.JANE_JOHN} />;
    } else if (isTwitter(sns)) {
      return <FollowerAttributeTwitter project_title={PROJECT_TYPES.JANE_JOHN} />;
    } else if (isTikTok(sns)) {
      return <FollowerAttributeTiktok project_title={PROJECT_TYPES.JANE_JOHN} />;
    } else {
      return <FollowerAttributeYoutube project_title={PROJECT_TYPES.JANE_JOHN} />;
    }
  };

  // 投稿コンテンツを表示
  const postContent = () => {
    /*if (isInstagram(props.sns)) {
      return <PostInstagram id={props.id} />;
    } else if (isTwitter(props.sns)) {
      return <PostTwitter />;
    } else if (isTikTok(sns)) {
      return <PostTiktok />;
    } else {
      return <PostYoutube />;
    }*/
    return <JanejoneInfluencerList id={props.id} snsType={props.sns as SnsType} />;
  };

  // 案件詳細コンテンツを表示
  const projectDetailContent = () => {
    return (
      <ProjectDetails
        markeValue={projectOverview.marketBackground}
        theme={projectOverview.problem}
        purpose={projectOverview.purpose}
        description={projectOverview.measure}
        evaluationItem={projectOverview.kpi.join('\n')}
        budget={`￥${projectOverview.budget.toLocaleString()} （税別）`}
        supplierInformation={
          projectOverview.orderInfo.companyName +
          '\n' +
          projectOverview.orderInfo.address +
          '\n' +
          projectOverview.orderInfo.represent +
          '\n' +
          projectOverview.orderInfo.established +
          '\n' +
          projectOverview.orderInfo.capital +
          '\n' +
          projectOverview.orderInfo.business
        }
      />
    );
  };

  // インフルエンサーからのフィードバックコンテンツを表示
  const influencer_feedback = () => {
    return (
      <Feedback
        projectType={projectType}
        sns={sns}
        isCompleteFeedback={isCompleteFeedback}
        setIsCompleteFeedback={setIsCompleteFeedback}
        getAnalysisFeedbackAdopters={updateAdopters(id)}
        projectId={id}
      />
    );
  };

  const selectTab = (): TabProps[] => {
    if (props.projectType === PROJECT_TYPES.SPIRIT) {
      return [
        {
          name: '結果サマリーと考察',
          key: 'summary',
          icon: <FileIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <FileIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: '投稿',
          key: 'post',
          icon: <ImagesIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <ImagesIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: 'インフルエンサーからのフィードバック',
          key: 'feedback',
          icon: <CommentIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <CommentIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: '案件概要',
          key: 'project',
          icon: <NotepadIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <NotepadIcon width={20} height={20} fill="#007CC2" />,
        },
      ];
    } else if (props.projectType === PROJECT_TYPES.JANE_JOHN || props.projectType === PROJECT_TYPES.OTHER) {
      return [
        {
          name: '結果',
          key: 'result',
          icon: <FileIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <FileIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: 'インフルエンサー一覧',
          key: 'post',
          icon: <GroupIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <GroupIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: 'インフルエンサーからのフィードバック',
          key: 'feedback',
          icon: <CommentIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <CommentIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: '案件概要',
          key: 'project',
          icon: <NotepadIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <NotepadIcon width={20} height={20} fill="#007CC2" />,
        },
      ];
    } else {
      return [];
    }
  };

  const handleTabSelect = (key: string) => {
    setTabKey(key);
  };

  // ダイアログを閉じる際の処理
  const onClose = useCallback(() => {
    setTabKey('result'); // タブをデフォルトに戻す
    setOpen(false); // ダイアログを閉じる
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-[1110px]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    {/* ヘッダー部分 */}
                    <div className="flex flex-col items-start gap-1 self-stretch p-6">
                      <div className="flex items-center gap-2 self-stretch">
                        <div className="flex flex-1 items-center justify-between self-stretch">
                          <div className="flex items-center gap-4">
                            <div className="flex flex-col items-start gap-1">
                              <div className="flex items-center gap-[10px]">
                                <span className="text-xs font-normal leading-none text-gray-700">{project}</span>
                              </div>
                              <div className="flex items-center gap-2">
                                <span className="text-base font-bold leading-6 text-gray-700">{projectName}</span>
                                <Badge
                                  color="blue"
                                  size="sm"
                                  round="custom"
                                  className="rounded-md bg-blue-100 p-2 text-blue-800"
                                >
                                  JANE JOHN
                                </Badge>
                              </div>
                            </div>
                          </div>
                          <span className="text-sm font-normal leading-5 text-gray-500">実施期間：{span}</span>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <XMarkIcon className="size-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* タブ部分 */}
                    <div className="flex items-center self-stretch px-4 py-0">
                      <div className="flex h-[38px] items-center justify-center self-stretch">
                        <div className="flex h-[38px] w-[1041px] items-center gap-8 border-b  border-gray-200 pl-0">
                          {selectTab().map((tab: TabProps) => {
                            return (
                              <button
                                key={tab.key}
                                className={clsx('flex flex-col items-center justify-center')}
                                disabled={tab.key == 'summary' ? false : +id % 2 == 0 ? true : false} // TODO 集計中の判定は別途行う必要あり
                                onClick={() => {
                                  handleTabSelect(tab.key);
                                }}
                              >
                                <div
                                  className={clsx(
                                    'flex items-center justify-center gap-2  px-1 pb-4 pt-0',
                                    tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                  )}
                                >
                                  {tabKey == tab.key ? tab.activeIcon : tab.icon}
                                  {tab.name}
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* コンテンツ表示部分 */}
                    <div id="main-frame" className="flex items-start gap-2 self-stretch p-6">
                      {displayContent()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
