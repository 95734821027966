import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CreationStatus, GetCreationOutput, PostingType } from '@/api/creation/api';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { Input } from '@/components/Elements/Input';
import { patchCreationImage, postCreationImage } from '@/hooks/Creation';
import { ValidationMessages } from '@/utils/message';

import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
import { ReactComponent as TrashIcon } from './assets/bx-trash-alt.svg';
import { CreationImageDraft } from './ImageCreateView';

type ImageType = {
  postingType: PostingType;
} & CreationImageDraft;

const MAX_DESCRIPTION_SIZE = 2000;
const MAX_MEDIA_TEXT_SIZE = 200;
const MAX_FILE_COUNT = 5;
const MAX_FILE_SIZE = 30; // MB
const ALLOWED_FILE_TYPES = {
  'image/jpeg': ['jpg', 'jpeg'],
  'image/png': ['png'],
  'image/gif': ['gif'],
  'video/mp4': ['mp4'],
} as const;

type ModalContentType = {
  isOpen: boolean;
  creationId: string;
  contentId: string;
  postingStatus: CreationStatus;
  imageDetail: ImageType;
  closeModal: () => void;
  deleteModal: () => void;
  // TODO: 投稿制作追加API追加されたらデータ再取得に変更
  // updatePost: () => void;
  setPostDetail: React.Dispatch<React.SetStateAction<GetCreationOutput>>;
  setImageDraft: React.Dispatch<React.SetStateAction<CreationImageDraft>>;
};

export const ImageModal = (props: ModalContentType) => {
  const schema = yup.object().shape({
    postingType: yup.string().required(),
    description: yup
      .string()
      .required(ValidationMessages.inputMessage('テキスト'))
      .max(MAX_DESCRIPTION_SIZE, ValidationMessages.strMaxLenMessage('画像 / 動画の説明', MAX_DESCRIPTION_SIZE)),
    movieURL: yup.string().when('postingType', {
      is: (value: PostingType) => value === PostingType.Youtube,
      then: yup.string().required('URLを入力してください').url('有効なURLを入力してください'),
      // false の場合はこの field を削除
      otherwise: yup.string().strip(),
    }),
    mediaText: yup.string().when('postingType', {
      is: (value: PostingType) => value === PostingType.Youtube,
      then: yup
        .string()
        .required(ValidationMessages.inputMessage('画像 / 動画の説明'))
        .max(MAX_MEDIA_TEXT_SIZE, `${MAX_MEDIA_TEXT_SIZE}文字以下で入力してください`),
      // false の場合はこの field を削除
      otherwise: yup.string().strip(),
    }),
    contents: yup.array().when('postingType', {
      is: (value: PostingType) => value !== PostingType.Youtube,
      then: yup.array().of(
        yup.object().shape({
          mediaImage: yup
            .mixed()
            .required('ファイルを選択してください')
            .test('fileSize', `ファイルサイズは${MAX_FILE_SIZE}MB以下にしてください`, (value) => {
              if (!value || !value.size) return true; // ファイルがない場合は検証をスキップ
              return value.size < MAX_FILE_SIZE * 1024 * 1024;
            }),
          textImage: yup
            .string()
            .required(ValidationMessages.inputMessage('画像 / 動画の説明'))
            .max(MAX_MEDIA_TEXT_SIZE, `${MAX_MEDIA_TEXT_SIZE}文字以下で入力してください`),
        })
      ),
      otherwise: yup.array().strip(),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ImageType>({
    resolver: yupResolver(schema),
    defaultValues: {
      postingType: props.imageDetail.postingType,
      description: props.imageDetail.description,
      contents: props.imageDetail.contents.length
        ? props.imageDetail.contents
        : [{ mediaImage: new File([], ''), textImage: '' }],
      movieURL: props.imageDetail.movieURL,
      mediaText: props.imageDetail.mediaText,
    },
  });

  const { fields, update, append, replace, remove } = useFieldArray({
    control,
    name: 'contents',
  });

  useEffect(() => {
    console.log('確認(errors)', errors);
    console.log('確認(fields)', fields);
  }, [errors, fields]);

  const submit = async (data: ImageType) => {
    try {
      console.log('確認(data)', data);
      const files =
        data.postingType === PostingType.Youtube
          ? undefined
          : data.contents.filter((content) => content.mediaImage.size).map((content) => content.mediaImage);
      const texts =
        data.postingType === PostingType.Youtube
          ? undefined
          : data.contents.filter((content) => content.mediaImage.size).map((content) => content.textImage);
      if (props.postingStatus === CreationStatus.Draft) {
        const res = await postCreationImage(
          props.creationId,
          data.description,
          files,
          texts,
          data.movieURL,
          data.mediaText
        );
        console.log('確認(response)', res);
        props.setPostDetail((prev) => {
          return {
            ...prev,
            status: CreationStatus.ContentsApproved,
          };
        });
      } else {
        const res = await patchCreationImage(
          props.creationId,
          props.contentId,
          data.description,
          files,
          texts,
          data.movieURL,
          data.mediaText
        );
        console.log('確認(response)', res);
      }
      props.setImageDraft({
        description: data.description,
        contents: data.contents,
        movieURL: data.movieURL,
        mediaText: data.mediaText,
      });
      reset();
      props.closeModal();
    } catch (e: any) {
      return;
    }
  };

  const handleOnDrop = (index: number) => (files: File[]) => {
    update(index, { mediaImage: files[0], textImage: fields[index].textImage });
  };

  const handleAddImage = () => {
    append({ mediaImage: new File([], ''), textImage: '' });
  };

  const handleMoveUp = (index: number) => {
    if (index == 0) return;
    const files = [...fields];
    const [sortFile] = files.splice(index, 1);
    files.splice(index - 1, 0, sortFile);
    replace(files);
  };

  const handleMoveDown = (index: number) => {
    const files = [...fields];
    const [sortFile] = files.splice(index, 1);
    files.splice(index + 1, 0, sortFile);
    replace(files);
  };

  const handleDelete = (index: number) => {
    remove(index);
  };

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-full w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                <form onSubmit={handleSubmit(submit)}>
                  <div className="h-full w-[720px] bg-white">
                    <div className="mb-[16px] flex h-full w-[720px]">
                      <div className="h-full w-[696px]">
                        <div className="mb-[24px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">
                            イメージ案を{props.postingStatus === CreationStatus.Draft ? '追加' : '編集'}
                          </span>
                        </div>
                        <input hidden {...register('postingType')} />
                        <div className="h-full w-[693px]">
                          {props.imageDetail.postingType === PostingType.Youtube ? (
                            <>
                              <div className="mb-[24px] h-full w-[693px]">
                                <div className="w-[696px]">
                                  <Input
                                    label="YouTubeの動画URL"
                                    classnamelabel="leading-5 items-start flex"
                                    {...register('movieURL')}
                                  ></Input>
                                </div>
                                <div>
                                  {errors && errors.movieURL && (
                                    <p className="pt-1 text-left text-base text-red-400">{errors.movieURL.message}</p>
                                  )}
                                </div>
                              </div>
                              <div className="mb-[24px] w-[693px]">
                                <div className="mb-[4px] flex h-[20px] items-start">
                                  <span className="mb-[4px] text-sm font-semibold leading-5 text-gray-700">
                                    動画の説明
                                  </span>
                                </div>
                                <textarea
                                  className="h-[192px] w-[693px] rounded-md border-[1px] border-gray-300 px-[16px] py-[8px]"
                                  {...register('mediaText')}
                                ></textarea>
                                <div>
                                  {errors && errors.mediaText && (
                                    <p className="text-left text-base text-red-400">{errors.mediaText.message}</p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                                <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                              </div>
                              <div className="mb-[24px] w-[693px]">
                                {fields.map((draft, index) => {
                                  return (
                                    <>
                                      {index !== 0 ? <hr className="mt-[16px]" /> : <></>}
                                      <div
                                        key={draft.id}
                                        className={`w-[693px] ${index > 0 ? 'py-[16px]' : 'pb-[16px]'}`}
                                      >
                                        <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
                                          <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
                                            <span className="text-sm font-semibold leading-5 text-gray-700">
                                              {index + 1}枚目
                                            </span>
                                          </div>
                                          <div className="flex w-[110px] items-center justify-around ">
                                            <button
                                              onClick={() => {
                                                handleMoveDown(index);
                                              }}
                                              disabled={fields.length === index + 1}
                                            >
                                              <ChevronDownIcon
                                                width={28}
                                                height={28}
                                                fill={fields.length === index + 1 ? '#E5E7EB' : '#374151'}
                                              />
                                            </button>
                                            <button
                                              onClick={() => {
                                                handleMoveUp(index);
                                              }}
                                              disabled={index === 0}
                                            >
                                              <ChevronDownIcon
                                                width={28}
                                                height={28}
                                                fill={index === 0 ? '#E5E7EB' : '#374151'}
                                                className="rotate-180"
                                              />
                                            </button>
                                            <button
                                              onClick={() => {
                                                handleDelete(index);
                                              }}
                                            >
                                              <TrashIcon width={22} height={22} fill="#6B7280" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
                                          <div className="h-[209px] w-[239px]">
                                            <Dropzone onDrop={handleOnDrop(index)}>
                                              {({ getRootProps, getInputProps }) => {
                                                return (
                                                  <div
                                                    className="mt-2 flex h-full w-full justify-center rounded-lg border border-dashed border-gray-900/25"
                                                    {...getRootProps()}
                                                  >
                                                    <div className="flex h-full w-full flex-col justify-center overflow-hidden">
                                                      {draft.mediaImage.size ? (
                                                        draft.mediaImage.type == 'video/mp4' ? (
                                                          <video
                                                            controls
                                                            className="rounded-lg object-contain text-gray-400"
                                                            src={URL.createObjectURL(draft.mediaImage)}
                                                          >
                                                            <track kind="captions" />
                                                          </video>
                                                        ) : (
                                                          <img
                                                            className="rounded-lg object-contain text-gray-400"
                                                            src={URL.createObjectURL(draft.mediaImage)}
                                                            alt=""
                                                          />
                                                        )
                                                      ) : (
                                                        <>
                                                          <div className="mt-4 flex flex-col justify-center text-sm leading-6 text-gray-600">
                                                            <div className="flex items-center justify-center">
                                                              <div className="flex h-[48px] w-[48px] items-center justify-center">
                                                                <AddIcon width={36} height={36} />
                                                              </div>
                                                            </div>
                                                            <p className="pl-1 text-sm font-medium">
                                                              ドラッグ&ドロップもしくは
                                                            </p>
                                                            <label
                                                              htmlFor="file-upload"
                                                              className="relative cursor-pointer rounded-md bg-white text-sm font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                              style={{ color: '#007CC2' }}
                                                            >
                                                              <span>アップロード</span>
                                                              <input
                                                                id="file-upload"
                                                                type="file"
                                                                className="sr-only"
                                                                accept={Object.keys(ALLOWED_FILE_TYPES).join(',')}
                                                                {...getInputProps()}
                                                              />
                                                            </label>
                                                          </div>
                                                          <p className="text-xs leading-5 text-gray-600">
                                                            対応ファイルはPNG, JPG, GIF, mp4
                                                          </p>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }}
                                            </Dropzone>
                                          </div>
                                          <div className="h-[209px] w-[416px]">
                                            <p className="text-left text-sm font-bold leading-5 text-gray-700">
                                              画像 / 動画の説明
                                            </p>
                                            <textarea
                                              key={draft.id}
                                              className="h-[185px] w-[416px] rounded-md border-[1px] border-gray-300"
                                              maxLength={MAX_MEDIA_TEXT_SIZE}
                                              {...register(`contents.${index}.textImage`)}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                                <div className="mt-[16px]">
                                  <Button
                                    startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                    variant="secoundary"
                                    size="smNopadding"
                                    className="mt-[16px] h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                                    onClick={handleAddImage}
                                    disabled={fields.length >= MAX_FILE_COUNT}
                                  >
                                    メディアイメージを追加する
                                  </Button>
                                </div>
                              </div>
                              <div>
                                {errors &&
                                  errors.contents &&
                                  errors.contents.map &&
                                  errors.contents.map((error, index) => {
                                    return (
                                      <p key={error?.message} className="mt-1 text-left text-red-500">
                                        {error?.mediaImage?.message?.length &&
                                          `${index + 1}枚目: ${error?.mediaImage?.message ?? ''}`}
                                        {error?.textImage?.message?.length &&
                                          `${index + 1}枚目: ${error?.textImage?.message ?? ''}`}
                                      </p>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mb-[32px] h-full w-[693px]">
                          <div className="h-[216px] w-[693px]">
                            <div className="mb-[4px] h-[20px] w-[56px]">
                              <span className="mb-[4px] text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                            </div>
                            <textarea
                              className="h-[192px] w-[693px] rounded px-[16px] py-[8px]"
                              {...register('description')}
                            />
                            <div className="mt-3 flex flex-col">
                              <div className="text-left">
                                {errors.description && <p className="text-red-500">{errors.description.message}</p>}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* 下記のpostDetail.statusになっている部分を全てselectedPost?.statusに戻す */}
                        {props.postingStatus !== CreationStatus.Draft && (
                          <div className="flex justify-start">
                            <button
                              onClick={props.deleteModal}
                              className="text-sm font-semibold leading-5 text-[#B91C1C]"
                            >
                              削除する
                            </button>
                          </div>
                        )}
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={props.closeModal} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={props.closeModal}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        type="submit"
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
