import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Banner } from '@/api/advertisement';
import { GetTeamInvoiceOutput } from '@/api/team';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as UserVoiceIcon } from '@/assets/icons/bx-user-voice.svg';
// import { GanttChart } from '@/components/Calendars/GanttChart';
import { Button } from '@/components/Elements';
import { MainLayout } from '@/components/Layout/MainLayout';
import CompleteModal from '@/components/ProjectManagements/Modals/CompleteMadal';
import { ROUTES } from '@/config/path';
import { firstSidebarSelect } from '@/config/sidebar';
import { fetchAccountInfo } from '@/hooks/AccountInfo';
import { getCompanyBanners } from '@/hooks/advertisement';
import { getImageUrl } from '@/utils/image';

import { fetchTeamInvoiceInfo } from '../../../hooks/TeamInfo';
import card from '../assets/Card.jpg';
import { ZENDESK_URL } from '../config';

export function Home(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldOpenModal = location.state?.shouldOpenModal;
  const modalMessage = location.state?.modalMessage || 'チームを作成しました';
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [teamInvoiceInfo, setTeamInvoiceInfo] = useState<GetTeamInvoiceOutput>();
  const [banner, setBanner] = useState<Banner | null>(null);

  const [unread, setUnread] = useState<number>(0);

  const getTeamInvoiceInfo = async () => {
    try {
      const data = await fetchTeamInvoiceInfo();
      setTeamInvoiceInfo(data);
      // console.log('data', data);
      // console.log('teamInvoiceInfo', teamInvoiceInfo);
    } catch (error) {
      Sentry.captureException(error);
      console.error('API call failed:', error);
    }
  };

  //FirstSidebar.tsxでのチーム切り替え後に請求書情報を取得するため、タイマーを設定
  const timer = () => {
    setTimeout(() => {
      getTeamInvoiceInfo();
    }, 500);
  };

  //アカウント情報から通知/お知らせの未読数を取得
  const getAccountInfo = async () => {
    try {
      const accountInfo = await fetchAccountInfo();
      setUnread(accountInfo.unreadCount);
      console.log('L65:accountInfo', accountInfo);
      console.log('L66:unread', unread);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    timer();

    setTimeout(() => {
      getAccountInfo();
    }, 1100);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // 先頭の要素が企業バナーの最新情報
        const [banner] = await getCompanyBanners();
        setBanner(banner);
      } catch (error: any) {
        Sentry.captureException(error);
        return;
      }
    })();
  }, [setBanner]);

  const createBannerImageURL = () => {
    return banner ? getImageUrl(banner.adCreativeMedia) : '';
  };

  useEffect(() => {
    if (shouldOpenModal) {
      setOpenModal(true);
      navigate('.', { state: { shouldOpenModal: false, modalMessage: modalMessage } });
    }
  }, [navigate, shouldOpenModal, modalMessage]);

  const handleNewProject = () => {
    navigate(ROUTES.PROJECT);
  };

  const handlePlanSelect = () => {
    navigate(ROUTES.PLAN_SELECT);
  };

  const handleNortification = () => {
    navigate(ROUTES.NORTIFICATION);
  };

  const handleBilling = () => {
    navigate(ROUTES.BILLING);
  };

  const handleZendesk = () => {
    window.open(ZENDESK_URL, '_blank', 'noopener,noreferrer');
  };

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.home,
        secondSideBarselected: '',
      }}
    >
      <div className="container mx-auto h-full min-h-screen bg-gray-100">
        <div className="flex w-[1056px] justify-between bg-gray-100 pt-8">
          <span className="text-2xl font-bold leading-8">ホーム</span>
          <Button
            size="smNopadding"
            className="h-[38px] w-[200px] font-bold"
            startIcon={<PlusIcon width={20} height={20} fill="white"></PlusIcon>}
            onClick={handleNewProject}
          >
            <span className="text-sm font-medium leading-5 text-white">新しい案件を作成</span>
          </Button>
        </div>

        <div id="main-frame" className="flex h-full min-h-screen w-[1056px] flex-col items-start gap-4 bg-gray-100">
          {/* <div id="project-schedule" className="flex flex-col items-start self-stretch rounded-lg border-gray-200">
            <div className="flex w-[1056px] flex-col items-start self-stretch border-gray-200">
              <div className="flex w-[1056px] items-center gap-4 self-stretch border-b border-gray-200 px-6 py-5">
                <div className="w-[792px]">
                  <span className="text-lg font-medium leading-6 text-gray-900">案件スケジュール</span>
                </div>
                <Button
                  size="smNopadding"
                  className="h-[38px] w-[200px] font-bold"
                  startIcon={<PlusIcon width={20} height={20} fill="white"></PlusIcon>}
                  onClick={handleNewProject}
                >
                  <span className="text-sm font-medium leading-5 text-white">新しい案件を作成する</span>
                </Button>
              </div>
              <div id="gantt-chart" className="w-[1008px] p-6">
                <GanttChart isEmpty={false} isHomeView={true}></GanttChart>
              </div>
            </div>
          </div> */}
          <div className="mt-4 flex flex-col items-start gap-4 self-stretch">
            <div className="flex gap-4 self-stretch rounded-lg  bg-white p-4">
              <div className="h-[203px] w-[325px] rounded-lg border border-gray-200 ">
                <div className="flex flex-col items-start  border-b border-gray-200 p-4">
                  <span className="text-lg font-medium leading-6 text-gray-900">現在のプラン</span>
                </div>
                <div className="flex h-[146px] flex-1 flex-col justify-between self-stretch">
                  <div className="flex flex-col items-start self-stretch p-4">
                    <div className="flex flex-col">
                      <span className="text-xl font-bold leading-7 text-gray-700">
                        {teamInvoiceInfo?.plan === 'light'
                          ? 'ライトプラン'
                          : teamInvoiceInfo?.plan === 'basic'
                          ? 'ベーシックプラン'
                          : teamInvoiceInfo?.plan === 'enterprise'
                          ? 'エンタープライズプラン'
                          : teamInvoiceInfo?.plan === 'trial'
                          ? '無料プラン'
                          : teamInvoiceInfo?.plan === 'suspend'
                          ? '停止中'
                          : ''}
                      </span>
                    </div>
                  </div>
                  <div className="p-4">
                    <Button size="sm" className="h-9 w-[293px] font-bold" variant="white" onClick={handlePlanSelect}>
                      <span className="text-sm font-medium leading-5 text-gray-700">プランを確認する</span>
                    </Button>
                  </div>
                </div>
              </div>

              <div className="h-[203px] w-[325px] rounded-lg border border-gray-200 ">
                <div className="border-b border-gray-200 p-4">
                  <span className="text-lg font-medium leading-6 text-gray-900">最新のお知らせ</span>
                </div>
                <div className="flex h-[146px] flex-1 flex-col justify-between self-stretch">
                  <div className="flex flex-col items-start self-stretch p-4">
                    <div className="flex flex-col">
                      <div className="flex items-start gap-[2px]">
                        <span className="text-xl font-bold leading-9">未読：</span>
                        <span className="text-xl font-bold leading-9">{unread.toLocaleString()}件</span>
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <Button size="sm" className="h-9 w-[293px] font-bold" variant="white" onClick={handleNortification}>
                      <span className="text-sm font-medium leading-5 text-gray-700">一覧</span>
                    </Button>
                  </div>
                </div>
              </div>

              <div id="information" className="h-[203px] w-[325px] rounded-lg border border-gray-200 ">
                <div className="border-b border-gray-200 p-4">
                  <span className="text-lg font-medium leading-6 text-gray-900">今月の利用金額</span>
                </div>
                <div className="flex h-[146px] flex-1 flex-col justify-between self-stretch">
                  <div className="flex flex-col items-start self-stretch p-4">
                    <div className="flex items-start">
                      <span className="text-3xl font-bold leading-9">￥</span>
                      <span className="text-3xl font-bold leading-9">
                        {teamInvoiceInfo?.planAmount.toLocaleString()}
                      </span>
                    </div>
                    <div>
                      <span className="text-sm font-medium leading-5 text-gray-500">
                        対象案件数：{teamInvoiceInfo?.projectCounts}
                      </span>
                    </div>
                  </div>
                  <div className="px-4 pb-4 pt-0">
                    <Button size="sm" className="h-9 w-[293px] font-bold" variant="white" onClick={handleBilling}>
                      <span className="text-sm font-medium leading-5 text-gray-700">詳細を確認する</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div id="banner" className="flex items-start gap-4">
              <div className="flex h-[272px] w-[296px] items-center justify-center">
                <a href={banner ? banner.url : ''} target="_blank" rel="noopener noreferrer">
                  <img src={createBannerImageURL()} alt="banner" />
                </a>
              </div>
              <div className="relative flex items-center justify-center">
                <img src={card} alt="card" className="h-[272px] w-[392px] rounded-lg"></img>
                <div className="absolute inline-flex flex-col items-start gap-6">
                  <div className="flex w-[248px] flex-col items-start gap-2 self-stretch">
                    <div className="flex items-center gap-4">
                      <UserVoiceIcon width={48} height={48} fill="white"></UserVoiceIcon>
                      <div>
                        <span className="text-xl font-bold leading-7 text-white">
                          コミュニティに
                          <br />
                          参加しませんか？
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="text-base font-medium leading-6 text-[#C2E9FF]">
                        コミュニティではInfulfectを利用するメンバーと疑問や質問を共有したり、解決に役立てることができます。
                      </span>
                    </div>
                  </div>
                  <div>
                    <Button size="sm" className="h-9 w-[248px] font-bold" variant="white" onClick={handleZendesk}>
                      <span className="text-sm font-medium leading-5 text-gray-700">コミュニティに参加する</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompleteModal
        open={openModal}
        setOpen={setOpenModal}
        modalText={modalMessage}
        buttonText="閉じる"
      ></CompleteModal>
    </MainLayout>
  );
}
