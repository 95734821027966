import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  // Creation,
  SnsType,
  GetCreationsOrderEnum,
  PostCreationInput,
  PostingType,
  CreationStatus,
  Creation,
  GetCreationsOutput,
  CreationPreviewItem,
} from '@/api/creation';
// import { ReactComponent as ArrowDropDownIcon } from '@/assets/icons/ArrowDropDown.svg';
import { ReactComponent as ChevronLeft } from '@/assets/icons/bx-chevron-left.svg';
// import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
// import { ReactComponent as ChevronsLeftIcon } from '@/assets/icons/bx-chevrons-left.svg';
// import { ReactComponent as ChevronsRightIcon } from '@/assets/icons/bx-chevrons-right.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { ReactComponent as CarouselIcon } from '@/assets/icons/carousel-icon.svg';
import { ReactComponent as ReelsIcon } from '@/assets/icons/reel-icon.svg';
import { Button } from '@/components/Elements';
import { DateTimePicker } from '@/components/Elements/DatePicker/DateTimePicker';
import { Pagenation, PageSize } from '@/components/Elements/Pagenation/Pagenation';
import { SelectMenus } from '@/components/Elements/SelectMenus';
import {
  getCreationAccountMeasures,
  getCreationAccounts,
  getCreations,
  postCreation,
  deleteCreation,
  getCreationPreview,
} from '@/hooks/Creation';
import { getImageUrlIfRelative } from '@/utils/image';
import { ValidationMessages } from '@/utils/message';

import {
  arrow_css_type,
  arrow_up_css_type,
} from '../../../components/ProjectManagements/Applicant/Contents/common/Types/common_types';

import { ReactComponent as ArtBoardImage } from './assets/ArtBoard7.svg';
import { ReactComponent as GritIcon } from './assets/bx-grid.svg';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';
import { ReactComponent as TeamWorkImage } from './assets/Team work_Two Color 2.svg';
import DotsDropdown from './DotsDropdown';

interface ProjectDetail {
  accountId: string;
  accountName: string;
  snsType: SnsType;
  projectName: string;
}

const MockPostList: Creation[] = [
  {
    id: '0'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.Feed,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '1'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.Other,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '2'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.OtherVideo,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '3'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.Reel,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '4'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.Stories,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '5'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.TikTok,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '6'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.XImagePost,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '7'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.XPost,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '8'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.Youtube,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
  {
    id: '9'.repeat(26),
    no: 1,
    postScheduledAt: '2021-02-12T00:00:00Z',
    type: PostingType.YoutubeShort,
    status: CreationStatus.Contents,
    measureId: '12345678901234567890ABCDEF',
    mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
    textImage: '画像の説明等',
    comments: 0,
  },
];

export const PostListView = () => {
  const getMeasureId = (): string => {
    const paths = window.location.pathname.split('/'); // ["/", "PRST_production_management", "(measureId)"]
    return paths.length > 2 && paths[1] === 'PRST_production_management' ? paths[2] : 'measureId';
  };
  const getAccountId = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('accountId') ?? 'accountId';
  };
  const [posts, setPosts] = useState<Creation[]>([]);
  const [projectDetail, setProjectDetail] = useState<ProjectDetail>({
    accountId: getAccountId(),
    accountName: 'アカウント名',
    snsType: SnsType.Other,
    projectName: '施策名',
  });
  const postingTypes = (type: SnsType): PostingType[] => {
    switch (type) {
      case SnsType.Instagram:
        return [PostingType.Feed, PostingType.Stories, PostingType.Reel];
      case SnsType.X:
        return [PostingType.XPost, PostingType.XImagePost];
      case SnsType.TikTok:
        return [PostingType.TikTok];
      case SnsType.Youtube:
        return [PostingType.Youtube, PostingType.YoutubeShort];
      default:
        return [PostingType.Other, PostingType.OtherVideo];
    }
  };
  const [selectType, setSelectType] = useState<PostingType | undefined>(undefined);
  const [selectDate, setSelectDate] = useState<Date | undefined>(undefined);
  const [addPostId, setAddPostId] = useState<string>('00000000000000000000000000');
  const [addPostModal, setAddPostModal] = useState<boolean>(false);
  const [addPostCompletionModal, setAddPostCompletionModal] = useState<boolean>(false);
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [postTypeError, setPostTypeError] = useState<boolean>(false);
  const [postTypeErrorMessage, setPostTypeErrorMessage] = useState<string>('');
  const [postScheduleDateError, setPostScheduleDateError] = useState<boolean>(false);
  const [postScheduleDateErrorMessage, setPostScheduleDateErrorMessage] = useState<string>('');
  const [previewImages, setPreviewImages] = useState<CreationPreviewItem[]>([]);

  const updatePosts = () => {
    getCreations(getMeasureId(), perPage, currentPage, order, orderBy).then((response: GetCreationsOutput) => {
      if (response) {
        // setPosts(response.rows);
        setPosts(MockPostList);
        setTotalRows(response.totalRows);
        setTotalPages(response.totalPages);
      }
    });
  };

  const postCreationValidation = (): boolean => {
    let isError = false;
    if (!selectType || !Object.values(PostingType).includes(selectType)) {
      isError = true;
      setPostTypeError(true);
      setPostTypeErrorMessage(ValidationMessages.selectMessage('投稿タイプ'));
    } else {
      setPostTypeError(false);
      setPostTypeErrorMessage('');
    }
    if (!selectDate) {
      isError = true;
      setPostScheduleDateError(true);
      setPostScheduleDateErrorMessage(ValidationMessages.selectMessage('投稿予定日'));
    } else {
      setPostScheduleDateError(false);
      setPostScheduleDateErrorMessage('');
    }
    return isError;
  };
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<PageSize>(10);
  const [order, setOrder] = useState<GetCreationsOrderEnum>('asc');
  const [orderBy, setOrderBy] = useState<string>('no');
  const [totalRows, setTotalRows] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sortStateArray, setSortStateArray] = useState<string[]>([
    'ascending',
    'ascending',
    'ascending',
    'ascending',
    'ascending',
  ]);
  const handleClick = () => {
    setAddPostModal(true);
    setAddPostCompletionModal(false);
  };

  const handleClick1 = () => {
    if (postCreationValidation()) return;
    const newPost: PostCreationInput = {
      type: selectType as PostingType,
      postingDate: selectDate
        ? selectDate
            .toLocaleDateString('ja-JP', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })
            .split('/')
            .join('-')
        : '',
    };
    console.log('newPost', newPost);
    postCreation(getMeasureId(), newPost).then(({ affected }) => {
      if (affected) {
        setAddPostId(affected);
      }
    });
    setAddPostModal(false);
    setAddPostCompletionModal(true);
  };

  const handleClick3 = () => {
    navigate(`/PRST_production_management/${getMeasureId()}/${addPostId}?accountId=${getAccountId()}`);
  };

  const handleClick5 = () => {
    navigate(`/PRST_production_management`);
  };

  /**
   * ソートを切り替える
   * @param word ソート項目
   * @param index インデックス (0: NO, 1: 投稿予定日, 2: タイプ, 3: ステータス, 4: コメント)
   */
  const handleSort = (word: string, index: number) => {
    const newSortStateArray = new Array(5).fill('ascending');
    newSortStateArray[index] = order === 'asc' ? 'descending' : 'ascending';
    setSortStateArray(newSortStateArray);

    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(word);
  };

  const selectPost = (creationId: string) => () => {
    const measureId = getMeasureId();
    navigate(`/PRST_production_management/${measureId}/${creationId}?accountId=${getAccountId()}`);
  };

  useEffect(() => {
    updatePosts();
  }, [currentPage, perPage, order, orderBy]);

  useEffect(() => {
    getCreationPreview(getMeasureId()).then((res) => {
      setPreviewImages(res);
    });
  }, [posts]);

  useEffect(() => {
    getCreationAccounts().then(async (response) => {
      if (response) {
        const account = response.find((account) => account.id === getAccountId());
        const project = await getCreationAccountMeasures(getAccountId());
        setProjectDetail({
          accountId: account?.id ?? 'accountId',
          accountName: account?.name ?? 'アカウント名',
          snsType: account?.snsType ?? SnsType.Other,
          projectName: project.rows.find((row) => row.id === getMeasureId())?.name ?? '施策名',
        });
      }
    });
  }, []);

  const arrow_under_css: arrow_css_type = {
    display: 'block',
    width: '9px',
    height: '9px',
    borderTop: 'solid 2px #4B5563',
    borderRight: 'solid 2px #4B5563',
    borderColor: '#4B5563 #4B5563 transparent transparent',
    transform: 'rotate(135deg)',
  };
  const arrow_up_css: arrow_up_css_type = {
    display: 'block',
    width: '9px',
    height: '9px',
    borderTop: 'solid 2px #4B5563',
    borderRight: 'solid 2px #4B5563',
    borderColor: '#4B5563 #4B5563 transparent transparent',
    transform: 'rotate(315deg)',
    marginTop: '4px',
  };

  const getCreationTypeDescription = (type: PostingType): string => {
    switch (type) {
      case PostingType.Reel:
        return 'リール';
      case PostingType.Feed:
        return 'フィード';
      case PostingType.Stories:
        return 'ストーリーズ';
      case PostingType.XImagePost:
        return 'X画像投稿';
      case PostingType.XPost:
        return 'X投稿';
      case PostingType.YoutubeShort:
        return 'YouTubeショート';
      case PostingType.Youtube:
        return 'YouTube';
      case PostingType.TikTok:
        return 'TikTok';
      case PostingType.OtherVideo:
        return 'その他動画';
      case PostingType.Other:
        return 'その他';
      default:
        return '';
    }
  };

  const getCreationStatusDescription = (status: CreationStatus): string => {
    switch (status) {
      case CreationStatus.Initialized:
        return '初期状態';
      case CreationStatus.Draft:
        return 'イメージ案下書き';
      case CreationStatus.DraftApproved:
        return 'イメージ案承認済	';
      case CreationStatus.Contents:
        return 'コンテンツ案下書き';
      case CreationStatus.ContentsApproved:
        return 'コンテンツ案承認済';
      case CreationStatus.Completed:
        return '完了';
      default:
        return '';
    }
  };

  const formatDateTime = (dateTime: string): string => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月は0から始まるため+1
    const day = date.getDate();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  const AddPostModal = () => {
    return (
      <Transition.Root show={addPostModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setAddPostModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[512px] rounded-lg bg-white p-[24px]">
                  <div className="w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] w-[464px]">
                      <div className="mb-[16px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">新規投稿制作</span>
                              <div className="ml-auto">
                                <CloseIcon
                                  width={24}
                                  height={24}
                                  fill="#9CA3AF"
                                  onClick={() => setAddPostModal(false)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-[16px] w-[464px]">
                        <SelectMenus
                          title="投稿タイプ"
                          options={[
                            { value: 'none', label: '選択してください' },
                            ...postingTypes(projectDetail.snsType).map((type) => {
                              return { value: type, label: getCreationTypeDescription(type) };
                            }),
                          ]}
                          widthSize="w-[464px]"
                          className="w-[173px] font-semibold"
                          value={selectType || 'none'}
                          onChange={(e: any) => {
                            setSelectType(e.value);
                          }}
                        />
                        {postTypeError && (
                          <div className="mb-[16px]">
                            <div className="pt-2 text-left text-base text-red-400">{postTypeErrorMessage}</div>
                          </div>
                        )}
                      </div>
                      <div className="w-[464px]">
                        <div className="mb-[4px] h-[20px] w-[70px]">
                          <span className="mb-[4px] text-sm font-semibold leading-5 text-gray-700">投稿予定日</span>
                        </div>
                        <DateTimePicker
                          selectedDate={selectDate}
                          onChange={(e: any) => {
                            setSelectDate(e);
                          }}
                          width="464px"
                        />
                        {postScheduleDateError && (
                          <div className="mb-[16px]">
                            <div className="pt-2 text-left text-base text-red-400">{postScheduleDateErrorMessage}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setAddPostModal(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleClick1}
                        // disabled={!postType || !postScheduleDate || postCreationError}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const AddPostCompletionModal = () => {
    return (
      <Transition.Root show={addPostCompletionModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setAddPostCompletionModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[403px] w-[380px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[355px] w-[332px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[24px] w-[332px]">
                      <div className="flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">新規投稿制作</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={handleClick} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-auto mb-[32px] flex h-[189px] w-[311px] justify-center">
                      <div>
                        <ArtBoardImage />
                      </div>
                    </div>
                    <div className="h-[78px] w-[332px]">
                      <div className="mb-[16px] flex h-[42px] w-[332px] flex-row items-center justify-end gap-3">
                        <Button
                          variant="white"
                          size="md"
                          className="whitespace-nowrap text-sm font-medium leading-5"
                          onClick={() => {
                            setAddPostCompletionModal(false);
                          }}
                        >
                          あとで
                        </Button>
                        <Button
                          variant="primary"
                          size="md"
                          className="whitespace-nowrap text-sm font-medium leading-5"
                          onClick={handleClick3}
                        >
                          イメージ案作成へ進む
                        </Button>
                      </div>
                      <div className="flex w-[332px] justify-end">
                        <button
                          className="whitespace-nowrap text-sm font-medium leading-5 text-gray-500"
                          onClick={handleClick3}
                        >
                          スキップしてコンテンツ制作からはじめる
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const PreviewModal = () => {
    return (
      <Transition.Root show={previewModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setPreviewModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[768px] rounded-lg bg-white p-[24px]">
                  <div className="w-[720px] rounded-lg bg-white">
                    <div className="mb-[16px] w-[720px]">
                      <div className="mb-[32px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">投稿並びプレビュー</span>
                              <div className="ml-auto">
                                <CloseIcon
                                  width={24}
                                  height={24}
                                  fill="#9CA3AF"
                                  onClick={() => setPreviewModal(false)}
                                />
                              </div>
                            </div>
                            <div className="mt-2">
                              <p className="text-left text-sm font-normal leading-5 text-gray-500">
                                コンテンツ制作で登録済みのメディアが表示されています
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mx-auto flex w-[520px] flex-row flex-wrap items-center">
                        {previewImages.map((image) => {
                          return (
                            <div key={image.image} className="relative flex h-[172px] w-[172px] flex-col">
                              <div className="h-[172px] w-[172px]">
                                <img
                                  src={getImageUrlIfRelative('assets/cl/thumbnail/67b7f8bc3aa5681c.png')}
                                  alt="preview"
                                  className="h-[172px] w-[172px] object-cover"
                                />
                              </div>
                              {image.type === 'movie' && (
                                <div className="absolute right-1 top-1 h-[12px] w-[20px] shadow-sm">
                                  <ReelsIcon />
                                </div>
                              )}
                              {image.type === 'multi_image' && (
                                <div className="absolute right-1 top-1 h-[12px] w-[20px] shadow-sm">
                                  <CarouselIcon />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setPreviewModal(false)}
                        // disabled={!postType || !postScheduleDate || postCreationError}
                      >
                        終了する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      <div>
        <div className="flex w-[1181px] border-b border-gray-200 py-[18px] pl-[16px]">
          <button onClick={handleClick5}>
            <ChevronLeft width={24} height={24} fill="#9CA3AF" />
          </button>
          <p className="ml-[16px] text-base font-bold leading-6 text-gray-700">{projectDetail.projectName}</p>
        </div>
        <div className="w-[940px] px-[24px] py-[16px]">
          <div>
            <div className="mb-[16px] flex w-[1133px] items-center justify-between">
              <p className="text-base font-semibold leading-7 text-gray-700">投稿制作一覧</p>
              <div className="flex justify-end">
                <Button
                  startIcon={<GritIcon className="size-[20px]" />}
                  size="md"
                  variant="white"
                  className="whitespace-nowrap text-sm font-medium leading-5"
                  style={{
                    marginRight: '16px',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    paddingLeft: '10px',
                    paddingRight: '12px',
                  }}
                  onClick={() => setPreviewModal(true)}
                >
                  並び順プレビュー
                </Button>
                <Button
                  startIcon={<PlusImage className="size-[20px]" />}
                  size="md"
                  className="whitespace-nowrap text-sm font-medium leading-5"
                  style={{
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    paddingLeft: '10px',
                    paddingRight: '12px',
                  }}
                  onClick={handleClick}
                >
                  投稿制作を新規追加
                </Button>
              </div>
            </div>
            {posts.length !== 0 ? (
              <div className="h-[475px] w-[1133px] rounded-lg border border-gray-200 bg-[#FFFFFF] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
                <table className="w-[1133px]">
                  {/* 1列目 */}
                  <thead className="flex">
                    <tr>
                      <th scope="col" className="sticky w-[88px] py-[10px] pl-[16px] text-left">
                        <span className="flex text-xs font-normal leading-4 tracking-wider text-gray-700">
                          NO{' '}
                          <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                            {sortStateArray[0] === 'ascending' ? (
                              <button onClick={() => handleSort('no', 0)} style={arrow_under_css}></button>
                            ) : (
                              <button onClick={() => handleSort('no', 0)} style={arrow_up_css}></button>
                            )}
                          </div>
                        </span>
                      </th>
                      <th scope="col" className="sticky w-[160px] py-[10px] pl-[16px] text-left">
                        <span className="flex text-xs font-normal leading-4 tracking-wider text-gray-700">
                          投稿予定日
                          <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                            {sortStateArray[1] === 'ascending' ? (
                              <button onClick={() => handleSort('postScheduledAt', 1)} style={arrow_under_css}></button>
                            ) : (
                              <button onClick={() => handleSort('postScheduledAt', 1)} style={arrow_up_css}></button>
                            )}
                          </div>
                        </span>
                      </th>
                      <th scope="col" className="sticky w-[136px] py-[10px] pl-[16px] text-left">
                        <span className="text-xs font-normal leading-4 tracking-wider text-gray-700">
                          メディアイメージ
                        </span>
                      </th>
                      <th scope="col" className="sticky w-[254px] py-[10px] pl-[16px] text-left">
                        <span className="text-xs font-normal leading-4 tracking-wider text-gray-700">
                          テキストイメージ
                        </span>
                      </th>
                      <th scope="col" className="sticky w-[140px] py-[10px] pl-[16px] text-left">
                        <span className="flex text-xs font-normal leading-4 tracking-wider text-gray-700">
                          タイプ
                          <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                            {sortStateArray[2] === 'ascending' ? (
                              <button onClick={() => handleSort('type', 2)} style={arrow_under_css}></button>
                            ) : (
                              <button onClick={() => handleSort('type', 2)} style={arrow_up_css}></button>
                            )}
                          </div>
                        </span>
                      </th>
                      <th scope="col" className="sticky w-[176px] py-[10px] pl-[16px] text-left">
                        <span className="flex text-xs font-normal leading-4 tracking-wider text-gray-700">
                          ステータス
                          <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                            {sortStateArray[3] === 'ascending' ? (
                              <button onClick={() => handleSort('status', 3)} style={arrow_under_css}></button>
                            ) : (
                              <button onClick={() => handleSort('status', 3)} style={arrow_up_css}></button>
                            )}
                          </div>
                        </span>
                      </th>
                      <th scope="col" className="sticky w-[122px] py-[10px] pl-[16px] text-left">
                        <span className="flex text-xs font-normal leading-4 tracking-wider text-gray-700">
                          コメント
                          <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                            {sortStateArray[4] === 'ascending' ? (
                              <button onClick={() => handleSort('comments', 4)} style={arrow_under_css}></button>
                            ) : (
                              <button onClick={() => handleSort('comments', 4)} style={arrow_up_css}></button>
                            )}
                          </div>
                        </span>
                      </th>
                      <th scope="col" className="sticky w-[77px] py-[10px] pl-[16px] text-left"></th>
                    </tr>
                  </thead>
                  {/* 2列目 */}
                  <tbody className="flex h-[356px] w-[1133px] flex-col overflow-x-hidden  overflow-y-scroll bg-white">
                    {posts.map((post, index) => (
                      <tr key={post.no} className={`cursor-pointer ${index % 2 == 0 ? 'bg-gray-50' : 'bg-white'}`}>
                        <td className="h-[64px] w-[88px]">
                          <button onClick={selectPost(post.id)}>
                            <div className="flex items-center">
                              <div className="py-6 pl-4">
                                <div className="text-sm font-normal leading-5 text-gray-700">{post.no}</div>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="h-[64px] w-[160px]">
                          <button onClick={selectPost(post.id)}>
                            <div className="flex items-center">
                              <div className="py-6  pl-2">
                                <div className="text-sm font-normal leading-5 text-gray-700">
                                  {formatDateTime(post.postScheduledAt)}
                                </div>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="h-[64px] w-[136px]">
                          <button onClick={selectPost(post.id)}>
                            <div className="flex items-center">
                              <div className="py-6">
                                <div className="pl-8 text-sm font-normal leading-5  text-gray-700">
                                  {post.mediaImage ? (
                                    <img
                                      src={getImageUrlIfRelative(post.mediaImage)}
                                      alt="icon"
                                      className="size-[40px] rounded-full"
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </div>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="h-[64px] w-[254px]">
                          <button onClick={selectPost(post.id)}>
                            <div className="flex items-center">
                              <div className="py-6 pl-2">
                                <div className="text-sm font-normal leading-5 text-gray-700">{post.textImage}</div>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="h-[64px] w-[140px]">
                          <button onClick={selectPost(post.id)}>
                            <div className="flex items-center">
                              <div className="py-6">
                                <div className="mr-[48px]  whitespace-nowrap rounded  bg-gray-100 px-[10px] py-[2px] text-sm font-normal leading-5 text-gray-700 ">
                                  {getCreationTypeDescription(post.type)}
                                </div>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="h-[64px] w-[176px]">
                          <button onClick={selectPost(post.id)}>
                            <div className="flex items-center">
                              <div className="py-6">
                                <div className="mr-[48px]  whitespace-nowrap rounded  bg-gray-100 px-[10px] py-[2px] text-sm font-normal leading-5 text-gray-700 ">
                                  {getCreationStatusDescription(post.status)}
                                </div>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="h-[64px] w-[122px]">
                          <button onClick={selectPost(post.id)}>
                            <div className="flex items-center">
                              <div className="py-6 pl-5">
                                <div className="text-sm font-normal leading-5 text-gray-700">{post.comments}</div>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="h-[64px] w-[57px]">
                          <DotsDropdown
                            modalText="この投稿制作"
                            listType="post"
                            deleteFunction={() => {
                              deleteCreation(post.id).then(() => {
                                updatePosts();
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {posts && posts.length && (
                  <div className="pt-4">
                    <Pagenation
                      currentPageNumber={currentPage}
                      currentPageSize={perPage}
                      togalDataCount={totalRows}
                      totalPageNumber={totalPages}
                      setPageNumber={setCurrentPage}
                      setPageSize={setPerPage}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="flex h-[525px] w-[1133px] items-center justify-center rounded shadow-[0_1px_3px_rgba(0,0,0,0.2),0_2px_1px_rgba(0,0,0,0.12),0_1px_1px_rgba(0,0,0,0.14)]">
                <div>
                  <TeamWorkImage />
                  <div className="flex justify-center">
                    <p className="w-[294px] text-center text-[14px] font-normal leading-6 text-[#6B7280]">
                      まだ施策がありません。
                      <br />
                      右上のボタンから施策を作成してみましょう！
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="relative">{AddPostModal()}</div>
        <div className="relative">{AddPostCompletionModal()}</div>
        <div className="relative">{PreviewModal()}</div>
      </div>
    </>
  );
};
