import AvatarImage from './assets/Avatar.png';
import { ReactComponent as BookarkIcon } from './assets/Bookmark.svg';
import { ReactComponent as HeartIcon } from './assets/bx-heart.svg';
import { ReactComponent as MessageIcon } from './assets/bx-message-rounded.svg';
import { ReactComponent as PaperPlaneIcon } from './assets/bx-paper-plane.svg';

interface Props {
  imagePath: string;
}

export const PRSTProductionManagementContentsApprovedView = ({ imagePath }: Props) => {
  return (
    <div className="w-[290px]">
      <div className="flex w-[290px] items-center justify-between rounded-t-sm border-x border-t border-x-gray-200 border-t-gray-200 px-[6px] py-[13px]">
        <img src={AvatarImage} alt="" className="" />
        <span className="w-full">1122329737</span>
      </div>
      <div className="w-[290px]">
        <img src={imagePath} alt="" className="w-[290px]" />
      </div>
      <div className="flex h-[100px] w-[290px] items-center justify-center rounded-b-sm border-x border-b border-x-gray-200 border-b-gray-200">
        <div className="mb-[13px] mt-[6px] w-[280px]">
          <div className="mb-[9px] flex w-[275px] items-center justify-between">
            <div className="flex w-[66px] items-center justify-between">
              <HeartIcon fill="#000000" />
              <MessageIcon fill="#000000" />
              <PaperPlaneIcon fill="#000000" />
            </div>
            <BookarkIcon />
          </div>
          <div className="w-[280px]">
            <div className="mb-[4px] h-[23px] w-[280px] text-xs font-normal leading-4 text-gray-800">「Canon360」</div>
            <div className="mb-[4px] h-[13px] w-[280px] text-[9px] font-medium text-gray-300">続きを読む</div>
            <div className="h-[13px] w-[280px] text-[9px] font-medium text-gray-400">2022年8月29日 10:58</div>
          </div>
        </div>
      </div>
    </div>
  );
};
