import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Fragment, useState, useEffect } from 'react';
import * as React from 'react';

import { ProjectSummaryBase as JaneJohnProjectSummaryBase } from '@/api/janeJohn';
import { DefaultApiFactory, ProjectSummaryBase as OtherProjectSummaryBase } from '@/api/other';
import { SPIRITApiFactory } from '@/api/spirit';
import { Badge } from '@/components/Elements/Badge';
import { Button } from '@/components/Elements/Button';
import { DatePicker } from '@/components/Elements/DatePicker/DatePicker';
import { Input } from '@/components/Elements/Input/Input';
import { RadioBox } from '@/components/Elements/RadioBox';
import { SelectMenus } from '@/components/Elements/SelectMenus';
import { TextArea } from '@/components/Elements/TextArea';
import { PROJECT_TYPES } from '@/config';
import { useAssetAriaListDataJson, useAssetDataJson, useAssetNumberJson } from '@/hooks/AssetDataJson';
import { axiosInstance } from '@/utils/axiosInstance';
import { ValidationMessages } from '@/utils/message';

// import { getOtherDetail } from '../../../hooks/OtherProject';

interface projectInterface {
  displayAdoption?: string;
  adoption?: string;
  displayReward?: string;
  reward?: string;
  displayDescription?: string;
  description?: string;
  displayFollower?: string;
  follower?: string;
  displayGender?: string;
  gender?: string;
  displayMinAge?: string;
  minAge?: string;
  displayMaxAge?: string;
  maxAge?: string;
  displayLocation?: string;
  location?: string;
  displayMinIncome?: string;
  minIncome?: string;
  displayMaxIncome?: string;
  maxIncome?: string;
  displayJob?: string;
  job?: string;
  main?: string;
  sub?: string;
}

export interface ProjectOverviewProps {
  open: boolean;
  recruitmentPlanCount: number;
  displayRecruitmentPlanCount: string;
  rewardAmount: number;
  displayRewardAmount: string;
  tags: string[];
  displayTags: string[];
  tag: string;
  description: string;
  displayDescription: string;
  project?: projectInterface;
  projectName?: string;
  radioBoxValue: string;
  displaySelectedPrefectures: string[];
  selectedPrefectures: string[];
  prefecturesRadioBoxValue: string;
  application_criteria?: string;
  adoptionError?: string;
  rewardAmountError?: string;
  minAgeError?: string;
  maxAgeError?: string;
  minIncomeError?: string;
  maxIncomeError?: string;
  prefectureError?: string;
  tagsError?: string;
  describeError?: string;
  creatorImage?: string;
  displayCreatorImage?: string;
  creatorImageError?: string;
  contractFrom?: string;
  displayContractFrom?: string;
  contractTo?: string;
  displayContractTo?: string;
}

interface Props {
  projectOverviewProps: ProjectOverviewProps;
  setProjectOverviewProps: React.Dispatch<React.SetStateAction<ProjectOverviewProps>>;
  PutProjectOverviewJaneJohn?: (projectSummaryBase?: JaneJohnProjectSummaryBase) => Promise<void>;
  PutProjectOverviewOther?: (projectSummaryBase?: OtherProjectSummaryBase) => Promise<void>;
  PutProjectOverview: () => Promise<void>; // APIの引数にはprojectOverviewPropsに格納されている値を使用する
  projectId?: string;
  displayRewards?: number | undefined;
  setDisplayRewards?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const SELECT_NAME = {
  FOLLOWER: 'follower',
  GENDER: 'gender',
  MIN_AGE: 'minAge',
  MAX_AGE: 'maxAge',
  LOCATION: 'location',
  MIN_INCOME: 'minIncome',
  MAX_INCOME: 'maxIncome',
  JOB: 'job',
  MAIN: 'main',
  SUB: 'sub',
};
//
export const ProjectOverview = (props: Props) => {
  const projectNameIsSpirit = props.projectOverviewProps.projectName === PROJECT_TYPES.SPIRIT;
  const [minimumReward, setMinimumReward] = useState<number | undefined>();
  const [standardReward, setStandardReward] = useState<number | undefined>();
  const [isStandard, setIsStandard] = useState<boolean>(false);
  console.log('ProjectOverviewProps:' + JSON.stringify(props, null, 2));

  const handleChage = (value: string, name: string) => {
    if (!props.setProjectOverviewProps) return;

    if (SELECT_NAME.FOLLOWER === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, follower: value },
      }));
    } else if (SELECT_NAME.GENDER === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, gender: value },
      }));
    } else if (SELECT_NAME.MIN_AGE === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, minAge: value },
      }));
    } else if (SELECT_NAME.MAX_AGE === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, maxAge: value },
      }));
    } else if (SELECT_NAME.LOCATION === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, location: value },
      }));
    } else if (SELECT_NAME.MIN_INCOME === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, minIncome: value },
      }));
    } else if (SELECT_NAME.MAX_INCOME === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, maxIncome: value },
      }));
    } else if (SELECT_NAME.JOB === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, job: value },
      }));
    } else if (SELECT_NAME.MAIN === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, main: value },
      }));
    } else if (SELECT_NAME.SUB === name) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        project: { ...projectOverviewProps.project, sub: value },
      }));
    }
    // 上記と同じ処理を props.setProjectOverviewProps で行う
  };

  const handleAdd = () => {
    if (props.projectOverviewProps.tag.length === 0) return;
    props.setProjectOverviewProps((projectOverviewProps) => ({
      ...projectOverviewProps,
      tags: [...projectOverviewProps.tags, projectOverviewProps.tag],
      tag: '',
    }));
  };
  const handleRemove = (index: number) => {
    const tmpTags = props.projectOverviewProps.tags.filter((_, i) => i !== index);
    props.setProjectOverviewProps((projectOverviewProps) => ({
      ...projectOverviewProps,
      tags: tmpTags,
    }));
  };

  // ボタンの実装

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#007CC2',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  const [isHokkaidoChecked, setIsHokkaidoChecked] = useState(true);
  const [isTouhokuChecked, setIsTohokuChecked] = useState(true);
  const [isKantoChecked, setIsKantoChecked] = useState(true);
  const [isChubuChecked, setIsChubuChecked] = useState(true);
  const [isKinkiChecked, setIsKinkiChecked] = useState(true);
  const [isChugokuChecked, setIsChugokuChecked] = useState(true);
  const [isShikokuChecked, setIsShikokuChecked] = useState(true);
  const [isKyushuOkinawaChecked, setIsKyushuOkinawaChecked] = useState(true);
  const tihouChecked = (area: string) => {
    switch (area) {
      case '北海道':
        return { isChecked: isHokkaidoChecked, setIsChecked: setIsHokkaidoChecked };
      case '東北':
        return { isChecked: isTouhokuChecked, setIsChecked: setIsTohokuChecked };
      case '関東':
        return { isChecked: isKantoChecked, setIsChecked: setIsKantoChecked };
      case '中部':
        return { isChecked: isChubuChecked, setIsChecked: setIsChubuChecked };
      case '近畿':
        return { isChecked: isKinkiChecked, setIsChecked: setIsKinkiChecked };
      case '中国':
        return { isChecked: isChugokuChecked, setIsChecked: setIsChugokuChecked };
      case '四国':
        return { isChecked: isShikokuChecked, setIsChecked: setIsShikokuChecked };
      case '九州沖縄':
        return { isChecked: isKyushuOkinawaChecked, setIsChecked: setIsKyushuOkinawaChecked };
      default:
        return { isChecked: false, setIsChecked: () => {} };
    }
  };

  // 北海道のチェックは他の都道府県と別で管理しているため、ここで選択するかの判定を行う
  useEffect(() => {
    if (props.projectOverviewProps.selectedPrefectures.includes('01')) setIsHokkaidoChecked(false);
  }, [props.projectOverviewProps.selectedPrefectures]);

  const prefecutureCodeDataJson = useAssetDataJson('/prefectures') ?? [];
  const areaListJsonData = useAssetAriaListDataJson('/areaList') ?? {};
  const getAreaData = (area: string) => {
    const areaKeys = Object.keys(areaListJsonData ?? {});
    return areaKeys.includes(area) ? areaListJsonData[area] : [];
  };

  const handle_tihou_change = (tihou: string) => {
    const handle_process = (
      tihouCode: string[],
      isChecked: boolean,
      setIsChecked: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      console.log('handleProcess:', props.projectOverviewProps.selectedPrefectures, tihouCode, isChecked);
      let newSelectedPrefectures: string[] = []; // newSelectedPrefectures には、選択されている都道府県が入る

      if (isChecked) {
        // チェックが入っている場合、選択されていない都道府県を追加
        newSelectedPrefectures = [
          ...props.projectOverviewProps.selectedPrefectures,
          ...tihouCode.filter((prefCode) => !props.projectOverviewProps.selectedPrefectures.includes(prefCode)),
        ];
      } else {
        // チェックが外れている場合、該当する都道府県を削除
        newSelectedPrefectures = props.projectOverviewProps.selectedPrefectures.filter((prefCode: string) => {
          return !tihouCode.includes(prefCode);
        });
      }

      // 新しい選択状態を更新
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        selectedPrefectures: newSelectedPrefectures,
      }));
      setIsChecked(!isChecked); // チェック状態をトグル
    };
    const { isChecked, setIsChecked } = tihouChecked(tihou);
    handle_process(
      getAreaData(tihou).map((pref) => pref.key),
      isChecked,
      setIsChecked
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, prefecture: string) => {
    if (event.target.checked) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        selectedPrefectures: [
          ...projectOverviewProps.selectedPrefectures,
          prefecutureCodeDataJson.find((pref) => pref.label.includes(prefecture))?.value ?? '',
        ].filter((value) => value !== ''),
      }));
    } else {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        selectedPrefectures: projectOverviewProps.selectedPrefectures.filter((value) =>
          prefecutureCodeDataJson.some((pref) => pref.value !== value)
        ),
      }));
    }
  };

  const children = (string_tihou: string[]) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '32px',
      }}
      className="text-sm font-medium leading-5 text-gray-500"
    >
      {string_tihou.map((value, index) => (
        <FormControlLabel
          label={value}
          control={
            <Checkbox
              checked={props.projectOverviewProps.selectedPrefectures.includes(
                prefecutureCodeDataJson.find((pref) => pref.label.includes(value))?.value ?? ''
              )}
              onChange={(event) => handleChange(event, value)}
              icon={<BpIcon />}
              checkedIcon={<BpCheckedIcon />}
            />
          }
          key={index}
        />
      ))}
    </Box>
  );

  const setDescription = props.projectOverviewProps.description ?? '';
  const setAdoption = props.projectOverviewProps.project?.adoption
    ? parseInt(props.projectOverviewProps.project?.adoption)
    : 0;

  let setReward = props.displayRewards ? props.displayRewards : 0;
  if (!isStandard) {
    setReward = props.projectOverviewProps.project?.reward ? parseInt(props.projectOverviewProps.project?.reward) : 0;
  } else {
    const reward = standardReward === undefined ? setReward : standardReward;
    setReward = props.projectOverviewProps.project?.reward ? reward : 0;
  }

  const setTags = props.projectOverviewProps.tags;
  const propsProjectName = props.projectOverviewProps.projectName;

  const projectOnClose = async (isSave: boolean) => {
    if (isSave) {
      if (projectNameIsSpirit) {
        await props.PutProjectOverview();
        if (props.setDisplayRewards && isStandard) {
          const standard = standardReward === undefined ? 0 : standardReward;
          props.setDisplayRewards(standard);
        } else if (props.setDisplayRewards && !isStandard) {
          props.setDisplayRewards(Number(props.projectOverviewProps.project?.reward));
        }

        //稼働内容変更に伴い相場報酬額が変更になった際、この処理がないとrewardsにundefinedが渡ってしまい、エラーとなるのを回避
        if (props.setDisplayRewards !== undefined && standardReward !== props.displayRewards) {
          props.setDisplayRewards(standardReward);
        }
      } else if (propsProjectName === PROJECT_TYPES.JANE_JOHN) {
        const setProjectSummaryBase: JaneJohnProjectSummaryBase = {
          adoptionPlan: setAdoption,
          rewards: setReward,
          description: setDescription,
          genres: setTags,
          rewardsType: null,
        };
        await props.PutProjectOverviewJaneJohn?.(setProjectSummaryBase);
      } else if (propsProjectName === PROJECT_TYPES.PRST || propsProjectName === PROJECT_TYPES.OTHER) {
        await props.PutProjectOverview();
      }
      const updateProjectStatus: projectInterface = {
        ...props.projectOverviewProps.project,
        displayAdoption: props.projectOverviewProps.project?.adoption,
        // displayReward: props.projectOverviewProps.project?.reward,
        displayReward: setReward.toString(),
        displayDescription: props.projectOverviewProps.description,
        displayFollower: props.projectOverviewProps.project?.follower,
        displayGender: props.projectOverviewProps.project?.gender,
        displayMinAge: props.projectOverviewProps.project?.minAge,
        displayMaxAge: props.projectOverviewProps.project?.maxAge,
        displayLocation: props.projectOverviewProps.project?.location,
        displayMinIncome: props.projectOverviewProps.project?.minIncome,
        displayMaxIncome: props.projectOverviewProps.project?.maxIncome,
        displayJob: props.projectOverviewProps.project?.job,
        reward: setReward.toString(),
      };
      const updateState = {
        ...props.projectOverviewProps,
        open: false,
        displayRecruitmentPlanCount: props.projectOverviewProps.recruitmentPlanCount.toString(),
        // displayRewardAmount: props.projectOverviewProps.rewardAmount.toString(),
        displayRewardAmount: setReward.toString(),
        tag: '',
        displayTags: props.projectOverviewProps.tags,
        displayDescription: props.projectOverviewProps.description,
        displaySelectedPrefectures: props.projectOverviewProps.selectedPrefectures,
        displayCreatorImage: props.projectOverviewProps.creatorImage,
        displayContractFrom: props.projectOverviewProps.contractFrom,
        displayContractTo: props.projectOverviewProps.contractTo,
        adoptionError: undefined,
        rewardAmountValidationCheck: undefined,
        prefectureError: undefined,
        project: updateProjectStatus,
        tagsError: undefined,
        describeError: undefined,
        rewardAmount: setReward,
      };
      props.setProjectOverviewProps(updateState);
    } else {
      const updateState = {
        ...props.projectOverviewProps,
        open: false,
        recruitmentPlanCount: Number(props.projectOverviewProps.displayRecruitmentPlanCount),
        // rewardAmount: Number(props.projectOverviewProps.displayRewardAmount),
        rewardAmount: setReward,
        tag: '',
        tags: props.projectOverviewProps.displayTags,
        description: props.projectOverviewProps.displayDescription,
        creatorImage: props.projectOverviewProps.displayCreatorImage,
        contractFrom: props.projectOverviewProps.displayContractFrom,
        contractTo: props.projectOverviewProps.displayContractTo,
        adoptionError: undefined,
        rewardAmountValidationCheck: undefined,
        prefectureError: undefined,
        tagsError: undefined,
        describeError: undefined,
        rewardAmountError: undefined,
      };
      console.log('ProjectOverview_noSave_updateState:' + JSON.stringify(updateState, null, 2));
      props.setProjectOverviewProps(updateState);
    }
  };

  useEffect(() => {
    const fetchSpiritProjectDetail = async () => {
      const projectId = props.projectId === undefined ? 'undefined' : props.projectId;
      console.log('案件詳細データの取得を開始します。projectId:', projectId);
      try {
        if (props.projectOverviewProps.projectName === PROJECT_TYPES.OTHER) {
          const defaultApi = DefaultApiFactory(undefined, undefined, axiosInstance);
          const response = await defaultApi.getOtherDetail(projectId);
          console.log('案件詳細データの取得に成功しました。', response);
          setMinimumReward(response.data.estimate.calculateReward.minimum);
          setStandardReward(response.data.estimate.calculateReward.standard);
          return response;
        } else {
          const defaultApi = SPIRITApiFactory(undefined, undefined, axiosInstance);
          const response = await defaultApi.getSpiritDetail(projectId);
          console.log('案件詳細データの取得に成功しました。', response);
          setMinimumReward(response.data.estimate.calculateReward.minimum);
          setStandardReward(response.data.estimate.calculateReward.standard);
          return response;
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error('案件詳細データの取得に失敗しました。', error);
      }
    };
    if (
      props.projectOverviewProps.projectName === PROJECT_TYPES.OTHER ||
      props.projectOverviewProps.projectName === PROJECT_TYPES.SPIRIT
    ) {
      if (props.projectOverviewProps.open) {
        fetchSpiritProjectDetail();
      }
    }
  }, [props.projectId, props.projectOverviewProps.open]);

  useEffect(() => {
    setIsStandard(props.projectOverviewProps.radioBoxValue === '0' ? true : false);

    if (props.setDisplayRewards && props.displayRewards) {
      props.setDisplayRewards(props.displayRewards);
    }
  }, [isStandard, props, props.projectOverviewProps.radioBoxValue]);

  useEffect(() => {
    if (!props.setDisplayRewards) return;
    props.setDisplayRewards(standardReward);
  }, [standardReward]);

  useEffect(() => {
    const updateProjectStatus: projectInterface = {
      ...props.projectOverviewProps.project,
      displayAdoption: props.projectOverviewProps.project?.adoption,
      displayReward: setReward.toString(),
      displayDescription: props.projectOverviewProps.description,
      displayFollower: props.projectOverviewProps.project?.follower,
      displayGender: props.projectOverviewProps.project?.gender,
      displayMinAge: props.projectOverviewProps.project?.minAge,
      displayMaxAge: props.projectOverviewProps.project?.maxAge,
      displayLocation: props.projectOverviewProps.project?.location,
      displayMinIncome: props.projectOverviewProps.project?.minIncome,
      displayMaxIncome: props.projectOverviewProps.project?.maxIncome,
      displayJob: props.projectOverviewProps.project?.job,
      reward: setReward.toString(),
    };

    const updateState = {
      ...props.projectOverviewProps,
      displayRecruitmentPlanCount: props.projectOverviewProps.recruitmentPlanCount.toString(),
      displayRewardAmount: setReward.toString(),
      tag: '',
      displayTags: props.projectOverviewProps.tags,
      displayDescription: props.projectOverviewProps.description,
      displaySelectedPrefectures: props.projectOverviewProps.selectedPrefectures,
      displayCreatorImage: props.projectOverviewProps.creatorImage,
      displayContractFrom: props.projectOverviewProps.contractFrom,
      displayContractTo: props.projectOverviewProps.contractTo,
      adoptionError: undefined,
      rewardAmountValidationCheck: undefined,
      prefectureError: undefined,
      project: updateProjectStatus,
      tagsError: undefined,
      describeError: undefined,
      rewardAmount: setReward,
    };
    props.setProjectOverviewProps(updateState);
  }, [props.displayRewards, isStandard, setReward]);

  /**
   * 採用予定人数のバリデーションチェック
   */
  const adoptionValidationCheck = (target: string | undefined): boolean => {
    if (!target) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        adoptionError: ValidationMessages.inputMessage('採用予定人数'),
      }));
      return false;
    } else if (Number(target) < 1) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        adoptionError: ValidationMessages.numMinMessage(1),
      }));
      return false;
    } else {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        adoptionError: undefined,
      }));
      return true;
    }
  };

  const rewardAmountValidationCheck = (target: number): boolean => {
    // const minimumReward = props.minimumReward ?? 1000;
    const valminimumReward = minimumReward ?? 1000;
    if (!target) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        rewardAmountError: ValidationMessages.inputMessage('報酬額'),
      }));
      return false;
    } else if (target < 0) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        rewardAmountError: ValidationMessages.numMinMessage(0),
      }));
      return false;
    } else if (target < valminimumReward) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        rewardAmountError: ValidationMessages.moreThanTargetMessage('インフルエンサー報酬額', valminimumReward),
      }));
      return false;
    } else {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        rewardAmountError: undefined,
      }));
      return true;
    }
  };

  /**
   * 年齢（下限）のバリデーションチェック（相関チェック）
   */
  const minAgeValidationCheck = (target: string | undefined): boolean => {
    const maxAge = props.projectOverviewProps.project?.maxAge;
    props.setProjectOverviewProps((projectOverviewProps) => ({
      ...projectOverviewProps,
      minAgeError: undefined,
      maxAgeError: undefined,
    }));
    if (target === 'none' || maxAge === 'none' || target === undefined || maxAge === undefined) {
      return true;
    } else if (Number(target) >= Number(maxAge)) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        minAgeError: '年齢（下限）は年齢（上限）以下で入力してください',
      }));
      return false;
    }
    return true;
  };

  /**
   * 年齢（上限）のバリデーションチェック（相関チェック）
   */
  const maxAgeValidationCheck = (target: string | undefined): boolean => {
    const minAge = props.projectOverviewProps.project?.minAge;
    props.setProjectOverviewProps((projectOverviewProps) => ({
      ...projectOverviewProps,
      minAgeError: undefined,
      maxAgeError: undefined,
    }));
    if (target === 'none' || minAge === 'none' || target === undefined || minAge === undefined) {
      return true;
    } else if (Number(target) <= Number(minAge)) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        maxAgeError: '年齢（上限）は年齢（下限）以上で入力してください',
      }));
      return false;
    }
    return true;
  };

  /**
   * 年収（下限）のバリデーションチェック（相関チェック）
   */
  const minIncomeValidationCheck = (target: string | undefined): boolean => {
    const maxIncome = props.projectOverviewProps.project?.maxIncome;
    props.setProjectOverviewProps((projectOverviewProps) => ({
      ...projectOverviewProps,
      maxIncomeError: undefined,
      minIncomeError: undefined,
    }));
    if (target === 'none' || maxIncome === 'none' || target === undefined || maxIncome === undefined) {
      return true;
    } else if (Number(target) >= Number(maxIncome)) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        minIncomeError: '年収（下限）は年収（上限）以下で入力してください',
      }));
      return false;
    }
    return true;
  };

  /**
   * 年収（上限）のバリデーションチェック（相関チェック）
   */
  const maxIncomeValidationCheck = (target: string | undefined): boolean => {
    const minIncome = props.projectOverviewProps.project?.minIncome;
    props.setProjectOverviewProps((projectOverviewProps) => ({
      ...projectOverviewProps,
      maxIncomeError: undefined,
      minIncomeError: undefined,
    }));
    if (target === 'none' || minIncome === 'none' || target === undefined || minIncome === undefined) {
      return true;
    } else if (Number(target) <= Number(minIncome)) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        maxIncomeError: '年収（上限）は年収（下限）以上で入力してください',
      }));
      return false;
    }
    return true;
  };

  /**
   * 都道府県のバリデーションチェック
   */
  const prefectureValidationCheck = (): boolean => {
    const prefecturesRadioBoxValue = props.projectOverviewProps.prefecturesRadioBoxValue;
    const selectedPrefectures = props.projectOverviewProps.selectedPrefectures;
    if (prefecturesRadioBoxValue === 'from_on' && selectedPrefectures.length === 0) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        prefectureError: ValidationMessages.selectMessage('居住地'),
      }));
      return false;
    } else {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        prefectureError: undefined,
      }));
      return true;
    }
  };

  /**
   * 歓迎条件のバリデーションチェック
   */
  const tagValidationCheck = (target: string): boolean => {
    const validateLength = projectNameIsSpirit ? 200 : 50;
    if (target.length > validateLength) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        tagsError: ValidationMessages.strMaxLenMessage('歓迎条件', validateLength),
      }));
      return false;
    } else {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        tagsError: undefined,
      }));
      return true;
    }
  };

  /**
   * 案件説明のバリデーションチェック
   */
  const descriptionValidationCheck = (target: string | undefined): boolean => {
    if (!target) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        describeError: ValidationMessages.inputMessage('案件概要'),
      }));
      return false;
    } else if (target.length > 2000) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        describeError: ValidationMessages.strMaxLenMessage('案件概要', 2000),
      }));
      return false;
    } else {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        describeError: undefined,
      }));
      return true;
    }
  };

  const creatorImageValidationCheck = (target: string | undefined, validResult = true): boolean => {
    if (target === undefined || target === '' || target === null) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        creatorImageError: ValidationMessages.inputMessage('クリエイターイメージ'),
      }));
      return false;
    } else if (target.length > 2000) {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        creatorImageError: ValidationMessages.strMaxLenMessage('クリエイターイメージ', 2000),
      }));
      return false;
    } else {
      props.setProjectOverviewProps((projectOverviewProps) => ({
        ...projectOverviewProps,
        creatorImageError: undefined,
      }));
      return validResult;
    }
  };

  /**
   * 保存ボタン押下時のバリデーションチェック
   */
  const saveValidationCheck = () => {
    const propsProjectName = props.projectOverviewProps.projectName;
    return [
      propsProjectName == PROJECT_TYPES.SPIRIT ||
      propsProjectName == PROJECT_TYPES.OTHER ||
      propsProjectName == PROJECT_TYPES.JANE_JOHN
        ? adoptionValidationCheck(props.projectOverviewProps.project?.adoption)
        : true,
      rewardAmountValidationCheck(props.projectOverviewProps.rewardAmount),
      minAgeValidationCheck(props.projectOverviewProps.project?.minAge),
      maxAgeValidationCheck(props.projectOverviewProps.project?.maxAge),
      minIncomeValidationCheck(props.projectOverviewProps.project?.minIncome),
      maxIncomeValidationCheck(props.projectOverviewProps.project?.maxIncome),
      prefectureValidationCheck(),
      descriptionValidationCheck(props.projectOverviewProps.description),
      propsProjectName == PROJECT_TYPES.PRST
        ? creatorImageValidationCheck(props.projectOverviewProps.creatorImage)
        : true,
      props.projectOverviewProps.adoptionError === undefined,
    ].some((result) => !result);
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    // getMonth()は0が1月を意味するため、1を加える
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // 月と日が1桁の場合、先頭に0を付けて2桁にする
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  // カンマを付与する
  const addComma = (target: string): string => `${target.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  const applicationRequir = useAssetNumberJson('/followers') ?? [];
  const ageOption = [{ value: 'none', label: '指定なし' }, ...(useAssetNumberJson('/age') ?? [])];
  const annualIncomeOption = [{ value: 'none', label: '指定なし' }, ...(useAssetNumberJson('/annualIncome') ?? [])];
  const jobOption = [{ value: 'none', label: '指定なし' }, ...(useAssetNumberJson('/jobs') ?? [])];

  return (
    <Transition.Root show={props.projectOverviewProps.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => projectOnClose(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      projectOnClose(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className=" sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                      案件概要
                    </Dialog.Title>
                  </div>
                </div>
                <div className="text-left">
                  <p className="text-sm text-gray-500">「※」が付いている項目は必須項目です</p>
                </div>
                <div className="pt-6">
                  <span className="text-base font-semibold leading-6">基本設定</span>
                </div>

                {props.projectOverviewProps.projectName == 'spirit' ? (
                  <div className="pt-6">
                    <div>
                      <Input
                        label="採用予定人数*"
                        style={{ width: '320px' }}
                        className={
                          (props.projectOverviewProps.adoptionError !== undefined
                            ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                            : '') + 'pl-[11px] focus:border-white'
                        }
                        type="number"
                        value={props.projectOverviewProps.recruitmentPlanCount}
                        onChange={(e: any) => {
                          props.setProjectOverviewProps((projectOverviewProps) => {
                            const updateState = {
                              ...projectOverviewProps,
                              project: { ...projectOverviewProps.project, adoption: e.target.value },
                              recruitmentPlanCount: +e.target.value,
                            };
                            return updateState;
                          });
                          adoptionValidationCheck(e.target.value);
                        }}
                      ></Input>
                      {props.projectOverviewProps.adoptionError !== undefined && (
                        <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.adoptionError}</div>
                      )}
                    </div>
                    <div className="pt-4 font-semibold">
                      <RadioBox
                        title="インフルエンサー報酬額*"
                        values={[
                          { value: '0', label: '適正相場' },
                          { value: '1', label: 'カスタム設定' },
                        ]}
                        name="reward"
                        value={props.projectOverviewProps.radioBoxValue}
                        // onChange の中に上記の value の値を選択できるような処理を書く
                        onClick={(e: any) => {
                          props.setProjectOverviewProps((projectOverviewProps) => ({
                            ...projectOverviewProps,
                            radioBoxValue: e.target.value,
                          }));
                          if (!isStandard) {
                            // eslint-disable-next-line no-self-assign
                            props.setProjectOverviewProps((projectOverviewProps) => ({
                              ...projectOverviewProps,
                              project: { ...projectOverviewProps.project, reward: setReward.toString() },
                              rewardAmount: setReward,
                            }));
                          }
                        }}
                        className="font-semibold"
                      ></RadioBox>
                    </div>
                    <div className="pt-4">
                      <>
                        <span className="flex items-center">
                          {/*input tag の横範囲変えてもいいのか */}
                          <Input
                            disabled={isStandard}
                            label=""
                            style={{ width: '320px' }}
                            classnamelabel="text-sm font-semibold leading-5"
                            value={
                              isStandard
                                ? standardReward
                                : props.projectOverviewProps.rewardAmount == 0
                                ? ''
                                : props.projectOverviewProps.rewardAmount
                            }
                            onChange={(e: any) => {
                              if (!props.setProjectOverviewProps) return;
                              props.setProjectOverviewProps((projectOverviewProps) => ({
                                ...projectOverviewProps,
                                project: { ...projectOverviewProps.project, reward: e.target.value },
                                rewardAmount: +e.target.value,
                              }));
                              rewardAmountValidationCheck(e.target.value);
                            }}
                            type="number"
                            className={
                              (props.projectOverviewProps.rewardAmountError !== undefined
                                ? 'border-red-400 pl-10 outline-none ring-red-400 [appearance:textfield] focus:ring-red-400 active:ring-red-400 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                                : '') +
                              'pl-10 [appearance:textfield] focus:border-white [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                            }
                            initialWord="¥"
                            lastWord="/1人"
                          ></Input>
                        </span>
                      </>
                      {props.projectOverviewProps.rewardAmountError !== undefined && (
                        <div className="pt-2 text-base text-red-400">
                          {props.projectOverviewProps.rewardAmountError}
                        </div>
                      )}
                      <div>
                        <span className="text-xs font-normal leading-5 text-gray-500">
                          相場報酬額：¥{addComma(String(standardReward))}
                          <br />
                          最低報酬額：¥{addComma(String(minimumReward))}
                        </span>
                      </div>
                    </div>
                    <div className="pt-4">
                      <div className="h-[1px] w-[696px] bg-gray-200"></div>
                    </div>
                  </div>
                ) : (
                  <div className="pt-6">
                    <div>
                      <span className="text-sm font-semibold leading-5 text-gray-700">採用予定人数*</span>
                    </div>
                    <div>
                      <Input
                        style={{ width: '320px' }}
                        classnamelabel="text-sm font-semibold leading-5"
                        type="number"
                        value={
                          props.projectOverviewProps.recruitmentPlanCount == 0
                            ? ''
                            : props.projectOverviewProps.recruitmentPlanCount
                        }
                        onChange={(e: any) => {
                          props.setProjectOverviewProps((projectOverviewProps) => {
                            const updateState = {
                              ...projectOverviewProps,
                              project: { ...projectOverviewProps.project, adoption: e.target.value },
                              recruitmentPlanCount: +e.target.value,
                            };
                            return updateState;
                          });
                          adoptionValidationCheck(e.target.value);
                        }}
                        className={
                          (props.projectOverviewProps.adoptionError !== undefined
                            ? 'border-red-400 pl-10 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                            : '') + 'pl-10 focus:border-white'
                        }
                      ></Input>
                      {props.projectOverviewProps.adoptionError !== undefined && (
                        <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.adoptionError}</div>
                      )}{' '}
                    </div>
                    <div className="pt-6">
                      <span className="text-sm font-semibold leading-5 text-gray-700">インフルエンサー報酬額*</span>
                    </div>
                    <div>
                      <span className="text-sm font-medium leading-5 text-gray-700">カスタム設定</span>
                    </div>
                    <div>
                      <Input
                        style={{ width: '320px' }}
                        classnamelabel="text-sm font-semibold leading-5"
                        type="text"
                        value={
                          props.projectOverviewProps.rewardAmount == 0
                            ? ''
                            : props.projectOverviewProps.rewardAmount.toLocaleString()
                        }
                        onChange={(e: any) => {
                          const valueWithoutCommas = e.target.value.replace(/,/g, '');
                          const numericValue = Number(valueWithoutCommas);
                          if (!isNaN(numericValue)) {
                            props.setProjectOverviewProps((projectOverviewProps) => ({
                              ...projectOverviewProps,
                              project: { ...projectOverviewProps.project, reward: numericValue.toString() },
                              rewardAmount: numericValue,
                            }));
                            rewardAmountValidationCheck(numericValue);
                          }
                        }}
                        className={
                          (props.projectOverviewProps.rewardAmountError !== undefined
                            ? 'border-red-400 pl-10 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                            : '') + 'pl-10 focus:border-white'
                        }
                        initialWord="¥"
                        initialWordPosition="10px"
                        lastWord="/1人"
                      ></Input>
                    </div>
                    {props.projectOverviewProps.rewardAmountError !== undefined && (
                      <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.rewardAmountError}</div>
                    )}
                    <div className="pt-6">
                      <div className="h-[1px] w-[696px] bg-gray-200"></div>
                    </div>
                  </div>
                )}
                {/* 応募条件 */}
                {props.projectOverviewProps.projectName == 'spirit' ? (
                  <div className="pt-6">
                    <span className="text-base font-semibold leading-6">応募条件</span>
                    <div className="pt-6">
                      <SelectMenus
                        title="フォロワー数下限*"
                        options={applicationRequir.map((obj) => {
                          return { value: `${obj.value}`, label: obj.label };
                        })}
                        className="w-[216px]"
                        value={props.projectOverviewProps.project?.follower || '1000'}
                        onChange={(e: any) => {
                          handleChage(e.value, SELECT_NAME.FOLLOWER);
                        }}
                      ></SelectMenus>
                    </div>
                    <div className="pt-8 font-semibold">
                      <SelectMenus
                        title="性別"
                        options={[
                          { value: 'none', label: '指定なし' },
                          { value: '1', label: '男性' },
                          { value: '2', label: '女性' },
                        ]}
                        className="w-[173px] font-semibold"
                        value={props.projectOverviewProps.project?.gender || 'none'}
                        onChange={(e: any) => {
                          handleChage(e.value, SELECT_NAME.GENDER);
                        }}
                      ></SelectMenus>
                    </div>
                    <div className="flex gap-4 pt-8">
                      <SelectMenus
                        title="年齢"
                        options={ageOption.map((age) => {
                          return { value: `${age.value}`, label: `${age.label}` };
                        })}
                        className="w-[173px]"
                        value={props.projectOverviewProps.project?.minAge || 'none'}
                        onChange={(e: any) => {
                          if (minAgeValidationCheck(e.value)) {
                            handleChage(e.value, SELECT_NAME.MIN_AGE);
                          }
                        }}
                      ></SelectMenus>
                      <div className="pt-7">
                        <span className="font-semibold text-gray-900">~</span>
                      </div>
                      <div className="pt-6">
                        <SelectMenus
                          title=""
                          options={ageOption.map((age) => {
                            return { label: age.label, value: `${age.value}` };
                          })}
                          className="w-[173px]"
                          value={props.projectOverviewProps.project?.maxAge || 'none'}
                          onChange={(e: any) => {
                            if (maxAgeValidationCheck(e.value)) {
                              handleChage(e.value, SELECT_NAME.MAX_AGE);
                            }
                          }}
                        ></SelectMenus>
                      </div>
                    </div>
                    {props.projectOverviewProps.minAgeError !== undefined && (
                      <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.minAgeError}</div>
                    )}
                    {props.projectOverviewProps.maxAgeError !== undefined && (
                      <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.maxAgeError}</div>
                    )}
                    <div className="pt-8">
                      <RadioBox
                        title="居住地"
                        values={[
                          { value: 'from_off', label: '指定なし' },
                          { value: 'from_on', label: '指定する' },
                        ]}
                        name="from"
                        value={props.projectOverviewProps.prefecturesRadioBoxValue}
                        // onChange の中に上記の value の値を選択できるような処理を書く
                        onClick={(e: any) => {
                          props.setProjectOverviewProps((projectOverviewProps) => ({
                            ...projectOverviewProps,
                            prefecturesRadioBoxValue: e.target.value,
                          }));
                        }}
                      ></RadioBox>
                      {props.projectOverviewProps.prefectureError !== undefined && (
                        <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.prefectureError}</div>
                      )}
                    </div>
                    {props.projectOverviewProps.prefecturesRadioBoxValue == 'from_on' ? (
                      <span>
                        <div className="pt-8">
                          <FormControlLabel
                            label="北海道"
                            className="text-sm font-semibold leading-5 text-gray-900"
                            control={
                              <Checkbox
                                checked={!isHokkaidoChecked}
                                onChange={() => {
                                  setIsHokkaidoChecked(!isHokkaidoChecked);
                                  if (isHokkaidoChecked) {
                                    props.setProjectOverviewProps((projectOverviewProps) => ({
                                      ...projectOverviewProps,
                                      selectedPrefectures: [...projectOverviewProps.selectedPrefectures, '01'],
                                    }));
                                  } else {
                                    props.setProjectOverviewProps((projectOverviewProps) => ({
                                      ...projectOverviewProps,
                                      selectedPrefectures: projectOverviewProps.selectedPrefectures.filter(
                                        (value) => value !== '01'
                                      ),
                                    }));
                                  }
                                }}
                                icon={<BpIcon />}
                                checkedIcon={<BpCheckedIcon />}
                              />
                            }
                          />
                        </div>
                        <div className="pt-8">
                          {Object.keys(areaListJsonData)
                            .filter((value) => value !== '北海道')
                            .map((value: string, index) => (
                              <div key={index}>
                                <FormControlLabel
                                  label={value}
                                  className="text-sm font-semibold leading-5 text-gray-900"
                                  control={
                                    <Checkbox
                                      checked={areaListJsonData[value].every((pref) =>
                                        props.projectOverviewProps.selectedPrefectures.includes(pref.key)
                                      )}
                                      onChange={() => {
                                        handle_tihou_change(value);
                                      }}
                                      icon={<BpIcon />}
                                      checkedIcon={<BpCheckedIcon />}
                                    />
                                  }
                                />
                                {typeof value == 'string' && children(getAreaData(value).map((area) => area.name))}
                              </div>
                            ))}
                        </div>
                      </span>
                    ) : (
                      <span></span>
                    )}

                    <div className="flex gap-4 pt-8">
                      <SelectMenus
                        title="年収"
                        options={annualIncomeOption.map((age) => {
                          return { label: age.label, value: `${age.value}` };
                        })}
                        value={props.projectOverviewProps.project?.minIncome || 'none'}
                        className="w-[173px]"
                        onChange={(e: any) => {
                          if (minIncomeValidationCheck(e.value)) {
                            handleChage(e.value, SELECT_NAME.MIN_INCOME);
                          }
                        }}
                      ></SelectMenus>
                      <div className="pt-7">
                        <span className="font-semibold text-gray-900">~</span>
                      </div>
                      <div className="pt-6">
                        <SelectMenus
                          title=""
                          options={annualIncomeOption.map((age) => {
                            return { label: age.label, value: `${age.value}` };
                          })}
                          className="w-[173px]"
                          value={props.projectOverviewProps.project?.maxIncome || 'none'}
                          onChange={(e: any) => {
                            if (maxIncomeValidationCheck(e.value)) {
                              handleChage(e.value, SELECT_NAME.MAX_INCOME);
                            }
                          }}
                        ></SelectMenus>
                      </div>
                    </div>
                    {props.projectOverviewProps.minIncomeError !== undefined && (
                      <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.minIncomeError}</div>
                    )}
                    {props.projectOverviewProps.maxIncomeError !== undefined && (
                      <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.maxIncomeError}</div>
                    )}
                    <div className="pt-8">
                      <SelectMenus
                        title="職業"
                        options={jobOption.map((job) => {
                          return { label: job.label, value: `${job.value}` };
                        })}
                        className="w-[410px]"
                        value={props.projectOverviewProps.project?.job}
                        widthSize="w-[410px]"
                        onChange={(e: any) => {
                          handleChage(e.value, SELECT_NAME.JOB);
                        }}
                      ></SelectMenus>
                    </div>
                    <div className="pt-4">
                      <div className="h-[1px] w-[696px] bg-gray-200"></div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="pt-6">
                  <span className="text-base font-semibold leading-6">歓迎条件</span>
                  <div className="pt-5">
                    <span className="text-sm font-semibold leading-5 text-gray-700">ジャンル</span>
                  </div>

                  <div className="pb-4">
                    <div className="flex">
                      <Input
                        label=""
                        style={{ width: '618px' }}
                        className={
                          (props.projectOverviewProps.tagsError !== undefined
                            ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                            : '') + 'pl-[11px] focus:border-white'
                        }
                        value={props.projectOverviewProps.tag}
                        onChange={(e: any) => {
                          props.setProjectOverviewProps((projectOverviewProps) => ({
                            ...projectOverviewProps,
                            tag: e.target.value,
                          }));
                          tagValidationCheck(e.target.value);
                        }}
                      ></Input>
                      <Button
                        variant="secoundary"
                        onClick={() => {
                          if (tagValidationCheck(props.projectOverviewProps.tag)) {
                            handleAdd();
                          }
                        }}
                        className="h-[40px] w-[80px]"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        追加
                      </Button>
                    </div>
                    {props.projectOverviewProps.tagsError !== undefined && (
                      <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.tagsError}</div>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {props.projectOverviewProps.tags.map((tag, index) => {
                      return (
                        <div key={index} className="pb-2">
                          <Badge
                            color="gray"
                            round="sm"
                            size="xs"
                            className="w-fit"
                            isRemovable={true}
                            handleRemove={() => {
                              handleRemove(index);
                            }}
                          >
                            {tag}
                          </Badge>
                        </div>
                      );
                    })}
                  </div>
                  <div className="pt-4">
                    <div className="h-[1px] w-[696px] bg-gray-200"></div>
                  </div>
                </div>
                <div className="pt-6">
                  <span className="text-base font-semibold leading-6">案件説明*</span>
                  <div className="pt-6"></div>
                </div>
                <TextArea
                  title=""
                  placeholder="案件説明"
                  helperText={`${props.projectOverviewProps.description.length}/2000`}
                  maxLength={2000}
                  className={
                    (props.projectOverviewProps.describeError !== undefined
                      ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                      : '') + 'h-[129px] w-[696px] pl-[11px] focus:border-white'
                  }
                  value={props.projectOverviewProps.description}
                  rows={4}
                  onChange={(e: any) => {
                    props.setProjectOverviewProps((projectOverviewProps) => ({
                      ...projectOverviewProps,
                      description: e.target.value,
                    }));
                    descriptionValidationCheck(e.target.value);
                  }}
                ></TextArea>
                {props.projectOverviewProps.describeError !== undefined && (
                  <div className="pt-2 text-base text-red-400">{props.projectOverviewProps.describeError}</div>
                )}
                {props.projectOverviewProps.projectName == 'prst' ? (
                  <div>
                    <div className="pt-6">
                      <span className="text-base font-semibold leading-6">募集クリエイターイメージ</span>
                      <div className="pt-6"></div>
                    </div>
                    <div>
                      <div>
                        <TextArea
                          title=""
                          placeholder="募集クリエイターイメージ"
                          helperText={`${props.projectOverviewProps?.creatorImage?.length || '0'}/2000`}
                          maxLength={2000}
                          className={
                            (props.projectOverviewProps.creatorImageError !== undefined
                              ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                              : '') + 'h-[105px] w-[678px] pl-[11px] focus:border-white'
                          }
                          value={props.projectOverviewProps.creatorImage}
                          rows={4}
                          onChange={(e: any) => {
                            props.setProjectOverviewProps((projectOverviewProps) => ({
                              ...projectOverviewProps,
                              creatorImage: e.target.value,
                            }));
                            creatorImageValidationCheck(e.target.value);
                          }}
                        ></TextArea>
                        {props.projectOverviewProps.creatorImageError !== undefined && (
                          <div className="pt-2 text-base text-red-400">
                            {props.projectOverviewProps.creatorImageError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="pt-6">
                      <span className="text-base font-semibold leading-6">契約期間</span>
                      <div className="pt-3"></div>
                      <span className="text-base font-semibold leading-6">期間</span>
                    </div>
                    <div>
                      <div className="mt-2 flex flex-row">
                        <div className="flex flex-col">
                          <div className="relative mt-2 flex w-[300px] items-center">
                            <div className="flex items-center justify-start">
                              <div className="w-[100px]">配信開始日</div>
                              <DatePicker
                                selectedDate={
                                  props.projectOverviewProps.contractFrom
                                    ? new Date(props.projectOverviewProps.contractFrom)
                                    : new Date()
                                }
                                onChange={(date: any) => {
                                  props.setProjectOverviewProps((projectOverviewProps) => ({
                                    ...projectOverviewProps,
                                    contractFrom: formatDate(date),
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mx-4 flex items-center">
                          <span className="text-sm font-normal leading-5 text-gray-400">〜</span>
                        </div>
                        <div className="flex flex-col">
                          <div className="relative mt-2 flex w-[300px] items-center">
                            <div className="flex items-center justify-start">
                              <div className="w-[100px]">配信終了日</div>

                              <DatePicker
                                selectedDate={
                                  props.projectOverviewProps.contractTo
                                    ? new Date(props.projectOverviewProps.contractTo)
                                    : new Date(new Date().setDate(new Date().getDate() + 1))
                                }
                                onChange={(date: any) => {
                                  props.setProjectOverviewProps((projectOverviewProps) => ({
                                    ...projectOverviewProps,
                                    contractTo: formatDate(date),
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#007CC2' }}
                    onClick={() => {
                      if (saveValidationCheck()) return;
                      projectOnClose(true);
                    }}
                  >
                    保存する
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      projectOnClose(false);
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
