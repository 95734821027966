import { ReactComponent as BookmarkIcon } from '@/assets/icons/bx-bookmark.svg';
import { ReactComponent as LeftCircleIcon } from '@/assets/icons/bx-chevron-left-circle.svg';
import { ReactComponent as RightCircleIcon } from '@/assets/icons/bx-chevron-right-circle.svg';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as MalekIcon } from '@/assets/icons/bx-male.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import { ReactComponent as ShowIcon } from '@/assets/icons/bx-show.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/bx-user.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x-circle.svg';
import { Stat } from '@/components/Elements/Stat';

export const SummaryFeed = (
  width: string,
  impressions: string,
  uniqueAccounts: string,
  engagementRate: string,
  engagement: string,
  goods: string,
  comments: string,
  share: string,
  saves: string,
  frequency: string
) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<ShowIcon width={24} height={24} fill="#FFFFFF" />}
          lable="インプレッション数"
          value={impressions}
          className={width}
        />
        <Stat
          icon={<UserIcon width={24} height={24} fill="#FFFFFF" />}
          lable="ユニークアカウント数"
          value={uniqueAccounts}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント率"
          value={engagementRate}
          className={width}
        />
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント数"
          value={engagement}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<LikeIcon width={24} height={24} fill="#FFFFFF" />}
          lable="いいね数"
          value={goods}
          className={width}
        />
        <Stat
          icon={<UserIcon width={24} height={24} fill="#FFFFFF" />}
          lable="コメント数"
          value={comments}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<ShareIcon width={24} height={24} fill="#FFFFFF" />}
          lable="シェア数"
          value={share}
          className={width}
        />

        <Stat
          icon={<BookmarkIcon width={24} height={24} fill="#FFFFFF" />}
          lable="保存数"
          value={saves}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<MalekIcon width={24} height={24} fill="#FFFFFF" />}
          lable="フリークエンシー"
          value={frequency}
          className={width}
        />
      </div>
    </div>
  );
};

export const SummaryStories = (
  width: string,
  impressions: string,
  uniqueAccounts: string,
  tapForward: string,
  tapBack: string,
  end: string
) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<ShowIcon width={24} height={24} fill="#FFFFFF" />}
          lable="インプレッション数"
          value={impressions}
          className={width}
        />
        <Stat
          icon={<UserIcon width={24} height={24} fill="#FFFFFF" />}
          lable="ユニークアカウント数"
          value={uniqueAccounts}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<RightCircleIcon width={24} height={24} fill="#FFFFFF" />}
          lable="「次へ」のタップ回数"
          value={tapForward}
          className={width}
        />
        <Stat
          icon={<LeftCircleIcon width={24} height={24} fill="#FFFFFF" />}
          lable="「戻る」のタップ回数"
          value={tapBack}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<CloseIcon width={24} height={24} fill="#FFFFFF" />}
          lable="メディアが閉じられた回数"
          value={end}
          className={width}
        />
        <></>
      </div>
    </div>
  );
};

export const SummaryReal = (
  width: string,
  uniqueAccounts: string,
  engagementRate: string,
  engagement: string,
  goods: string,
  comments: string,
  saves: string,
  shares: string,
  frequency: string
) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント率"
          value={`${engagementRate}`}
          className={width}
        />
        <Stat
          icon={<UserIcon width={24} height={24} fill="#FFFFFF" />}
          lable="ユニークアカウント数"
          value={uniqueAccounts}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<LikeIcon width={24} height={24} fill="#FFFFFF" />}
          lable="いいね数"
          value={goods}
          className={width}
        />
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント数"
          value={engagement}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<BookmarkIcon width={24} height={24} fill="#FFFFFF" />}
          lable="保存数"
          value={saves}
          className={width}
        />
        <Stat
          icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
          lable="コメント数"
          value={comments}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<MalekIcon width={24} height={24} fill="#FFFFFF" />}
          lable="フリークエンシー"
          value={frequency}
          className={width}
        />
        <Stat
          icon={<ShareIcon width={24} height={24} fill="#FFFFFF" />}
          lable="シェア数"
          value={shares}
          className={width}
        />
      </div>
    </div>
  );
};
