import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import * as React from 'react';

import {
  JaneJohnTask,
  JaneJohnTaskCreationType,
  JaneJohnTaskGroupInterview,
  JaneJohnTaskQuestion,
  JaneJohnTaskQuestionNum,
  JaneJohnTaskSpotConsul,
  JaneJohnTaskType,
} from '@/api/janeJohn';
import { Button } from '@/components/Elements/Button/Button';
import { RadioBox } from '@/components/Elements/RadioBox';
import { ValidationMessages } from '@/utils/message';

import { DatePicker } from '../../Elements/DatePicker/DatePicker';
interface displayOperationDetails {
  type: JaneJohnTaskType;
  groupInterViewDate: string | null;
  spotConsulStart: string | null;
  spotConsulEnd: string | null;
  questionNum: JaneJohnTaskQuestionNum | '';
  questionInterViewDate: string | null;
  questionCreateType: JaneJohnTaskCreationType | '';
}

interface Props {
  operationDetailsModalOpen: boolean;
  setOperationDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  operationDetailsProps: JaneJohnTask;
  setOperationDetailsProps: React.Dispatch<React.SetStateAction<JaneJohnTask>>;
  PutProjectTask: (janeJohnTask: JaneJohnTask) => Promise<void>;
}

export const ProjectOperationDetails = (props: Props) => {
  const questionNumSwitch = (questionNum: JaneJohnTaskQuestionNum): JaneJohnTaskQuestionNum => {
    switch (questionNum) {
      case JaneJohnTaskQuestionNum._10:
        return JaneJohnTaskQuestionNum._10;
      case JaneJohnTaskQuestionNum._20:
        return JaneJohnTaskQuestionNum._20;
      case JaneJohnTaskQuestionNum._30:
        return JaneJohnTaskQuestionNum._30;
      case JaneJohnTaskQuestionNum._40:
        return JaneJohnTaskQuestionNum._40;
      case JaneJohnTaskQuestionNum._50:
        return JaneJohnTaskQuestionNum._50;
      case JaneJohnTaskQuestionNum._60:
        return JaneJohnTaskQuestionNum._60;
      default:
        return JaneJohnTaskQuestionNum._10;
    }
  };
  const questionCreationSwitch = (questionCreation: JaneJohnTaskCreationType): JaneJohnTaskCreationType => {
    switch (questionCreation) {
      case JaneJohnTaskCreationType.Own:
        return JaneJohnTaskCreationType.Own;
      case JaneJohnTaskCreationType.Other:
        return JaneJohnTaskCreationType.Other;
      default:
        return JaneJohnTaskCreationType.Own;
    }
  };
  const [displayOperationDetails, setDisplayOperationDetails] = useState<displayOperationDetails>({
    type: props.operationDetailsProps.type,
    groupInterViewDate: props.operationDetailsProps.groupInterView?.interviewDate ?? null,
    spotConsulStart: props.operationDetailsProps.spotConsul?.startedAt ?? null,
    spotConsulEnd: props.operationDetailsProps.spotConsul?.endedAt ?? null,
    questionNum: questionNumSwitch(props.operationDetailsProps.question?.questionNum ?? JaneJohnTaskQuestionNum._10),
    questionInterViewDate: props.operationDetailsProps.question?.interviewDate ?? null,
    questionCreateType: questionCreationSwitch(
      props.operationDetailsProps.question?.creationType ?? JaneJohnTaskCreationType.Own
    ),
  });
  const [operationValidator, setOperationValidator] = useState({
    groupInterViewDate: '',
    spotConsulStart: '',
    spotConsulEnd: '',
    questionInterViewDate: '',
  });
  const detailTitle = () => {
    return (
      <div className="mt-8">
        <span className="text-sm font-semibold leading-5 text-gray-700">
          選択した案件タイプの詳細を入力してください。
        </span>
      </div>
    );
  };

  const questionNumMapping: { [key: string]: JaneJohnTaskQuestionNum } = {
    to_10: JaneJohnTaskQuestionNum._10,
    to_20: JaneJohnTaskQuestionNum._20,
    to_30: JaneJohnTaskQuestionNum._30,
    to_40: JaneJohnTaskQuestionNum._40,
    to_50: JaneJohnTaskQuestionNum._50,
    to_60: JaneJohnTaskQuestionNum._60,
  };

  // 文字列をJaneJohnTaskQuestionNum型に変換する
  const convertToJaneJohnTaskQuestionNum = (questionCount: string): JaneJohnTaskQuestionNum => {
    return questionNumMapping[questionCount];
  };

  // 文字列をJaneJohnTaskCreationType型に変換する
  const convertToJaneJohnTaskCreationType = (input: string | undefined): JaneJohnTaskCreationType => {
    if (input === undefined) return JaneJohnTaskCreationType.Own;
    const creationTypes: { [key: string]: JaneJohnTaskCreationType } = {
      own: JaneJohnTaskCreationType.Own,
      other: JaneJohnTaskCreationType.Other,
    };
    return creationTypes[input.toLowerCase()];
  };

  // 文字列をJaneJohnTaskType型に変換する関数
  const convertToJaneJohnTaskType = (input: string): JaneJohnTaskType => {
    const taskTypes: { [key: string]: JaneJohnTaskType } = {
      group_interview: JaneJohnTaskType.GroupInterview,
      spot_consul: JaneJohnTaskType.SpotConsul,
      questionnaire: JaneJohnTaskType.Questionnaire,
    };
    return taskTypes[input.toLowerCase()];
  };

  // 日付をJSTに変換する関数
  function formatDateToJST(dateInput: Date | null): string | null {
    if (dateInput === null) return null;

    const date = dateInput;
    // YYYY-MM-DD形式で日付をフォーマット
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth()は0から始まるため、1を加える
    const day = date.getDate();

    // 月と日が1桁の場合は、0を付けて2桁にする
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  function formatDate(dateInput: string | null): Date | undefined {
    if (dateInput === undefined) return undefined;
    if (dateInput === null) return undefined;
    const date = new Date(dateInput);
    return date;
  }

  // APIリクエストのため、画面入力値をJaneJohnTask型に変換する関数
  const apiSetRequestBody = (request: displayOperationDetails): JaneJohnTask => {
    const janeJohnTaskGroupInterview: JaneJohnTaskGroupInterview = {
      interviewDate: request.groupInterViewDate,
    };

    const janeJohnTaskSpotConsul: JaneJohnTaskSpotConsul = {
      startedAt: request.spotConsulStart,
      endedAt: request.spotConsulEnd,
    };

    const janeJohnTaskQuestion: JaneJohnTaskQuestion = {
      interviewDate: request.questionInterViewDate,
      questionNum: request.questionNum === '' ? JaneJohnTaskQuestionNum._10 : request.questionNum,
      creationType: request.questionCreateType === '' ? JaneJohnTaskCreationType.Own : request.questionCreateType,
    };

    const janeJohnTask: JaneJohnTask = {
      type: request.type,
      groupInterView: request.type === JaneJohnTaskType.GroupInterview ? janeJohnTaskGroupInterview : null,
      spotConsul: request.type === JaneJohnTaskType.SpotConsul ? janeJohnTaskSpotConsul : null,
      question: request.type === JaneJohnTaskType.Questionnaire ? janeJohnTaskQuestion : null,
    };

    return janeJohnTask;
  };

  const detail = () => {
    if (displayOperationDetails.type === JaneJohnTaskType.GroupInterview) {
      return (
        <div>
          {detailTitle()}
          <div className="mt-4">
            <label htmlFor="startday" className="block text-base font-normal leading-5 text-gray-700">
              インタビュー実施日*
            </label>
            <div className="mt-2 flex flex-row">
              <DatePicker
                selectedDate={formatDate(displayOperationDetails.groupInterViewDate)}
                onChange={(date: any) => {
                  setDisplayOperationDetails((prev) => ({
                    ...prev,
                    groupInterViewDate: formatDateToJST(date),
                  }));
                }}
                width="199px"
                dateClassName={{
                  picker: 'px-[37.5px] py-[10px] font-normal leading-5 text-gray-700',
                  calender: 'pr-[37.5px]',
                }}
                isError={operationValidator.groupInterViewDate !== ''}
              />
            </div>
          </div>
        </div>
      );
    } else if (displayOperationDetails.type === JaneJohnTaskType.SpotConsul) {
      return (
        <div>
          {detailTitle()}
          <div className="mt-4">
            <label htmlFor="startday" className="block text-base font-normal leading-5 text-gray-700">
              実施期間*
            </label>
            <div className="mt-2 flex flex-row">
              <div className="flex flex-col">
                <div className="relative flex w-[199px] items-center">
                  <DatePicker
                    selectedDate={formatDate(displayOperationDetails.spotConsulStart)}
                    onChange={(date: any) => {
                      setDisplayOperationDetails((prev) => ({
                        ...prev,
                        spotConsulStart: formatDateToJST(date),
                      }));
                    }}
                    width="199px"
                    dateClassName={{
                      picker: 'px-[37.5px] py-[10px] font-normal leading-5 text-gray-700',
                      calender: 'pr-[37.5px]',
                    }}
                    isError={operationValidator.spotConsulStart !== ''}
                  />
                </div>
              </div>
              <div className="mx-4 flex items-center">
                <span className="text-sm font-normal leading-5 text-gray-400">〜</span>
              </div>
              <div className="flex flex-col">
                <div className="relative flex w-[199px] items-center">
                  <DatePicker
                    selectedDate={formatDate(displayOperationDetails.spotConsulEnd)}
                    onChange={(date: any) => {
                      setDisplayOperationDetails((prev) => ({
                        ...prev,
                        spotConsulEnd: formatDateToJST(date),
                      }));
                    }}
                    width="199px"
                    dateClassName={{
                      picker: 'px-[37.5px] py-[10px] font-normal leading-5 text-gray-700',
                      calender: 'pr-[37.5px]',
                    }}
                    isError={operationValidator.spotConsulEnd !== ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (displayOperationDetails.type == JaneJohnTaskType.Questionnaire) {
      return (
        <div>
          {detailTitle()}
          <div className="mt-4">
            <label htmlFor="startday" className="block text-base font-normal leading-5 text-gray-700">
              アンケート実施日*
            </label>
            <div className="relative mt-2 flex w-[199px] items-center">
              <DatePicker
                selectedDate={formatDate(displayOperationDetails.questionInterViewDate)}
                onChange={(date: any) => {
                  setDisplayOperationDetails((prev) => {
                    return {
                      ...prev,
                      questionInterViewDate: formatDateToJST(date),
                    };
                  });
                }}
                width="199px"
                dateClassName={{
                  picker: 'px-[37.5px] py-[10px] font-normal leading-5 text-gray-700',
                  calender: 'pr-[37.5px]',
                }}
                isError={operationValidator.questionInterViewDate !== ''}
              />
            </div>
            <p className="text-[14px] text-[#F00]">{operationValidator.questionInterViewDate || ''}</p>
            <div className="mt-6">
              <div className="mb-2">
                <span className="block text-base font-normal leading-5 text-gray-700">アンケート設問数*</span>
              </div>
              <RadioBox
                values={[
                  { value: 'to_10', label: '〜10問' },
                  { value: 'to_20', label: '〜20問' },
                  { value: 'to_30', label: '〜30問' },
                  { value: 'to_40', label: '〜40問' },
                  { value: 'to_50', label: '〜50問' },
                  { value: 'to_60', label: '〜60問' },
                ]}
                name="questionCounts"
                value={displayOperationDetails.questionNum}
                onClick={(e: any) => {
                  setDisplayOperationDetails((prev) => ({
                    ...prev,
                    questionNum: convertToJaneJohnTaskQuestionNum(e.target.value),
                  }));
                }}
              ></RadioBox>
            </div>
            <div className="mt-6">
              <div className="mb-2">
                <span className="block text-base font-normal leading-5 text-gray-700">アンケート作成*</span>
              </div>
              <RadioBox
                values={[
                  { value: 'own', label: '自社で作成する' },
                  { value: 'other', label: '作成を依頼する' },
                ]}
                name="create"
                value={displayOperationDetails.questionCreateType}
                onClick={(e: any) => {
                  setDisplayOperationDetails((prev) => ({
                    ...prev,
                    questionCreateType: convertToJaneJohnTaskCreationType(e.target.value),
                  }));
                }}
              ></RadioBox>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };
  const handleOnSave = async (request: displayOperationDetails) => {
    const validationGroupInterViewDate =
      request.type === JaneJohnTaskType.GroupInterview && request.groupInterViewDate === null
        ? ValidationMessages.selectMessage('インタビュー実施日')
        : '';
    const validationSpotStartDate =
      request.type === JaneJohnTaskType.SpotConsul && request.spotConsulStart === null
        ? ValidationMessages.selectMessage('実施期間ー開始日')
        : '';
    const validationSpotEndDate =
      request.type === JaneJohnTaskType.SpotConsul && request.spotConsulEnd === null
        ? ValidationMessages.selectMessage('実施期間ー終了日')
        : '';
    const validationQuestionInterViewDate =
      request.type === JaneJohnTaskType.Questionnaire && request.questionInterViewDate === null
        ? ValidationMessages.selectMessage('インタビュー実施日')
        : '';
    setOperationValidator({
      groupInterViewDate: validationGroupInterViewDate,
      spotConsulStart: validationSpotStartDate,
      spotConsulEnd: validationSpotEndDate,
      questionInterViewDate: validationQuestionInterViewDate,
    });
    try {
      if (
        validationGroupInterViewDate === '' &&
        validationSpotStartDate === '' &&
        validationSpotEndDate === '' &&
        validationQuestionInterViewDate === ''
      ) {
        // props.setOperationDetailsProps(apiSetRequestBody(request));
        console.log('クリック');
        await props.PutProjectTask(apiSetRequestBody(request));
        props.setOperationDetailsProps(apiSetRequestBody(request));
        console.log('props.operationDetailProps', props.operationDetailsProps);
        props.setOperationDetailsModalOpen(false);
      }
    } catch (error) {
      console.log('error:' + error);
    }
  };
  const handleOnClose = () => {
    props.setOperationDetailsModalOpen(false);
    setOperationValidator({
      groupInterViewDate: '',
      spotConsulStart: '',
      spotConsulEnd: '',
      questionInterViewDate: '',
    });
  };

  return (
    <Transition.Root show={props.operationDetailsModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={handleOnClose}
        // onClose={() => {
        //   propsOperationDetailsProps.onClose(false);
        // }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative min-h-[346px] w-[768px] rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleOnClose}
                    // onClick={() => {
                    //   propsOperationDetailsProps.onClose(false);
                    // }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                      稼働内容詳細
                    </Dialog.Title>
                    <div className="text-left">
                      <p className="text-sm text-gray-500">「※」が付いている項目は必須項目です</p>
                    </div>
                    <div className="pt-6">
                      <div>
                        <div className="pb-4">
                          <span className="text-sm font-semibold leading-5 text-gray-700">
                            実施案件タイプを選択してください。
                          </span>
                        </div>
                        <RadioBox
                          values={[
                            { value: 'group_interview', label: 'グループインタビュー' },
                            { value: 'spot_consul', label: 'スポットコンサル' },
                            { value: 'questionnaire', label: 'アンケート' },
                          ]}
                          name="operationType"
                          value={displayOperationDetails.type}
                          onClick={(e: any) => {
                            const taskType = convertToJaneJohnTaskType(e.target.value);
                            setOperationValidator({
                              groupInterViewDate: '',
                              spotConsulStart: '',
                              spotConsulEnd: '',
                              questionInterViewDate: '',
                            });
                            setDisplayOperationDetails((prev) => ({
                              ...prev,
                              type: taskType,
                            }));
                          }}
                          // onClick={(e: any) => propsOperationDetailsProps.setOperationType(e.target.value)}
                        ></RadioBox>
                      </div>
                    </div>
                    <div>
                      {detail()}
                      <p className="text-[14px] text-[#F00]">{operationValidator.groupInterViewDate || ''}</p>
                      <p className="text-[14px] text-[#F00]">{operationValidator.spotConsulStart || ''}</p>
                      <p className="text-[14px] text-[#F00]">{operationValidator.spotConsulEnd || ''}</p>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    onClick={() => {
                      console.log('PutProjectTask before props:' + JSON.stringify(props, null, 2));
                      handleOnSave(displayOperationDetails);
                    }}
                  >
                    保存する
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleOnClose}
                  >
                    キャンセル
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
