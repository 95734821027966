import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreationStatus, GetCreationOutput, PostingType, SnsType } from '@/api/creation';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { PhaseNavigation } from '@/components/ProjectManagements/PhaseNavigation/Components/PhaseNavigation';
import { getCreation, getCreationAccountMeasures, getCreationAccounts } from '@/hooks/Creation';

import { phaseInfo } from '../interfaces/PRSTProductionManagementInterface';

import { AlertContentsApprove } from './AlertContentsApprove';
import { AlertImageApprove } from './AlertImageApprove';
import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ContentView } from './ContentView';
import { ImageView } from './ImageView';

interface ProjectDetail {
  accountId: string;
  accountName: string;
  snsType: SnsType;
  projectName: string;
}

export const PostDetailView = () => {
  const getMeasureId = (): string => {
    const paths = window.location.pathname.split('/'); // ["/", "PRST_production_management", "(measureId)"]
    return paths.length > 2 && paths[1] === 'PRST_production_management' ? paths[2] : 'measureId';
  };
  const getCreationId = (): string => {
    const paths = window.location.pathname.split('/'); // ["/", "PRST_production_management", "(measureId)"]
    return paths.length > 3 && paths[1] === 'PRST_production_management' ? paths[3] : 'creationId';
  };
  const getAccountId = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('accountId') ?? 'accountId';
  };
  // 各コンテンツタイプの動作確認用のモックデータ
  const typeChange = (id: string) => {
    switch (id) {
      case '0'.repeat(26):
        return PostingType.Feed;
      case '1'.repeat(26):
        return PostingType.Other;
      case '2'.repeat(26):
        return PostingType.OtherVideo;
      case '3'.repeat(26):
        return PostingType.Reel;
      case '4'.repeat(26):
        return PostingType.Stories;
      case '5'.repeat(26):
        return PostingType.TikTok;
      case '6'.repeat(26):
        return PostingType.XImagePost;
      case '7'.repeat(26):
        return PostingType.XPost;
      case '8'.repeat(26):
        return PostingType.Youtube;
      case '9'.repeat(26):
        return PostingType.YoutubeShort;
      default:
        return PostingType.Other;
    }
  };
  const updatePostDetail = () => {
    getCreation(getCreationId()).then((response) => {
      if (response) {
        // setPostDetail(response);
        setPostDetail({
          id: '12345678901234567890ABCDEF',
          no: 1,
          postScheduledAt: '2021-02-12T00:00:00Z',
          type: typeChange(getCreationId()),
          status: CreationStatus.Draft,
          draft: {
            contentId: '12345678901234567890ABCDEF',
            images: null,
            comments: 0,
            description: '投稿内容',
          },
        } as GetCreationOutput);
      }
    });
  };
  const [postDetail, setPostDetail] = useState<GetCreationOutput>({
    id: '',
    no: 1,
    postScheduledAt: new Date().toISOString(),
    type: PostingType.Other,
    status: CreationStatus.Initialized,
  } as GetCreationOutput);
  const [projectDetail, setProjectDetail] = useState<ProjectDetail>({
    accountId: getAccountId(),
    accountName: 'アカウント名',
    snsType: SnsType.Other,
    projectName: '施策名',
  });
  const navigate = useNavigate();

  const handleClick1 = () => {
    navigate(`/PRST_production_management/${getMeasureId()}?accountId=${getAccountId()}`);
  };

  const statusToStepNum = (status: CreationStatus): number => {
    switch (status) {
      case CreationStatus.Draft:
        return 1;
      case CreationStatus.DraftApproved:
        return 2;
      case CreationStatus.Contents:
        return 3;
      case CreationStatus.ContentsApproved:
        return 4;
      case CreationStatus.Completed:
        return 5;
      default:
        return 1;
    }
  };
  const phase: phaseInfo[] = [
    {
      name: 'イメージ案制作',
      value: 'create',
      isCurrent: postDetail?.status === CreationStatus.Draft || postDetail?.status === null,
      isDone: 0 < statusToStepNum(postDetail?.status || CreationStatus.Initialized),
    },
    {
      name: 'イメージ案承認',
      value: 'selection',
      isCurrent: postDetail?.status === CreationStatus.DraftApproved,
      isDone: 1 < statusToStepNum(postDetail?.status || CreationStatus.Initialized),
    },
    {
      name: 'コンテンツ制作',
      value: 'check',
      isCurrent: postDetail?.status === CreationStatus.Contents,
      isDone: 2 < statusToStepNum(postDetail?.status || CreationStatus.Initialized),
    },
    {
      name: 'コンテンツ承認',
      value: 'doing',
      isCurrent: postDetail?.status === CreationStatus.ContentsApproved,
      isDone: 3 < statusToStepNum(postDetail?.status || CreationStatus.Initialized),
    },
    {
      name: '完了',
      value: 'done',
      isCurrent: postDetail?.status === CreationStatus.Completed,
      isDone: 4 < statusToStepNum(postDetail?.status || CreationStatus.Initialized),
    },
  ];

  useEffect(() => {
    getCreationAccounts().then(async (response) => {
      if (response) {
        const account = response.find((account) => account.id === getAccountId());
        const project = await getCreationAccountMeasures(getAccountId());
        setProjectDetail({
          accountId: account?.id ?? 'accountId',
          accountName: account?.name ?? 'アカウント名',
          snsType: account?.snsType ?? SnsType.Other,
          projectName: project.rows.find((row) => row.id === getMeasureId())?.name ?? '施策名',
        });
      }
    });
    updatePostDetail();
  }, []);

  return (
    <>
      <div className="relative">
        <div className="mb-[30px] mt-[16px] flex h-[45px] w-[1181px] items-center justify-between border-b border-gray-200 py-[11px] pl-[16px] pr-[40px]">
          <div className="flex h-[24px] w-[1149px] items-center">
            <div className="flex h-[24px] w-[628px] items-center justify-between">
              <button onClick={handleClick1}>
                <ChevronLeftIcon width={24} height={24} fill="#9CA3AF" />
              </button>
              <div className="flex h-[24px] w-[297px] items-center justify-between">
                <div className="flex h-[24px] w-[136px] items-center">
                  <p className="h-[12px] w-[136px] whitespace-nowrap align-middle text-xs font-normal leading-none text-[#374151]">
                    {projectDetail.projectName}
                  </p>
                </div>
                <div className="flex h-[24px] w-[146px] items-center justify-between">
                  <p className="h-[24px] w-[70px] text-base font-bold leading-6 text-gray-700">{postDetail?.no}</p>
                  <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-red-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-red-800">
                    {postDetail?.status}
                  </p>
                </div>
              </div>
              <p className="h-[20px] w-[247px] whitespace-nowrap text-sm font-normal leading-5 text-gray-500">
                {postDetail?.contents?.comments}
              </p>
            </div>
          </div>
          <div>
            <DotsHorizontalIcon width={24} height={24} className="ml-[24px]" />
          </div>
        </div>
        {/* <StepPanel_ImageApproving /> */}
        <div className="px-[24px]">{PhaseNavigation(phase)}</div>
        {postDetail?.status === CreationStatus.DraftApproved && (
          <div className="mt-[25px] px-[24px]">
            <AlertImageApprove />
          </div>
        )}
        {postDetail?.status === CreationStatus.ContentsApproved && (
          <div className="mt-[25px] px-[24px]">
            <AlertContentsApprove />
          </div>
        )}
        {(postDetail?.contents || statusToStepNum(postDetail?.status || CreationStatus.Initialized) > 1) && (
          <ContentView postDetail={postDetail} setPostDetail={setPostDetail} updatePost={updatePostDetail} />
        )}
        {postDetail?.draft && (
          <ImageView postDetail={postDetail} setPostDetail={setPostDetail} updatePost={updatePostDetail} />
        )}
      </div>
    </>
  );
};
