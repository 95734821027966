// import { FirstSidebar } from '@/components/Elements/FirstSideBar';
// import { SecondSidebar } from '@/components/Elements/SecondSideBar';
import { MainLayout } from '@/components/Layout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { AccountProvider } from '../components/AccountContext';
import { ManagementView } from '../components/ManagementView';

export const Management = () => {
  return (
    <>
      <div className="flex">
        <AccountProvider>
          {/* <FirstSidebar /> */}
          {/* <SecondSidebar /> */}
          <MainLayout
            sidebarProps={{
              firstSideBarselected: firstSidebarSelect.calendar,
              secondSideBarselected: secondSidebarSelect.prstPost,
            }}
          >
            <ManagementView />;
          </MainLayout>
        </AccountProvider>
      </div>
    </>
  );
};
