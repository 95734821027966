import React, { useEffect, useState, useRef } from 'react';

import {
  AnalysisSpiritPostContentX,
  AnalysisSpiritPostContentXMediaTypeEnum,
  AnalysisSpiritPostInsightX,
  SnsType,
} from '@/api/analysisSpirit';
import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChenverUp } from '@/assets/icons/chevron-up.svg';
import { SNS_TYPES } from '@/config';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { getImageUrlIfRelative } from '@/utils/image';

import { postProps } from '../../../interfaces/analysisInterface';
import ModalCommon from '../modals/modalCommon';
import { MediaProps, tableBodyCommonProps, XContent } from '../modals/modalInterface';
import { SummaryPostCommon } from '../modals/tabs/summaryCommon';

export interface tableBodyProps {
  icon: string;
  id: string;
  applyingInfluencerId: string;
  postedAt: string;
  mediaType: string;
  image: string;
  engagementRate: string;
  numberOfEngagements: number;
  goods: number;
  comment: number;
  repost: number;
  sumViewNum: number;
  postCount: number;
  mediaProps: MediaProps;
}

interface ascInfoProps {
  id: boolean;
  engagementRate: boolean;
  numberOfEngagements: boolean;
  goods: boolean;
  comment: boolean;
  repost: boolean;
  sumViewNum: boolean;
}
export interface PostTwitterProps {
  postTasks: postProps[];
}

export default function PostTwitter(props: PostTwitterProps) {
  const [rowTableBody, setRowTableBody] = useState<tableBodyProps[]>([]);
  // const [tableBody, setTableBody] = useState<tableBodyProps[]>([]);
  const [ascInfo, setAscInfo] = useState<ascInfoProps>({
    id: true,
    engagementRate: true,
    numberOfEngagements: true,
    goods: true,
    comment: true,
    repost: true,
    sumViewNum: true,
  });
  // 初期画面に表示するテーブルデータ作成用ステート
  const [currentData, setCurrentData] = useState<tableBodyProps[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const xContent: XContent = {
    postImage: '',
    postVideo: '',
    postMedias: null,
    multiMediaImages: [],
    mediaType: 'video',
    caption: '',
  };
  const [modalInformation, setModalInformation] = useState<tableBodyCommonProps>({
    icon: '',
    id: '',
    applyingInfluencerId: '',
    postedAt: '',
    mediaType: '',
    image: '',
    engagementRate: '',
    numberOfEngagements: 0,
    goods: 0,
    comment: 0,
    repost: 0,
    sumViewNum: 0,
    postCount: 0,
    mediaProps: {
      snsType: 'x',
      content: xContent,
    },
  });
  //何回目の投稿かを保持するステート
  const postTask = props.postTasks[0] ?? '';
  const task = postTask.task ?? '';
  const taskId = task.taskId ?? '';
  const [selectedPost, setSelectedPost] = useState<string>(taskId);
  const [thumbnail, setThumbnail] = useState<string>('');

  const setPostTask = (taskId: string) => {
    setSelectedPost(taskId);
  };

  const generateThumbnail = (url: string) => {
    const video = document.createElement('video');
    video.src = getImageUrlIfRelative(url);
    // setVideoURL(video.src);
    video.style.display = 'none';
    video.crossOrigin = 'anonymous';
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    const ctx = canvas.getContext('2d');
    // canvas.width = 100;
    // canvas.height = 100;
    if (video.src) {
      console.log('video.src', video.src);
      video.addEventListener('loadeddata', () => {
        video.currentTime = 0;
      });
      video.addEventListener('seeked', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        console.log('video.videoWidth', video.videoWidth);
        console.log('video.videoHeight', video.videoHeight);
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL();
        console.log('image', image);
        setThumbnail(image);
      });
    }
  };

  const tablebodies = (postCount = 1): tableBodyProps[] => {
    const tmpPostTask = props.postTasks.filter((p) => p.task.taskId == selectedPost)[0];
    console.log('tmpPostTask:' + JSON.stringify(tmpPostTask, null, 2));
    const result: tableBodyProps[] = [];
    postCount = props.postTasks.findIndex((post) => post.task.taskId == selectedPost) + 1;
    tmpPostTask.posts.posts.forEach((post) => {
      const insight: AnalysisSpiritPostInsightX = post.insight as AnalysisSpiritPostInsightX;
      const content: AnalysisSpiritPostContentX = post.content as AnalysisSpiritPostContentX;
      result.push({
        icon: post.account.snsAccountThumbnail,
        id: post.account.snsAccountNo,
        applyingInfluencerId: post.account.applyingInfluencerId,
        postedAt: post.content?.postedAt ?? '',
        // mediaType:
        //   content?.mediaType === 'photo'
        //     ? 'photo'
        //     : content?.mediaType === 'video'
        //     ? 'video'
        //     : content?.mediaType === 'multipleMedia' && content?.multiMediaImages?.[0].includes('mp4')
        //     ? 'video'
        //     : '',
        mediaType: content?.mediaType ?? '',
        image: content?.mediaType
          ? content?.mediaType === AnalysisSpiritPostContentXMediaTypeEnum.Photo
            ? getImageUrlIfRelative(content?.postImage ?? '')
            : content?.mediaType === AnalysisSpiritPostContentXMediaTypeEnum.Video
            ? generateThumbnail(content?.postVideo ?? '')
            : content?.mediaType === AnalysisSpiritPostContentXMediaTypeEnum.MultipleMedia &&
              content?.multiMediaImages?.[0].includes('mp4')
            ? generateThumbnail(content?.multiMediaImages?.[0] ?? '')
            : content?.mediaType === AnalysisSpiritPostContentXMediaTypeEnum.MultipleMedia
            ? getImageUrlIfRelative(content?.multiMediaImages?.[0] ?? '')
            : ''
          : '',
        engagementRate: insight ? `${insight.engagementRate}%` : '0%',
        numberOfEngagements: insight ? insight.engagementCount : 0,
        goods: insight ? insight.goodCount : 0,
        comment: insight ? insight.replyCount : 0,
        repost: insight ? insight.repostCount : 0,
        sumViewNum: insight ? insight.viewCount : 0,
        postCount: postCount,
        mediaProps: {
          snsType: SNS_TYPES.TWITTER,
          content: {
            postImage: getImageUrlIfRelative(content?.postImage ?? ''),
            postVideo: content?.postVideo ?? '',
            postMedias: content?.postMedias,
            multiMediaImages: content?.multiMediaImages ?? [],
            mediaType: content?.mediaType ?? 'video',
            caption: content?.caption ?? '',
          },
        },
      } as tableBodyProps);
    });

    return result;
  };

  const handleSort = (name: keyof tableBodyProps, value: boolean) => {
    const tmp = rowTableBody;
    if (value) {
      tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] > b[name] ? 1 : -1));
    } else {
      tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] < b[name] ? 1 : -1));
    }
    // setTableBody(tmp);

    setAscInfo({ ...ascInfo, [name]: value });
  };
  const moveRef_header = useRef<HTMLDivElement>(null);
  const moveRef_body = useRef<HTMLDivElement>(null);
  const scrollLimit = 260;

  const scroll_event = (e: any) => {
    if (moveRef_header.current && moveRef_body.current) {
      moveRef_header.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
      moveRef_body.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
    }
  };

  const handleOpenModal = (info: tableBodyProps) => {
    setModalInformation(info);
    setOpen(true);
  };

  useEffect(() => {
    const bodies = tablebodies();
    // setTableBody(bodies);
    setRowTableBody(bodies);
    // console.log('tableBody', tableBody);
    // console.log('props', props);
    // console.log('postTasks', props.postTasks);
    console.log('thumbnail', thumbnail);
    // 初期画面に表示するテーブルデータ作成
    // const data = bodies.slice(0, perPage);
    setCurrentData(bodies);
  }, [selectedPost]);

  const sortComponent = (isAsc: boolean, name: keyof tableBodyProps) => {
    return (
      <div className="rounded-[4px] bg-gray-200">
        <div className="flex h-5 w-5 items-center  justify-center ">
          {isAsc ? (
            <ChenverDown width={14} height={14} onClick={() => handleSort(name, false)} stroke="#9CA3AF" />
          ) : (
            <ChenverUp width={14} height={14} onClick={() => handleSort(name, true)} stroke="#9CA3AF" />
          )}
        </div>
      </div>
    );
  };

  const emptyGetAnalysisSpiritPostTaskPostsOutput = {
    posts: [
      {
        account: {
          snsAccountNo: '',
          snsAccountThumbnail: '',
          applyingInfluencerId: '',
        },
        content: {
          postImage: '',
          postVideo: '',
          postMedias: [],
          multiMediaImages: [],
          mediaType: 'video',
          caption: '',
          postedAt: '',
        },
        insight: {
          engagementRate: 0,
          engagementCount: 0,
          goodCount: 0,
          replyCount: 0,
          repostCount: 0,
          viewCount: 0,
        },
      },
    ],
  };

  const emptyPostProps = {
    task: {
      taskId: '',
      taskName: '',
      taskType: '',
      taskStatus: '',
      taskStatusText: '',
      taskStatusColor: '',
      taskStatusIcon: '',
      taskStatusIconColor: '',
    },
    posts: emptyGetAnalysisSpiritPostTaskPostsOutput,
  };

  const emptyAnalysisSpiritPostInsightX = {
    engagementRate: 0,
    engagementCount: 0,
    goodCount: 0,
    replyCount: 0,
    repostCount: 0,
    viewCount: 0,
  };

  const selectPostTab = () => {
    const tmpPostTask = props.postTasks.filter((p) => p.task.taskId == selectedPost)[0];
    if (!tmpPostTask) return [];
    const tmpPosts = tmpPostTask ?? emptyPostProps;
    const tmpGetAnalysisSpiritPostTaskPostsOutput = tmpPosts.posts ?? emptyGetAnalysisSpiritPostTaskPostsOutput;
    const tmpSummary = tmpGetAnalysisSpiritPostTaskPostsOutput.summary as AnalysisSpiritPostInsightX;
    const summary = tmpSummary ?? emptyAnalysisSpiritPostInsightX;
    console.log('ポストタスク', tmpPostTask);
    console.log('サマリー', summary);
    return SummaryPostCommon(
      'w-[366px]',
      SnsType.X,
      summary.engagementRate ? summary.engagementRate.toLocaleString() : '集計中',
      summary.engagementCount ? summary.engagementCount.toLocaleString() : '集計中',
      summary.goodCount ? summary.goodCount.toLocaleString() : '集計中',
      summary.replyCount ? summary.replyCount.toLocaleString() : '集計中',
      summary.repostCount ? summary.repostCount.toLocaleString() : '集計中',
      summary.viewCount ? summary.viewCount.toLocaleString() : '集計中'
    );
  };
  return (
    <div className="flex flex-col items-start gap-6 self-stretch p-6">
      <div className="flex flex-col items-start gap-10 self-stretch">
        <div className="flex flex-col items-start gap-10 self-stretch">
          <div className="flex items-center gap-6">
            <div className="flex h-[34px] flex-col items-start">
              <div className="w-[177px]">
                <select
                  id="graph-type"
                  className="flex w-[190px] flex-1 items-center justify-center gap-2 rounded-md border border-gray-300 py-[9px] pl-[17px] pr-[13px] text-sm font-medium leading-5 text-gray-700"
                  defaultValue={selectedPost}
                  onChange={(e) => {
                    setPostTask(e.target.value);
                    // 投稿回数を変更する度にテーブルの内容を変更するために下記２つの関数を入れています。
                    // setTableBody(tablebodies());
                    setRowTableBody(tablebodies());
                  }}
                >
                  {props.postTasks.map((postTask, index) => {
                    return (
                      <option key={postTask.task.taskId} value={postTask.task.taskId}>
                        {index + 1}回目投稿
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="flex w-[740px] flex-col items-start gap-4">
            <div className="flex flex-col items-start gap-1 self-stretch">
              <span className="text-sm font-bold">投稿サマリー</span>
            </div>
            {selectPostTab()}
          </div>
          <div className="flex flex-col items-start gap-4 self-stretch">
            <span className="text-sm font-bold">投稿一覧</span>
            <div className="flex flex-col items-start self-stretch rounded-sm border">
              <div className="flex flex-col items-start gap-2">
                <div>
                  <div className="divide-y divide-gray-200 rounded-t-lg bg-white">
                    <div
                      className="w-[1050px] overflow-auto scrollbar-hide"
                      ref={moveRef_header}
                      onScroll={(event) => scroll_event(event)}
                    >
                      <div className="sticky top-0 inline-block">
                        <div className="flex h-[56px]">
                          <div className="flex w-[100px] items-center bg-white px-6 py-4">
                            <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                              アイコン
                            </span>
                          </div>
                          <div className="flex w-[152px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                ID
                              </span>
                              {/* {sortComponent(ascInfo.id, 'id')} */}
                            </div>
                          </div>
                          <div className="flex w-[120px] items-center justify-center bg-white px-6 py-4">
                            <span className="whitespace-nowrap text-center text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                              サムネイル
                            </span>
                          </div>
                          <div className="flex w-[200px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                エンゲージメント率
                              </span>
                              {sortComponent(ascInfo.engagementRate, 'engagementRate')}
                            </div>
                          </div>
                          <div className="flex w-[200px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                エンゲージメント数
                              </span>
                              {sortComponent(ascInfo.numberOfEngagements, 'numberOfEngagements')}
                            </div>
                          </div>
                          <div className="flex w-[132px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                いいね数
                              </span>
                              {sortComponent(ascInfo.goods, 'goods')}
                            </div>
                          </div>
                          <div className="flex w-[132px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                返信数
                              </span>
                              {sortComponent(ascInfo.comment, 'comment')}
                            </div>
                          </div>
                          <div className="flex w-[142px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                リポスト数
                              </span>
                              {sortComponent(ascInfo.repost, 'repost')}
                            </div>
                          </div>
                          <div className="flex w-[152px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                合計視聴回数
                              </span>
                              {sortComponent(ascInfo.sumViewNum, 'sumViewNum')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="max-h-[210px] w-[1050px] divide-y divide-gray-200 overflow-auto"
                      onScroll={(event) => scroll_event(event)}
                      ref={moveRef_body}
                    >
                      {currentData.every((item) => Object.values(item).every((value) => value === '')) ||
                      currentData.length === 0 ? (
                        <div className="flex justify-center py-20 text-2xl">
                          投稿一覧は、投稿が行われた後に表示されます
                        </div>
                      ) : (
                        currentData.map((body: tableBodyProps, index: number) => {
                          return (
                            <div
                              key={index}
                              onClick={() => handleOpenModal(body)}
                              onKeyDown={() => handleOpenModal(body)}
                              className={
                                'flex h-[72px] w-[1802px] items-center hover:cursor-pointer hover:bg-gray-100' +
                                (index % 2 == 0 ? '' : ' bg-gray-50')
                              }
                              role="presentation"
                            >
                              <div className="flex w-[98px] justify-center px-6 py-4">
                                <img src={body.icon} alt="" className="h-[40px] w-[40px] rounded-full" />
                              </div>
                              <div className="w-[152px] px-6 py-4">
                                <span>{body.id}</span>
                              </div>
                              <div className="flex w-[120px] justify-center px-6 py-4">
                                {body.mediaType && body.image ? (
                                  <div>
                                    <img src={body.image} alt="" className="h-[40px] w-[40px] rounded-[6px]" />
                                  </div>
                                ) : body.mediaType && thumbnail ? (
                                  <div>
                                    <img src={thumbnail} alt="" className="h-[40px] w-[40px] rounded-[6px]" />
                                  </div>
                                ) : (
                                  <p>-</p>
                                )}
                              </div>
                              <div className="w-[200px] px-6 py-4">
                                <span>{body.engagementRate}</span>
                              </div>
                              <div className="w-[200px] px-6 py-4">
                                <span>{body.numberOfEngagements}</span>
                              </div>
                              <div className="w-[132px] px-6 py-4">
                                <span>{body.goods}</span>
                              </div>
                              <div className="w-[132px] px-6 py-4">
                                <span>{body.comment}</span>
                              </div>
                              <div className="w-[142px] px-6 py-4">
                                <span>{body.repost}</span>
                              </div>
                              <div className="w-[152px] px-6 py-4">
                                {body.image == '-' ? <span>-</span> : <span>{body.sumViewNum}</span>}
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        accountInformation={modalInformation}
        initialTabKey="postInsight"
        modalType={'sns'}
        getInfluencerData={fetchInfluencerApplicantDetail}
      />
    </div>
  );
}
