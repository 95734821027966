import { isUseServiceSite } from '@/utils/envUtil';
/* eslint-disable */
const AUTH0_CONFIG = {
  domain: process.env.REACT_APP_AUTH_ZERO_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH_ZERO_CLIENT_ID || '',
  audience: process.env.REACT_APP_AUTH_ZERO_AUDIENCE || '',
  redirectUri: `${window.location.origin}/auth/sign-in-post`,
  returnTo: isUseServiceSite()
    ? process.env.REACT_APP_SERVICE_SITE_URL || ''
    : `${window.location.origin}/auth/sign-out-post`,
  connectionRealm: '',
  language: 'ja',
  termsLink: process.env.REACT_APP_AUTH_ZERO_TERMS_LINK || '',
};

export default AUTH0_CONFIG;
/* eslint-enable */
