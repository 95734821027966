import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play.svg';

import { modalArgsEnum } from '../../features/project_analysis/interfaces/modalInterface';
import sampleX from '../ProjectManagements/Applicant/Contents/common/Pic/beauty.png';

// Dupricated:共通版の src/components/Preview/previewCommon.tsx を使用してください
export const PreviewPost = (body: string, posted_method: modalArgsEnum) => {
  return (
    <div className="flex w-[432px] flex-col items-start gap-[15px]">
      <div
        className="flex flex-col items-start gap-[10px] self-stretch bg-white px-4 pb-4 pt-3"
        id="twitter-post-preview"
      >
        <div className="flex flex-col items-start gap-3 self-stretch" id="content">
          <div className="flex flex-col items-start gap-2 self-stretch">
            <div id="main-content" className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex h-[640px] flex-col items-start gap-4 self-stretch">
                <div className="flex h-[366px] w-[400px] flex-1 flex-col items-start gap-[10px] self-stretch">
                  {posted_method === modalArgsEnum.VIDEO ? (
                    <div className="flex flex-1 items-center justify-center gap-[10px] self-stretch rounded-lg border border-[#D3DADF] bg-[#D3DADF] ">
                      <PlayIcon height={132} width={132} fill="white" />
                    </div>
                  ) : (
                    <div>
                      <img src={sampleX} alt="sampleX" className="h-[372px] w-[400px] rounded-lg" />
                    </div>
                  )}
                </div>
                <span className="whitespace-pre-wrap text-xl font-medium leading-7">{body}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
