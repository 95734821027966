import clsx from 'clsx';
import * as React from 'react';

export type TooltipProps = React.HTMLAttributes<HTMLDivElement> & {
  descriptionList?: string[];
  inner?: React.ReactElement;
  isSpeechBubble: boolean;
  variant: keyof typeof variants;
  toolTipClassName?: string;
  tooltipText?: React.ReactElement | string;
};

const variants = {
  gray: 'bg-gray-100 ring-1 ring-gray-100',
  black: 'bg-black ring-black',
};

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(({ className = '', ...props }, ref) => {
  return (
    <div ref={ref} className={className} {...props}>
      <div className={clsx('rounded-lg', variants[props.variant], props.toolTipClassName)}>
        {props.tooltipText ? (
          <span className="text-sm font-normal">{props.tooltipText}</span>
        ) : (
          props.descriptionList?.length &&
          props.descriptionList.map((item) => (
            <div key={item} className="text-sm font-normal">
              {item}
            </div>
          ))
        )}
        {props.inner && props.inner}
      </div>
      {props.isSpeechBubble && (
        <div className="pl-[39px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="17" viewBox="0 0 26 17" fill="none">
            <path d="M13 17L0.00961829 0.499999L25.9904 0.500002L13 17Z" fill="#F3F4F6" />
          </svg>
        </div>
      )}
    </div>
  );
});

Tooltip.displayName = 'Tooltip';
