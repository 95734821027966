import { MainLayout } from '@/components/Layout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PostListView } from '../components/PostListView';

export const ManagementProjectList = () => {
  return (
    <>
      <div className="flex">
        <MainLayout
          sidebarProps={{
            firstSideBarselected: firstSidebarSelect.calendar,
            secondSideBarselected: secondSidebarSelect.prstPost,
          }}
        >
          <PostListView />;
        </MainLayout>
      </div>
    </>
  );
};
