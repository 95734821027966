import clsx from 'clsx';
import * as React from 'react';
import Datepicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as Calendar } from '@/assets/icons/calendar.svg';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  selectedDate?: Date | undefined;
  onChange?: any;
  width?: string;
  dateClassName?: {
    picker?: string;
    calender?: string;
  };
  isError?: boolean;
};

export const DateTimePicker = (inputProps: InputProps) => {
  return (
    <form>
      <div className="relative flex align-middle">
        <Datepicker
          selected={inputProps.selectedDate}
          onChange={inputProps.onChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="yyyy/MM/dd HH:mm"
          placeholderText="yyyy/mm/dd HH:mm"
          className={clsx(
            'rounded-lg border-gray-200 shadow-sm',
            inputProps.width ? 'w-[' + inputProps.width + ']' : '',
            inputProps.dateClassName?.picker !== undefined ? inputProps.dateClassName.picker : '',
            inputProps.isError ? 'border-red-400 ring-red-400 focus:ring-red-400 active:ring-red-400' : ''
          )}
          timeCaption="Time"
        ></Datepicker>
        <span
          className={clsx(
            'pointer-events-none absolute right-0 top-[10px] pr-[15px]',
            inputProps.dateClassName?.calender !== undefined ? inputProps.dateClassName.calender : ''
          )}
        >
          <Calendar />
        </span>
      </div>
    </form>
  );
};

DateTimePicker.displayName = 'DateTimePicker';
