import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';

import AvatarImage2 from './assets/Avatar2.png';
import { ReactComponent as DislikeIcon } from './assets/bx-dislike.svg';
import { ReactComponent as LikeIcon } from './assets/bx-like.svg';
import { ReactComponent as MoneyIcon } from './assets/bx-money.svg';
import { ReactComponent as ShareIcon } from './assets/bx-share.svg';
import Camera360Image from './assets/Camera360Image.png';
import InstaStoryPlayIcon from './assets/InstaStoryPlayIcon.png';
import NewImage from './assets/NewImage.png';

export const PRSTProductionManagementContentsApprovingYouTubeView = ({ imagePath }: { imagePath: string }) => {
  return (
    <div className="w-[270px]">
      <div className="relative mb-[1px] h-[370px] w-[270px]">
        <img src={imagePath} alt="" className="mt-[13px] w-[270px]" />
        <div className="absolute left-[10px] top-[-13px] z-10 h-[83px] w-[187px] font-['Jomhuria'] text-[50px] text-[#FFFFFF]">
          <img src={NewImage} alt="" />
        </div>
        <div className="absolute left-[152px] top-[90px] z-10 h-[83px] w-[107px] whitespace-nowrap font-['Jomhuria'] text-[36px] text-[#FFFFFF]">
          <img src={Camera360Image} alt="" />
        </div>
        <div className="mb-[20px] h-[196px] w-[270px] pl-[10px] pt-[3px]">
          <div className="flex items-center">
            <div className="mr-[121px] h-[22px] w-[119px] text-[14px] font-semibold">Camera360</div>
            <ChevronDownIcon width={15} height={15} fill="#000000" />
          </div>
          <div className="mb-[4px] text-[8px] font-medium">1.5万回視聴 １時間前 #LANVINen</div>
          <div className="mb-[13px] flex items-center justify-between">
            <div className="flex items-center">
              <img src={AvatarImage2} alt="" className="mr-[5px]" />
              <div className="text-[9px] font-medium">1122329737</div>
            </div>
            <div className="mr-[5px] flex h-[20px] w-[84px] items-center justify-center rounded-[40px] bg-black text-white">
              <span className="h-[20px] whitespace-nowrap text-center align-middle text-[6px] font-normal leading-5">
                チャンネル登録
              </span>
            </div>
          </div>
          <div className="mb-[9px] flex h-[22px] items-center justify-between">
            <div className="flex h-[22px] w-[77px] items-center justify-between rounded-[40px] bg-gray-100">
              <LikeIcon width={12} height={12} fill="#000000" className="ml-[9px]" />
              <div className="h-[16px] w-[16px] text-[8px] font-normal">12K</div>
              <div className="h-[15px] border-l-[1px] border-gray-200"></div>
              <DislikeIcon width={12} height={12} fill="#000000" className="mr-[5px]" />
            </div>
            <div className="flex h-[22px] w-[44px] items-center justify-around rounded-[40px] bg-gray-100">
              <ShareIcon width={12} height={12} fill="#000000" />
              <div className="h-[16px] whitespace-nowrap text-[8px] font-normal">共有</div>
            </div>
            <div className="flex h-[22px] w-[69px] items-center justify-around rounded-[40px] bg-gray-100">
              <PlusIcon width={12} height={12} fill="#000000" />
              <div className="h-[16px] whitespace-nowrap text-[8px] font-normal">リミックス</div>
            </div>
            <div className="flex h-[22px] w-[40px] items-center justify-around rounded-l-[40px] bg-gray-100">
              <MoneyIcon width={12} height={12} fill="#000000" />
              <div className="h-[16px] whitespace-nowrap text-[8px] font-normal">寄付</div>
            </div>
          </div>
          <div className="h-[64px] w-[255px] rounded-[8px] bg-gray-100 pl-[11px] pt-[5px]">
            <div className="mb-[7px] flex h-[20px] items-center">
              <div className="mr-[10px] h-[20px] whitespace-nowrap text-[8px] font-medium leading-5">コメント</div>
              <div className="h-[20px] whitespace-nowrap text-[8px] font-medium leading-5">340</div>
            </div>
            <div className="flex items-center">
              <img src={AvatarImage2} alt="" className="mr-[5px]" />
              <div className="h-[15px] text-[9px] font-medium">面白いです！</div>
            </div>
          </div>
        </div>
        <div>
          <img src={InstaStoryPlayIcon} alt="" />
        </div>
      </div>
    </div>
  );
};
