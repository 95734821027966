import { CalendarIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  GetJaneJohnDetailOutput,
  // ProjectDetailHeader,
  JaneJohnTaskType,
  ProjectImageRow,
  ProjectKpi,
  ProjectKpiItem,
  SnsType,
} from '@/api/janeJohn';
import { FractionType, GetProjectOverviewOutput, ProjectStatus } from '@/api/project';
import { ReactComponent as ApplicantIcon } from '@/assets/icons/bx-group.svg';
import { MainLayout } from '@/components/Layout/MainLayout';
import ApplicantContent, {
  ApplicantProps,
  GetProjectApplicantsOutput,
} from '@/components/ProjectManagements/Applicant/Contents/ApplicantContent';
import { ApplicantSchedule } from '@/components/ProjectManagements/Applicant/Contents/common/Types/common_types';
import { PhaseNavigation } from '@/components/ProjectManagements/PhaseNavigation/Components/PhaseNavigation';
import { TabView } from '@/components/ProjectManagements/PhaseNavigation/Components/TabView';
import { phaseInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/PhaseNavigationInterfaces';
import { TabInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/TabViewInterfaces';
import ProjectOverview from '@/components/ProjectManagements/ProjectOverview/Components/ProjectOverview';
import { projectOverview } from '@/components/ProjectManagements/ProjectOverview/Interfaces/ProjectOverViewInterfaces';
import ScheduleContent, { ScheduleProps } from '@/components/ProjectManagements/ProjectSchedule/Components/Schedule';
import {
  // projectSchedule,
  ScheduleList,
} from '@/components/ProjectManagements/ProjectSchedule/Interface/ProjectScheduleInterface';
import { PROJECT_STATUS } from '@/config';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
import { GetJaneJohnDetail, getJaneJohnProjectApplicants, getJaneJohnProjectSchedules } from '@/hooks/JaneJohn';
import { getProjectOverviews } from '@/hooks/ProjectInfo';
import { ProjectSchedule } from '@/types/JaneJohn';
import { formatYYYYMMDD } from '@/utils/format';
import { getImageUrlIfRelative } from '@/utils/image';
import { convertStatusToStepForJaneJohn, convertStatusToDisplayLabelForJaneJohn } from '@/utils/projectUtils';

// import Image from '../assets/samples/Image.png';
import Recruitment from '../components/details/recruitment';
import { PageHeader } from '../components/layouts/headers';
import { projectDetail } from '../interfaces/projectInterface';

function filterBySchedule(items: ScheduleList): ApplicantSchedule {
  const recruitmentPeriod = items.find((item) => item.label.trim() == 'インフルエンサー募集期間');
  const selectionPeriod = items.find((item) => item.label.trim() == 'インフルエンサー選定期間');
  const schedule: ApplicantSchedule = {
    from: formatYYYYMMDD(recruitmentPeriod?.from || ''),
    to: formatYYYYMMDD(selectionPeriod?.to || ''),
  };
  return schedule;
}

export function JaneJohn(): React.ReactElement {
  const [outlineInfo, setOutlineInfo] = useState<projectOverview>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    scheduleStartedAt: null,
    scheduleEndedAt: null,
    budget: 0,
    kpi: [],
    orderInfo: {
      companyName: '',
      address: '',
      capital: '',
      represent: '',
      established: '',
      business: '',
    },
  });
  const [projectSchedule, setProjectSchedule] = useState<ScheduleProps>({
    scheduleData: [],
    projectStatus: 0,
  });
  const [projectTitle, setProjectTitle] = useState<string>('');
  const [sns, setSns] = useState<string>('');
  const [projectImage, setProjectImage] = useState<string>('');
  const [openTitleDialog, setOpenTitleDialog] = useState<boolean>(false);
  const [openDetailImages, setOpenDetailImages] = useState<boolean>(false);
  const [detailImages, setDetailImages] = useState<ProjectImageRow[]>([]);
  const [openProjectOverview, setOpenProjectOverview] = useState<boolean>(false);
  const [recruitmentPlanCount, setRecruitmentPlanCount] = useState<number>(0);
  const [rewardAmount, setRewardAmount] = useState<number>(0);
  const [tag, setTag] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [displayTags, setDisplayTags] = useState<string[]>([]);
  const [overviewDescription, setOverviewDescription] = useState<string>('');
  const [genres, setGenres] = useState<string[]>([]);
  const [openPublicAtention, setOpenPublicAtention] = useState<boolean>(false);
  const [visibilitySettings, setVisibilitySettings] = useState<number>(-1);
  const [considerations, setConsiderations] = useState<string[]>([]);
  const conpanyName = 'ABCカンパニー';
  const [schedules, setSchedules] = useState<Array<ProjectSchedule>>([]);
  const [kpi, setKpi] = useState<ProjectKpi>({ items: [] as ProjectKpiItem[] });
  // ダイアログのuseStateはここ
  const [dialogProjectTitle, setDialogProjectTitle] = useState<string>('');
  const [dialogSns, setDoialogSns] = useState<string>('');
  const [dialogFile, setDialogFile] = useState<string>('');
  const [dialogDetailImages, setDialogDetailImages] = useState<ProjectImageRow[]>([]);
  const [dialogRecruitmentPlanCount, setDialogRecruitmentPlanCount] = useState<number>(0);
  const [dialogRewardAmount, setDialogRewardAmount] = useState<number>(0);
  const [dialogTag, setDialogTag] = useState<string>('');
  const [dialogTags, setDialogTags] = useState<string[]>([]);
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [dialogVisibilitySettings, setDialogVisibilitySettings] = useState<number>(-1);
  const [dialogConsiderations, setDialogConsiderations] = useState<string[]>([]);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  // 応募者タブ
  const [sortData, setSortData] = useState<string>('asc');
  const [order, setOrder] = useState<string>('idx');
  const [status, setStatus] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(10);
  const [applicantListCurrentPage, setApplicantListCurrentPage] = useState<number>(1);

  const search = useLocation().search;

  const queryString = new URLSearchParams(search);
  // TODO statusはAPIから取得する項目
  // const projectStatus: string = queryString.get('status') || PROJECT_STATUS.DRAFT;
  const [projectStatus, setProjectStatus] = useState<string>(ProjectStatus.Draft);
  const queryTab: string = queryString.get('tab') || 'overview';
  // 戻り
  const projectType: string = queryString.get('type') || 'JANEJOHN';
  const queryStringRows: number = +(queryString.get('rows') || 30);
  const queryCurrentPage: number = +(queryString.get('page') || 1);
  const queryStatus: string = queryString.get('filter') || '';
  // TODO ダミーデータ用のクエリストリング不要になったら削除する
  const queryDummySize: number = +(queryString.get('size') || 300);
  const projectId = queryString.get('projectId') || '';

  const pahse: phaseInfo[] = [
    {
      name: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.DRAFT),
      isCurrent: PROJECT_STATUS.DRAFT == projectStatus || PROJECT_STATUS.REVIEW == projectStatus,
      isDone: convertStatusToStepForJaneJohn(projectStatus) > convertStatusToStepForJaneJohn(PROJECT_STATUS.REVIEW),
    },
    {
      name: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.APPROVED),
      isCurrent: PROJECT_STATUS.APPROVED == projectStatus,
      isDone: convertStatusToStepForJaneJohn(projectStatus) > convertStatusToStepForJaneJohn(PROJECT_STATUS.APPROVED),
    },
    {
      name: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.FINAL_CONFIRMATION),
      isCurrent: PROJECT_STATUS.FINAL_CONFIRMATION == projectStatus,
      isDone:
        convertStatusToStepForJaneJohn(projectStatus) >
        convertStatusToStepForJaneJohn(PROJECT_STATUS.FINAL_CONFIRMATION),
    },
    {
      name: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.IMPLEMENTATION),
      isCurrent: PROJECT_STATUS.IMPLEMENTATION == projectStatus,
      isDone:
        convertStatusToStepForJaneJohn(projectStatus) > convertStatusToStepForJaneJohn(PROJECT_STATUS.IMPLEMENTATION),
    },
    {
      name: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.COMPLETION),
      isCurrent: PROJECT_STATUS.COMPLETION == projectStatus,
      isDone: convertStatusToStepForJaneJohn(projectStatus) > convertStatusToStepForJaneJohn(PROJECT_STATUS.COMPLETION),
    },
  ];

  const [tabInfo, setTabInfo] = useState<TabInfo[]>([]);
  const [fetchJaneJohnProjectDetailsResult, setFetchJaneJohnProjectDetailsResult] = useState<GetJaneJohnDetailOutput>();
  const [fetchProjectsOverViews, setFetchProjectsOverViews] = useState<GetProjectOverviewOutput>();

  // 案件応募者情報
  const [applicant, setApplicant] = useState<GetProjectApplicantsOutput>({
    from: 0,
    to: 0,
    perPage: 0,
    totalRows: 0,
    totalPages: 0,
    rows: [],
    count: {
      applicant: 0,
      approved: 0,
      reserved: 0,
      canBeReserver: 0,
    },
    rewards: 0,
    total: 0,
    adoptionPlan: 0,
    taxRate: 0,
    fractionType: '',
  });

  // 案件詳細情報取得APIリクエスト
  useEffect(() => {
    console.log('projectStatus:' + projectStatus);
    const executeFetchJaneJohnProjectDetailsResult = async (project_id: string) => {
      try {
        const data = await GetJaneJohnDetail(project_id);
        console.log('project_id:', project_id);
        console.log('executeFetchJaneJohnProjectDetailsResult:', data);
        setFetchJaneJohnProjectDetailsResult(data);
        setDetailImages(data.detailImages);
        setProjectStatus(data.header.projectStatus);
        const kpi = data.kpi;
        setKpi(kpi);
        setApplicant((prev) => {
          return {
            ...prev,
            adoptionPlan: data.summary.adoptionPlan,
            taxRate: data.estimate.taxRate,
            fractionType: data.estimate.fractionType,
            rewards: data.summary.rewards,
            total: data.estimate.total,
          };
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error('API call failed:', error);
      }
    };
    executeFetchJaneJohnProjectDetailsResult(projectId);
  }, [projectStatus, applicant.rows]);

  // 案件概要取得APIリクエスト
  const executeFetchProjectsOverViews = async (project_id: string) => {
    const data = await getProjectOverviews(project_id);
    console.log('getProjectOverviewsReslut:', data);
    setFetchProjectsOverViews(data);
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = executeFetchProjectsOverViews(projectId);
        console.log('getProjectOverviews:', result);
        console.log('fetchProjectsOverViews:', fetchProjectsOverViews);
        result.then((data) => {
          console.log('esult.then((data):', data);
          const strArr: string[] = [];
          const displayKpi = data && data.kpi ? data.kpi : strArr;
          const displayBudget = data && data.budget ? data.budget : 0;

          setOutlineInfo({
            marketBackground: data?.marketBackground ?? '-',
            problem: data?.problem ?? '-',
            purpose: data?.purpose ?? '-',
            measure: data?.measure ?? '-',
            kpi: displayKpi,
            budget: displayBudget,
            scheduleStartedAt: data?.scheduleEndedAt ?? '',
            scheduleEndedAt: data?.scheduleEndedAt ?? '',
            orderInfo: data?.orderInfo,
          });
        });
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const setDetail = (detail?: GetJaneJohnDetailOutput) => {
    if (detail == undefined) {
      return;
    }
    const summary = detail && detail.summary ? detail.summary : undefined;

    console.log('detail:', detail);
    // const question = question.;

    setProjectTitle(detail?.title || '');
    setSns(detail.snsType);
    setProjectImage(detail?.featuredImage || '');
    setRecruitmentPlanCount(summary?.adoptionPlan || 0);
    setRewardAmount(summary?.rewards || 0);
    // setTag(detail.tag);
    setTags(detail?.tags || []);
    setDisplayTags(detail?.tags || []);
    setOverviewDescription(summary?.description || '');
    setGenres(summary?.genres || []);
    setDetailImages(
      detail.detailImages.map((image) => {
        return {
          objectKey: getImageUrlIfRelative(image.objectKey),
          id: image.id,
        };
      })
    );
    setSchedules(
      detail.schedules.map((schedule) => {
        return {
          label: schedule.label,
          from: schedule.from,
          to: schedule.to,
        };
      })
    );
    setKpi({
      items: detail.kpi.items,
    });
  };

  const formatDate = (dateString: string | null): string => {
    console.log('formatDate_dateString:', dateString);
    if (dateString == null || dateString == '' || dateString == ' ') return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const emptyJaneJohnDetail: GetJaneJohnDetailOutput = {
    header: {
      projectGroupName: null,
      projectName: '',
      projectType: 'other',
      projectStatus: 'draft',
      teamId: '',
      teamName: '',
      teamManagerName: null,
      scheduleStartedAt: null,
      scheduleEndedAt: null,
      managerId: null,
      managerName: null,
      feedbackStatus: null,
    }, // ProjectDetailHeader の初期値
    title: null, // string 型の初期値
    snsType: SnsType.Instagram, // SnsType の初期値
    featuredImage: null, // string 型の初期値
    detailImages: [], // ProjectImageRow 型の配列の初期値
    tags: null, // string 型の配列の初期値
    selectionStartedAt: null, // string 型の初期値
    selectionEndedAt: null, // string 型の初期値
    summary: { adoptionPlan: 0, rewardsType: null, rewards: 0, genres: null, description: '' }, // ProjectSummaryBase の初期値
    task: {
      type: JaneJohnTaskType.GroupInterview,
      groupInterView: {
        interviewDate: null, // JaneJohnTaskGroupInterview の初期値
      },
      spotConsul: null,
      question: null,
    }, // JaneJohnTask の初期値
    details: {
      notice: [],
      isSecret: false,
      companyName: '',
    }, // ProjectSummaryDetails の初期値
    schedules: [], // ProjectSchedule 型の配列の初期値
    kpi: { items: [] }, // ProjectKpi の初期値
    estimate: {
      isCalculated: false, // boolean 型の初期値
      items: {
        // ProjectEstimateItemGroups の初期値
        fixedItems: [], // ProjectEstimateItem の配列の初期値
        optionFixedItems: [], // ProjectEstimateItem の配列の初期値
        optionFreeItems: [], // ProjectEstimateItem の配列の初期値
      },
      optionChoices: [], // ProjectOptionChoice の配列の初期値
      subtotal: 0, // number 型の初期値
      tax: 0, // number 型の初期値
      total: 0, // number 型の初期値
      taxRate: 0, // number 型の初期値
      fractionType: FractionType.Floor, // FractionType の初期値
      calculateReward: {
        // ProjectCalculateReward の初期値
        isCalculated: false,
        standard: 0,
        minimum: 0,
      },
    }, // ProjectEstimate の初期値
  };
  const [janeJohnTitle, setJaneJohnTitle] = useState<GetJaneJohnDetailOutput>(emptyJaneJohnDetail);
  const [janeJohnNotice, setJaneJohnNotice] = useState<GetJaneJohnDetailOutput>(emptyJaneJohnDetail);
  const [janeJohnTask, setJaneJohnTask] = useState<GetJaneJohnDetailOutput>(emptyJaneJohnDetail);
  const [janeJohnSummary, setJaneJohnSummary] = useState<GetJaneJohnDetailOutput>(emptyJaneJohnDetail);
  const [janeJohnDetailSetting, setJaneJohnDetailSetting] = useState<GetJaneJohnDetailOutput>(emptyJaneJohnDetail);
  const [janeJohnOperationDetails, setJaneJohnOperationDetails] =
    useState<GetJaneJohnDetailOutput>(emptyJaneJohnDetail);
  const [janeJohnDetailImages, setJaneJohnDetailImages] = useState<GetJaneJohnDetailOutput>(emptyJaneJohnDetail);

  const projectDetailInfo: projectDetail = {
    janeJohnTitle: janeJohnTitle,
    setJaneJohnTitle: setJaneJohnTitle,
    janeJohnNotice: janeJohnNotice,
    setJaneJohnNotice: setJaneJohnNotice,
    janeJohnTask: janeJohnTask,
    setJaneJohnTask: setJaneJohnTask,
    janeJohnSummary: janeJohnSummary,
    setJaneJohnSummary: setJaneJohnSummary,
    janeJohnDetailSetting: janeJohnDetailSetting,
    setJaneJohnDetailSetting: setJaneJohnDetailSetting,
    janeJohnOperationDetails: janeJohnOperationDetails,
    setJaneJohnOperationDetails: setJaneJohnOperationDetails,
    janeJohnDetailImages: janeJohnDetailImages,
    setJaneJohnDetailImages: setJaneJohnDetailImages,
    status: projectStatus,
    setStatus: setProjectStatus,
    projectTitle: projectTitle,
    setProjectTitle: setProjectTitle,
    dialogProjectTitle: dialogProjectTitle,
    setDialogProjectTitle: setDialogProjectTitle,
    projectImage: projectImage,
    setProjectImage: setProjectImage,
    detailImages: detailImages,
    description:
      convertStatusToStepForJaneJohn(projectStatus) != 1
        ? {
            applicationConditions: '5,000フォロワー以上',
            welcomeConditions: '居住地 東京',
            budget: '￥50,000/月\n※制作実績により報酬額が変動する場合があります',
            description:
              '5年後、10年後もずっと若々しく麗しい肌印象でいるために。\n美肌の起源に着目し全方位からエイジングケアを網羅する、カナデル史上最高峰オールインワン「カナデルプレミア ゼロ」を是非お試しください。',
            start: '2023年3月1日',
            end: '2023年8月31日',
          }
        : undefined,
    kpi: kpi,
    scadule: schedules,
    openTitleDialog: openTitleDialog,
    setOpenTitleDialog: setOpenTitleDialog,
    sns: sns,
    setSns: setSns,
    dialogSns: dialogSns,
    setDialogSns: setDoialogSns,
    dialogFile: dialogFile,
    setDialogFile: setDialogFile,
    openDetailImages: openDetailImages,
    setOpenDetailImages: setOpenDetailImages,
    setDetailImages: setDetailImages,
    dialogDetailImages: dialogDetailImages,
    setDialogDetailImages: setDialogDetailImages,
    openDialogProjectOverview: openProjectOverview,
    setOpenDialogProjectOverview: setOpenProjectOverview,
    recruitmentPlanCount: convertStatusToStepForJaneJohn(projectStatus) == 1 ? recruitmentPlanCount : 10,
    setRecruitmentPlanCount: setRecruitmentPlanCount,
    dialogRecruitmentPlanCount: dialogRecruitmentPlanCount,
    setDialogRecruitmentPlanCount: setDialogRecruitmentPlanCount,
    rewardAmount: convertStatusToStepForJaneJohn(projectStatus) == 1 ? rewardAmount : 50000,
    setRewardAmount: setRewardAmount,
    dialogRewardAmount: dialogRewardAmount,
    setDialogRewardAmount: setDialogRewardAmount,
    tag: tag,
    setTag: setTag,
    tags: tags,
    setTags: setTags,
    displayTags: displayTags,
    setDisplayTags: setDisplayTags,
    dialogTag: dialogTag,
    setDialogTag: setDialogTag,
    dialogTags: dialogTags,
    setDialogTags: setDialogTags,
    overviewDescription: overviewDescription,
    setOverviewDescription: setOverviewDescription,
    genres: genres,
    setGenres: setGenres,
    dialogDescription: dialogDescription,
    setDialogDescription: setDialogDescription,
    openDialogPublicAttention: openPublicAtention,
    setOpenDialogPublicAttention: setOpenPublicAtention,
    visibilitySettings: visibilitySettings,
    setVisibilitySettings: setVisibilitySettings,
    conpanyName: conpanyName,
    dialogVisibilitySettings: dialogVisibilitySettings,
    setDialogVisibilitySettings: setDialogVisibilitySettings,
    considerations: considerations,
    setConsiderations: setConsiderations,
    dialogConsiderations: dialogConsiderations,
    setDialogConsiderations: setDialogConsiderations,
    isRequesting: isRequesting,
    setIsRequesting: setIsRequesting,
    projectId: projectId,
  };

  useEffect(() => {
    const getProjectSchedules = async () => {
      try {
        const response = await getJaneJohnProjectSchedules(projectId);
        if (response) {
          console.log('案件スケジュール取得APIリクエスト成功:', response);
          setProjectSchedule({
            projectStatus: convertStatusToStepForJaneJohn(projectStatus),
            scheduleData: response,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error('案件スケジュール取得APIリクエスト失敗:', error);
      }
    };
    getProjectSchedules();
  }, []);

  const getApplicantProp = useCallback(
    async (rows = 10, page: number) => {
      try {
        const response = await getJaneJohnProjectApplicants(projectId, rows, page, sortData, order, status);
        if (response) {
          const applicantDataWithRewards = {
            from: response.from,
            to: response.to,
            perPage: response.perPage,
            totalRows: response.totalRows,
            totalPages: response.totalPages,
            rows: response.rows,
            count: response.count,
          };
          setApplicant((prev) => {
            return {
              ...prev,
              ...applicantDataWithRewards,
            };
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error('応募者データの取得に失敗しました。', error);
        return null;
      }
    },
    [projectId, sortData, order, status]
  );

  useEffect(() => {
    getApplicantProp(perPage, applicantListCurrentPage);
  }, [getApplicantProp, perPage, applicantListCurrentPage]);

  useEffect(() => {
    getApplicantProp(perPage, applicantListCurrentPage);
  }, [getApplicantProp, perPage, applicantListCurrentPage]);

  useEffect(() => {
    console.log('fetchJaneJohnProjectDetailsResult:' + fetchJaneJohnProjectDetailsResult?.snsType);
    setDetail(fetchJaneJohnProjectDetailsResult);
    if (fetchJaneJohnProjectDetailsResult) {
      console.log('----------------fetchJaneJohnProjectDetailsResult----------------');
      console.log(fetchJaneJohnProjectDetailsResult);
      setJaneJohnDetailSetting(fetchJaneJohnProjectDetailsResult);
    }
    console.log('outlineInfo :' + JSON.stringify(outlineInfo, null, 2));
    console.log('snsType:' + sns);
    setTabInfo([
      {
        title: '案件概要',
        icon: DocumentTextIcon,
        component: (props: projectOverview) => <ProjectOverview {...props} />,
        componentProps: {
          marketBackground: outlineInfo.marketBackground,
          problem: outlineInfo.problem,
          purpose: outlineInfo.purpose,
          measure: outlineInfo.measure,
          kpi: outlineInfo.kpi,
          budget: outlineInfo.budget,
          scheduleStartedAt: formatDate(outlineInfo.scheduleStartedAt),
          scheduleEndedAt: formatDate(outlineInfo.scheduleEndedAt),
          orderInfo: outlineInfo.orderInfo,
        },
        query: 'overview',
        isActive: queryTab === 'overview',
      },
      {
        title: '募集内容詳細',
        icon: PencilIcon,
        component: (props: projectDetail) => <Recruitment {...props} />,
        componentProps: projectDetailInfo,
        query: 'detail',
        isActive: queryTab === 'detail',
      },
      {
        title: 'スケジュール',
        icon: CalendarIcon,
        component: (props: ScheduleProps) => <ScheduleContent {...props} />,
        componentProps: {
          scheduleData: projectSchedule ? projectSchedule.scheduleData : [],
          projectStatus: projectSchedule ? projectSchedule.projectStatus : 0,
        },
        query: 'schedule',
        isActive: queryTab === 'schedule',
      },
      {
        title: '応募者',
        icon: ApplicantIcon,
        component: (props: ApplicantProps) => <ApplicantContent {...props} />,
        componentProps: {
          applicant: applicant,
          setApplicant: setApplicant,
          projectId: projectId,
          getApplicantData: getApplicantProp,
          schedule: projectSchedule ? filterBySchedule(projectSchedule.scheduleData) : undefined,
          sortData: sortData,
          setSortData: setSortData,
          applicantListCurrentPage: applicantListCurrentPage,
          setApplicantListCurrentPage: setApplicantListCurrentPage,
          perPage: perPage,
          setPerPage: setPerPage,
          setOrderWord: setOrder,
          setSelectionStatus: setStatus,
          status: projectStatus,
        },
        query: 'applicant',
        isActive: queryTab === 'applicant',
        className: 'fill-current',
      },
    ]);
  }, [fetchJaneJohnProjectDetailsResult, outlineInfo, projectSchedule, applicant.rows, queryTab]);

  // どうしてもdetailImagesが更新できないので、detailImages専用で分けたuseEffectで更新する（他と一緒にやると無限ループする、もっといいやり方ある気がする）
  useEffect(() => {
    console.log('Updated detailImages_janejohn.tsx:', detailImages);
    // Recruitmentタブの情報を更新する
    setTabInfo((prevTabInfo) =>
      prevTabInfo.map((tab) =>
        tab.query === 'detail'
          ? {
              ...tab,
              componentProps: {
                ...tab.componentProps,
                detailImages: detailImages,
              },
            }
          : tab
      )
    );
  }, [detailImages]);

  const header =
    fetchJaneJohnProjectDetailsResult && fetchJaneJohnProjectDetailsResult.header
      ? fetchJaneJohnProjectDetailsResult.header
      : undefined;

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.janeJohn,
      }}
    >
      <div className="flex min-h-screen w-[1181px] bg-gray-50">
        <div className="w-[1181px] flex-1 bg-gray-50">
          <div>{PageHeader(projectType, queryStringRows, queryCurrentPage, queryStatus, queryDummySize, header)}</div>
          <div className="px-6">{PhaseNavigation(pahse)}</div>
          <div className="flex-1 pt-[10px]">
            <div className="px-6">
              <div className="border-[1px] border-gray-200">{tabInfo.length > 0 && <TabView tabInfo={tabInfo} />}</div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
