import clsx from 'clsx';

import { AnalysisSpiritFeedbackAdopterRow } from '@/api/analysisSpirit';
import { ProjectType } from '@/api/influencer';
import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChenverUp } from '@/assets/icons/chevron-up.svg';
import { SelectMenus } from '@/components/Elements/SelectMenus';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';

import { SelectFeedback } from './feedbackCommon';
import FeedbackStars from './feesbackStar';

import './feedbackTable.css';

interface FeedbackTableBodyProps {
  influencers: AnalysisSpiritFeedbackAdopterRow[];
  isGoodQuantity: boolean;
  setModalOpen: (id: string) => void;
  selectFeedback: (feedback: SelectFeedback) => void;
  isAsc?: boolean;
}

export const TableHeaderIncompleated = (isGoodQuantity: boolean) => {
  return (
    <thead className={clsx('h-[44px] rounded-lg', 'bg-[#F3F4F6]')}>
      <tr>
        <th id="before-scroll" className="sticky top-0 px-6 py-4 text-left">
          <div className="w-[50px] whitespace-nowrap text-xs font-medium text-gray-500">アイコン</div>
        </th>
        <th id="before-scroll" className="sticky top-0 px-6 py-4 text-left">
          <div className="w-[161px] whitespace-nowrap text-xs font-medium text-gray-500">ID</div>
        </th>
        <th id="before-scroll" className="sticky top-0 px-6 py-4 text-left">
          <div className="w-[226px] whitespace-nowrap text-xs font-medium text-gray-500">インフルエンサーへの評価</div>
        </th>
        {isGoodQuantity && (
          <th id="before-scroll" className="sticky top-0 w-full px-6 py-4 text-left">
            <div className="flex items-center">
              <div className="whitespace-nowrap text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                good qualityボーナス
              </div>
              <div className="group relative pl-[7.33px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.3996 8.00156C14.3996 11.5362 11.5342 14.4016 7.99961 14.4016C4.46499 14.4016 1.59961 11.5362 1.59961 8.00156C1.59961 4.46694 4.46499 1.60156 7.99961 1.60156C11.5342 1.60156 14.3996 4.46694 14.3996 8.00156ZM7.99962 5.60156C7.70451 5.60156 7.44569 5.76093 7.30616 6.00214C7.08492 6.38459 6.59554 6.51528 6.21309 6.29405C5.83064 6.07281 5.69995 5.58343 5.92119 5.20098C6.33495 4.48571 7.11027 4.00156 7.99962 4.00156C9.3251 4.00156 10.3996 5.07608 10.3996 6.40156C10.3996 7.44654 9.73177 8.33553 8.79962 8.665V8.80156C8.79962 9.24339 8.44145 9.60156 7.99962 9.60156C7.55779 9.60156 7.19962 9.24339 7.19962 8.80156V8.00156C7.19962 7.55973 7.55779 7.20156 7.99962 7.20156C8.44145 7.20156 8.79962 6.84339 8.79962 6.40156C8.79962 5.95973 8.44145 5.60156 7.99962 5.60156ZM7.99961 12.0016C8.44144 12.0016 8.79961 11.6434 8.79961 11.2016C8.79961 10.7597 8.44144 10.4016 7.99961 10.4016C7.55778 10.4016 7.19961 10.7597 7.19961 11.2016C7.19961 11.6434 7.55778 12.0016 7.99961 12.0016Z"
                    fill="#9CA3AF"
                  />
                </svg>
                <Tooltip
                  isSpeechBubble={true}
                  variant="gray"
                  toolTipClassName="px-3 py-2"
                  tooltipText="Good Qualityボーナス対象案件に限り、案件実施後に企業(依頼主)から高評価を得たインフルエンサーに追加でボーナス報酬を提供できる仕組みです。通常よりも質の高い投稿が見込まれます。"
                  className="absolute left-[-34px] top-[-160px] hidden w-[320px] whitespace-pre-wrap text-xs group-hover:inline-block"
                ></Tooltip>
              </div>
            </div>
          </th>
        )}
      </tr>
    </thead>
  );
};

export const TableHeaderCompleated = (
  setIsAsc: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  projectType: string,
  isAsc?: boolean
) => {
  return (
    <thead className=" bg-gray-100">
      <tr>
        <th id="before-scroll" className="sticky top-0 items-start px-6 py-4">
          <div className="w-[50px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">アイコン</div>
        </th>
        <th id="before-scroll" className="sticky top-0 px-6 py-4 text-left">
          <div className="w-[161px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">ID</div>
        </th>
        <th id="before-scroll" className="sticky top-0 px-5 py-4 text-left">
          <div className="w-[226px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
            インフルエンサーからのフィードバック
          </div>
        </th>
        <th id="before-scroll" className="sticky top-0 self-stretch py-4 pl-6 text-left">
          <div className="flex w-[223px] gap-1">
            <div className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              インフルエンサーへの評価
            </div>
            {projectType === ProjectType.JaneJohn ? (
              <div></div>
            ) : (
              <div className="flex h-5 w-5 items-center justify-center rounded bg-gray-200">
                {isAsc === undefined || isAsc ? (
                  <ChenverDown
                    width={14}
                    height={14}
                    onClick={() => {
                      setIsAsc(false);
                    }}
                    stroke="#4B5563"
                  />
                ) : (
                  <ChenverUp
                    width={14}
                    height={14}
                    onClick={() => {
                      setIsAsc(true);
                    }}
                    stroke="#4B5563"
                  />
                )}
              </div>
            )}
          </div>
        </th>
      </tr>
    </thead>
  );
};

export const TableBodyIncompleate = (props: Omit<FeedbackTableBodyProps, 'isAsc'>) => {
  return (
    <tbody className={clsx('overflow-x-hidden overflow-y-scroll')}>
      {props.influencers.map((influencer) => (
        <tr
          key={influencer.snsAccountNo}
          className="h-[66px] cursor-pointer items-center self-stretch even:bg-gray-50 hover:bg-gray-100"
          onClick={() =>
            // 詳細モーダルを開く
            props.setModalOpen(influencer.adoptedInfluencerId)
          }
        >
          <td className="px-6">
            <button className="h-[72px]" onClick={() => props.setModalOpen(influencer.adoptedInfluencerId)}>
              <div className="flex items-center justify-center">
                <img src={influencer.snsAccountThumbnail || ''} alt="" className="h-[32px]  rounded-full" />
              </div>
            </button>
          </td>
          <td className="px-6">
            <button
              className="flex h-[72px] items-center"
              onClick={() => props.setModalOpen(influencer.adoptedInfluencerId)}
            >
              <div className="text-xs font-medium leading-4 text-gray-500">{influencer.snsAccountNo}</div>
            </button>
          </td>
          <td className="px-6">
            <div className="flex h-[72px] items-center">
              <SelectMenus
                title=""
                options={[
                  { value: '1', label: '', children: FeedbackStars(1) },
                  { value: '2', label: '', children: FeedbackStars(2) },
                  { value: '3', label: '', children: FeedbackStars(3) },
                  { value: '4', label: '', children: FeedbackStars(4) },
                  { value: '5', label: '', children: FeedbackStars(5) },
                ]}
                widthSize="w-[138px]"
                className="text-sm font-medium leading-5 text-gray-700"
                value={influencer.evaluation.toString()}
                buttonClass="pl-[17px]"
                onChange={(e: any) => {
                  props.selectFeedback({
                    adoptedInfluencerId: influencer.adoptedInfluencerId,
                    evaluation: +e.value,
                  });
                }}
              />
            </div>
          </td>
          {props.isGoodQuantity && (
            <td className="px-6">
              <div className="flex h-[72px] items-center">
                <SelectMenus
                  title=""
                  options={[
                    { value: '1', label: '候補にする' },
                    { value: '2', label: '候補にしない' },
                  ]}
                  widthSize="w-[150px]"
                  className="text-sm font-medium leading-5 text-gray-700"
                  value={influencer.isGoodQualityCandidate ? '1' : '2'}
                  onChange={(e: any) => {
                    props.selectFeedback({
                      adoptedInfluencerId: influencer.adoptedInfluencerId,
                      snsAccountId: influencer.snsAccountId,
                      isGoodQualityCandidate: e.value === '1',
                    });
                  }}
                />
              </div>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};

export const TableBodyCompleated = (props: Pick<FeedbackTableBodyProps, 'influencers' | 'isAsc' | 'setModalOpen'>) => {
  const currentData =
    props.isAsc === undefined
      ? props.influencers
      : props.isAsc
      ? props.influencers.sort((a: AnalysisSpiritFeedbackAdopterRow, b: AnalysisSpiritFeedbackAdopterRow) =>
          a['evaluation'] > b['evaluation'] ? 1 : -1
        )
      : props.influencers.sort((a: AnalysisSpiritFeedbackAdopterRow, b: AnalysisSpiritFeedbackAdopterRow) =>
          a['evaluation'] < b['evaluation'] ? 1 : -1
        );
  return (
    <tbody className="overflow-x-hidden overflow-y-scroll">
      {currentData.map((influencer) => (
        <tr
          key={influencer.snsAccountNo}
          className="h-[66px] cursor-pointer self-stretch even:bg-gray-50 hover:bg-gray-100"
          onClick={() => props.setModalOpen(influencer.adoptedInfluencerId)}
        >
          <td>
            <div className="flex h-[72px] items-center justify-center">
              <img src={influencer.snsAccountThumbnail || ''} alt="" className="h-[32px] rounded-full" />
            </div>
          </td>
          <td>
            <div className="flex h-[72px] items-center px-6 py-4">
              <div className="text-xs font-medium leading-4 text-gray-500">{influencer.snsAccountNo}</div>
            </div>
          </td>
          <td>
            <div className="flex h-[72px] items-center px-3 py-4">
              <div className="text-sm font-normal leading-5 text-gray-500">{influencer.feedbackComment ?? ''}</div>
            </div>
          </td>
          <td>
            <div className="flex items-center px-6 py-4">{FeedbackStars(influencer.evaluation)}</div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};
