import { useState, useEffect, useRef } from 'react';

import { ReactComponent as TrashIcon } from '@/assets/icons/bx-trash.svg';

import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import EditImage from './assets/bx-edit-alt.svg';
import DeletePopup from './DeletePopup';

interface Props {
  modalText: string;
  listType: 'measure' | 'post';
  deleteFunction: () => void;
}

export default function DotsDropdown(props: Props): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const insideRef = useRef<HTMLButtonElement>(null);
  // const options = [{ text: '削除する', link: '/PRST_post_delete' }];
  const [open, setOpen] = useState(false);

  const change_status = () => {
    setOpen(true);
  };

  useEffect(() => {
    const el = insideRef.current;
    if (!el) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsActive(false);
      } else {
        return;
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [insideRef]);

  return (
    <div className="relative inline-block text-left">
      <button
        ref={insideRef}
        className="w-[24px] gap-x-1.5 text-sm font-medium leading-5 text-gray-700"
        onClick={() => setIsActive(!isActive)}
      >
        <DotsHorizontalIcon />
        <div>{DeletePopup(open, setOpen, props.modalText, () => props.deleteFunction())}</div>
      </button>
      {isActive && (
        <div className="absolute right-0 top-0 z-10 mt-[28px] w-[128px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none">
          {props.listType === 'measure' && (
            <div className="py-2 pl-3">
              <a href="#">
                <div className="flex items-center">
                  <div>
                    <img src={EditImage} alt="" />
                  </div>
                  <div className="pl-3 text-sm text-gray-700">編集する</div>
                </div>
              </a>
            </div>
          )}
          <div className="py-2 pl-4">
            <a href="#" onClick={() => change_status()}>
              <div className="flex items-center">
                <TrashIcon className="h-5 w-5 fill-red-600" aria-hidden="true" />
                <div className="pl-3 text-sm text-red-600">削除する</div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
