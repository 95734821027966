import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';

import { CreationStatus, GetCreationOutput } from '@/api/creation';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as ErrorIcon } from '@/assets/icons/bx-error.svg';
import { ReactComponent as PencilImage } from '@/assets/icons/bx-pencil.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import {
  deleteCreationImage,
  // getCreation,
  postCreationApprove,
} from '@/hooks/Creation';

import { ReactComponent as EditIcon } from './assets/bx-edit-alt.svg';
import { ReactComponent as MenuLeftIcon } from './assets/bx-menu-alt-left.svg';
import CommentIcon from './assets/CommentIcon.png';
import { ReactComponent as InformationImage } from './assets/Information carousel_Two Color 1.svg';
import { ImageModal } from './ImageModal';

interface ImageCreateViewProps {
  postDetail: GetCreationOutput;
  setPostDetail: React.Dispatch<React.SetStateAction<GetCreationOutput>>;
  updatePost: () => void;
}

export type CreationImageDraft = {
  contents: {
    mediaImage: File;
    textImage: string;
  }[];
  description: string;
  movieURL: string;
  mediaText: string;
};

export const ImageCreateView = ({ postDetail, setPostDetail }: ImageCreateViewProps) => {
  const [creationImageDraft, setCreationImageDraft] = useState<CreationImageDraft>({
    contents: [],
    description: postDetail?.draft?.description || '',
    movieURL: postDetail?.draft?.movieUrl || '',
    mediaText: postDetail?.draft?.movieDescription || '',
  });
  const [mediaImageIndex, setImageMediaIndex] = useState<number>(0);
  // const [imageCreationErrorMessage, setImageCreationErrorMessage] = useState<string>('');
  const changeMediaImageIndex = (index: number, length: number) => {
    if (length > index + 1) {
      const newIndex = index + 1;
      setImageMediaIndex(newIndex);
    }
    if (length === index + 1) {
      setImageMediaIndex(0);
    }
  };
  const statusToStepNum = (status: CreationStatus): number => {
    switch (status) {
      case CreationStatus.Draft:
        return 1;
      case CreationStatus.DraftApproved:
        return 2;
      case CreationStatus.Contents:
        return 3;
      case CreationStatus.ContentsApproved:
        return 4;
      case CreationStatus.Completed:
        return 5;
      default:
        return 0;
    }
  };

  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

  const [imageDeleteModal, setImageDeleteModal] = useState<boolean>(false);
  const handleImageDeleteModal = () => {
    setImageDeleteModal(true);
  };

  const [imageApproved, setImageApproved] = useState<boolean>(false);

  const handleImageApproved = () => {
    if (creationImageDraft.contents.length > 0) {
      postCreationApprove(postDetail.id, postDetail.draft?.contentId ?? '').then(() => {
        // updatePostDetail();
        setPostDetail((prev) => {
          return {
            ...prev,
            status: CreationStatus.Contents,
          };
        });
        setImageApproved(true);
      });
    }
  };

  const handleImageDelete = () => {
    deleteCreationImage('12345678901234567890ABCDEF', '12345678901234567890ABCDEF');
  };

  const DeleteImageModal = () => {
    return (
      <Transition.Root show={imageDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setImageDeleteModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="pointer-events-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="pointer-events-auto relative h-full w-[536px] overflow-hidden rounded-lg bg-white p-[24px]">
                    <div className="h-full w-[488px] bg-white">
                      <div className="mb-[10px] flex h-full w-[488px]">
                        <div className="mr-[16px] h-full">
                          <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-red-100">
                            <ErrorIcon width={24} height={24} fill="#DC2626"></ErrorIcon>
                          </div>
                        </div>
                        <div className="h-full w-[408px]">
                          <div className="mb-[8px] flex w-[408px] items-center justify-start">
                            <span className="text-lg font-medium leading-6 text-gray-900">
                              イメージ案を削除してよろしいですか？
                            </span>
                          </div>
                          <div className="flex w-[408px] items-center justify-start">
                            <span className="text-sm font-normal leading-5 text-gray-500">
                              この作業は取り戻せません。
                            </span>
                          </div>
                        </div>
                        <div>
                          <CloseIcon
                            width={24}
                            height={24}
                            fill="#9CA3AF"
                            onClick={() => {
                              setImageDeleteModal(false);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex h-[38px] w-[488px] items-center justify-end">
                        <Button
                          variant="white"
                          size="md"
                          className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                          onClick={() => {
                            setImageDeleteModal(false);
                          }}
                        >
                          キャンセル
                        </Button>
                        <Button
                          variant="red"
                          size="md"
                          className="h-[38px] w-[90px] whitespace-nowrap rounded-md text-sm font-medium leading-5"
                          onClick={handleImageDelete}
                        >
                          削除する
                        </Button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      {statusToStepNum(postDetail.status) > 1 ||
      creationImageDraft.contents.length ||
      creationImageDraft.movieURL.length ? (
        <div className="px-[24px]">
          <div className="mt-[25px] h-[755px] w-[1133px] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[707px] w-[1085px]">
              <div className="mb-[24px] flex h-[38px] w-[1085px] items-center justify-between">
                <div className="flex h-[28px] w-[92px] items-center">
                  <span className="text-lg font-semibold leading-7 text-gray-500">イメージ案</span>
                </div>
                <div className="flex h-[38px] w-[206px] items-center justify-between">
                  {/* ToDo 下記のpostStatusになっている部分を全てselectedPost.statusに戻す */}
                  {statusToStepNum(postDetail.status) > 2 || imageApproved ? (
                    <div className="flex h-[24px] w-[76px] items-center justify-between bg-green-100 px-[8px] py-[2px]">
                      <span className="text-sm font-medium leading-5 text-green-800">承認済</span>
                      <button>
                        <ChevronDownIcon fill="#10B981" width={16} height={16} />
                      </button>
                    </div>
                  ) : (
                    <Button onClick={handleImageApproved} className="text-sm font-medium leading-5">
                      承認する
                    </Button>
                  )}
                  <button onClick={() => setIsImageModalOpen(true)}>
                    <EditIcon width={24} height={24} fill="#6B7280" />
                  </button>
                  <button>
                    {/* <CommentIcon width={24} height={24} /> */}
                    <img src={CommentIcon} alt="" />
                  </button>
                </div>
              </div>
              <div className="h-[32px] w-[1085px]">
                <div className="mb-[8px] flex h-[20px] w-[56px] items-center">
                  <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                </div>
                <div className="mb-[8px] flex h-[28px] w-[162px] items-center">
                  <div className="mr-[5px] flex h-[20px] w-[56px] items-center">
                    <span className="text-sm font-semibold leading-5 text-gray-700">
                      {mediaImageIndex + 1} / {creationImageDraft.contents.length}枚目
                    </span>
                  </div>
                  <div className="h-[28px] w-[101px]">
                    <button onClick={() => changeMediaImageIndex(mediaImageIndex, creationImageDraft.contents.length)}>
                      <ChevronRightIcon width={28} height={28} fill="#374151" />
                    </button>
                  </div>
                </div>
                <div className="mb-[24px] flex h-[298px] w-[1085px] justify-between py-[10px]">
                  <div className="w-[290px]">
                    {creationImageDraft.contents.length > mediaImageIndex &&
                      (creationImageDraft.contents[mediaImageIndex].mediaImage.type == 'video/mp4' ? (
                        <video
                          controls
                          className="w-[290px]"
                          src={URL.createObjectURL(creationImageDraft.contents[mediaImageIndex].mediaImage)}
                        >
                          <track kind="captions" />
                        </video>
                      ) : (
                        <img
                          className="w-[290px]"
                          src={URL.createObjectURL(creationImageDraft.contents[mediaImageIndex].mediaImage)}
                          alt=""
                        />
                      ))}
                  </div>
                  <div className="h-[236px] w-[715px] py-[10px]">
                    <div className="mb-[20px] flex h-[20px] w-[124px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <div className="flex h-[16px] w-[96px] items-center">
                        <span className="text-xs font-normal leading-4 text-gray-800">画像 / 動画の説明</span>
                      </div>
                    </div>
                    <div className="h-[176px] w-[715px] text-xs font-normal leading-4 text-gray-800">
                      {creationImageDraft.contents[mediaImageIndex]?.textImage || 'No description'}
                    </div>
                  </div>
                </div>
                <div className="mb-[24px] h-px w-[1085px] border-b border-[#DDDDDD]"></div>
                <div className="h-[242px] w-[1085px]">
                  <div className="mb-[8px] h-[20px] w-[1085px] px-[16px]">
                    <span className="h-[20px] w-[56px] text-sm font-semibold leading-5 text-gray-800">テキスト</span>
                  </div>
                  <div className="h-[214px] w-[1085px] p-[16px]">{postDetail && postDetail.draft?.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[1133px] px-[24px] py-[25px]">
          <div className="flex h-[525px] w-[1133px] items-center justify-center rounded bg-[#FFFFFF] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[478px] w-[1085px]">
              <div className="mb-[24px] h-[28px] w-[92px]">
                <p className="text-lg font-semibold leading-7 text-gray-500">イメージ案</p>
              </div>
              <div className="flex h-[326px] w-[1085px] items-center justify-center py-[48px]">
                <div className="h-[230px] w-[252px] pb-[4px]">
                  <div className="mb-[24px] h-[164px] w-[252px]">
                    <InformationImage width={252} height={136} />
                    <div className="mt-[8px] flex h-[20px] w-[252px] justify-center">
                      <p className="w-[252px] text-center text-[14px] font-normal leading-5 text-[#6B7280]">
                        イメージ案がまだ制作されていません。
                      </p>
                    </div>
                  </div>
                  <Button
                    startIcon={<PencilImage fill="#FFFFFF" className="h-[20px] w-[20px]" />}
                    onClick={() => setIsImageModalOpen(true)}
                  >
                    イメージ案を制作する
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative">{DeleteImageModal()}</div>
      <div className="relative">
        <ImageModal
          isOpen={isImageModalOpen}
          creationId={postDetail.id}
          contentId={postDetail.draft?.contentId || '123456789ABCDEF'}
          postingStatus={postDetail.status}
          imageDetail={{
            ...creationImageDraft,
            contents: creationImageDraft.contents,
            postingType: postDetail.type,
          }}
          closeModal={() => setIsImageModalOpen(false)}
          deleteModal={handleImageDeleteModal}
          setPostDetail={setPostDetail}
          setImageDraft={setCreationImageDraft}
        />
      </div>
    </>
  );
};
