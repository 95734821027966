import { SnsType } from '@/api/influencer';
import { PreviewCommonPost } from '@/components/Preview/previewCommon';

import { stringToEnum } from '../../../../interfaces/modalInterface';
import { getMediaData } from '../modalCommonParts';
import { MediaProps, SNSContent } from '../modalInterface';

export interface accountInsightCommonProps {
  // 共通項目
  id: string;
  insight: JSX.Element;
  selected: string;
  snsType: string;
  mediaProps?: MediaProps;
  body?: string;
  url?: string;
}

// SNSアカウント詳細_投稿インサイト全体表示部品
export default function AccountInsightCommon(props: accountInsightCommonProps) {
  const href = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  const type = searchParams.get('type');
  console.log('props.mediaProps:' + JSON.stringify(props.mediaProps, null, 2));
  const snsType = props.mediaProps?.snsType.toString() || '';
  const mediaData = getMediaData(
    props.mediaProps?.snsType.toString() || '',
    (props.mediaProps?.content as SNSContent) ?? ''
  );
  const mediaType = mediaData.mediaType ?? '';
  const mediaTypeEnum = stringToEnum(mediaType) ?? '';
  console.log('mediaData:' + JSON.stringify(mediaData, null, 2));

  if (snsType === SnsType.X) {
    return (
      <div className="flex w-[1094px] flex-col items-start gap-10">
        <div className="flex flex-col items-start gap-6 self-stretch">
          <span className="text-base font-semibold leading-6">{props.selected}</span>
          {href.includes('project-analysis') && type === 'emerald_post' ? (
            <div className="flex h-[938px] items-start justify-center gap-10 self-stretch">
              {/* preview */}
              <PreviewCommonPost
                snsType={snsType}
                posted_method={mediaTypeEnum}
                mediaData={mediaData}
                body={props.body}
              />

              {/* stat */}
              <div className="hidden w-[577px] flex-1 flex-col items-start gap-4">{props.insight}</div>
            </div>
          ) : (
            <div className="flex items-start gap-10 self-stretch">
              {/* preview */}
              <PreviewCommonPost
                snsType={snsType}
                posted_method={mediaTypeEnum}
                mediaData={mediaData}
                body={props.body}
              />

              {/* stat */}
              <div className="flex w-[577px] flex-1 flex-col items-start gap-4">{props.insight}</div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (snsType === SnsType.Youtube) {
    return (
      <div className="flex flex-col items-start gap-10">
        <div className="flex flex-col items-start gap-6 self-stretch">
          <span className="text-base font-semibold leading-6">{props.selected}</span>
          <div className="flex items-start gap-10 self-stretch">
            {/* preview */}
            <PreviewCommonPost
              snsType={snsType}
              posted_method={mediaTypeEnum}
              mediaData={mediaData}
              body={props.body}
              url={props.url}
            />
            {/* stat */}
            <div className="flex w-[577px] flex-1 flex-col items-start gap-4">{props.insight}</div>
          </div>
        </div>
      </div>
    );
  } else if (snsType === SnsType.Instagram) {
    return (
      <div className="flex w-[1094px] flex-col items-start gap-10">
        <div className="flex flex-col items-start gap-6 self-stretch">
          <span className="text-base font-semibold leading-6">{props.selected}</span>
          {href.includes('project-analysis') && type === 'emerald_post' ? (
            <div className="flex h-[938px] items-start justify-center gap-10 self-stretch">
              {/* preview */}
              <PreviewCommonPost
                snsType={snsType}
                posted_method={mediaTypeEnum}
                mediaData={mediaData}
                body={props.body}
              />

              {/* stat */}
              <div className="hidden flex-1 flex-col items-start gap-4">{props.insight}</div>
            </div>
          ) : (
            <div className="flex items-start gap-10 self-stretch">
              {/* preview */}
              <PreviewCommonPost
                snsType={snsType}
                posted_method={mediaTypeEnum}
                mediaData={mediaData}
                body={props.body}
              />

              {/* stat */}
              <div className="flex flex-1 flex-col items-start gap-4">{props.insight}</div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (snsType === SnsType.TikTok) {
    return (
      <div className="flex w-[1049px] flex-col items-start gap-10">
        <div className="flex flex-col items-start gap-6 self-stretch">
          <span className="text-base font-semibold leading-6">{props.selected}</span>
          <div className="flex items-start gap-10 self-stretch">
            {/* preview */}
            <PreviewCommonPost
              snsType={snsType}
              posted_method={mediaTypeEnum}
              mediaData={mediaData}
              body={props.body}
            />

            {/* stat */}
            <div className="flex w-[577px] flex-1 flex-col items-start gap-4">{props.insight}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
