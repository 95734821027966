import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CreationStatus, GetCreationOutput, PostingType } from '@/api/creation/api';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { Input } from '@/components/Elements/Input';
import { postCreationContents } from '@/hooks/Creation';

import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';

/**
 * 半角#
 * 半角スペース区切り
 * 全角スペース区切り
 * 半角カンマ区切り
 * 改行区切り
 */
const hashtagRegex = /#([^\s,、\n]+)/g;
const countHashtagMatches = (text: string): number => {
  const matches = text.match(hashtagRegex);
  return matches ? matches.length : 0;
};

const postingValidation = {
  [PostingType.Feed]: 2200,
  [PostingType.Reel]: 2200,
  [PostingType.Stories]: 500,
  [PostingType.XPost]: 280,
  [PostingType.XImagePost]: 280,
  [PostingType.TikTok]: 150,
  [PostingType.Youtube]: 5000,
  [PostingType.YoutubeShort]: 2000,
  [PostingType.Other]: 2200,
  [PostingType.OtherVideo]: 2200,
};

// NOTE: useFieldArrayでFile型のみだとエラーを起こすのでFileをラップした型を使用している
type WrapFile = {
  file: File;
};

type ContentType = {
  text: string;
  files: WrapFile[];
  mediaURL: string;
};

const MAX_FILE_COUNT = 5;
const MAX_FILE_SIZE = 31457280;
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];

const checkBlankFile = (file: File) => {
  return !file.name && !file.size && !file.type;
};

type ModalContentType = {
  isOpen: boolean;
  creationId: string;
  postingType: PostingType;
  closeModal: () => void;
  // TODO: 投稿制作追加API追加されたらデータ再取得に変更
  // updatePost: () => void;
  setPostDetail: React.Dispatch<React.SetStateAction<GetCreationOutput>>;
};

export const ContentModal = (props: ModalContentType) => {
  const [hashTagCount, setHashTagCount] = useState<number>(0);
  const schema = yup.object().shape({
    text: yup
      .string()
      .required('テキストを入力してください')
      .max(
        postingValidation[props.postingType] as number,
        `${postingValidation[props.postingType]}文字以下で入力してください`
      ),
    mediaURL: yup.string().url('有効なURLを入力してください'),
    files: yup.array().of(
      yup.object().shape({
        file: yup
          .mixed()
          .nullable()
          .test('fileType', '許可されていないファイル形式です', (value) => {
            if (!value || !value.type) return true; // ファイルがない場合は検証をスキップ
            return ALLOWED_FILE_TYPES.includes(value.type);
          })
          .test(
            'fileSize',
            `ファイルサイズは${Math.floor(MAX_FILE_SIZE / (1024 * 1024))}MB以下にしてください`,
            (value) => {
              if (!value || !value.size) return true; // ファイルがない場合は検証をスキップ
              return value.size < MAX_FILE_SIZE;
            }
          ),
      })
    ),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ContentType>({
    resolver: yupResolver(schema),
    defaultValues: {
      text: '',
      files: [{ file: {} as File }] as WrapFile[],
      mediaURL: '',
    },
  });

  const { fields, update, append, replace, remove } = useFieldArray({
    control,
    name: 'files',
  });

  const submit = async (data: ContentType) => {
    try {
      const files = data.files.filter((wrapFile) => wrapFile.file.size).map((wrapFile) => wrapFile.file);
      const res = await postCreationContents(props.creationId, data.text, files, data.mediaURL);
      console.log('確認(response)', res);
      props.setPostDetail((prev) => {
        return {
          ...prev,
          status: CreationStatus.ContentsApproved,
        };
      });
      reset();
      props.closeModal();
    } catch (e: any) {
      return;
    }
  };

  const handleOnDrop = (index: number) => (files: File[]) => {
    update(index, { file: files[0] });
    // 上限は5枚まで
    if (index + 1 >= MAX_FILE_COUNT) return;
    append({ file: {} as File });
  };

  useEffect(() => {
    watch((value) => {
      setHashTagCount(countHashtagMatches(value.text ?? ''));
    });
  }, [watch, setHashTagCount]);

  const handleMoveLeft = (index: number) => {
    if (index == 0) return;
    const files = [...fields];
    const [sortFile] = files.splice(index, 1);
    if (checkBlankFile(sortFile.file)) return;
    files.splice(index - 1, 0, sortFile);
    replace(files);
  };

  const handleMoveRight = (index: number) => {
    const rightFile = fields[index + 1];
    if (checkBlankFile(rightFile.file)) return;
    const files = [...fields];
    const [sortFile] = files.splice(index, 1);
    files.splice(index + 1, 0, sortFile);
    replace(files);
  };

  const handleDelete = (index: number) => {
    remove(index);
  };

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-full w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                <form onSubmit={handleSubmit(submit)}>
                  <div className="h-full w-[720px] bg-white">
                    <div className="mb-[16px] flex h-full w-[720px]">
                      <div className="h-full w-[696px]">
                        <div className="mb-[24px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">コンテンツを追加</span>
                        </div>
                        <div className="mb-[32px] h-full w-[693px]">
                          <div className="h-full w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="mt-3 h-full">
                              {props.postingType == PostingType.Youtube ? (
                                <div className="mb-[32px] h-[64px] w-[693px]">
                                  <Input
                                    type="text"
                                    label="YouTubeの動画URL"
                                    classnamelabel="text-sm font-semibold leading-5 text-gray-700 text-left"
                                    placeholder="https://example.com"
                                    isPlaceHolderRight={false}
                                    className="h-[40px] w-[693px] px-[16px] py-[8px]"
                                    {...register('mediaURL')}
                                  ></Input>
                                  <div className="text-left">
                                    {errors.text && <p className="text-red-500">{errors.mediaURL?.message}</p>}
                                  </div>
                                </div>
                              ) : (
                                <div className="grid grid-cols-3 gap-5">
                                  {fields.map((file: WrapFile, index: number) => {
                                    return (
                                      <div key={crypto.randomUUID()}>
                                        <div className="flex w-full justify-between">
                                          <div>
                                            <p>{index + 1}枚目</p>
                                          </div>
                                          <div className="flex h-[28px] w-[64px] items-center justify-between">
                                            {index == 0 || checkBlankFile(file.file) ? (
                                              <ChevronLeftIcon width={28} height={28} fill="#E5E7EB" />
                                            ) : (
                                              <ChevronLeftIcon
                                                width={28}
                                                height={28}
                                                fill="#374151"
                                                onClick={() => {
                                                  handleMoveLeft(index);
                                                }}
                                                className="cursor-pointer"
                                              />
                                            )}
                                            {index + 1 == MAX_FILE_COUNT ||
                                            checkBlankFile(file.file) ||
                                            checkBlankFile(fields[index + 1].file) ? (
                                              <ChevronRightIcon width={28} height={28} fill="#E5E7EB" />
                                            ) : (
                                              <ChevronRightIcon
                                                width={28}
                                                height={28}
                                                fill="#374151"
                                                onClick={() => {
                                                  handleMoveRight(index);
                                                }}
                                                className="cursor-pointer"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="relative flex h-[209px] w-[211px] flex-col items-center justify-center">
                                          {!checkBlankFile(file.file) && (
                                            <div className="absolute right-0 top-2 flex items-center justify-center">
                                              <CloseIcon
                                                className="mr-1 mt-1 cursor-pointer rounded-lg bg-slate-100"
                                                width={24}
                                                height={24}
                                                fill="#9CA3AF"
                                                onClick={() => {
                                                  handleDelete(index);
                                                }}
                                              />
                                            </div>
                                          )}
                                          <Dropzone onDrop={handleOnDrop(index)}>
                                            {({ getRootProps, getInputProps }) => {
                                              return (
                                                <div
                                                  className="mt-2 flex h-full w-full justify-center rounded-lg border border-dashed border-gray-900/25"
                                                  {...getRootProps()}
                                                >
                                                  <div className="flex h-full w-full flex-col justify-center overflow-hidden">
                                                    {file.file.size ? (
                                                      file.file.type == 'video/mp4' ? (
                                                        <video
                                                          controls
                                                          className="rounded-lg object-contain text-gray-400"
                                                          src={URL.createObjectURL(file.file)}
                                                        >
                                                          <track kind="captions" />
                                                        </video>
                                                      ) : (
                                                        <img
                                                          className="rounded-lg object-contain text-gray-400"
                                                          src={URL.createObjectURL(file.file)}
                                                          alt=""
                                                        />
                                                      )
                                                    ) : (
                                                      <>
                                                        <div className="mt-4 flex flex-col justify-center text-sm leading-6 text-gray-600">
                                                          <div className="flex items-center justify-center">
                                                            <div className="flex h-[48px] w-[48px] items-center justify-center">
                                                              <AddIcon width={36} height={36} />
                                                            </div>
                                                          </div>
                                                          <p className="pl-1 text-sm font-medium">
                                                            ドラッグ&ドロップもしくは
                                                          </p>
                                                          <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer rounded-md bg-white text-sm font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                            style={{ color: '#007CC2' }}
                                                          >
                                                            <span>アップロード</span>
                                                            <input
                                                              id="file-upload"
                                                              type="file"
                                                              className="sr-only"
                                                              {...getInputProps()}
                                                            />
                                                          </label>
                                                        </div>
                                                        <p className="text-xs leading-5 text-gray-600">
                                                          対応ファイルはPNG, JPG, GIF, mp4
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Dropzone>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            {errors &&
                              errors.files &&
                              errors.files.map &&
                              errors.files.map((error, index) => {
                                return (
                                  <p key={error?.message} className="mt-1 text-left text-red-500">
                                    {`${index + 1}枚目: ${error?.file?.message ?? ''}`}
                                  </p>
                                );
                              })}
                          </div>
                        </div>
                        <div className="mb-[32px] h-[216px] w-[693px]">
                          <div className="mb-[4px] h-[20px] w-[56px]">
                            <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                          </div>
                          <textarea className="h-[192px] w-[693px] rounded px-[16px] py-[8px]" {...register('text')} />
                          <div className="mt-3 flex flex-col">
                            <div className="text-left">
                              {errors.text && <p className="text-red-500">{errors.text.message}</p>}
                            </div>
                            <div className="text-left">
                              <p className="text-gray-500">#ハッシュタグ数 {hashTagCount}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <CloseIcon
                          width={24}
                          height={24}
                          fill="#9CA3AF"
                          onClick={() => {
                            props.closeModal();
                          }}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => {
                          props.closeModal();
                        }}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        type="submit"
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
