import AvatarImage from './assets/Avatar2.png';
import { ReactComponent as HeartTwitterIcon } from './assets/bx-heart-twitter.svg';
import { ReactComponent as MessageIcon } from './assets/bx-message.svg';
import { ReactComponent as RepeatIcon } from './assets/bx-repeat.svg';

export const PRSTProductionManagementContentsApprovingTwitterNoImageView = () => {
  return (
    <div className="h-[265px] w-[263px]">
      <div className="mb-[5px]">
        <div className="mb-[10px] flex items-center">
          <img src={AvatarImage} alt="" className="mr-[5px]" />
          <span className="mr-[5px] whitespace-nowrap text-[9px] font-medium">1122329737</span>
          <span className="whitespace-nowrap text-[9px] font-medium text-[#0D0D0D]">@LIDDELL_official</span>
        </div>
        <div className="mb-[5px] w-[263px]">
          <p className="text-[8px] font-normal leading-3">
            「Canon360」
            <br />
            来週からイベントを開催！
            <br />
            賞金もあります...💌
            <br />
            <br />
            ----------------------------------------------------
            <br />
            <br />
            「バレンタインデーフォトイベント」
            <br />
            期間：2021/02/01 - 2/14
            <br />
            あなたの「愛するもの」をCanonのカメラで撮ってください！
            <br />
            愛するものはなんでも構いません!
            <br />
            タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
            <br />
            賞金15万円
            <br />
            <br />
            ----------------------------------------------------
            <br />
            <br />
            ぜひ参加してくださいね！
          </p>
          <p className="text-[8px] font-normal leading-3 text-[#0047FF8C]">#LANVINenBleu,</p>
        </div>
      </div>
      <p className="mb-[5px] text-[8px] font-medium leading-3 text-gray-400">2022年8月29日 10:58</p>
      <div className="mb-[5px] w-[279px] border-t border-gray-200"></div>
      <div className="flex h-[13px] w-[70px] items-center justify-between">
        <div className="flex h-[13px] w-[20px] items-start justify-between">
          <MessageIcon fill="#9CA3AF" />
          <span className="text-[8px] font-medium leading-3 text-gray-400">1</span>
        </div>
        <div className="flex h-[13px] w-[21px] items-start justify-between">
          <RepeatIcon fill="#9CA3AF" />
          <span className="text-[8px] font-medium leading-3 text-gray-400">1</span>
        </div>
        <div className="flex h-[13px] w-[21px] items-start justify-between">
          <HeartTwitterIcon fill="#9CA3AF" />
          <span className="text-[8px] font-medium leading-3 text-gray-400">1</span>
        </div>
      </div>
    </div>
  );
};
