import { CalendarIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  GetOtherDetailOutput,
  ProjectStatus,
  SnsType,
  FractionType,
  ProjectKpiItem,
  GetOtherApplicantsRowsEnum,
  GetOtherApplicantsOrderEnum,
  GetOtherApplicantsStatusEnum,
} from '@/api/other';
import { GetProjectOverviewOutput } from '@/api/project';
import { ReactComponent as ApplicantIcon } from '@/assets/icons/bx-group.svg';
import { MainLayout } from '@/components/Layout/MainLayout';
import ApplicantContent, {
  ApplicantProps,
  GetProjectApplicantsOutput,
} from '@/components/ProjectManagements/Applicant/Contents/ApplicantContent';
import { ApplicantSchedule } from '@/components/ProjectManagements/Applicant/Contents/common/Types/common_types';
import { PhaseNavigation } from '@/components/ProjectManagements/PhaseNavigation/Components/PhaseNavigation';
import { TabView } from '@/components/ProjectManagements/PhaseNavigation/Components/TabView';
import { phaseInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/PhaseNavigationInterfaces';
import { TabInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/TabViewInterfaces';
import ProjectOverview from '@/components/ProjectManagements/ProjectOverview/Components/ProjectOverview';
import { projectOverview } from '@/components/ProjectManagements/ProjectOverview/Interfaces/ProjectOverViewInterfaces';
import ScheduleContent, { ScheduleProps } from '@/components/ProjectManagements/ProjectSchedule/Components/Schedule';
import {
  projectSchedule,
  ScheduleList,
} from '@/components/ProjectManagements/ProjectSchedule/Interface/ProjectScheduleInterface';
import { PROJECT_TYPES } from '@/config';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
import { getApplicantData, GetOtherApplicantsOrderByEnum, getOtherDetail, getOtherSchedule } from '@/hooks/Other';
import { getProjectOverviews } from '@/hooks/ProjectInfo';
import { formatYYYYMMDD } from '@/utils/format';
import {
  convertStatusToStep,
  convertStatusToDisplayLabelForOther,
  convertStatusToStepForOther,
} from '@/utils/projectUtils';

// import { DummyOtherProjectApplicantItem } from '../../project_management/dummy';
import Recruitment from '../components/details/Recruitment';
import { PageHeader } from '../components/layouts/headers';
import { projectDetail } from '../interfaces/otherProjectInterface';

const initKPIItems: ProjectKpiItem[] = [
  {
    name: 'リーチ数',
    comment: '-',
    isFixed: false,
  },
  {
    name: 'エンゲージメント数',
    comment: '-',
    isFixed: false,
  },
  {
    name: 'エンゲージメント率',
    comment: '-',
    isFixed: false,
  },
  {
    name: 'フォロワー増加数',
    comment: '-',
    isFixed: false,
  },
];

export type projectDetailOther = projectDetail & {
  snsType: SnsType;
  featuredImage: string | null;
  projectDetailOther: GetOtherDetailOutput;
  setProjectDetailSpirit: React.Dispatch<React.SetStateAction<GetOtherDetailOutput>>;
  fetchSpiritProjectDetail: () => Promise<AxiosResponse<GetOtherDetailOutput, any> | undefined>;
  minimumReward: number | undefined;
  setMinimumReward: React.Dispatch<React.SetStateAction<number | undefined>>;
  standardReward: number | undefined;
  setStandardReward: React.Dispatch<React.SetStateAction<number | undefined>>;
  displayRewards?: number | undefined;
  setDisplayRewards?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const initOtherDetail: GetOtherDetailOutput = {
  header: {
    projectGroupName: '',
    projectName: '',
    projectType: 'other',
    projectStatus: ProjectStatus.Draft,
    teamId: '',
    teamName: '',
    teamManagerName: '',
    scheduleStartedAt: '',
    scheduleEndedAt: '',
    managerId: '',
    managerName: '',
    feedbackStatus: 'ready',
  }, // ProjectDetailHeader の初期値
  title: '', // string 型の初期値
  snsType: SnsType.Instagram, // SnsType の初期値
  featuredImage: '', // string 型の初期値
  detailImages: [], // ProjectImageRow 型の配列の初期値
  tags: [], // string 型の配列の初期値
  selectionStartedAt: '', // string 型の初期値
  selectionEndedAt: '', // string 型の初期値
  summary: { adoptionPlan: 0, rewardsType: 0, rewards: 0, genres: [], description: '' }, // ProjectSummaryBase の初期値
  details: {
    notice: [],
    isSecret: false,
    companyName: '',
  }, // ProjectSummaryDetails の初期値
  schedules: [], // ProjectSchedule 型の配列の初期値
  kpi: { items: initKPIItems }, // ProjectKpi の初期値
  estimate: {
    isCalculated: false, // boolean 型の初期値
    items: {
      // ProjectEstimateItemGroups の初期値
      fixedItems: [], // ProjectEstimateItem の配列の初期値
      optionFixedItems: [], // ProjectEstimateItem の配列の初期値
      optionFreeItems: [], // ProjectEstimateItem の配列の初期値
    },
    optionChoices: [], // ProjectOptionChoice の配列の初期値
    subtotal: 0, // number 型の初期値
    tax: 0, // number 型の初期値
    total: 0, // number 型の初期値
    taxRate: 0, // number 型の初期値
    fractionType: FractionType.Floor, // FractionType の初期値
    calculateReward: {
      // ProjectCalculateReward の初期値
      isCalculated: false,
      standard: 0,
      minimum: 0,
    },
  }, // ProjectEstimate の初期値
};

export function OtherProject(): React.ReactElement {
  // 応募者選定期間抽出用
  // from: インフルエンサーの募集期間の開始
  // to: インフルエンサーの選定期間の終了
  function filterByOtherSchedule(items: ScheduleList): ApplicantSchedule {
    const recruitmentPeriod = items.find((item) => item.label.trim() == 'インフルエンサー募集期間');
    const selectionPeriod = items.find((item) => item.label.trim() == 'インフルエンサー選定期間');
    const schedule: ApplicantSchedule = {
      from: formatYYYYMMDD(recruitmentPeriod?.from || ''),
      to: formatYYYYMMDD(selectionPeriod?.to || ''),
    };
    return schedule;
  }
  const search = useLocation().search;
  const [tabInfo, setTabInfo] = useState<TabInfo[]>([]);

  // query から値を取得 ここから
  const queryString = new URLSearchParams(search);
  // const projectStatus: string = queryString.get('status') || ProjectStatus.Draft;
  const queryTab: string = queryString.get('tab') || 'overview';
  const projectId = queryString.get('projectId') || '';

  // 案件概要
  const [outlineInfo, setOutlineInfo] = useState<projectOverview>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    scheduleStartedAt: null,
    scheduleEndedAt: null,
    budget: 0,
    kpi: [],
    orderInfo: {
      companyName: '',
      address: '',
      capital: '',
      represent: '',
      established: '',
      business: '',
    },
    otherFlg: false,
  });
  // 応募者
  const [applicant, setApplicant] = useState<GetProjectApplicantsOutput>({
    from: 0,
    to: 0,
    perPage: 0,
    totalRows: 0,
    totalPages: 0,
    // rows: DummyOtherProjectApplicantItem,
    rows: [],
    count: {
      applicant: 0,
      approved: 0,
      reserved: 0,
      canBeReserver: 0,
    },
    rewards: 0,
    total: 0,
    adoptionPlan: 0,
    taxRate: 0,
    fractionType: '',
  });
  const [order, setOrder] = useState<GetOtherApplicantsOrderEnum>('asc');
  const [applicantListCurrentPage, setApplicantListCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<GetOtherApplicantsRowsEnum>(10);
  const [orderBy, setOrderBy] = useState<GetOtherApplicantsOrderByEnum>('idx');
  const [selectionStatus, setSelectionStatus] = useState<GetOtherApplicantsStatusEnum>();

  // 案件詳細
  const [otherDetail, setOtherDetail] = useState<GetOtherDetailOutput>(initOtherDetail);

  // スケジュール
  const [projectSchedule, setProjectSchedule] = useState<projectSchedule>({
    projectStatus: convertStatusToStep(otherDetail.header.projectStatus),
    scheduleData: [],
    projectType: '',
  });

  const [projectScheduleDate, setProjectScheduleDate] = useState<{
    projectScheduleStartedAt: string;
    projectScheduleEndedAt: string;
  }>({ projectScheduleStartedAt: '', projectScheduleEndedAt: '' });

  const [snsType, setSnsType] = useState<string | undefined>(undefined);

  // その他案件一覧にて画面上ステータス選択時、選択したステータスにてフィルタ、ページネーションが再作成されること
  const pahse: phaseInfo[] = [
    {
      name: convertStatusToDisplayLabelForOther(ProjectStatus.Draft),
      isCurrent:
        ProjectStatus.Draft == otherDetail.header.projectStatus ||
        ProjectStatus.Review == otherDetail.header.projectStatus,
      isDone:
        convertStatusToStepForOther(otherDetail.header.projectStatus) >
        convertStatusToStepForOther(ProjectStatus.Draft),
    },
    {
      name: convertStatusToDisplayLabelForOther(ProjectStatus.Approved),
      isCurrent: ProjectStatus.Approved == otherDetail.header.projectStatus,
      isDone:
        convertStatusToStepForOther(otherDetail.header.projectStatus) >
        convertStatusToStepForOther(ProjectStatus.Approved),
    },
    {
      name: convertStatusToDisplayLabelForOther(ProjectStatus.FinalConfirmation),
      isCurrent: ProjectStatus.FinalConfirmation == otherDetail.header.projectStatus,
      isDone:
        convertStatusToStepForOther(otherDetail.header.projectStatus) >
        convertStatusToStepForOther(ProjectStatus.FinalConfirmation),
    },
    {
      name: convertStatusToDisplayLabelForOther(ProjectStatus.Implementation),
      isCurrent: ProjectStatus.Implementation == otherDetail.header.projectStatus,
      isDone:
        convertStatusToStepForOther(otherDetail.header.projectStatus) >
        convertStatusToStepForOther(ProjectStatus.Implementation),
    },
    {
      name: convertStatusToDisplayLabelForOther(ProjectStatus.Completion),
      isCurrent: ProjectStatus.Completion == otherDetail.header.projectStatus,
      isDone:
        convertStatusToStepForOther(otherDetail.header.projectStatus) >
        convertStatusToStepForOther(ProjectStatus.Completion),
    },
  ];

  const getApplicantProp =
    (
      projectId: string,
      order: GetOtherApplicantsOrderEnum,
      detail?: GetOtherDetailOutput,
      orderBy?: GetOtherApplicantsOrderByEnum,
      status?: GetOtherApplicantsStatusEnum
    ) =>
    async (rows: GetOtherApplicantsRowsEnum, page: number) => {
      try {
        const applicantData = await getApplicantData(projectId, rows, page, order, orderBy, status);
        if (applicantData) {
          const applicantDataWithRewards = {
            from: applicantData.from,
            to: applicantData.to,
            perPage: applicantData.perPage,
            totalRows: applicantData.totalRows,
            totalPages: applicantData.totalPages,
            rows: applicantData.rows,
            count: applicantData.count,
          };
          let applicantDetail = {};
          if (Object.keys(detail ?? {}).length === 0 || detail === undefined) {
            const projectDetail = await getOtherDetail(projectId);
            applicantDetail = {
              rewards: projectDetail.summary.rewards ?? 0,
              total: projectDetail.estimate.total ?? 0,
              adoptionPlan: projectDetail.summary.adoptionPlan ?? 0,
              taxRate: projectDetail.estimate.taxRate ?? 0,
              fractionType: projectDetail.estimate.fractionType ?? '',
            };
          } else {
            console.log('errorDetail:', detail);
            applicantDetail = {
              rewards: detail.summary.rewards,
              total: detail.estimate.total,
              adoptionPlan: detail.summary.adoptionPlan,
              taxRate: detail.estimate.taxRate,
              fractionType: detail.estimate.fractionType,
            };
          }
          setApplicant((prev) => {
            return { ...prev, ...applicantDataWithRewards, ...applicantDetail };
          });
        }
      } catch (error: any) {
        Sentry.captureException(error);
      }
    };

  useEffect(() => {
    (async () => {
      try {
        // 案件概要
        const projectOverview: GetProjectOverviewOutput = await getProjectOverviews(projectId);
        console.log('projectOvervew', projectOverview);
        // 案件詳細
        const projectDetailOutput: GetOtherDetailOutput = await getOtherDetail(projectId);
        setOtherDetail(projectDetailOutput);
        console.log('projectDetailOutput', projectDetailOutput);
        // 案件スケジュール
        const projectSchedule = await getOtherSchedule(projectId);
        console.log('projectSchedule', projectSchedule);

        const convertedKpiArray: string[] = projectOverview.kpi.map((element) => `・${element}\n`);
        await getApplicantProp(
          projectId,
          order,
          projectDetailOutput,
          orderBy,
          selectionStatus
        )(GetOtherApplicantsRowsEnum.NUMBER_10, 1);
        if (projectOverview) {
          setOutlineInfo({
            marketBackground: projectOverview.marketBackground,
            problem: projectOverview.problem,
            purpose: projectOverview.purpose,
            measure: projectOverview.measure,
            scheduleStartedAt: projectOverview.scheduleStartedAt,
            scheduleEndedAt: projectOverview.scheduleEndedAt,
            budget: projectOverview.budget,
            kpi: convertedKpiArray,
            orderInfo: projectOverview.orderInfo,
            otherFlg: true,
          });
        }
        if (projectSchedule) {
          setProjectSchedule({
            projectStatus: convertStatusToStepForOther(otherDetail.header.projectStatus),
            scheduleData: projectSchedule,
            projectType: PROJECT_TYPES.OTHER,
          });
        }

        setProjectScheduleDate((prevState) => ({
          ...prevState,
          projectScheduleStartedAt: projectOverview.scheduleStartedAt ?? '',
          projectScheduleEndedAt: projectOverview.scheduleEndedAt ?? '',
        }));

        if (projectDetailOutput) {
          setSnsType(projectDetailOutput.snsType);
        }
      } catch (error: any) {
        Sentry.captureException(error);
      }
    })();
  }, [projectId]);

  useEffect(() => {
    getApplicantProp(projectId, order, otherDetail, orderBy, selectionStatus)(perPage, applicantListCurrentPage);
  }, [order, applicantListCurrentPage, orderBy, selectionStatus]);

  useEffect(() => {
    setTabInfo([
      {
        title: '案件概要',
        icon: DocumentTextIcon,
        component: (props: projectOverview) => <ProjectOverview {...props} />,
        componentProps: outlineInfo,
        query: 'overview',
        isActive: queryTab === 'overview',
      },
      {
        title: '募集内容詳細',
        icon: PencilIcon,
        component: (props: projectDetail) => <Recruitment {...props} />,
        componentProps: {
          projectId,
          otherDetail,
          setOtherDetail,
        },
        query: 'detail',
        isActive: queryTab === 'detail',
      },
      {
        title: 'スケジュール',
        icon: CalendarIcon,
        component: (props: ScheduleProps) => <ScheduleContent {...props} />,
        componentProps: {
          projectStatus: otherDetail.header.projectStatus,
          scheduleData: projectSchedule === undefined ? [] : projectSchedule.scheduleData,
        },
        query: 'schedule',
        isActive: queryTab === 'schedule',
      },
      {
        title: '応募者',
        icon: ApplicantIcon,
        component: (props: ApplicantProps) => <ApplicantContent {...props} />,
        componentProps: {
          applicant: applicant,
          setApplicant: setApplicant,
          projectId: projectId,
          getApplicantData: getApplicantProp(projectId, order, otherDetail, orderBy, selectionStatus),
          sortData: order,
          setSortData: setOrder,
          applicantListCurrentPage: applicantListCurrentPage,
          setApplicantListCurrentPage: setApplicantListCurrentPage,
          schedule: filterByOtherSchedule(projectSchedule?.scheduleData || []),
          perPage: perPage,
          setPerPage: setPerPage,
          setSelectionStatus: setSelectionStatus,
          setOrderWord: setOrderBy,
          //応募者のsnsタイプ追加したら消す
          snsType: snsType,
          status: otherDetail.header.projectStatus,
        },
        query: 'applicant',
        isActive: queryTab === 'applicant',
        className: 'fill-current',
      },
    ]);
  }, [outlineInfo, queryTab, projectSchedule, applicant, projectId, otherDetail]);

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.other,
      }}
    >
      <div className="flex h-[auto] w-[1440px] bg-gray-50">
        <div className="h-full w-[1181px] bg-gray-50">
          <div>
            {PageHeader(
              otherDetail.header.projectGroupName ?? '',
              otherDetail.header.projectName ?? '',
              projectScheduleDate
            )}
          </div>
          <div>{PhaseNavigation(pahse)}</div>
          <div className="pt-[10px] ">
            <div className="h-full w-[1181px] border-t-[1px] border-gray-200">
              {tabInfo.length > 0 && <TabView tabInfo={tabInfo} />}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
