import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  AffectOutput,
  CreationAccountRow,
  DefaultApi,
  GetCreationAccountMeasuresOrderEnum,
  GetCreationAccountMeasuresOutput,
  GetCreationAccountMeasuresRowsEnum,
  GetCreationOutput,
  PostCreationAccountInput,
  PostCreationInput,
  PostMeasureInput,
  PostCreationApproveOutput,
  GetCreationsRowsEnum,
  GetCreationsOrderEnum,
  GetCreationsOutput,
  CreationPreviewItem,
} from '@/api/creation/api';
import { axiosInstance } from '@/utils/axiosInstance';

/**
 * 制作管理アカウント一覧を取得するAPIです。 <br>制作管理アカウント一覧を返却します。
 * @summary 【Mock】制作管理アカウント一覧取得API
 */
export const getCreationAccounts = async () => {
  let response: AxiosResponse<CreationAccountRow[]> = {} as AxiosResponse<CreationAccountRow[]>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getCreationAccounts();
    return response.data as CreationAccountRow[];
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 制作管理アカウントを登録するAPIです。 <br>制作管理アカウントを登録します。
 * @summary 【Mock】制作管理アカウント登録API
 */
export const postCreationAccount = async (postCreationAccountInput?: PostCreationAccountInput) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postCreationAccount(postCreationAccountInput);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 制作管理施策一覧を取得するAPIです。 <br>制作管理施策一覧を返却します。
 * @summary 【Mock】制作管理施策一覧取得API
 */
export const getCreationAccountMeasures = async (
  accountId: string,
  rows?: GetCreationAccountMeasuresRowsEnum,
  page?: number,
  order?: GetCreationAccountMeasuresOrderEnum,
  orderBy?: string
) => {
  let response: AxiosResponse<GetCreationAccountMeasuresOutput> = {} as AxiosResponse<GetCreationAccountMeasuresOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getCreationAccountMeasures(accountId, rows, page, order, orderBy);
    return response.data as GetCreationAccountMeasuresOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 制作管理施策を登録するAPIです。 <br>制作管理施策を登録します。
 * @summary 【Mock】制作管理施策登録API
 * @param {PostMeasureInput} [postMeasureInput]
 */
export const postMeasure = async (accountId: string, postMeasureInput?: PostMeasureInput) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postMeasure(accountId, postMeasureInput);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 投稿制作を作成するAPIです。 <br>投稿制作を作成します。
 * @summary 【Mock】投稿制作作成API
 */
export const postCreation = async (measureId: string, postCreationInput: PostCreationInput) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postCreation(measureId, postCreationInput);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 投稿制作一覧を取得するAPIです。 <br>投稿制作一覧を返却します。
 * @summary 【Mock】投稿制作一覧取得API
 */
export const getCreations = async (
  measureId: string,
  rows?: GetCreationsRowsEnum,
  page?: number,
  order?: GetCreationsOrderEnum,
  orderBy?: string
) => {
  let response: AxiosResponse<GetCreationsOutput> = {} as AxiosResponse<GetCreationsOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getCreations(measureId, rows, page, order, orderBy);
    return response.data as GetCreationsOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 指定した投稿制作の削除を行うAPIです。
 * @summary 【Mock】投稿制作削除API
 * @param {string} creationId 投稿制作ID
 */
export const deleteCreation = async (creationId: string) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.deleteCreation(creationId);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 投稿制作の施策内のプレビューをします。
 * @summary 【Mock】投稿制作並び順プレビューAPI
 * @param {string} measureId 制作管理施策ID
 */
export const getCreationPreview = async (measureId: string) => {
  let response: AxiosResponse<CreationPreviewItem[]> = {} as AxiosResponse<CreationPreviewItem[]>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getCreationPreview(measureId);
    return response.data as CreationPreviewItem[];
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * イメージ案を削除するAPIです。 <br>イメージ案IDを指定してイメージ案を削除します。
 * @summary 【Mock】イメージ案削除API
 */
export const deleteCreationImage = async (creationId: string, contentsId: string) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.deleteCreationImage(creationId, contentsId);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 投稿制作詳細を取得するAPIです。 <br>投稿制作詳細を返却します。
 * @summary 【Mock】投稿制作詳細取得API
 */
export const getCreation = async (creationId: string) => {
  let response: AxiosResponse<GetCreationOutput> = {} as AxiosResponse<GetCreationOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getCreation(creationId);
    return response.data as GetCreationOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * コンテンツを作成するAPIです。 <br>コンテンツを作成します。
 * @summary 【Mock】コンテンツ作成API
 */
export const postCreationContents = async (
  creationId: string,
  description: string,
  media?: Array<File>,
  movieUrl?: string
) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postCreationContents(creationId, description, media, movieUrl);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 投稿制作を承認するAPIです。 <br>コンテンツIDを指定して投稿制作を承認します。
 * @summary 【Mock】投稿制作承認API
 * @memberof DefaultApi
 */
export const postCreationApprove = async (creationId: string, contentsId: string) => {
  let response: AxiosResponse<PostCreationApproveOutput> = {} as AxiosResponse<PostCreationApproveOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postCreationApprove(creationId, contentsId);
    return response.data as PostCreationApproveOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * イメージ案を作成するAPIです。 <br>投稿制作IDを指定してイメージ案を作成します。
 * @summary 【Mock】イメージ案作成API
 */

export const postCreationImage = async (
  creationId: string,
  description: string,
  mediaImages?: Array<File>,
  textImages?: Array<string>,
  movieUrl?: string | null,
  movieDescription?: string | null
) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postCreationImage(
      creationId,
      description,
      mediaImages,
      textImages,
      movieUrl,
      movieDescription
    );
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * イメージ案を編集するAPIです。 <br>イメージ案IDを指定してイメージ案を編集します。
 * @summary 【Mock】イメージ案編集API
 */
export const patchCreationImage = async (
  creationId: string,
  contentsId: string,
  description: string,
  mediaImages?: Array<File>,
  textImages?: Array<string>,
  movieUrl?: string | null,
  movieDescription?: string | null
) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.patchCreationImage(
      creationId,
      contentsId,
      description,
      mediaImages,
      textImages,
      movieUrl,
      movieDescription
    );
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
