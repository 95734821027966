import React from 'react';
import { Link } from 'react-router-dom';

import Help from '@/assets/icons/bx-help-circle.png';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { Button } from '@/components/Elements/Button';
import { DatePicker } from '@/components/Elements/DatePicker/DatePicker';
import { Input } from '@/components/Elements/Input';
import { RadioBox } from '@/components/Elements/RadioBox';
import { SelectMenus } from '@/components/Elements/SelectMenus';
import { TextArea } from '@/components/Elements/TextArea';
import { Tooltip } from '@/components/Elements/Tooltip';

import { ReactComponent as CrossIcon } from '../../assets/Cross.svg';
import { ReactComponent as TrashIcon } from '../../assets/Trash.svg';
import { SelectType } from '../../PopupInterfaces';
import Layout, { Step } from '../Layout';

export default function Store(
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  isTop: boolean,
  isLast: boolean
): React.ReactElement {
  const tooltipText = (
    <span>
      ご提供いただける選択肢の組み合わせをご登録ください。
      <br />
      <Link
        className="text-blue-500"
        to={
          'https://support.influfect.com/hc/ja/articles/8334722994575-Q-プロモーション用の商品が複数ある場合-選択肢-の登録方法がわかりません'
        }
        target="_blank"
      >
        詳細
      </Link>
      をご覧ください。
    </span>
  );
  const description = () => {
    return (
      <div className="pt-6">
        <Step seq="01" title="稼働概要"></Step>
        <div className="pt-6">
          <Input
            label="稼働内容タイトル*"
            helper={`${selected[index].title.length}/100`}
            placeholder="稼働内容タイトル"
            isPlaceHolderRight={false}
            value={selected[index].title}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].title = e.target.value;
              titleValidationCheck(newSelected, index, setSelected);
            }}
            className={
              (selected[index].titleError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : '') + 'pl-[11px] focus:border-white'
            }
          ></Input>
          {selected[index].titleError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].titleError}</div>
          )}
        </div>
        <div className=" pt-6">
          <div className="text-sm font-semibold leading-5 text-gray-700">購入期間*</div>
          <div className="flex">
            <DatePicker
              selectedDate={selected[index].buyingPeriodStart}
              onChange={(date: any) => {
                const newSelected = [...selected];
                newSelected[index].buyingPeriodStart = date;
                setSelected(newSelected);
                buyingPeriodStartValidationCheck(selected, index, setSelected);
              }}
              className={
                selected[index].buyingPeriodStartError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : ''
              }
              width="240px"
            />
            <span className="px-2 pt-2">〜</span>
            <DatePicker
              selectedDate={selected[index].buyingPeriodEnd}
              onChange={(date: any) => {
                const newSelected = [...selected];
                newSelected[index].buyingPeriodEnd = date;
                setSelected(newSelected);
                buyingPeriodEndValidationCheck(selected, index, setSelected);
              }}
              className={
                selected[index].buyingPeriodEndError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : ''
              }
              width="240px"
            />
          </div>
          {selected[index].buyingPeriodStartError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].buyingPeriodStartError}</div>
          )}
          {selected[index].buyingPeriodEndError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].buyingPeriodEndError}</div>
          )}
        </div>
        <div className="pt-6">
          <TextArea
            title="店舗情報*"
            helperText={`${selected[index].storeInfo.length}/2000`}
            maxLength={2000}
            rows={4}
            placeholder="店舗情報"
            value={selected[index].storeInfo}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].storeInfo = e.target.value;
              setSelected(newSelected);
              storeInfoValidationCheck(newSelected, index, setSelected);
            }}
            className={
              (selected[index].storeInfoError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : '') + 'h-[105px] w-[630px]'
            }
          ></TextArea>
          {selected[index].storeInfoError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].storeInfoError}</div>
          )}
        </div>
        <div className="pt-6">
          <div>
            <div>
              <span className="text-sm font-semibold leading-5 text-gray-700">購入エビデンス設定*</span>
            </div>
            <div>
              <span className="text-gray-500">
                インフルエンサーにWEB・実店舗で購入した商品のレシートや購入履歴を提出してもらう
              </span>
            </div>
          </div>
          <RadioBox
            values={[
              { value: '1', label: '設定しない' },
              { value: '2', label: '設定する' },
            ]}
            value={selected[index].purchaseEvidenceSettings}
            onClick={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].purchaseEvidenceSettings = e.target.value;
              setSelected(newSelected);
            }}
            name={`${index}-Store`}
          ></RadioBox>
        </div>
        {selected[index].purchaseEvidenceSettings === '2' ? (
          <div className="pt-6">
            <Input
              placeholder="例：購入したレシートを提出してください。"
              isPlaceHolderRight={false}
              value={selected[index].purchaseEvidenceText}
              onChange={(e: any) => {
                const newSelected = [...selected];
                newSelected[index].purchaseEvidenceText = e.target.value;
                setSelected(newSelected);
                purchaseEvidenceTextValidationCheck(newSelected, index, setSelected);
              }}
              className={
                selected[index].purchaseEvidenceTextError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : ''
              }
            ></Input>
            {selected[index].purchaseEvidenceTextError !== undefined && (
              <div className="pt-2 text-base text-red-400">{selected[index].purchaseEvidenceTextError}</div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const itemDescription = () => {
    return (
      <div className="pt-6">
        <Step seq="02" title="商品の説明"></Step>
        <div className="pt-6">
          <TextArea
            title="買ってもらう商品説明*"
            helperText={`${selected[index].purchaseItemExplanation.length}/2000`}
            maxLength={2000}
            rows={4}
            placeholder="商品説明"
            value={selected[index].purchaseItemExplanation}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].purchaseItemExplanation = e.target.value;
              setSelected(newSelected);
              purchaseItemExplanationValidationCheck(newSelected, index, setSelected);
            }}
            className={
              (selected[index].purchaseItemExplanationError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : '') + 'h-[105px] w-[630px]'
            }
          ></TextArea>
          {selected[index].purchaseItemExplanationError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].purchaseItemExplanationError}</div>
          )}
        </div>
        <div className="pt-6">
          <Input
            label="買ってもらう商品の金額*"
            placeholder="¥0"
            isPlaceHolderRight={false}
            lastWord="/1人"
            value={selected[index].purchaseItemPrice}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].purchaseItemPrice = e.target.value;
              setSelected(newSelected);
              purchaseItemPriceValidationCheck(newSelected, index, setSelected);
            }}
            className={
              selected[index].purchaseItemPriceError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : ''
            }
            unit="1人"
            width="240px"
          ></Input>
          {selected[index].purchaseItemPriceError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].purchaseItemPriceError}</div>
          )}
        </div>
      </div>
    );
  };

  const item = () => {
    if (selected[index].productDetails.length == 0) {
      selected[index].productDetails.push({
        itemTitle: '',
        itemTitleError: undefined,
        options: ['', ''],
        optionsError: undefined,
        noAddedOptionError: undefined,
        allowInfluencerProductPickup: '0',
        maxSelectionCount: 1,
      });
      setSelected(selected);
    }
    // 商品名を変更する関数
    const handleItemTitleChange = (detailsIndex: number) => (e: any) => {
      const newSelected = [...selected];
      newSelected[index].productDetails[detailsIndex].itemTitle = e.target.value;
      setSelected(newSelected);
    };
    // 選択肢を変更する関数
    const handleOptionChange = (detailsIndex: number, optionIndex: number) => (e: any) => {
      const newSelected = [...selected];
      newSelected[index].productDetails[detailsIndex].options[optionIndex] = e.target.value;
      setSelected(newSelected);
    };
    // 選択肢を追加する関数
    const addOptions = (detailsIndex: number) => {
      const newSelected = [...selected];
      newSelected[index].productDetails[detailsIndex].noAddedOptionError = undefined;
      newSelected[index].productDetails[detailsIndex].options.push('');
      setSelected(newSelected);
    };
    // 選択肢を削除する関数
    const deleteOption = (detailsIndex: number, optionIndex: number) => {
      const newSelected = [...selected];
      if (newSelected[index].productDetails[detailsIndex].options.length === 1) {
        newSelected[index].productDetails[detailsIndex].noAddedOptionError = '選択肢は1つ以上入力してください';
      }
      newSelected[index].productDetails[detailsIndex].options.splice(optionIndex, 1);
      setSelected(newSelected);
    };
    // インフルエンサーの商品選択可否を変更する関数
    const handleInfluencerProductPickupChange = (detailsIndex: number, value: string) => {
      const newSelected = [...selected];
      newSelected[index].productDetails[detailsIndex].allowInfluencerProductPickup = value;
      setSelected(newSelected);
    };
    // 選択可能数を変更する関数
    const handleMaxSelectionCountChange = (detailsIndex: number, value: number) => {
      const newSelected = [...selected];
      newSelected[index].productDetails[detailsIndex].maxSelectionCount = value;
      setSelected(newSelected);
    };

    const clickCrossButton = (detailsIndex: number) => {
      const newSelected = [...selected];
      newSelected[index].productDetails.splice(detailsIndex, 1);
      setSelected(newSelected);
    };

    return (
      <div className="pt-6">
        {selected[index].productDetails.map((item, detailsIndex) => (
          <div className="mt-[10px] flex flex-col justify-start rounded-lg bg-gray-50 p-[20px]" key={detailsIndex}>
            <div className="flex w-full items-center ">
              <div style={{ fontSize: '18px' }}>{detailsIndex + 1}つ目の商品の設定</div>
              {detailsIndex !== 0 && (
                <button className="ml-auto" onClick={() => clickCrossButton(detailsIndex)}>
                  <CrossIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="items-center pt-3">
              <div className="flex w-full flex-col items-start pt-6">
                <label>{'選択肢の説明*'}</label>
                <TextArea
                  helperText={`${item.itemTitle.length}/140`}
                  maxLength={140}
                  rows={4}
                  placeholder="選択肢の説明"
                  value={item.itemTitle}
                  onChange={(date: any) => {
                    handleItemTitleChange(detailsIndex)(date);
                    itemTitleValidationCheck(selected, index, setSelected, detailsIndex);
                  }}
                  className={
                    (selected[index].productDetails[detailsIndex].itemTitleError !== undefined
                      ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                      : '') + 'h-[105px] w-[616px]'
                  }
                ></TextArea>
              </div>
              {selected[index].productDetails[detailsIndex].itemTitleError !== undefined && (
                <div className="pt-2 text-base text-red-400">
                  {selected[index].productDetails[detailsIndex].itemTitleError}
                </div>
              )}
              <div className="flex w-full flex-col items-start pt-6">
                <div className="group relative flex">
                  <label>{'選択肢を追加する*'}</label>
                  <img className="ml-4 h-[20px] w-[20px]" src={Help} alt="" />
                  <Tooltip
                    isSpeechBubble={true}
                    variant="gray"
                    toolTipClassName="px-3 py-2"
                    tooltipText={tooltipText}
                    className="absolute left-[108px] top-[-72px] hidden w-[500px] whitespace-pre-wrap text-xs group-hover:inline-block"
                  ></Tooltip>
                </div>
                {item.options.map((option, optionIndex) => (
                  <div key={optionIndex} className="w-[616px]">
                    <div className="mb-[5px] flex w-full items-center gap-2">
                      <div className="w-[584px]">
                        <Input
                          key={optionIndex}
                          placeholder="種類名、サイズ、カラー、品番など"
                          isPlaceHolderRight={false}
                          value={option}
                          onChange={(date: any) => {
                            handleOptionChange(detailsIndex, optionIndex)(date);
                            optionsValidationCheck(selected, index, setSelected, detailsIndex);
                          }}
                          className={
                            selected[index].productDetails[detailsIndex].optionsError !== undefined &&
                            selected[index].productDetails[detailsIndex].optionsError![optionIndex] !== ''
                              ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                              : ''
                          }
                        />
                      </div>
                      {optionIndex > 0 && (
                        <button onClick={() => deleteOption(detailsIndex, optionIndex)}>
                          <TrashIcon stroke="#9CA3AF" />
                        </button>
                      )}
                    </div>
                    {selected[index].productDetails[detailsIndex].optionsError !== undefined &&
                      selected[index].productDetails[detailsIndex].optionsError![optionIndex] !== '' && (
                        <div className="pb-[5px] text-base text-red-400">
                          {selected[index].productDetails[detailsIndex].optionsError![optionIndex]}
                        </div>
                      )}
                  </div>
                ))}
                {selected[index].productDetails[detailsIndex].noAddedOptionError !== undefined &&
                  selected[index].productDetails[detailsIndex].noAddedOptionError !== '' && (
                    <div className="pb-[5px] text-base text-red-400">
                      {selected[index].productDetails[detailsIndex].noAddedOptionError}
                    </div>
                  )}
                <button
                  className="flex w-[100px] justify-start"
                  style={{ color: '#007CC2' }}
                  onClick={() => addOptions(detailsIndex)}
                >
                  ＋種類を追加
                </button>
              </div>
              <div className="flex w-full flex-col items-start pt-6">
                <label>{'インフルエンサーの商品選択可否*'}</label>
                <div className="flex">
                  <RadioBox
                    name={`${index}-${detailsIndex}-Store`}
                    values={[
                      { label: '選択可能', value: '0' },
                      { label: '選択不可', value: '1' },
                    ]}
                    value={item.allowInfluencerProductPickup}
                    onClick={(e: any) => handleInfluencerProductPickupChange(detailsIndex, e.target.value)}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col items-start pt-6">
                <label>{'選択可能数*'}</label>
                <SelectMenus
                  title=""
                  options={[
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    // { value: '6', label: '6' },
                    // { value: '7', label: '7' },
                    // { value: '8', label: '8' },
                    // { value: '9', label: '9' },
                    // { value: '10', label: '10' },
                  ]}
                  value={item.maxSelectionCount === 0 ? '1' : item.maxSelectionCount?.toString()}
                  onChange={(option: any) => handleMaxSelectionCountChange(detailsIndex, option.value)}
                  widthSize="w-[130px]"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const itemDetail = () => {
    const addProduct = (): void => {
      const newSelected = [...selected];
      newSelected[index].productDetails.push({
        itemTitle: '',
        itemTitleError: undefined,
        options: [''],
        optionsError: undefined,
        noAddedOptionError: undefined,
        allowInfluencerProductPickup: '0',
        maxSelectionCount: 1,
      });
      setSelected(newSelected);
    };

    return (
      <div className="pt-6">
        <Step seq="03" title="商品詳細"></Step>
        {item()}
        <div className="pt-6">
          <div className="flex flex-col items-center justify-center rounded-lg bg-gray-50 py-4">
            <Button variant="secoundary" startIcon={<PlusIcon fill="#007CC2"></PlusIcon>} onClick={addProduct}>
              選択肢を追加する
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Layout
        key={selected[index].id}
        titile="バイイング（店舗）"
        isTop={isTop}
        isLast={isLast}
        selected={selected}
        index={index}
        setSelected={setSelected}
        isOpen={selected[index].isNew === true}
      >
        <div className="w-[696px] border-x-[1px] border-b-[1px] px-6 pb-6">
          {description()}
          {itemDescription()}
          {itemDetail()}
        </div>
      </Layout>
    </>
  );
}

const titleValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean => {
  if (selected[index].title.length === 0) {
    const newSelected = [...selected];
    newSelected[index].titleError = '稼働内容タイトルを入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].title.length > 100) {
    const newSelected = [...selected];
    newSelected[index].titleError = '稼働内容タイトルは100文字以内で入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].titleError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const buyingPeriodStartValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (!selected[index].buyingPeriodStart) {
    const newSelected = [...selected];
    newSelected[index].buyingPeriodStartError = '購入期間（開始日）を入力してください。';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].buyingPeriodEnd && selected[index].buyingPeriodStart! > selected[index].buyingPeriodEnd!) {
    const newSelected = [...selected];
    newSelected[index].buyingPeriodStartError = '購入期間（開始日）は終了日より前に設定してください。';
    setSelected(newSelected);
    newSelected[index].buyingPeriodEndError = undefined;
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].buyingPeriodStartError = undefined;
    if (selected[index].buyingPeriodEnd) {
      newSelected[index].buyingPeriodEndError = undefined;
    }
    setSelected(newSelected);
  }

  return validResult;
};

const buyingPeriodEndValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].buyingPeriodEnd === undefined || selected[index].buyingPeriodEnd === null) {
    const newSelected = [...selected];
    newSelected[index].buyingPeriodEndError = '購入期間（終了日）を入力してください。';
    setSelected(newSelected);
    validResult = false;
  } else if (
    selected[index].buyingPeriodStart &&
    selected[index].buyingPeriodStart! > selected[index].buyingPeriodEnd!
  ) {
    const newSelected = [...selected];
    newSelected[index].buyingPeriodEndError = '購入期間（終了日）は開始日より後に設定してください。';
    setSelected(newSelected);
    newSelected[index].buyingPeriodStartError = undefined;
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].buyingPeriodEndError = undefined;
    if (selected[index].buyingPeriodStart) {
      newSelected[index].buyingPeriodStartError = undefined;
    }
    setSelected(newSelected);
  }

  return validResult;
};

const storeInfoValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].storeInfo.length === 0) {
    const newSelected = [...selected];
    newSelected[index].storeInfoError = '店舗情報を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].storeInfo.length > 2000) {
    const newSelected = [...selected];
    newSelected[index].storeInfoError = '店舗情報は2000文字以内で入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].storeInfoError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const purchaseEvidenceTextValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].purchaseEvidenceSettings === '2') {
    if (selected[index].purchaseEvidenceText.length === 0) {
      const newSelected = [...selected];
      newSelected[index].purchaseEvidenceTextError = '購入エビデンステキストを入力してください';
      setSelected(newSelected);
      validResult = false;
    } else if (selected[index].purchaseEvidenceText.length > 100) {
      const newSelected = [...selected];
      newSelected[index].purchaseEvidenceTextError = '購入エビデンステキストは100文字以内で入力してください';
      setSelected(newSelected);
      validResult = false;
    } else {
      const newSelected = [...selected];
      newSelected[index].purchaseEvidenceTextError = undefined;
      setSelected(newSelected);
    }
  } else {
    const newSelected = [...selected];
    newSelected[index].purchaseEvidenceTextError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const purchaseItemExplanationValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].purchaseItemExplanation.length === 0) {
    const newSelected = [...selected];
    newSelected[index].purchaseItemExplanationError = '買ってもらう商品説明を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].purchaseItemExplanation.length > 2000) {
    const newSelected = [...selected];
    newSelected[index].purchaseItemExplanationError = '買ってもらう商品説明は2000文字以内で入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].purchaseItemExplanationError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const purchaseItemPriceValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].purchaseItemPrice.length === 0) {
    const newSelected = [...selected];
    newSelected[index].purchaseItemPriceError = '買ってもらう商品の金額を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (isNaN(Number(selected[index].purchaseItemPrice))) {
    const newSelected = [...selected];
    newSelected[index].purchaseItemPriceError = '買ってもらう商品の金額は数字を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (Number(selected[index].purchaseItemPrice) < 1) {
    const newSelected = [...selected];
    newSelected[index].purchaseItemPriceError = '買ってもらう商品の金額は1以上を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].purchaseItemPriceError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const itemTitleValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  detailsIndex: number,
  validResult = true
): boolean => {
  if (selected[index].productDetails[detailsIndex].itemTitle.length === 0) {
    const newSelected = [...selected];
    newSelected[index].productDetails[detailsIndex].itemTitleError = '選択肢の説明を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].productDetails[detailsIndex].itemTitle.length > 140) {
    const newSelected = [...selected];
    newSelected[index].productDetails[detailsIndex].itemTitleError = '選択肢の説明は140文字以内で入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].productDetails[detailsIndex].itemTitleError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const optionsValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  detailsIndex: number,
  validResult = true
): boolean => {
  const optionError: string[] = [];
  let noAddedOptionError = undefined;
  if (selected[index].productDetails[detailsIndex].options.length === 0) {
    noAddedOptionError = '選択肢は1つ以上入力してください';
    validResult = false;
  } else {
    selected[index].productDetails[detailsIndex].options.map((option) => {
      if (option.length === 0) {
        optionError.push('選択肢を入力してください');
        validResult = false;
      } else if (option.length > 50) {
        optionError.push('選択肢は50文字以内で入力してください');
        validResult = false;
      } else {
        optionError.push('');
      }
    });
  }
  const newSelected = [...selected];
  newSelected[index].productDetails[detailsIndex].optionsError = optionError;
  newSelected[index].productDetails[detailsIndex].noAddedOptionError = noAddedOptionError;
  setSelected(newSelected);
  return validResult;
};

export function storeValidationCheck(
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean {
  validResult = titleValidationCheck(selected, index, setSelected, validResult);
  validResult = buyingPeriodStartValidationCheck(selected, index, setSelected, validResult);
  validResult = buyingPeriodEndValidationCheck(selected, index, setSelected, validResult);
  validResult = storeInfoValidationCheck(selected, index, setSelected, validResult);
  validResult = purchaseEvidenceTextValidationCheck(selected, index, setSelected, validResult);
  validResult = purchaseItemExplanationValidationCheck(selected, index, setSelected, validResult);
  validResult = purchaseItemPriceValidationCheck(selected, index, setSelected, validResult);
  selected[index].productDetails.map((item, detailsIndex) => {
    validResult = itemTitleValidationCheck(selected, index, setSelected, detailsIndex, validResult);
    validResult = optionsValidationCheck(selected, index, setSelected, detailsIndex, validResult);
  });
  return validResult;
}
