import InstaStoryPlayIcon from './assets/InstaStoryPlayIcon.png';

export const PRSTProductionManagementContentsApprovingInstagramReelView = ({ imagePath }: { imagePath: string }) => {
  return (
    <div className="h-[531px]">
      <img src={imagePath} alt="" className="mb-[20px]" />
      <img src={InstaStoryPlayIcon} alt="" />
    </div>
  );
};
