import { SnsType } from '@/api/project';

import { SelectType } from './PopupInterfaces';
import { ProjectOperationDetailsProps } from './ProjectSplitOperationDetails';

export default function SelectMenu(
  menus: string[],
  selected: SelectType[] | undefined,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  //setSnsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
  menuId: number,
  //SetMenuId: React.Dispatch<React.SetStateAction<number>>,
  sns_name: SnsType,
  isPlaceholderFirst = false,
  setPopup_DetailArgs: React.Dispatch<React.SetStateAction<ProjectOperationDetailsProps>>
) {
  const changeState = (event: any) => {
    if (selected) {
      setSelected([
        ...selected,
        {
          id: menuId,
          key: sns_name,
          name: event.target.value,
          title: '',
          titleError: undefined,
          productInfo: '',
          productInfoError: undefined,
          scheduledDeliveryDate: undefined,
          scheduledDeliveryDateError: undefined,
          postPeriodBegin: undefined,
          postPeriodBeginError: undefined,
          postPeriodEnd: undefined,
          postPeriodEndError: undefined,
          postMethod: '1',
          postImageCount: 0,
          postMovieCount: 0,
          postMovieAndImageCountError: undefined,
          postRequestContent: '',
          postRequestContentError: undefined,
          advertisement: '',
          hashTag: '',
          hashTagsError: undefined,
          hashTags: [],
          hashTagError: undefined,
          optionalHashTag: '',
          optionalHashTagError: undefined,
          optionalHashTags: [],
          optionalHashTagsError: undefined,
          showToHireeOnlyHashTags: false,
          accountTag: '',
          accountTagError: undefined,
          accountTags: [],
          accountTagsError: undefined,
          showToHireeOnlyAccountTags: false,
          locationInfo: '',
          lacationInfoError: undefined,
          showToHireeOnlyLocationInfo: false,
          postUrl: '',
          postUrlError: undefined,
          showToHireeOnlyPostUrl: false,
          movieType: '',
          buyingPeriodStart: undefined,
          buyingPeriodStartError: undefined,
          buyingPeriodEnd: undefined,
          buyingPeriodEndError: undefined,
          storeInfo: '',
          storeInfoError: undefined,
          purchaseEvidenceSettings: '1',
          purchaseEvidenceText: '',
          purchaseEvidenceTextError: undefined,
          purchaseItemExplanation: '',
          purchaseItemExplanationError: undefined,
          purchaseItemPrice: '',
          purchaseItemPriceError: undefined,
          eventDatesStart: undefined,
          eventDatesStartError: undefined,
          eventDatesEnd: undefined,
          eventDatesEndError: undefined,
          eventDetails: '',
          eventDetailsError: undefined,
          shoppingUrl: '',
          shoppingUrlError: undefined,
          downloadUsagePeriodStart: undefined,
          downloadUsagePeriodStartError: undefined,
          downloadUsagePeriodEnd: undefined,
          downloadUsagePeriodEndError: undefined,
          downloadURL: '',
          downloadURLError: undefined,
          detail: '',
          detailError: undefined,
          productDetails: [],
          selectLists: [],
          highlight: '0',
          publicWeek: 1,
          isNew: true,
        },
      ]);
    } else {
      setSelected([
        {
          id: menuId,
          key: sns_name,
          name: event.target.value,
          title: '',
          titleError: undefined,
          productInfo: '',
          productInfoError: undefined,
          scheduledDeliveryDate: undefined,
          scheduledDeliveryDateError: undefined,
          postPeriodBegin: undefined,
          postPeriodBeginError: undefined,
          postPeriodEndError: undefined,
          postPeriodEnd: undefined,
          postMethod: '1',
          postImageCount: 0,
          postMovieCount: 0,
          postMovieAndImageCountError: undefined,
          postRequestContent: '',
          postRequestContentError: undefined,
          advertisement: '',
          hashTag: '',
          hashTagsError: undefined,
          hashTags: [],
          hashTagError: undefined,
          optionalHashTag: '',
          optionalHashTagError: undefined,
          optionalHashTags: [],
          optionalHashTagsError: undefined,
          showToHireeOnlyHashTags: false,
          accountTag: '',
          accountTagError: undefined,
          accountTags: [],
          accountTagsError: undefined,
          showToHireeOnlyAccountTags: false,
          locationInfo: '',
          lacationInfoError: undefined,
          showToHireeOnlyLocationInfo: false,
          postUrl: '',
          postUrlError: undefined,
          showToHireeOnlyPostUrl: false,
          movieType: '',
          buyingPeriodStart: undefined,
          buyingPeriodStartError: undefined,
          buyingPeriodEnd: undefined,
          buyingPeriodEndError: undefined,
          storeInfo: '',
          storeInfoError: undefined,
          purchaseEvidenceSettings: '1',
          purchaseEvidenceText: '',
          purchaseEvidenceTextError: undefined,
          purchaseItemExplanation: '',
          purchaseItemExplanationError: undefined,
          purchaseItemPrice: '',
          purchaseItemPriceError: undefined,
          eventDatesStart: undefined,
          eventDatesStartError: undefined,
          eventDatesEnd: undefined,
          eventDatesEndError: undefined,
          eventDetails: '',
          eventDetailsError: undefined,
          shoppingUrl: '',
          shoppingUrlError: undefined,
          downloadUsagePeriodStart: undefined,
          downloadUsagePeriodStartError: undefined,
          downloadUsagePeriodEnd: undefined,
          downloadUsagePeriodEndError: undefined,
          downloadURL: '',
          downloadURLError: undefined,
          detail: '',
          detailError: undefined,
          productDetails: [],
          selectLists: [],
          highlight: '0',
          publicWeek: 1,
          isNew: true,
        },
      ]);
    }

    setPopup_DetailArgs((prev) => ({
      ...prev,
      snsMenuOpen: false,
      menuId: menuId + 1,
    }));
  };

  return (
    <div>
      <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
        {sns_name}
      </label>

      <select
        id="location"
        name="location"
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        onChange={(event) => changeState(event)}
      >
        {menus.map((option, index) =>
          isPlaceholderFirst && index === 0 ? (
            <option key={index} value="" disabled selected hidden>
              {option}
            </option>
          ) : (
            <option key={index}>{option}</option>
          )
        )}
      </select>
    </div>
  );
}
