import { Dialog, Transition } from '@headlessui/react';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { GetEmeraldPostListRowsEnum } from '@/api/emeraldPost';
import { GetOtherListOrderEnum, GetOtherListOutput, GetOtherListRowsEnum, GetOtherListStatusEnum } from '@/api/other';
import { GetProjectGroupsOutput, ProjectGroup, ProjectStatus } from '@/api/project';
import {
  GetSpiritListOrderEnum,
  GetSpiritListOutput,
  GetSpiritListRowsEnum,
  GetSpiritListStatusEnum,
  ProjectTypeListCounts,
} from '@/api/spirit';
import { ReactComponent as CheckIcon } from '@/assets/icons/bx-check-circle.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements/Button';
import { MainLayout } from '@/components/Layout/MainLayout';
import { PROJECT_TYPES, PROJECT_STATUS } from '@/config/index';
import { ROUTES } from '@/config/path';
import { firstSidebarSelect } from '@/config/sidebar';
// eslint-disable-next-line no-restricted-imports
import Order from '@/features/project/components/Modals/Order';
import { FetchEmeraldPostListInfo } from '@/hooks/EmeraldPost';
import { fetchJaneJohnListInfo } from '@/hooks/JaneJohn';
import { getOtherList } from '@/hooks/Other';
import { insertPostReplicationInfo } from '@/hooks/project';
import { createProjects, fetchProjectGroups } from '@/hooks/ProjectInfo';
import { fetchPRSTListInfo } from '@/hooks/PRST';
import { getSpiritList } from '@/hooks/SPIRIT';
import {
  EmeraldPostDataCountType,
  FetchEmeraldPostListResponse,
  FetchEmeraldPostQueryOrder,
} from '@/types/EmeraldPost';
import { FetchJaneJohnListResponse, FetchJaneJohnQueryParamater, JaneJohnDataCountType } from '@/types/JaneJohn';
import { projectPostDataType } from '@/types/project';
import { FetchPRSTListResponse, FetchPRSTQueryParamater, PRSTDataCountType } from '@/types/PRST';
import {
  convertStatusToDisplayLabelForSpirit,
  convertStatusToDisplayLabelForEmeraldPost,
  convertStatusToDisplayLabelForJaneJohn,
  convertStatusToDisplayLabelForPrst,
  convertStatusToDisplayLabelForOther,
} from '@/utils/projectUtils';
import { selectedSecondSidebarValue } from '@/utils/pureUtils';

import { ListHeader } from '../components/layouts/headers';
import ProjectTable from '../components/project/table';
import { projectInformation, projectTableInformation } from '../interfaces/projectInterface';
interface statusHeader {
  label: string;
  status: string;
  count: number;
}

const emerandStatus = [
  PROJECT_STATUS.POST_DRAFT,
  PROJECT_STATUS.WAIT_POST,
  PROJECT_STATUS.POSTING,
  PROJECT_STATUS.POST_COMPLETION,
];
const prstStatus = [
  PROJECT_STATUS.POST_DRAFT,
  PROJECT_STATUS.APPROVED,
  PROJECT_STATUS.FINAL_CONFIRMATION,
  PROJECT_STATUS.COMPLETION,
];

const projectStatus = [
  PROJECT_STATUS.POST_DRAFT,
  PROJECT_STATUS.APPROVED,
  PROJECT_STATUS.FINAL_CONFIRMATION,
  PROJECT_STATUS.COMPLETION,
];

export type OtherRegistModalType = {
  projectId?: string;
  isOpen: boolean;
  projectName: string;
  projectTitle: string;
};

export function ProjectManagementList(): React.ReactElement {
  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const navigate = useNavigate();

  const projectType: string = queryString.get('type') || '';
  const retQueryRows = (rows: string | null) => {
    if (Number(rows) === 10) {
      return 10;
    } else if (Number(rows) === 50) {
      return 50;
    } else if (Number(rows) === 100) {
      return 100;
    } else {
      return 30;
    }
  };

  const queryStringRows: 10 | 30 | 50 | 100 = retQueryRows(queryString.get('rows'));
  const queryCurrentPage: number = +(queryString.get('page') || 1);
  const projectOrder: FetchEmeraldPostQueryOrder = queryString.get('order') === 'asc' ? 'asc' : 'desc';
  const queryFilter: string = queryString.get('filter') || '';

  const selectProjectStatus = (projectType: string) => {
    if (projectType === PROJECT_TYPES.EMERALD_POST) {
      return emerandStatus;
    } else if (projectType === PROJECT_TYPES.PRST) {
      return prstStatus;
    } else {
      return projectStatus;
    }
  };

  const existsStatus = selectProjectStatus(projectType);
  const queryStatus = existsStatus.includes(queryFilter) ? queryFilter : PROJECT_STATUS.ALL;

  const [open, setOpen] = useState({ id: '', flag: false });
  const [alert, setAlert] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(queryStatus);
  const [projects, setProjects] = useState<projectInformation[]>([]);
  const [currentProjects, setCurrentProjects] = useState<projectInformation[]>([]);
  const [statusList, setStatusList] = useState<statusHeader[]>([]);
  const [perPage, setPerPage] = useState<number>([10, 30, 50, 100].includes(queryStringRows) ? queryStringRows : 30);
  const [startPageNumber, setStartPageNumber] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(queryCurrentPage);
  const [tableInformation, setTableInformation] = useState<projectTableInformation>({
    total: currentProjects.length,
    perPage: perPage,
    setPerPage: setPerPage,
    startPageNumber: startPageNumber,
    setStartPageNumber: setStartPageNumber,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
    status: status,
  });

  type otherModalDataType = {
    isOpen: boolean;
    projectManagementName: string;
    projectName: string;
    selectedProjectGroupName: string;
    inputProjectGroupName: string;
    projectGroupId?: string;
    filterProjectGroups: ProjectGroup[];
    errors?: {
      createApiError?: string;
      isProjectNameError?: boolean;
      isGroupNameError?: boolean;
      projectNameErrorMessage?: string;
    };
  };

  const initializeOtherModal = {
    isOpen: false,
    projectManagementName: '',
    projectName: '',
    selectedProjectGroupName: '',
    inputProjectGroupName: '',
    filterProjectGroups: [],
  };

  const [otherModalData, setOtherModalData] = useState<otherModalDataType>(initializeOtherModal);
  const [projectGroups, setProjectGroups] = useState<Array<ProjectGroup>>([]);

  const isNoCacheRef = useRef(false);

  const setStatusProject = useCallback(
    (data: ProjectTypeListCounts) => {
      setStatusList([
        { label: '全ての案件', status: PROJECT_STATUS.ALL, count: data.all },
        {
          label: convertStatusToDisplayLabelForOther(PROJECT_STATUS.DRAFT),
          status: PROJECT_STATUS.DRAFT,
          count: data.draft + data.review,
        },
        {
          label: convertStatusToDisplayLabelForOther(PROJECT_STATUS.APPROVED),
          status: 'approved',
          count: data.approved,
        },
        {
          label: convertStatusToDisplayLabelForOther(PROJECT_STATUS.FINAL_CONFIRMATION),
          status: PROJECT_STATUS.FINAL_CONFIRMATION,
          count: data.finalConfirmation,
        },
        {
          label:
            projectType == PROJECT_TYPES.SPIRIT
              ? convertStatusToDisplayLabelForOther(PROJECT_STATUS.IMPLEMENTATION)
              : convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.IMPLEMENTATION),
          status: PROJECT_STATUS.IMPLEMENTATION,
          count: data.implementation,
        },
        {
          label: convertStatusToDisplayLabelForSpirit(PROJECT_STATUS.COMPLETION),
          status: PROJECT_STATUS.COMPLETION,
          count: data.completion,
        },
      ]);
    },
    [projectType]
  );

  // API連携後用
  const [APIprojects, setAPIProjects] = useState<projectPostDataType[]>([]);
  const [currentAPIProjects, setCurrentAPIProjects] = useState<projectPostDataType[]>([]);
  const setStatusEmaerandPost = (count: EmeraldPostDataCountType) => {
    setStatusList([
      { label: '全ての案件', status: PROJECT_STATUS.ALL, count: count.all },
      {
        label: convertStatusToDisplayLabelForEmeraldPost(PROJECT_STATUS.POST_DRAFT),
        status: PROJECT_STATUS.POST_DRAFT,
        count: count.postDraft,
      },
      {
        label: convertStatusToDisplayLabelForEmeraldPost(PROJECT_STATUS.WAIT_POST),
        status: PROJECT_STATUS.WAIT_POST,
        count: count.waitPost,
      },
      {
        label: convertStatusToDisplayLabelForEmeraldPost(PROJECT_STATUS.POSTING),
        status: PROJECT_STATUS.POSTING,
        count: count.posting,
      },
      {
        label: convertStatusToDisplayLabelForEmeraldPost(PROJECT_STATUS.POST_COMPLETION),
        status: PROJECT_STATUS.POST_COMPLETION,
        count: count.postCompletion,
      },
    ]);
  };
  const setStatusJaneJohn = (count: JaneJohnDataCountType) => {
    console.log('L189:J ', PROJECT_STATUS.DRAFT);
    console.log('L190:J ', PROJECT_STATUS.POST_DRAFT);
    console.log('L190:J ', PROJECT_STATUS.APPROVED);
    setStatusList([
      { label: '全ての案件', status: PROJECT_STATUS.ALL, count: count.all },
      {
        label: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.DRAFT),
        status: PROJECT_STATUS.DRAFT,
        count: count.draft,
      },
      {
        label: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.APPROVED),
        status: PROJECT_STATUS.APPROVED,
        count: count.approved,
      },
      {
        label: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.FINAL_CONFIRMATION),
        status: PROJECT_STATUS.FINAL_CONFIRMATION,
        count: count.finalConfirmation,
      },
      {
        label: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.IMPLEMENTATION),
        status: PROJECT_STATUS.IMPLEMENTATION,
        count: count.implementation,
      },
      {
        label: convertStatusToDisplayLabelForJaneJohn(PROJECT_STATUS.COMPLETION),
        status: PROJECT_STATUS.COMPLETION,
        count: count.completion,
      },
    ]);
  };

  const setStatusPRST = (count: PRSTDataCountType) => {
    setStatusList([
      { label: '全ての案件', status: PROJECT_STATUS.ALL, count: count.all },
      {
        label: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.DRAFT),
        status: PROJECT_STATUS.DRAFT,
        count: count.draft + count.review,
      },
      {
        label: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.APPROVED),
        status: PROJECT_STATUS.APPROVED,
        count: count.approved,
      },
      {
        label: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.FINAL_CONFIRMATION),
        status: PROJECT_STATUS.FINAL_CONFIRMATION,
        count: count.finalConfirmation,
      },
      {
        label: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.IMPLEMENTATION),
        status: PROJECT_STATUS.IMPLEMENTATION,
        count: count.acceptCompletion,
      },
    ]);
  };

  const setStatusSpirit = (data: ProjectTypeListCounts) => {
    setStatusList([
      { label: '全ての案件', status: PROJECT_STATUS.ALL, count: data.all },
      {
        label: convertStatusToDisplayLabelForSpirit(ProjectStatus.Draft),
        status: ProjectStatus.Draft,
        count: data.draft + data.review,
      },
      {
        label: convertStatusToDisplayLabelForSpirit(ProjectStatus.Approved),
        status: ProjectStatus.Approved,
        count: data.approved,
      },
      {
        label: convertStatusToDisplayLabelForSpirit(ProjectStatus.FinalConfirmation),
        status: ProjectStatus.FinalConfirmation,
        count: data.finalConfirmation,
      },
      {
        label: convertStatusToDisplayLabelForSpirit(ProjectStatus.Implementation),
        status: ProjectStatus.Implementation,
        count: data.implementation,
      },
      {
        label: convertStatusToDisplayLabelForSpirit(ProjectStatus.Completion),
        status: ProjectStatus.Completion,
        count: data.completion,
      },
    ]);
  };

  useEffect(() => {
    // API連携後用
    if (projectType === PROJECT_TYPES.EMERALD_POST) {
      (async () => {
        // await dummyEmeraldPostProjects();
        let fetchTableData: FetchEmeraldPostListResponse;
        try {
          fetchTableData = await FetchEmeraldPostListInfo({
            rows: perPage as GetEmeraldPostListRowsEnum,
            page: currentPage,
            order: projectOrder,
            noChache: isNoCacheRef.current,
          });
          isNoCacheRef.current = false;
          if (fetchTableData) {
            console.log('fetchTableData:', fetchTableData);
            const tmpProjects =
              status === PROJECT_STATUS.ALL
                ? fetchTableData.rows
                : fetchTableData.rows.filter((project) => project.status == status);
            console.log(tmpProjects);
            // API連携後、ダミーデータは削除する
            tmpProjects.forEach((project) => {
              project.project = project.projectId;
              project.start = project.startDate;
              project.end = project.endDate;
              project.sns = 'instagram';
            });
            setAPIProjects(tmpProjects);
            console.log('APIProjects:', currentAPIProjects);
            setTableInformation({
              total: fetchTableData.counts.all,
              perPage: fetchTableData.perPage,
              setPerPage: setPerPage,
              startPageNumber: startPageNumber,
              setStartPageNumber: setStartPageNumber,
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
              status: 'emerald_post',
            });
            console.log(`tableinfo`, tableInformation);
            setCurrentAPIProjects(tmpProjects);
            setStatusEmaerandPost(fetchTableData.counts);
          }
        } catch (ex: any) {
          Sentry.captureException(ex);
          console.log('effect_error:', ex);
        }
      })();
    } else if (projectType === PROJECT_TYPES.JANE_JOHN) {
      (async () => {
        let fetchTableData: FetchJaneJohnListResponse;
        try {
          fetchTableData = await fetchJaneJohnListInfo({
            // rows: queryStringRows,
            rows: perPage as FetchJaneJohnQueryParamater['rows'],
            page: currentPage,
            order: projectOrder,
            noChache: isNoCacheRef.current,
          });
          isNoCacheRef.current = false;
          if (fetchTableData) {
            console.log('fetchTableData:', fetchTableData);
            const tmpProjects =
              status === PROJECT_STATUS.ALL
                ? fetchTableData.rows
                : fetchTableData.rows.filter((project) => project.status == status);
            console.log('tmpProjects:', tmpProjects);
            tmpProjects.forEach((project) => {
              project.project = project.projectGroupName;
              project.start = project.startDate;
              project.end = project.endDate;
            });
            setAPIProjects(tmpProjects);
            console.log('APIProjects:', currentAPIProjects);
            setTableInformation({
              total: fetchTableData.counts.all,
              perPage: fetchTableData.perPage,
              setPerPage: setPerPage,
              startPageNumber: startPageNumber,
              setStartPageNumber: setStartPageNumber,
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
              status: status,
            });
            console.log(`tableinfo`, tableInformation);
            setCurrentAPIProjects(tmpProjects);
            setStatusJaneJohn(fetchTableData.counts);
          }
        } catch (ex: any) {
          Sentry.captureException(ex);
          console.log('effect_error:', ex);
        }
      })();
    } else if (projectType === PROJECT_TYPES.PRST) {
      (async () => {
        let fetchTableData: FetchPRSTListResponse;
        try {
          fetchTableData = await fetchPRSTListInfo({
            // rows: queryStringRows,
            rows: perPage as FetchPRSTQueryParamater['rows'],
            page: currentPage,
            order: projectOrder,
            noChache: isNoCacheRef.current,
          });
          isNoCacheRef.current = false;
          console.log('fetchTableData:', fetchTableData);
          console.log('rows:', queryStringRows);
          console.log('page:', currentPage);
          console.log('order:', projectOrder);
          if (fetchTableData) {
            console.log('fetchTableData:', fetchTableData);
            const tmpProjects =
              status === PROJECT_STATUS.ALL
                ? fetchTableData.rows
                : fetchTableData.rows.filter((project) => project.status == status);
            console.log('tmpProjects:', tmpProjects);
            tmpProjects.forEach((project) => {
              project.project = project.projectGroupName;
              project.start = project.startDate;
              project.end = project.endDate;
            });
            setAPIProjects(tmpProjects);
            console.log('APIProjects:', currentAPIProjects);
            setTableInformation({
              total: fetchTableData.counts.all,
              perPage: fetchTableData.perPage,
              setPerPage: setPerPage,
              startPageNumber: startPageNumber,
              setStartPageNumber: setStartPageNumber,
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
              status: status,
            });
            console.log(`tableinfo`, tableInformation);
            setCurrentAPIProjects(tmpProjects);
            setStatusPRST(fetchTableData.counts);
          }
        } catch (ex: any) {
          Sentry.captureException(ex);
          console.log('effect_error:', ex);
        }
      })();
    } else {
      // API連携時、ダミーデータ、ダミーファイルは削除する。

      let data: projectInformation[] | undefined = undefined;
      const newStatus = status === PROJECT_STATUS.ALL ? undefined : status;
      console.log('L429: perPage', perPage);
      console.log(newStatus);

      if (projectType === PROJECT_TYPES.SPIRIT) {
        (async () => {
          try {
            const getSpiritListResponse: GetSpiritListOutput = await getSpiritList(
              newStatus as GetSpiritListStatusEnum,
              // undefined,
              undefined,
              perPage as GetSpiritListRowsEnum,
              currentPage,
              GetSpiritListOrderEnum.Desc,
              undefined,
              isNoCacheRef.current
            );
            isNoCacheRef.current = false;
            console.log('getSpiritListResponse:', getSpiritListResponse);
            if (getSpiritListResponse) {
              data = getSpiritListResponse.rows.map((item) => ({
                no: item.no,
                project: item.projectGroupName !== null ? item.projectGroupName : undefined,
                projectId: item.projectId,
                projectName: item.projectName,
                projectType: item.projectType,
                status: item.status,
                sns: item.sns,
                start: item.startDate,
                end: item.endDate,
              }));
            }
            if (data) {
              // eslint-disable-next-line prefer-const
              let tmpProjects: any = [];
              if (status === PROJECT_STATUS.ALL) {
                tmpProjects.push(...data);
              } else if (status === ProjectStatus.Draft) {
                const draftData = data.filter((project) => project.status == status);
                const reviewData = data.filter((project) => project.status == ProjectStatus.Review);
                tmpProjects.push(...draftData, ...reviewData);
                const sortData = tmpProjects.sort((a: { no: number }, b: { no: number }) => {
                  return b.no - a.no;
                });
                tmpProjects = [];
                tmpProjects.push(...sortData);
              } else {
                const tmpData = data.filter((project) => project.status == status);
                tmpProjects.push(...tmpData);
              }
              setProjects(tmpProjects);
              setCurrentProjects(data);
              if (status === PROJECT_STATUS.ALL) {
                castTableInfo(getSpiritListResponse.totalRows, perPage, startPageNumber, currentPage);
              } else if (status === PROJECT_STATUS.DRAFT) {
                castTableInfo(
                  getSpiritListResponse.counts.draft + getSpiritListResponse.counts.review,
                  perPage,
                  startPageNumber,
                  currentPage
                );
              } else if (status === PROJECT_STATUS.APPROVED) {
                castTableInfo(getSpiritListResponse.counts.approved, perPage, startPageNumber, currentPage);
              } else if (status === PROJECT_STATUS.FINAL_CONFIRMATION) {
                castTableInfo(getSpiritListResponse.counts.finalConfirmation, perPage, startPageNumber, currentPage);
              } else if (status === PROJECT_STATUS.IMPLEMENTATION) {
                castTableInfo(getSpiritListResponse.counts.implementation, perPage, startPageNumber, currentPage);
              } else {
                castTableInfo(getSpiritListResponse.counts.completion, perPage, startPageNumber, currentPage);
              }
              // ステータス表示
              setStatusSpirit(getSpiritListResponse.counts);
            }
          } catch (ex: any) {
            Sentry.captureException(ex);
            console.log('effect_error:', ex);
          }
        })();
      } else if (projectType === PROJECT_TYPES.OTHER) {
        (async () => {
          try {
            const getOtherListResponse: GetOtherListOutput = await getOtherList(
              newStatus as GetOtherListStatusEnum,
              undefined,
              perPage as GetOtherListRowsEnum,
              currentPage,
              GetOtherListOrderEnum.Desc,
              undefined,
              isNoCacheRef.current
            );
            isNoCacheRef.current = false;
            console.log('getOtherListResponse:', getOtherListResponse.counts.approved);
            if (getOtherListResponse) {
              data = getOtherListResponse.rows.map((item) => ({
                no: item.no,
                project: item.projectGroupName !== null ? item.projectGroupName : undefined,
                projectId: item.projectId,
                projectName: item.projectName,
                projectType: item.projectType,
                status: item.status,
                start: item.startDate,
                end: item.endDate,
              }));
            }
            if (data) {
              // eslint-disable-next-line prefer-const
              let tmpProjects: any = [];
              if (status === PROJECT_STATUS.ALL) {
                tmpProjects.push(...data);
              } else if (status === ProjectStatus.Draft) {
                const draftData = data.filter((project) => project.status == status);
                const reviewData = data.filter((project) => project.status == ProjectStatus.Review);
                tmpProjects.push(...draftData, ...reviewData);
                const sortData = tmpProjects.sort((a: { no: number }, b: { no: number }) => {
                  return b.no - a.no;
                });
                tmpProjects = [];
                tmpProjects.push(...sortData);
              } else {
                const tmpData = data.filter((project) => project.status == status);
                tmpProjects.push(...tmpData);
              }
              setProjects(tmpProjects);
              setCurrentProjects(data);
              if (status === PROJECT_STATUS.ALL) {
                castTableInfo(getOtherListResponse.totalRows, perPage, startPageNumber, currentPage);
              } else if (status === PROJECT_STATUS.DRAFT) {
                castTableInfo(
                  getOtherListResponse.counts.draft + getOtherListResponse.counts.review,
                  perPage,
                  startPageNumber,
                  currentPage
                );
              } else if (status === PROJECT_STATUS.APPROVED) {
                const tmpData = data.filter((project) => project.status.toLowerCase() === 'approved');
                tmpProjects.push(...tmpData);
                castTableInfo(getOtherListResponse.counts.approved, perPage, startPageNumber, currentPage);
              } else if (status === PROJECT_STATUS.FINAL_CONFIRMATION) {
                castTableInfo(getOtherListResponse.counts.finalConfirmation, perPage, startPageNumber, currentPage);
              } else if (status === PROJECT_STATUS.IMPLEMENTATION) {
                castTableInfo(getOtherListResponse.counts.implementation, perPage, startPageNumber, currentPage);
              } else {
                castTableInfo(getOtherListResponse.counts.completion, perPage, startPageNumber, currentPage);
              }

              setStatusProject(getOtherListResponse.counts);
            }
          } catch (ex: any) {
            Sentry.captureException(ex);
            console.log(typeof status);
            console.log(typeof PROJECT_STATUS.APPROVED);
            console.log('effect_error:', ex);
          }
        })();
      }
    }
  }, [
    projectType,
    currentPage,
    queryStringRows,
    perPage,
    status,
    startPageNumber,
    queryStatus,
    open.flag,
    otherModalData.isOpen,
  ]);

  const handleStatus = (target: string) => {
    console.log('L496:target ', typeof target);
    setStatus(target);
    setCurrentPage(1);
    setStartPageNumber(1);
    const queryString = new URLSearchParams(search);
    queryString.set('page', '1');
    queryString.set('filter', target);
    history.pushState(null, '', `?${queryString.toString()}`);
  };

  const castTableInfo = (total: number, perPage: number, startPageNumber: number, currentPage: number) => {
    setTableInformation({
      total: total,
      perPage: perPage,
      setPerPage: setPerPage,
      startPageNumber: startPageNumber,
      setStartPageNumber: setStartPageNumber,
      currentPage: currentPage,
      setCurrentPage: setCurrentPage,
      status: status,
    });
  };

  const toast = () => {
    return (
      <div className="relative w-full pb-6">
        <div className="flex h-[52px] w-[896px] flex-row items-center rounded-md bg-green-50 pt-0">
          <CheckIcon fill="#34D399"></CheckIcon>
          <span className="text-sm font-medium leading-5 text-green-800">案件を複製しました。</span>
        </div>
      </div>
    );
  };

  const setModalValue = (value: string, name: string) => {
    const errorsObj: Required<Pick<otherModalDataType, 'errors'>> = {
      errors: {},
    };
    if (name == 'projectName') {
      if (value.length > 50) {
        errorsObj.errors.isProjectNameError = true;
        errorsObj.errors.projectNameErrorMessage = '案件管理名は50文字以下で入力してください';
      } else if (value.length == 0) {
        errorsObj.errors.isProjectNameError = true;
        errorsObj.errors.projectNameErrorMessage = '案件管理名は必須項目です';
      }

      setOtherModalData((prevState) => ({
        ...prevState,
        projectName: value,
        ...errorsObj,
      }));
    } else {
      const tmpFilterProjectGroups = projectGroups.filter((projectGroup: ProjectGroup) => {
        return value.length > 0 && projectGroup.name?.startsWith(value);
      });
      if (value.length > 50) {
        errorsObj.errors.isGroupNameError = true;
      } else if (value.length == 0) {
        setOtherModalData((prevState) => ({
          ...prevState,
          selectedProjectGroupName: '',
        }));
      } else {
        errorsObj.errors.isGroupNameError = false;
      }

      setOtherModalData((prevState) => ({
        ...prevState,
        projectManagementName: value,
        filterProjectGroups: tmpFilterProjectGroups,
        inputProjectGroupName: value,
        projectGroupId: undefined,
        errors: {
          ...prevState.errors,
          ...errorsObj.errors,
        },
      }));
    }
  };
  const handleModalClose = () => {
    setOtherModalData(initializeOtherModal);
  };

  const handleSelectExistProject = (id: string, name: string) => {
    setOtherModalData((prevState) => ({
      ...prevState,
      projectManagementName: name,
      projectGroupId: id,
      filterProjectGroups: [],
    }));
  };

  const handleCreateProject = async () => {
    try {
      await createProjects({
        projectGroupId: otherModalData.projectGroupId,
        projectGroupName: otherModalData.inputProjectGroupName,
        projectName: otherModalData.projectName,
        projectType: 'other',
        sns: 'other',
        cost: null,
        durations: null,
        adoptionPlan: null,
        marketBackground: null,
        problem: null,
        purpose: null,
        measure: null,
      });
      setOtherModalData(initializeOtherModal);
      navigate(ROUTES.PROJECT_LIST_OTHER);
    } catch (error: any) {
      console.log(error);
      Sentry.captureException(error);
      setOtherModalData((prevState) => ({
        ...prevState,
        errors: {
          createApiError: error.toString(),
        },
      }));
    }
  };

  const fetchProjectGroupsMore = async (page: number, rows?: Array<ProjectGroup>) => {
    const data: GetProjectGroupsOutput = await fetchProjectGroups(100, page, 'asc');
    const tmpRows = rows ? rows.concat(data.rows) : data.rows;
    setProjectGroups(tmpRows);

    if (data.totalPages > page) {
      fetchProjectGroupsMore(page + 1, tmpRows);
    }
  };

  useEffect(() => {
    try {
      fetchProjectGroupsMore(1);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  const copyModal = () => {
    return (
      <Transition.Root show={open.flag} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setOpen({ id: open.id, flag: false })}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF]/75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5">
                  <div className="h-[168px] w-[512px] rounded-lg bg-white">
                    <div className="flex flex-col p-0">
                      <div className="flex flex-row items-center">
                        <div className="flex w-full flex-col">
                          <div className="flex pt-4 text-center">
                            <span className="text-lg font-medium leading-6">案件を複製しますか？</span>
                            <div className="ml-auto">
                              <CloseIcon
                                width={24}
                                height={24}
                                fill="#9CA3AF"
                                onClick={() => setOpen({ id: '', flag: false })}
                              />
                            </div>
                          </div>
                          <div className="pt-2 text-left">
                            <span className="text-sm font-normal leading-5 text-gray-500">
                              複製後は案件の削除ができません。
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pl-6 pt-6">
                      <div className="flex flex-row items-center justify-end gap-3 p-0">
                        <Button variant="white" size="md" onClick={() => setOpen({ id: '', flag: false })}>
                          キャンセル
                        </Button>
                        <Button
                          variant="primary"
                          size="md"
                          onClick={async () => {
                            setAlert(true);
                            isNoCacheRef.current = true;
                            const data = await insertPostReplicationInfo(open.id);
                            console.log('replicationdata', data);
                            setOpen({ id: '', flag: false });
                            setCurrentPage(currentPage);
                          }}
                        >
                          複製を確定
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const otherRegistModalOpen = () => {
    setOtherModalData((prevState) => ({
      ...prevState,
      isOpen: true,
    }));
  };

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: selectedSecondSidebarValue(projectType),
      }}
    >
      <div className="relative">{copyModal()}</div>
      <div className="flex h-[auto] w-[1440px] bg-gray-50">
        <div className="h-[auto] w-[1181px] bg-gray-50">
          <div>
            <div className="pl-4">{ListHeader(projectType, otherRegistModalOpen)}</div>
            {/* その他の案件を追加 */}
            {projectType === PROJECT_TYPES.OTHER && otherModalData.isOpen && (
              <div
                id="project_modal"
                className="fixed inset-0 z-[100] bg-[#6B7280BF]/75 opacity-100 transition-opacity"
              >
                <Order
                  handleClose={handleModalClose}
                  isStatusOrder={true}
                  projectManagementName={otherModalData.projectManagementName}
                  projectName={otherModalData.projectName}
                  setModalValue={setModalValue}
                  projectGroups={otherModalData.filterProjectGroups}
                  handleSelectExistProject={handleSelectExistProject}
                  handleCreateProject={handleCreateProject}
                  apiError={otherModalData.errors?.createApiError}
                  projectNameError={otherModalData.errors?.isProjectNameError ?? false}
                  projectNameErrorMessage={otherModalData.errors?.projectNameErrorMessage ?? ''}
                  projectGroupNameError={otherModalData.errors?.isGroupNameError ?? false}
                  inputProjectGroupName={otherModalData.inputProjectGroupName}
                  selectedProjectGroupName={otherModalData.selectedProjectGroupName}
                />
              </div>
            )}
            <div className="pl-6 pt-6">
              {alert && <div>{toast()}</div>}
              <div className="flex flex-col">
                <div className="flex flex-row items-start gap-8">
                  {statusList.map((item: statusHeader) => {
                    return (
                      <button
                        key={item.status}
                        className={clsx(
                          'flex flex-col items-center justify-center px-3 py-2',
                          item.status == status ? 'rounded-md bg-[#E0F4FF]' : ''
                        )}
                        onClick={() => handleStatus(item.status)}
                      >
                        <span
                          className={clsx(
                            'text-sm font-medium leading-5 ',
                            item.status == status ? 'text-[#004A75]' : 'text-gray-500'
                          )}
                        >
                          {item.label}({item.count ? item.count : 0})
                        </span>
                      </button>
                    );
                  })}
                </div>
                <div className="flex flex-col items-start">
                  {projectType === PROJECT_TYPES.EMERALD_POST ||
                  projectType === PROJECT_TYPES.JANE_JOHN ||
                  projectType === PROJECT_TYPES.PRST
                    ? ProjectTable({
                        projects: APIprojects,
                        projectType: projectType,
                        setOpen: setOpen,
                        tableInfo: tableInformation,
                      })
                    : ProjectTable({
                        projects: projects,
                        projectType: projectType,
                        setOpen: setOpen,
                        tableInfo: tableInformation,
                        setTableInformation: setTableInformation,
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
