import React, { createContext, useContext, useState, useEffect } from 'react';
//import { set } from 'react-hook-form';

import {
  CreationAccountMeasure,
  CreationAccountRow,
  GetCreationAccountMeasuresOrderEnum,
  GetCreationAccountMeasuresOutput,
  GetCreationAccountMeasuresRowsEnum,
  GetCreationOutput,
  PostCreationAccountInput,
  PostCreationInput,
  PostMeasureInput,
  CreationStatus,
  GetCreationsRowsEnum,
  GetCreationsOrderEnum,
  GetCreationsOutput,
} from '@/api/creation';
import { PostingType } from '@/api/project';
import {
  getCreation,
  getCreationAccountMeasures,
  getCreationAccounts,
  getCreations,
  postCreation,
  postCreationAccount,
  postMeasure,
  // postCreation,
} from '@/hooks/Creation';

import { Comment, AccountContextType, Reply } from '../interfaces/PRSTProductionManagementInterface';

export const AccountContext = createContext<AccountContextType | undefined>(undefined);

type Props = {
  children?: React.ReactNode;
};

export const AccountProvider: React.FC<Props> = ({ children }) => {
  const [accounts, setAccounts] = useState<CreationAccountRow[]>([]);
  const [projectPageData, setProjectPageData] = useState<Omit<GetCreationAccountMeasuresOutput, 'rows'> | null>(null);
  const [projects, setProjects] = useState<CreationAccountMeasure[]>([]);
  const [posts, setPosts] = useState<GetCreationsOutput | null>(null);
  const [selectedProject, setSelectedProject] = useState<CreationAccountMeasure | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<CreationAccountRow | null>(null);
  const [selectedPost, setSelectedPost] = useState<GetCreationOutput | null>(null);
  const [imageComments, setImageComments] = useState<Comment[]>([]);
  // const [selectedImageComment, setSelectedImageComment] = useState<Comment | null>(null);
  const [imageCommentReplys, setImageCommentReplys] = useState<Reply[]>([]);
  const [contentComments, setContentComments] = useState<Comment[]>([]);
  const [contentCommentReplys, setContentCommentReplys] = useState<Reply[]>([]);
  const [postStatus, setPostStatus] = useState<CreationStatus>(CreationStatus.Draft);

  const addAccount = (accountInput: PostCreationAccountInput) => {
    postCreationAccount(accountInput).then(async () => {
      const response = await getCreationAccounts();
      setAccounts(response);
      setSelectedAccount(
        response.find((account) => account.name === accountInput.name && account.name === accountInput.snsType) || null
      );
    });
    console.log('---AccountContext.tsx---');
    console.log('新しく作成されたアカウント（account）:', accountInput);
  };

  const selectAccount = (selectAccount: PostCreationAccountInput) => {
    setSelectedAccount(
      accounts.find((account) => account.name === selectAccount.name && account.snsType === selectAccount.snsType) ||
        null
    );
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
  };

  const updateProjects = (
    accountId: string,
    rows?: GetCreationAccountMeasuresRowsEnum,
    page?: number,
    order?: GetCreationAccountMeasuresOrderEnum,
    orderBy?: string
  ) => {
    getCreationAccountMeasures(accountId, rows, page, order, orderBy).then((response) => {
      setProjects(response.rows);
      setProjectPageData(response);
    });
  };

  const addProject = (accountId: string, measureInput: PostMeasureInput) => {
    postMeasure(accountId, measureInput).then(async () => {
      if (selectedAccount) {
        updateProjects(selectedAccount.id);
      }
    });

    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
    console.log('追加されたプロジェクト（measureInput）:', measureInput);
    console.log('追加されたプロジェクト全て（projects）:', projects);
  };

  const addPost = (measureId: string, postCreationInput: PostCreationInput) => {
    postCreation(measureId, postCreationInput).then(async (affect) => {
      const response = await getCreations(measureId);
      setPosts(response);
      console.log('addPost_response:', response);
      changeSelectedPost(affect.affected);
    });
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
    console.log('選択された投稿制作（selectedPost）:', selectedPost);
    console.log('投稿制作追加後のposts:', posts);
  };

  const selectProject = (measureId: string) => {
    // TODO: measureIdが追加されたら書き換える
    setSelectedProject(projects.find((project) => project.id === measureId) || null);
    console.log('---AccountContext.tsx---');
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
  };

  const changeSelectedProject = (accountId: string, measureId: string) => {
    getCreationAccountMeasures(accountId).then((response) => {
      setSelectedProject(response.rows.find((project) => project.name === measureId) || null);
    });
    console.log('---AccountContext.tsx---');
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
  };

  // TODO: getCreationのレスポンスがMockで無くなったらリクエストのstatuを削除する
  const changeSelectedPost = (creationId: string, statu?: CreationStatus) => {
    getCreation(creationId).then((response) => {
      // setSelectedPost(response);
      console.log('changeSelectedPost: ', response);

      // 各コンテンツタイプの動作確認用のモックデータ
      const typeChange = (id: string) => {
        switch (id) {
          case '0'.repeat(26):
            return PostingType.Feed;
          case '1'.repeat(26):
            return PostingType.Other;
          case '2'.repeat(26):
            return PostingType.OtherVideo;
          case '3'.repeat(26):
            return PostingType.Reel;
          case '4'.repeat(26):
            return PostingType.Stories;
          case '5'.repeat(26):
            return PostingType.TikTok;
          case '6'.repeat(26):
            return PostingType.XImagePost;
          case '7'.repeat(26):
            return PostingType.XPost;
          case '8'.repeat(26):
            return PostingType.Youtube;
          case '9'.repeat(26):
            return PostingType.YoutubeShort;
          default:
            return PostingType.Other;
        }
      };
      setSelectedPost({
        id: '12345678901234567890ABCDEF',
        no: 1,
        postScheduledAt: '2021-02-12T00:00:00Z',
        type: typeChange(creationId),
        status: statu ?? CreationStatus.Contents,
        draft: {
          contentId: '12345678901234567890ABCDEF',
          images: [
            {
              mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
              textImage: '画像の説明等',
            },
          ],
          comments: 0,
          description: '投稿内容',
        },
        contents: {
          contentId: '12345678901234567890ABCDEF',
          media: ['assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg'],
          body: '投稿内容',
          comments: 0,
        },
      } as GetCreationOutput);
    });
    console.log('---AccountContext.tsx---');
    console.log('選択された投稿制作（selectedPost）:', selectedPost);
  };

  const addImageComments = (comment: Comment) => {
    setImageComments((prev) => [...prev, comment]);
    console.log('追加されたイメージコメント全て', imageComments);
  };

  const addImageCommentReplys = (reply: Reply, index: number) => {
    imageComments[index].replys.push(reply);
    setImageCommentReplys((prev) => [...prev, reply]);
  };

  const addContentComments = (comment: Comment) => {
    setContentComments((prev) => [...prev, comment]);
    console.log('追加されたコンテンツコメント全て', contentComments);
  };

  const addContentCommentReplys = (reply: Reply, index: number) => {
    contentComments[index].replys.push(reply);
    setContentCommentReplys((prev) => [...prev, reply]);
  };
  // const addSelectedImageComment = (comment: Comment) => {
  //   setSelectedImageComment(comment);
  // };

  const getPosts = (
    measureId: string,
    rows: GetCreationsRowsEnum,
    page: number,
    order?: GetCreationsOrderEnum,
    orderBy?: string
  ) => {
    getCreations(measureId, rows, page, order, orderBy).then((response: GetCreationsOutput) => {
      // setPosts(response);
      console.log('---GetPosts---', response);
      setPosts({
        from: 1,
        to: 10,
        perPage: 10,
        totalRows: 10,
        totalPages: 1,
        rows: [
          {
            id: '0'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.Feed,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '1'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.Other,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '2'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.OtherVideo,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '3'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.Reel,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '4'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.Stories,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '5'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.TikTok,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '6'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.XImagePost,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '7'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.XPost,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '8'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.Youtube,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
          {
            id: '9'.repeat(26),
            no: 1,
            postScheduledAt: '2021-02-12T00:00:00Z',
            type: PostingType.YoutubeShort,
            status: CreationStatus.Contents,
            measureId: '12345678901234567890ABCDEF',
            mediaImage: 'assets/project/01JE1A60FM7PJKCHANAWFZBJC5/31abd5727b7f2b34.jpg',
            textImage: '画像の説明等',
            comments: 0,
          },
        ],
      });
    });
  };

  useEffect(() => {
    (async () => {
      const accounts = await getCreationAccounts();
      setAccounts(accounts);
      setSelectedAccount(accounts.length > 0 ? accounts[0] : null);
      if (accounts.length > 0) {
        updateProjects(accounts[0].id);
      } else {
        setProjects([]);
      }
      const path = window.location.pathname;
      const pathParts = path.split('/');
      if (selectedAccount?.id && pathParts.length > 3 && pathParts[1] === 'PRST_production_management') {
        changeSelectedProject(selectedAccount.id, pathParts[2]);
        changeSelectedPost(pathParts[3]);
      }
    })();
    console.log('---AccountContext.tsx---');
    console.log('現在登録されているアカウント全て（accounts）:', accounts);
  }, []);

  return (
    <AccountContext.Provider
      value={{
        accounts,
        addAccount,
        selectedAccount,
        setSelectedAccount,
        selectAccount,
        projects,
        updateProjects,
        projectPageData,
        selectedProject,
        addProject,
        selectProject,
        changeSelectedProject,
        getPosts,
        posts,
        addPost,
        selectedPost,
        changeSelectedPost,
        // imageComments: [],
        imageComments,
        addImageComments,
        // contentComments: [],
        imageCommentReplys,
        addImageCommentReplys,
        contentComments,
        addContentComments,
        contentCommentReplys,
        addContentCommentReplys,
        // selectedImageComment,
        // addSelectedImageComment,
        postStatus,
        setPostStatus,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};
