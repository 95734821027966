import React from 'react';

import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { Button } from '@/components/Elements/Button';
import { DatePicker } from '@/components/Elements/DatePicker/DatePicker';
import { Input } from '@/components/Elements/Input';
import { RadioBox } from '@/components/Elements/RadioBox';
import { SelectMenus } from '@/components/Elements/SelectMenus';
import { TextArea } from '@/components/Elements/TextArea';
import { isValidUrl } from '@/utils/inputMultiValidationCheck';

import { ReactComponent as CrossIcon } from '../assets/Cross.svg';
import { ReactComponent as TrashIcon } from '../assets/Trash.svg';
import { SelectType } from '../PopupInterfaces';

import Layout, { Step } from './Layout';

export default function WebService(
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  isTop: boolean,
  isLast: boolean
): React.ReactElement {
  const description = () => {
    return (
      <div className="pt-6">
        <Step seq="01" title="稼働概要"></Step>
        <div className="pt-6">
          <Input
            label="稼働内容タイトル*"
            helper={`${selected[index].title.length}/100`}
            placeholder="稼働内容タイトル"
            isPlaceHolderRight={false}
            value={selected[index].title}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].title = e.target.value;
              titleValidationCheck(newSelected, index, setSelected);
            }}
            className={
              (selected[index].titleError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : '') + 'pl-[11px] focus:border-white'
            }
          ></Input>
          {selected[index].titleError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].titleError}</div>
          )}
        </div>
        <div className="pt-6">
          <div className="text-sm font-semibold leading-5 text-gray-700">ダウンロード・利用期間*</div>
          <div className="flex">
            <DatePicker
              selectedDate={selected[index].downloadUsagePeriodStart}
              onChange={(date: any) => {
                const newSelected = [...selected];
                newSelected[index].downloadUsagePeriodStart = date;
                setSelected(newSelected);
                downloadUsagePeriodStartValidationCheck(newSelected, index, setSelected);
              }}
              className={
                selected[index].downloadUsagePeriodStartError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : ''
              }
              width="240px"
            />
            <span className="px-2 pt-2">〜</span>
            <DatePicker
              selectedDate={selected[index].downloadUsagePeriodEnd}
              onChange={(date: any) => {
                const newSelected = [...selected];
                newSelected[index].downloadUsagePeriodEnd = date;
                setSelected(newSelected);
                downloadUsagePeriodEndValidationCheck(newSelected, index, setSelected);
              }}
              className={
                selected[index].downloadUsagePeriodEndError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : ''
              }
              width="240px"
            />
          </div>
          {selected[index].downloadUsagePeriodStartError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].downloadUsagePeriodStartError}</div>
          )}
          {selected[index].downloadUsagePeriodEndError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].downloadUsagePeriodEndError}</div>
          )}
        </div>
        <div className="pt-6">
          <Input
            label="ダウンロード・利用先URL*"
            helper={`${selected[index].downloadURL.length}/100`}
            placeholder="ダウンロード・利用先URL*"
            isPlaceHolderRight={false}
            value={selected[index].downloadURL}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].downloadURL = e.target.value;
              downloadURLValidationCheck(newSelected, index, setSelected);
            }}
            className={
              selected[index].downloadURLError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : ''
            }
          ></Input>
          {selected[index].downloadURLError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].downloadURLError}</div>
          )}
        </div>
        <div className="pt-6">
          <TextArea
            title="詳細*"
            helperText={`${selected[index].detail.length}/2000`}
            maxLength={2000}
            rows={4}
            placeholder="詳細"
            value={selected[index].detail}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].detail = e.target.value;
              setSelected(newSelected);
              detailValidationCheck(newSelected, index, setSelected);
            }}
            className={
              (selected[index].detailError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : '') + 'h-[105px] w-[650px]'
            }
          ></TextArea>
          {selected[index].detailError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].detailError}</div>
          )}
        </div>
        <div className="pt-6">
          <div>
            <div>
              <span className="text-sm font-semibold leading-5 text-gray-700">利用エビデンス設定*</span>
            </div>
            <div>
              <span className="text-gray-500">インフルエンサーに利用した画面のスクリーンショットを提出してもらう</span>
            </div>
          </div>
          <RadioBox
            values={[
              { value: '1', label: '設定しない' },
              { value: '2', label: '設定する' },
            ]}
            value={
              selected[index].purchaseEvidenceSettings === undefined
                ? '1'
                : selected[index].purchaseEvidenceSettings === '2'
                ? '2'
                : '1'
            }
            onClick={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].purchaseEvidenceSettings = e.target.value;
              setSelected(newSelected);
            }}
            name={`${index}-Store`}
          ></RadioBox>
        </div>
        {selected[index].purchaseEvidenceSettings === '2' ? (
          <div className="pt-6">
            <Input
              placeholder="例：画面のスクリーンショットを撮ってアップロードしてください。"
              isPlaceHolderRight={false}
              value={selected[index].purchaseEvidenceText}
              onChange={(e: any) => {
                const newSelected = [...selected];
                newSelected[index].purchaseEvidenceText = e.target.value;
                purchaseEvidenceTextValidationCheck(newSelected, index, setSelected);
              }}
              className={
                selected[index].purchaseEvidenceTextError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : ''
              }
            ></Input>
            {selected[index].purchaseEvidenceTextError !== undefined && (
              <div className="pt-2 text-base text-red-400">{selected[index].purchaseEvidenceTextError}</div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const item = () => {
    if (selected[index].selectLists.length == 0) {
      selected[index].selectLists.push({
        selectExplanation: '',
        selectExplanationError: undefined,
        selectType: ['', ''],
        selectTypeError: undefined,
        noAddedSelectTypeError: undefined,
        maxSelectionCount: 1,
      });
      setSelected(selected);
    }
    // 選択肢を変更する関数
    const handleOptionChange = (detailsIndex: number, optionIndex: number) => (e: any) => {
      const newSelected = [...selected];
      newSelected[index].selectLists[detailsIndex].selectType[optionIndex] = e.target.value;
      setSelected(newSelected);
    };
    // 選択肢を追加する関数
    const addOptions = (detailsIndex: number) => {
      const newSelected = [...selected];
      newSelected[index].selectLists[detailsIndex].noAddedSelectTypeError = undefined;
      newSelected[index].selectLists[detailsIndex].selectType.push('');
      setSelected(newSelected);
    };
    // 選択肢を削除する関数
    const deleteOption = (detailsIndex: number, optionIndex: number) => {
      const newSelected = [...selected];
      if (newSelected[index].selectLists[detailsIndex].selectType.length === 1) {
        newSelected[index].selectLists[detailsIndex].noAddedSelectTypeError = '選択肢を1つ以上入力してください。';
      }
      newSelected[index].selectLists[detailsIndex].selectType.splice(optionIndex, 1);
      setSelected(newSelected);
    };
    // 選択可能数を変更する関数
    const handleMaxSelectionCountChange = (detailsIndex: number, value: number) => {
      const newSelected = [...selected];
      newSelected[index].selectLists[detailsIndex].maxSelectionCount = value;
      setSelected(newSelected);
    };

    const clickCrossButton = (detailsIndex: number) => {
      const newSelected = [...selected];
      newSelected[index].selectLists.splice(detailsIndex, 1);
      setSelected(newSelected);
    };

    return (
      <div className="pt-6">
        {selected[index].selectLists.map((item, detailsIndex) => (
          <div className="mt-[10px] flex flex-col justify-start rounded-lg bg-gray-50 p-[20px]" key={detailsIndex}>
            <div className="flex items-center">
              <div style={{ fontSize: '18px' }}>選択肢の設定</div>
              <button className="ml-auto" onClick={() => clickCrossButton(detailsIndex)}>
                <CrossIcon className="h-5 w-5" />
              </button>
            </div>
            <div className="items-center pt-3">
              <div className="pt-6">
                <TextArea
                  title="選択肢の説明*"
                  helperText={`${item.selectExplanation.length}/2000`}
                  maxLength={2000}
                  rows={4}
                  placeholder="選択肢の説明"
                  value={item.selectExplanation}
                  onChange={(e: any) => {
                    const newSelected = [...selected];
                    newSelected[index].selectLists[detailsIndex].selectExplanation = e.target.value;
                    setSelected(newSelected);
                    selectExplanationValidationCheck(newSelected, index, setSelected, detailsIndex);
                  }}
                  className={
                    (selected[index].selectLists[detailsIndex].selectExplanationError !== undefined
                      ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                      : '') + 'h-[105px]  w-[616px]'
                  }
                ></TextArea>
                {selected[index].selectLists[detailsIndex].selectExplanationError !== undefined && (
                  <div className="pt-2 text-base text-red-400">
                    {selected[index].selectLists[detailsIndex].selectExplanationError}
                  </div>
                )}
              </div>
              <div className="flex w-full flex-col items-start pt-6">
                <div className="text-sm font-semibold leading-5 text-gray-700">選択肢の種類*</div>
                {item.selectType.map((option, optionIndex) => (
                  <div key={optionIndex} className="w-[616px]">
                    <div className="mb-[5px] flex w-full items-center gap-2">
                      <div className="w-[584px]">
                        <Input
                          key={optionIndex}
                          placeholder="例:オープン前の9:00-10:00"
                          isPlaceHolderRight={false}
                          value={option}
                          onChange={(e: any) => {
                            handleOptionChange(detailsIndex, optionIndex)(e);
                            selectTypeValidationCheck(selected, index, setSelected, detailsIndex);
                          }}
                          className={
                            selected[index].selectLists[detailsIndex].selectTypeError !== undefined &&
                            selected[index].selectLists[detailsIndex].selectTypeError![optionIndex] !== ''
                              ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                              : ''
                          }
                        />
                      </div>
                      {optionIndex > 0 && (
                        <button onClick={() => deleteOption(detailsIndex, optionIndex)}>
                          <TrashIcon stroke="#9CA3AF" />
                        </button>
                      )}
                    </div>
                    {selected[index].selectLists[detailsIndex].selectTypeError !== undefined &&
                      selected[index].selectLists[detailsIndex].selectTypeError![optionIndex] !== '' && (
                        <div className="text-base text-red-400">
                          {selected[index].selectLists[detailsIndex].selectTypeError![optionIndex]}
                        </div>
                      )}
                  </div>
                ))}
                {selected[index].selectLists[detailsIndex].noAddedSelectTypeError !== undefined &&
                  selected[index].selectLists[detailsIndex].noAddedSelectTypeError !== '' && (
                    <div className="text-base text-red-400">
                      {selected[index].selectLists[detailsIndex].noAddedSelectTypeError}
                    </div>
                  )}
                <button
                  className="flex w-[100px] justify-start"
                  style={{ color: '#007CC2' }}
                  onClick={() => addOptions(detailsIndex)}
                >
                  ＋種類を追加
                </button>
              </div>
              <div className="flex w-full flex-col items-start pt-6">
                <div className="text-sm font-semibold leading-5 text-gray-700">選択可能数*</div>
                <SelectMenus
                  title=""
                  options={[
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    // { value: '6', label: '6' },
                    // { value: '7', label: '7' },
                    // { value: '8', label: '8' },
                    // { value: '9', label: '9' },
                    // { value: '10', label: '10' },
                  ]}
                  value={item.maxSelectionCount === 0 ? '1' : item.maxSelectionCount?.toString()}
                  onChange={(option: any) => handleMaxSelectionCountChange(detailsIndex, option.value)}
                  widthSize="w-[130px]"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const itemDetail = () => {
    const addProduct = (): void => {
      const newSelected = [...selected];
      newSelected[index].selectLists.push({
        selectExplanation: '',
        selectExplanationError: undefined,
        selectType: ['', ''],
        selectTypeError: undefined,
        noAddedSelectTypeError: undefined,
        maxSelectionCount: 1,
      });
      setSelected(newSelected);
    };

    return (
      <div className="pt-6">
        {item()}
        <div className="pt-6">
          <div className="flex flex-col items-center justify-center rounded-lg bg-gray-50 py-4">
            <Button variant="secoundary" startIcon={<PlusIcon fill="#007CC2"></PlusIcon>} onClick={addProduct}>
              選択肢を追加する
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Layout
        key={selected[index].id}
        titile="Webサービス・アプリ利用など"
        isTop={isTop}
        isLast={isLast}
        selected={selected}
        index={index}
        setSelected={setSelected}
        isOpen={selected[index].isNew === true}
      >
        <div className="w-[696px] border-x-[1px] border-b-[1px] px-6 pb-6">
          {description()}
          {itemDetail()}
        </div>
      </Layout>
    </>
  );
}

const titleValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean => {
  if (selected[index].title.length === 0) {
    const newSelected = [...selected];
    newSelected[index].titleError = '稼働内容タイトルを入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].title.length > 100) {
    const newSelected = [...selected];
    newSelected[index].titleError = '稼働内容タイトルは100文字以内で入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].titleError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const downloadUsagePeriodStartValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean => {
  if (!selected[index].downloadUsagePeriodStart) {
    const newSelected = [...selected];
    newSelected[index].downloadUsagePeriodStartError = 'ダウンロード・利用期間（開始）を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (
    selected[index].downloadUsagePeriodEnd &&
    selected[index].downloadUsagePeriodStart! > selected[index].downloadUsagePeriodEnd!
  ) {
    const newSelected = [...selected];
    newSelected[index].downloadUsagePeriodStartError =
      'ダウンロード・利用期間（開始）はダウンロード・利用期間（終了）より前の日付を入力してください';
    newSelected[index].downloadUsagePeriodEndError = undefined;
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].downloadUsagePeriodStartError = undefined;
    if (selected[index].downloadUsagePeriodEnd) {
      newSelected[index].downloadUsagePeriodEndError = undefined;
    }
    setSelected(newSelected);
  }
  return validResult;
};

const downloadUsagePeriodEndValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean => {
  if (!selected[index].downloadUsagePeriodEnd) {
    const newSelected = [...selected];
    newSelected[index].downloadUsagePeriodEndError = 'ダウンロード・利用期間（終了）を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (
    selected[index].downloadUsagePeriodStart &&
    selected[index].downloadUsagePeriodStart! > selected[index].downloadUsagePeriodEnd!
  ) {
    const newSelected = [...selected];
    newSelected[index].downloadUsagePeriodEndError =
      'ダウンロード・利用期間（終了）はダウンロード・利用期間（開始）より後の日付を入力してください';
    newSelected[index].downloadUsagePeriodStartError = undefined;
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].downloadUsagePeriodEndError = undefined;
    if (selected[index].downloadUsagePeriodStart) {
      newSelected[index].downloadUsagePeriodStartError = undefined;
    }
    setSelected(newSelected);
  }
  return validResult;
};

const downloadURLValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean => {
  if (selected[index].downloadURL.length === 0) {
    const newSelected = [...selected];
    newSelected[index].downloadURLError = 'ダウンロード・利用先URLを入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (!isValidUrl(selected[index].downloadURL)) {
    const newSelected = [...selected];
    newSelected[index].downloadURLError = 'ダウンロード・利用先URLを正しく入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].downloadURLError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const detailValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean => {
  if (selected[index].detail.length === 0) {
    const newSelected = [...selected];
    newSelected[index].detailError = '詳細を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].detail.length > 2000) {
    const newSelected = [...selected];
    newSelected[index].detailError = '詳細は2000文字以内で入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].detailError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const purchaseEvidenceTextValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].purchaseEvidenceSettings === '2') {
    if (selected[index].purchaseEvidenceText.length === 0) {
      const newSelected = [...selected];
      newSelected[index].purchaseEvidenceTextError = '購入エビデンステキストを入力してください';
      setSelected(newSelected);
      validResult = false;
    } else if (selected[index].purchaseEvidenceText.length > 100) {
      const newSelected = [...selected];
      newSelected[index].purchaseEvidenceTextError = '購入エビデンステキストは100文字以内で入力してください';
      setSelected(newSelected);
      validResult = false;
    } else {
      const newSelected = [...selected];
      newSelected[index].purchaseEvidenceTextError = undefined;
      setSelected(newSelected);
    }
  } else {
    const newSelected = [...selected];
    newSelected[index].purchaseEvidenceTextError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const selectExplanationValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  detailsIndex: number,
  validResult = true
): boolean => {
  if (selected[index].selectLists[detailsIndex].selectExplanation.length === 0) {
    const newSelected = [...selected];
    newSelected[index].selectLists[detailsIndex].selectExplanationError = '選択肢の説明を入力してください';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].selectLists[detailsIndex].selectExplanation.length > 2000) {
    const newSelected = [...selected];
    newSelected[index].selectLists[detailsIndex].selectExplanationError =
      '選択肢の説明は2000文字以内で入力してください';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].selectLists[detailsIndex].selectExplanationError = undefined;
    setSelected(newSelected);
  }
  return validResult;
};

const selectTypeValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  detailsIndex: number,
  validResult = true
): boolean => {
  const selectTypeError: string[] = [];
  let noAddedOptionError = undefined;
  if (selected[index].selectLists[detailsIndex].selectType.length === 0) {
    noAddedOptionError = '選択肢を1つ以上入力してください。';
    validResult = false;
  } else {
    selected[index].selectLists[detailsIndex].selectType.forEach((item) => {
      if (item.length === 0) {
        selectTypeError.push('選択肢の種類を入力してください');
        validResult = false;
      } else if (item.length > 50) {
        selectTypeError.push('選択肢の種類は50文字以内で入力してください');
        validResult = false;
      } else {
        selectTypeError.push('');
      }
    });
  }
  const newSelected = [...selected];
  newSelected[index].selectLists[detailsIndex].selectTypeError = selectTypeError;
  newSelected[index].selectLists[detailsIndex].noAddedSelectTypeError = noAddedOptionError;
  setSelected(newSelected);

  return validResult;
};

export function webServiceValidationCheck(
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean {
  validResult = titleValidationCheck(selected, index, setSelected, validResult);
  validResult = downloadUsagePeriodStartValidationCheck(selected, index, setSelected, validResult);
  validResult = downloadUsagePeriodEndValidationCheck(selected, index, setSelected, validResult);
  validResult = downloadURLValidationCheck(selected, index, setSelected, validResult);
  validResult = detailValidationCheck(selected, index, setSelected, validResult);
  validResult = purchaseEvidenceTextValidationCheck(selected, index, setSelected, validResult);
  selected[index].selectLists.forEach((item, detailsIndex) => {
    validResult = selectExplanationValidationCheck(selected, index, setSelected, detailsIndex, validResult);
    validResult = selectTypeValidationCheck(selected, index, setSelected, detailsIndex, validResult);
  });
  return validResult;
}
